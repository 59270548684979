import { DataService } from "../base/base-data.service";

class UserDataService extends DataService {
    apiUrl = '/user'

    async getUserByPhone(phone) {
        return await this.get(`${this.apiUrl}/getByPhone?phone=${phone}`);
    }
    async getInitUser() {
        return await this.get(`${this.apiUrl}/get-init-user`);
    }

    async getUserId(id) {
        return await this.get(`${this.apiUrl}/get-user-by-Id?id=${id}`);
    }

    async resetPassword(req) {
        return await this.put(`${this.apiUrl}/reset-password`, req);
    }

    async resetPasswordByUser(req) {
        return await this.put(`${this.apiUrl}/reset-password-by-user`, req);
    }

    async update(req) {
        return await this.put(`${this.apiUrl}/update`, req);
    }

    async logout(request) {
        return await this.post(`${this.apiUrl}/logout`, request);
    }

    async getUserInfoById(userId) {
        return await this.get(`${this.apiUrl}/get-user-info-by-id?userId=${userId}`);
    }

    async checkExistEmailByType(data) {
        return await this.post(`${this.apiUrl}/create-refresh-token-by-email`, data);
    }

    async checkRefeshToken(refreshTokenKey) {
        return await this.get(`${this.apiUrl}/check-refresh-token/${refreshTokenKey}`);
    }

    async updatePasswordByEmail(req) {
        return await this.put(`${this.apiUrl}/update-password-by-email`, req);
    }
}

let userDataService = new UserDataService();

export default userDataService;