import { connect } from 'react-redux';
import { CreateOrderScreen } from './create-order.screen';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

const mapStateToProps = ({ session }) => {
    return {
        userId: session.auth.user.userid
    };
};

export default compose(
    withTranslation('translations'),
    connect(mapStateToProps, null),
    withRouter
)(CreateOrderScreen)
