import React, { Component } from 'react';
import { Content } from 'antd/lib/layout/layout';
import { Button, Checkbox, Divider } from 'antd';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import { FORMAT_DATETIME } from '../../common/constants';

export class ImportListSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            initialData: this.props.initialData,
        }
    }

    render() {
        let { initialData } = this.state;
        const { t } = this.props;
        let contentSumarize = "";
        if (initialData.length === 0) {
            contentSumarize = <span>{t("messages.noDataDisplay")}</span>;
        }
        else {
            contentSumarize = initialData?.map((order, index) => this.displayOrder(order, index));
        }
        return (
            <Content
                className='beecow-pickup-list-summary'
                style={{ padding: 20 }}>
                { contentSumarize }
            </Content>
        )
    }

    displayOrder(order, index) {
        const { t } = this.props;
        let { orderInfo } = order;
        return (
            <div className='beecow-products-info' key={index}>
                <div className='row'>
                    <div className='col-sm-4'>
                        <p><b>{t('tableColumn.orderId')}: <Link style={{ textDecoration: 'underline' }} to={`/view-order/${orderInfo?.id}`}>{orderInfo?.code}</Link></b></p>
                        <p><b>{t('tableColumn.orderCreatedDate')}:</b> {moment.utc(orderInfo?.createdTime).local().format(FORMAT_DATETIME)}</p>
                        <p><b>{t('tableColumn.totalItemsInOrder')}:</b> {_.sumBy(orderInfo?.orderItems, "amount")}</p>
                        <p><b>{t('tableColumn.totalWeightInOrder')}:</b> {_.sumBy(orderInfo?.orderItems, function (i) { return i.amount * i.product?.weight })}kg</p>
                    </div>
                    <div className="col-sm-4">
                        <p><b>{t('tableColumn.pickedListId')}: <Link style={{ textDecoration: 'underline' }} to={`/view-pickup-list/${orderInfo?.orderPickup?.id}`}>{orderInfo?.orderPickup?.code}</Link></b></p>
                        <p><b>{t('tableColumn.pickedDate')}:</b> {moment.utc(orderInfo?.pickedDate).local().format(FORMAT_DATETIME)}</p>
                        <p><b>{t('tableColumn.assignedVehicle')}:</b> {orderInfo?.orderPickup?.vehicle?.code}</p>
                        <p><b>{t('tableColumn.assignedDriver')}:</b> {orderInfo?.orderPickup?.driver?.fullName}</p>
                    </div>
                    <div className="col-sm-4">
                        <Button style={{ width: 150 }} type="primary" onClick={() => this.props?.onEdit(index)}>{t('button.editProductInfo')}</Button>&nbsp;&nbsp;
                        <Button style={{ width: 150 }} type="primary" onClick={() => this.props?.onRemove(index)}>{t('button.removeThisOrder')}</Button>
                    </div>
                </div>
                <table className="table table-borderless">
                    <thead>
                        <tr>
                            <th>{t('tableColumn.no')}</th>
                            <th>{t('tableColumn.productCode')}</th>
                            <th>{t('tableColumn.productName')}</th>
                            <th>{t('tableColumn.productCategory')}</th>
                            <th>{t('tableColumn.size')} (m)</th>
                            <th>{t('tableColumn.weight')} (kg)</th>
                            <th>{t('tableColumn.amount')}</th>
                            <th>{t('tableColumn.reason')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orderInfo?.orderItems?.map((orderItem, index) => {
                            return (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{orderItem?.product?.code}</td>
                                    <td>{orderItem?.product?.name}</td>
                                    <td>{orderItem?.product?.packageType?.name}</td>
                                    <td>{orderItem?.product?.length}m - {orderItem?.product?.width}m - {orderItem?.product?.height}m</td>
                                    <td>{orderItem?.product?.weight}kg</td>
                                    <td>{orderItem?.amountScanned || 0}/{orderItem?.amount}</td>
                                    <td>{(orderItem?.amountScanned === orderItem?.amount) ? <Checkbox checked={orderItem?.scanned} disabled /> : <span>{t(orderItem?.scanReasonFailed)}</span>}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <Divider />
            </div>
        )
    }

    formatAddress(address) {
        let arr = [];
        if (address && address.street) {
            arr.push(address.street);
        }
        if (address && address.ward) {
            let ward = (address.ward.prefix || "") + ' ' + address.ward.name;
            arr.push(ward);
        }
        if (address && address.district) {
            let district = (address.ward.prefix || "") + ' ' + address.district.name;
            arr.push(district);
        }
        if (address && address.city) {
            arr.push(address.city.name);
        }
        return arr.join(', ');
    }
}