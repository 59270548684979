import React, { Component } from "react";
import { Form, Input, Button, message } from "antd";
import { MailOutlined } from "@ant-design/icons";

import userDataService from "../../data-services/user/user-data.service";
import languageService from "../../services/language.service";
import { userTypes } from '../../constants/user-constants';
import './forgot-password.scss';

export default class ForgotPasswordForm extends Component {
  render() {
    const { t } = this.props;
    return (
      <>
        <Form
          name="normal_login"
          className="m-auto centered shadow p-4 pt-5 mb-5 bg-white rounded mt-50px"
          initialValues={{ rememberMe: true }}
          onFinish={this.onFinish}
        >
          <div className="mb-3 text-center">
            <div className="logo">
              <div className="name d-flex justify-content-center">
                <h1>{t("signIn.forgotPassword")}</h1>                
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    message: `${t("messages.inputIsNotValidEmailAddress")}`,
                  },
                  {
                    required: true,
                    message: `${t("messages.pleaseInputEmail")}`,
                  },
                ]}
              >
                <Input
                  prefix={<MailOutlined className="site-form-item-icon" />}
                  placeholder={t("signIn.email")}
                />
              </Form.Item>
            </div>
          </div>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button col-12"
            >
              {t("button.next")}
            </Button>

            <span>{t("signIn.haveAccount")}</span>
            <a
              className="ml-2"
              href="javascript:void(0)"
              onClick={() => {
                if (this.props.callBack) this.props.callBack();
              }}
            >
              {t("signIn.loginHere")}
            </a>
          </Form.Item>
        </Form>
      </>
    );
  }

  onFinish = values => {
    const { t } = this.props;
    var data = {
      userType: userTypes.STATION,
      email : values.email
    };
    userDataService.checkExistEmailByType(data).then(res => {
      console.log(res);
      if (res.success) {
        message.success(t("messages.emailSuccess"));
        if (this.props.callBack) this.props.callBack();
      } else {
        if(!res.isType) {
          message.error(t("signIn.thisPageOnlyAllowsToAcceptByStation"));
        } else {
          message.error(t("messages.emailFailed"));
        }
      }
    });
  };
}
