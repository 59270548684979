import React, { Component } from 'react';
import { Content } from 'antd/lib/layout/layout';
import './summarize.scss';
import { TEXT_VND } from '../../../constants/common';
import { formatAmount } from '../../../utils/order';

export default class SummarizeComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            productCategories: props.productCategories ?? [],
            deliveryOptions: props.deliveryOptions ?? [],
            shippingServices: props.shippingServices ?? [],
            stationInfo: props.stationInfo ?? {},
            shippingInfo: props.shippingInfo ?? {},
            productInfo: props.productInfo ?? {},
            deliveryInfo: props.deliveryInfo ?? {},
            paymentInfo: props.paymentInfo ?? {},
        }
    }

    render() {
        return (
            <Content className='beecow-summarize-info'>
                {this.renderBookingInfo()}
                {this.renderProductsInfo()}
                <div className='row'>
                    <div className='col-sm-4'>
                        {this.renderDelivery()}
                        {this.renderRoute()}
                    </div>
                    <div className='col-sm-8'>
                        {this.renderPayment()}
                    </div>
                </div>
            </Content>
        )
    }

    renderBookingInfo = () => {
        const { t } = this.props;
        var { shippingInfo } = this.state;
        return (
            <div className='row'>
                <div className='col-sm-4'>
                    <h3>{t('order.senderInfo')}</h3>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='row pl-4 mx-auto'>
                                <p className='mr-5'>{t('order.senderPhone')}:</p>
                                <p>{shippingInfo?.senderInfo?.phoneNumber}</p>
                            </div>
                            <div className='row pl-4 mx-auto'>
                                <p className='mr-5'>{t('order.senderName')}:</p>
                                <p>{shippingInfo?.senderInfo?.fullName}</p>
                            </div>
                            <div className='row pl-4 mx-auto'>
                                <p className='mr-5'>{t('order.senderAddress')}:</p>
                                <p>{this.formatedAddress(shippingInfo?.senderInfo?.address)}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-4 mx-auto'>
                    <h3>{t('order.receiverInfo')}</h3>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='row pl-4 mx-auto'>
                                <p className='mr-5'>{t('order.receiverPhone')}:</p>
                                <p>{shippingInfo?.receiverInfo?.phoneNumber}</p>
                            </div>
                            <div className='row pl-4 mx-auto'>
                                <p className='mr-5'>{t('order.receiverName')}:</p>
                                <p>{shippingInfo?.receiverInfo?.fullName}</p>
                            </div>
                            <div className='row pl-4 mx-auto'>
                                <p className='mr-5'>{t('order.receiverAddress')}:</p>
                                <p>{this.formatedAddress(shippingInfo?.receiverInfo?.address)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderProductsInfo = () => {
        const { t } = this.props;
        var { productInfo } = this.state;
        var products = productInfo?.products;
        var productHtml = null;
        var totalItems = 0;
        var totalWeight = 0;
        if (products) {
            totalItems = products.reduce((result, obj) => {
                if (obj) {
                    result += parseInt(obj.amount);
                    return result;
                }
                else {
                    return result;
                }
            }, 0) ?? 0;

            totalWeight =  products?.reduce((result, obj) => {
                if (obj) {
                    result += parseInt(obj.amount) * parseInt(obj.weight);
                    return result;
                }
                else {
                    return result;
                }
            }, 0) ?? 0;

            productHtml = products?.map((p) => {
                var category = this.state.productCategories.find((i) => i.id == p.categoryId);
                return (
                    <tr>
                        <td className='pl-3'>{p?.name}</td>
                        <td className='pl-3'>{t(category.languageKey)}</td>
                        <td className='text-center'>{p?.length} x {p?.width} x {p?.height}</td>
                        <td className='text-center'>{p?.weight} Kg</td>
                        <td className='text-center'>{p?.amount}</td>
                    </tr>
                )
            });
        }
        return <>
            <div className='row mt-4 mb-5'>
                <div className='col-sm-12'>
                    <h3>{t('order.productInformation')}</h3>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>{t('tableColumn.productName')}</th>
                                <th>{t('tableColumn.productCategory')}</th>
                                <th className='text-center'>{t('tableColumn.size')} (m)</th>
                                <th className='text-center'>{t('tableColumn.weight')} (Kg)</th>
                                <th className='text-center'>{t('tableColumn.amount')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {productHtml}
                            <tr className='line'></tr>
                            <tr>
                                <td className='pl-3'></td>
                                <td className='pl-3'></td>
                                <td className='text-center'></td>
                                <td className='text-left'>{t('tableColumn.totalProductsInPackage')}:</td>
                                <td className='text-center'>{totalItems}</td>
                            </tr>
                            <tr>
                                <td className='pl-3'></td>
                                <td className='pl-3'></td>
                                <td className='text-center'></td>
                                <td className='text-left'>{t('tableColumn.totalWeight')}:</td>
                                <td className='text-center'>{totalWeight} Kg</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    }

    renderDelivery = () => {
        const { t } = this.props;
        var { deliveryInfo } = this.state;
        return <>
            <h3>{t('order.deliveryMethod') }</h3>
            <div className='row'>
                <div className='col-sm-12'>
                    <p className='pl-4 mr-5'>{t(deliveryInfo?.deliveryOption?.languageKey)}</p>
                </div>
                <div className='col-sm-12'>
                    <p className='pl-4 mr-5'>{t('text.shippingService')}: <span>{t(deliveryInfo?.shippingService?.languageKey)}</span></p>
                </div>
            </div>
        </>
    }

    renderRoute = () => {
        const { t } = this.props;
        var { deliveryInfo } = this.state;
        return <>
            <div className='mt-3'>
                <h3>{t('order.routing')}</h3>
                <div className='row'>
                    <div className='col-sm-12'>
                        <div className='row mx-auto'>
                            <p className='col-sm-4'>{t('tableColumn.provider')}:</p>
                            <p className='col-sm-8'>{deliveryInfo?.route?.company?.name}</p>
                        </div>
                        <div className='row mx-auto'>
                            <p className='col-sm-4'>{t('tableColumn.routeId')}:</p>
                            <p className='col-sm-8'>{deliveryInfo?.route?.routeId}</p>
                        </div>
                        <div className='row mx-auto'>
                            <p className='col-sm-4'>{t('tableColumn.routeName')}:</p>
                            <p className='col-sm-8'>{deliveryInfo?.route?.routeName}</p>
                        </div>
                    </div>
                </div>

            </div>
        </>
    }

    renderPayment = () => {
        const { t } = this.props;
        var { paymentInfo } = this.state;
        var { paymentDetail } = paymentInfo;

        var codAmountFormated = formatAmount(paymentInfo.codAmount) == 'null' ? 0 + TEXT_VND : formatAmount(paymentInfo.codAmount) + TEXT_VND;

        var CODFeeFormated = formatAmount(paymentInfo?.paymentDetail?.codFee) + TEXT_VND;
        var pickupFromDoorFeeFormated = formatAmount(paymentInfo?.paymentDetail?.pickupFromDoorFee) + TEXT_VND;
        var deliveryToDoorFeeFormated = formatAmount(paymentInfo?.paymentDetail?.deliveryToDoorFee) + TEXT_VND;
        var interProvinceFeeFormated = formatAmount(paymentInfo?.paymentDetail?.interProvinceFee) + TEXT_VND;
        var totalFeeFormated = formatAmount(paymentInfo?.paymentDetail?.totalFee) + TEXT_VND;

        return <>
            <h3>{t('order.paymentMethod') }</h3>
            <div className='row'>
                <div className='col-sm-4 pl-5'>
                    <div className='row pl-4 mx-auto'>
                        <p className='mr-5'>{t(paymentInfo?.paymentType?.languageKey)}</p>
                    </div>
                    <div className='row pl-4 mx-auto'>
                        <p className='mr-5'>{t(paymentInfo?.payBy?.languageKey)}</p>
                    </div>
                </div>
                <div className='col-sm-6'>
                    <div className='total-card'>
                        {
                            paymentInfo.isCod && paymentDetail?.codFee > 0 &&
                            <>
                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <p className='m-0'>{t('text.codAmount')}</p>
                                        <p className='m-0 pl-4 text-detail font-italic'>{t('text.codAmountDeltail-1')}</p>
                                        <p className='m-0 pl-4 text-detail font-italic'>{t('text.codAmountDeltail-2')}</p>
                                    </div>

                                    <div className='col-sm-4 m-0 '>
                                        <p className='float-right'>{codAmountFormated}</p>
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <p className='col-sm-6'>{t('text.codFee')}</p>
                                    <div className='col-sm-4 m-0 '>
                                        <p className='float-right'>{CODFeeFormated}</p>
                                    </div>
                                </div>
                            </>
                        }

                        {
                            paymentDetail?.pickupFromDoorFee > 0 &&
                            <div className='row'>
                                <p className='col-sm-6 m-0'><b>{t('text.pickupFee')}:</b></p>
                                <div className='col-sm-4 m-0 '>
                                    <p className='float-right'>{pickupFromDoorFeeFormated}</p>
                                </div>
                            </div>
                        }

                        {
                            paymentDetail?.deliveryToDoorFee > 0 &&
                            <div className='row mt-1'>
                                <p className='col-sm-6 m-0'><b>{t('text.deliveryFee')}:</b></p>
                                <div className='col-sm-4 m-0 '>
                                    <p className='float-right'>{deliveryToDoorFeeFormated}</p>
                                </div>
                            </div>
                        }

                        {
                            paymentDetail?.interProvinceFee > 0 &&
                            <div className='row mt-1'>
                                <p className='col-sm-6 m-0'><b>{t('text.interProvinceFee')}:</b></p>
                                <div className='col-sm-4 m-0 '>
                                    <p className='float-right'>{interProvinceFeeFormated}</p>
                                </div>
                            </div>
                        }

                        <div className='row mt-1'>
                            <p className='col-sm-6 m-0'><b>{t('text.totalShippingFee')}:</b></p>
                            <div className='col-sm-4 m-0 '>
                                <p className='text-danger float-right'><b>{totalFeeFormated}</b></p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    }

    formatedAddress = (address) => {
        var formatedAddress = address?.street ?? '';
        formatedAddress += address?.ward?.name ? `, ${address?.ward?.name}` : '';
        formatedAddress += address?.district?.name ? `, ${address?.district?.name}` : '';
        formatedAddress += address?.city?.name ? `, ${address?.city?.name}` : '';

        return formatedAddress;
    }
}