import { DataService } from "../base/base-data.service";

class CompaniesDataService extends DataService {
    apiUrl = '/company'

    async getById(id) {
        return await this.get(`${this.apiUrl}/${id}`);
    }
}

let companiesDataService = new CompaniesDataService();

export default companiesDataService;