import { Component } from "react";
import Layout, { Content } from "antd/lib/layout/layout";
import {
  SnippetsOutlined,
  CarOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import { Space, Card, Steps, Timeline, Input, Row, Col, Image } from "antd";
import TopBar from "../../components/topbar";
import orderDataService from "../../data-services/orders/orders-data.service";
import moment from "moment";
import {
  orderTrackingPhaseType,
  orderTrackingStatusType,
} from "../../constants/order-history.constant";
import { Link } from "react-router-dom";
import './view-order-history.screen.scss';
import { images } from "../../constants/imagesConstant";

const { Step } = Steps;
const { Search } = Input;

export class ViewOrderHistoryScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderCode: "",
      orderInfo: null,
      showLabelNoData: false
    };
  }

  componentDidMount() {
    const { orderCode } = this.props?.match?.params || {};
    if (orderCode) {
      this.setState({ orderCode: (orderCode.toLowerCase().includes('undefined') || orderCode.toLowerCase().includes('null')) ? '' : orderCode?.toUpperCase() });
      this.getOrderTrackingListFromServer(orderCode);
    }
  }
  
  onSearch = (orderCode) => {
    orderCode = orderCode?.trim();
    this.getOrderTrackingListFromServer(orderCode);
  };

  onChange = (input) => {
    let value = input.target.value?.trim();
    this.setState({ orderCode: value });
  };

  onPressEnter = (e) => {
    let orderCode = this.state.orderCode;
    this.getOrderTrackingListFromServer(orderCode);
  };

  getOrderTrackingListFromServer(orderCode) {
    if (orderCode?.length > 0) {
      orderDataService
        .getOrderTrackingByOrderCode(orderCode)
        .then((response) => this.setState({ orderInfo: response, showLabelNoData: true }));
    }
  }

  renderOrderInfo = (order) => {
    const { t } = this.props;
    return (
      <Card>
        <b>{t("order.senderInfo")}: </b>
        <br />
        <span>
          &emsp;- {t("order.senderPhone")}: {order?.senderPhone}
        </span>
        <br />
        <span>
          &emsp;- {t("order.senderName")}: {order?.senderName}
        </span>
        <br />
        <span>
          &emsp;- {t("order.senderAddress")}: {order?.senderAddress}
        </span>
        <br />
        <br />

        <b>{t("order.receiverInfo")}: </b>
        <br />
        <span>
          &emsp;- {t("order.receiverPhone")}: {order?.senderPhone}
        </span>
        <br />
        <span>
          &emsp;- {t("order.receiverName")}: {order?.senderName}
        </span>
        <br />
        <span>
          &emsp;- {t("order.receiverAddress")}: {order?.senderAddress}
        </span>
        <br />
        <br />
      </Card>
    );
  };

  writeStoryAsTimeline(orderTrackingItem) {
    const { t } = this.props;
    let createdDate = moment
      .utc(orderTrackingItem.createdDate)
      .local()
      .format("HH:mm DD-MM-yyyy");

    let json = orderTrackingItem?.jsonData;

    switch (orderTrackingItem.phaseType) {
      case orderTrackingPhaseType.createdOrEditOrder:
        return (
          <Timeline.Item>
            {json?.modified ? (
              <span>
                {createdDate} "{json.modifiedByAccountName}"{" "}
                {t("text.modifiedThis")} "
                <Link to={`/detail-order/${json?.orderId}`}>
                  {json?.orderCode}
                </Link>
                ". {t("text.pleaseOpenViewOrderDetail")}
              </span>
            ) : (
              <span>
                {createdDate} {t("text.createdOrder")} '
                <Link to={`/view-order/${json?.orderId}`}>
                  {json?.orderCode}
                </Link>
                ' {t("text.by")} '{json.senderName}'
              </span>
            )}
          </Timeline.Item>
        );
      case orderTrackingPhaseType.createOrEditPickupList:
        return (
          <Timeline.Item>
            {json?.modified ? (
              <span>
                {createdDate} "{json.currentStationAdminName}"{" "}
                {t("text.modifiedThis")} "
                <Link to={`/view-pickup-list/${json?.orderPickupId}`}>
                  {json?.orderPickupCode}
                </Link>
                ". {t("text.pleaseOpenViewPickupListForMoreDetail")}
              </span>
            ) : (
              <span>
                {createdDate} {t("text.createdPickupList")} '
                <Link to={`/view-pickup-list/${json?.orderPickupId}`}>
                  {json?.orderPickupCode}
                </Link>
                ' {t("text.by")} '{json.currentStationAdminName}'
              </span>
            )}
          </Timeline.Item>
        );

      case orderTrackingPhaseType.orderAssignedToDriver:
        return (
          <Timeline.Item>
            <span>
              {createdDate} {t("text.orderAssignedTo")} '{json?.vehicleCode}' -
              '{t(json?.vehicleTypeName)}' - '{json?.driverName}'
            </span>
          </Timeline.Item>
        );

      case orderTrackingPhaseType.driverGoingToPickOrder:
        return (
          <Timeline.Item>
            <span>
              {createdDate} '{json?.driverName}' {t("text.goingToPickTheOrder")}
            </span>
          </Timeline.Item>
        );

      case orderTrackingPhaseType.driverPickedOrder:
        return (
          <Timeline.Item>
            <span>
              {createdDate} '{json?.driverName}' {t("text.pickedTheOrder")}
            </span>
          </Timeline.Item>
        );

      case orderTrackingPhaseType.driverCanNotPickOrderWithReason:
        return (
          <Timeline.Item>
            <span>
              {createdDate} '{json?.driverName}'{" "}
              {t("text.cannotPickTheOrderWithReason")}: '{json?.reason}'
            </span>
          </Timeline.Item>
        );

      case orderTrackingPhaseType.orderFinishedPickingProcessAndWaitingForImport:
        return (
          <Timeline.Item>
            <span>
              {createdDate} {t("text.pickingAndWaitingImport")} '
              {json?.stationCode} {json?.stationName}'
            </span>
          </Timeline.Item>
        );

      case orderTrackingPhaseType.createOrEditOrderImportedToStation:
        return (
          <Timeline.Item>
            {json?.modified ? (
              <span>
                {createdDate} "{json?.stationAdminAccountName}"{" "}
                {t("text.modifiedThis")} "
                <Link to={`/packages/view/${json?.importListId}`}>
                  {json?.importListCode}
                </Link>
                ". {t("text.pleaseOpenViewImportListForMoreDetail")}
              </span>
            ) : (
              <span>
                {createdDate} {t("text.orderImportedTo")} '{json?.stationCode}{" "}
                {json?.stationName}' {t("text.by2")} '
                {json?.stationAdminAccountName}'.{" "}
                {json?.totalNumberOfProductsBeforeScanning -
                  json?.totalNumberOfProductsAfterScanning >
                0
                  ? `${t("text.missingProducts")}`
                  : ""}{" "}
                {t("text.viewMoreDetailAt")} '
                <Link to={`/packages/view/${json?.importListId}`}>
                  {json?.importListCode}
                </Link>
                ' .
              </span>
            )}
          </Timeline.Item>
        );

      case orderTrackingPhaseType.createOrEditExportListByStation:
        return (
          <Timeline.Item>
            {json?.modified ? (
              <span>
                {createdDate} "{json?.stationAdminAccountName}"{" "}
                {t("text.modifiedThis")} "
                <Link to={`/view-export-list/${json?.orderExportListId}`}>
                  {json?.orderExportListCode}
                </Link>
                ". {t("text.pleaseOpenViewExportListForMoreDetail")}
              </span>
            ) : (
              <span>
                {createdDate} {t("text.createdExportListBy")} '
                {json?.stationAdminAccountName}'.{" "}
                {json?.totalNumberOfProductsBeforeScanning -
                  json?.totalNumberOfProductsAfterScanning >
                0
                  ? `${t("text.missingProducts")}`
                  : ""}{" "}
                {t("text.viewMoreDetailAt")} '
                <Link to={`/view-export-list/${json?.orderExportListId}`}>
                  {json?.orderExportListCode}
                </Link>
                ' .
              </span>
            )}
          </Timeline.Item>
        );

      case orderTrackingPhaseType.driverPickedThisExportListToTransportWithRoute:
        return (
          <Timeline.Item>
            <span>
              {createdDate} '{json?.driverAccountName}'{" "}
              {t("text.pickedExportListToTransport")} '{json?.routeCode}{" "}
              {json?.routeName}' {t("text.andVehicle")} '{json?.vehicleCode}{" "}
              {t(json?.vehicleTypeName)}'
            </span>
          </Timeline.Item>
        );

      case orderTrackingPhaseType.orderExportedToVehicle:
        return (
          <Timeline.Item>
            <span>
              {createdDate} {t("text.orderExportedTo")} '{json?.vehicleCode}'
            </span>
          </Timeline.Item>
        );

      case orderTrackingPhaseType.orderIsInTransitToStation:
        return (
          <Timeline.Item>
            <span>
              {createdDate} {t("text.inTransitNextStation")} '
              {json?.stationCode} {json?.stationName}'
            </span>
          </Timeline.Item>
        );

      case orderTrackingPhaseType.orderIsInTransitToLastStation:
        return (
          <Timeline.Item>
            <span>
              {createdDate} {t("text.inTransitLastStation")} '
              {json?.stationCode} {json?.stationName}'
            </span>
          </Timeline.Item>
        );

      case orderTrackingPhaseType.driverArrivedAndImportingToLastStation:
        return (
          <Timeline.Item>
            <span>
              {createdDate} '{json?.driverAccountName}'{" "}
              {t("text.arrivedLastStation")} '{json?.stationCode}{" "}
              {json?.stationName}'
            </span>
          </Timeline.Item>
        );

      case orderTrackingPhaseType.createOrEditImportedToLastStation:
        return (
          <Timeline.Item>
            {json?.modified ? (
              <span>
                {createdDate} "{json?.currentStationAccountName}"{" "}
                {t("text.modifiedThis")} "
                <Link to={`/view-import-from-vehicle/${json?.orderImportId}`}>
                  {json?.orderImportCode}
                </Link>
                ". {t("text.pleaseOpenViewImportFromVehicleListForMoreDetail")}
              </span>
            ) : (
              <span>
                {createdDate} '{json?.currentStationAccountName}'{" "}
                {t("text.importedLastStation")} '{json?.stationCode}{" "}
                {json?.stationName}'{" ."}
                {json?.totalNumberOfProductsBeforeScanning -
                  json?.totalNumberOfProductsAfterScanning >
                0
                  ? `${t("text.missingProducts")}`
                  : ""}{" "}
                {t("text.viewMoreDetailAt")} '
                <Link to={`/view-import-from-vehicle/${json?.orderImportId}`}>
                  {json?.orderImportCode}
                </Link>
                '
              </span>
            )}
          </Timeline.Item>
        );

      case orderTrackingPhaseType.createOrEditDeliveryListByStation:
        return (
          <Timeline.Item>
            {json?.modified ? (
              <span>
                {createdDate} "{json?.currentStationAccountName}"{" "}
                {t("text.modifiedThis")} "
                <Link
                  to={`/view-delivery-list-detail/${json?.orderDeliveryId}`}
                >
                  {json?.orderDeliveryCode}
                </Link>
                ". {t("text.pleaseOpenViewDeliveryListForMoreDetail")}
              </span>
            ) : (
              <span>
                {createdDate} {t("text.createdDeliveryListby")} '
                {json?.currentStationAccountName}'
              </span>
            )}
          </Timeline.Item>
        );

      case orderTrackingPhaseType.orderAssignedToDriverToDeliver:
        return (
          <Timeline.Item>
            <span>
              {createdDate} {t("text.orderAssignedTo")} '{json?.vehicleCode}' -{" "}
              '{t(json?.vehicleType)}' - '{json?.driverAccountName}'
            </span>
          </Timeline.Item>
        );

      case orderTrackingPhaseType.driverGoingToDeliverOrder:
        return (
          <Timeline.Item>
            <span>
              {createdDate} '{json?.driverName}' {t("text.goingToDeliver")}
            </span>
          </Timeline.Item>
        );

      case orderTrackingPhaseType.driverDeliveredOrderToRecipient:
        return (
          <Timeline.Item>
            <span>
              {createdDate} '{json?.driverName}' {t("text.deliveredOrderTo")} '
              {json?.receiverName}'
            </span>
          </Timeline.Item>
        );

      case orderTrackingPhaseType.driverCanNotDeliverOrderWithReason:
        return (
          <Timeline.Item>
            <span>
              {createdDate} '{json?.driverName}'{" "}
              {t("text.cannotDeliverWithReason")}: '{json?.reason}'
            </span>
          </Timeline.Item>
        );

      case orderTrackingPhaseType.orderFinishedDeliveryProcess:
        return (
          <Timeline.Item>
            <span>
              {createdDate} {t("text.finishedDelivery")}
            </span>
          </Timeline.Item>
        );

      case orderTrackingPhaseType.recipientOrderAtStation:
        return (
          <Timeline.Item>
            <span>
              {createdDate} '{json?.receiverName}'{" "}
              {t("text.receivedOrderAtStation")} '{json?.stationCode}{" "}
              {json?.stationName}'
            </span>
          </Timeline.Item>
        );

      default:
        return <Timeline.Item></Timeline.Item>;
    }
  }

  getOrderTrackingStatusType(type) {
    if (this.state?.orderInfo && this.state.orderInfo?.orderTrackings) {
      let lastOrderTracking = this.state.orderInfo?.orderTrackings[0];
      if (lastOrderTracking) {
        if (type === orderTrackingStatusType.orderBeingProcessed) {
          if (
            lastOrderTracking.phaseType >=
            orderTrackingPhaseType.createdOrEditOrder
          ) {
            return "finish";
          }
        }

        if (type === orderTrackingStatusType.inTransit) {
          if (
            lastOrderTracking.phaseType >
            orderTrackingPhaseType.createOrEditPickupList
          ) {
            return "finish";
          }
        }

        if (type === orderTrackingStatusType.delivered) {
          if (
            lastOrderTracking.phaseType ===
              orderTrackingPhaseType.driverDeliveredOrderToRecipient ||
            lastOrderTracking.phaseType >=
              orderTrackingPhaseType.orderFinishedDeliveryProcess
          ) {
            return "finish";
          }
        }
      }
    }
    return "wait";
  }

  render() {
    const { t } = this.props;
    return (
      <Layout className="bg-content">
        <div className="bg-body">
          <TopBar title={t("title.trackingOrder")} />
          <Content className="content">
            <Card>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Row gutter={24}>
                  <Col className="gutter-row" span={8}>
                    <Search
                      value={this.state.orderCode}
                      onChange={this.onChange}
                      onPressEnter={this.onPressEnter}
                      onSearch={this.onSearch}
                      placeholder={t("placeholder.pleaseInputTrackingOrderId")}
                      allowClear
                      enterButton={t("button.search")}
                      size="large"
                    />
                  </Col>
                </Row>
                {this.state.orderInfo?.order && this.state.orderInfo?.orderTrackings && (
                  <>
                    <Card style={{ borderRadius: 20 }}>
                      <Steps>
                        <Step
                          status={this.getOrderTrackingStatusType(
                            orderTrackingStatusType.orderBeingProcessed
                          )}
                          title={t("title.orderBeingProcessed")}
                          icon={<SnippetsOutlined />}
                        />
                        <Step
                          status={this.getOrderTrackingStatusType(
                            orderTrackingStatusType.inTransit
                          )}
                          title={t("title.inTransit")}
                          icon={<CarOutlined />}
                        />
                        <Step
                          status={this.getOrderTrackingStatusType(
                            orderTrackingStatusType.delivered
                          )}
                          title={t("title.delivered")}
                          icon={<FileDoneOutlined />}
                        />
                      </Steps>
                    </Card>

                    <Card style={{ borderRadius: 20 }}>
                      <h4>
                        <b style={{ textDecoration: "underline" }}>
                          {t("title.orderDetails")}:
                        </b>
                      </h4>
                      <Row gutter={24}>
                        <Col className="gutter-row" span={8}>
                          {this.renderOrderInfo(this.state.orderInfo.order)}
                        </Col>
                        <Col className="gutter-row" span={15} offset={1}>
                          <Timeline>
                            {this.state.orderInfo?.orderTrackings &&
                              this.state.orderInfo?.orderTrackings.map(
                                (value, index) =>
                                  this.writeStoryAsTimeline(value)
                              )}
                          </Timeline>
                        </Col>
                      </Row>
                    </Card>
                  </>
                )}
                {!this.state.orderInfo?.order && !this.state.orderInfo?.orderTrackings && this.state.showLabelNoData &&(
                  <>
                  <div className='noData'>
                    <Image src={images.noRecordFound} />
                    </div>
                    <div className='noData'>{t("messages.noDataToDisplay")}</div>
                  </>
                )
                }
              </Space>
            </Card>
          </Content>
        </div>
      </Layout>
    );
  }
}
