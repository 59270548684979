import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getOrderPickup } from '../../../modules/orders/orders.actions';
import actionTypes from '../../../modules/orders/orders.types';
import { ListOrderPickupScreen } from './list-order-pickup.screen';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
  return {
    orders: state.order?.listOrderPickup,
    currentStationId: state.session?.auth?.user?.currentStationId,
    vehicles: state.order?.vehicles || [],
    selectedOrders: state.order?.selectedOrders
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listOrders: (stationId) => dispatch(getOrderPickup(stationId)),
    updateSelectedOrder: (keys) => {
      return dispatch({
        type: actionTypes.UPDATE_SELECTED_ORDER,
        payload: keys
      });
    }
  }
}

export default compose(
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(ListOrderPickupScreen)

