import moment from 'moment';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import TopBar from '../../components/topbar';
import { Table, Button, Image } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import { getSpecialServiceIcon } from '../../utils/order';
import iconWareHouse from '../../assets/icons/warehouse.png'
import { deliveryHouseMethods } from '../../constants/order-constant';

import './view-pickup-list.screen.scss'
import languageService from '../../services/language.service';

const unitWeight = 'Kg';
export class ViewPickupListScreen extends Component {
    constructor(props) {
        super(props)
        this.onChange = this.onChange.bind(this);
        this.state = {
            searchText: '',
            searchedColumn: '',
        }
    }

    componentDidMount() {
        const { id } = this.props?.match?.params || {};
        if (this.props && this.props.getPickupListById) {
            this.props.getPickupListById(id);
        } else {
            console.log('Cannot get pickup list');
        }
    }

    getTableColumns(orders) {
        const { t } = this.props;
        let deliveries = orders?.map(d => { return { text: t(d.deliveryMethod), value: d.deliveryMethod } });
        let deliveryOptions = deliveries?.filter((d, i) => deliveries?.findIndex(o => o.value === d.value) === i);
        let packages = orders?.map(d => { return { text: d.packageInfo, value: d.packageInfo } });
        let packageInfoOptions = packages?.filter((d, i) => packages?.findIndex(o => o.value === d.value) === i);
        let columns = [
            {
                className: 'w-120px',
                title: t('tableColumn.orderId'),
                dataIndex: 'orderId',
                sorter: (a, b) => a.orderId.localeCompare(b.orderId),
                render: (text, data) => <Link target='_blank' to={`/view-order/${data.id}`}>{text}</Link>
            },
            {
                title: t('tableColumn.pickupAddress'),
                dataIndex: 'from',
                render: text => <span dangerouslySetInnerHTML={{ __html: text }} />
            },
            {
                title: t('tableColumn.packageInfo'),
                dataIndex: 'packageInfo',
                sorter: (a, b) => a.packageInfo.localeCompare(b.packageInfo),
                render: (name) => <span>{name}{getSpecialServiceIcon(name)}</span>,
                filters: packageInfoOptions,
                onFilter: (value, record) => record.packageInfo.indexOf(value) === 0,
            },
            {
                title: t('tableColumn.deliveryMethod'),
                dataIndex: 'deliveryMethod',
                sorter: (a, b) => a.deliveryMethod.localeCompare(b.deliveryMethod),
                render: (text) => <span>{t(text)}<br />{deliveryHouseMethods.includes(text?.toLowerCase()) && <Image src={iconWareHouse} width={30} />}</span>,
                filters: deliveryOptions,
                onFilter: (value, record) => record.deliveryMethod.indexOf(value) === 0,
            },
            {
                className: 'w-140px',
                title: t('tableColumn.totalWeight'),
                dataIndex: 'totalWeight',
                sorter: (a, b) => a.totalWeight - b.totalWeight,
                render: text => <span>{text} {unitWeight}</span>
            },
            {
                className: 'w-140px',
                title: t('tableColumn.totalProductsInPackage'),
                dataIndex: 'totalProductsInPackage',
                sorter: (a, b) => a.totalProductsInPackage - b.totalProductsInPackage,
            },
            {
                className: 'w-140px',
                title: t('tableColumn.totalItemsInPackage'),
                dataIndex: 'totalItemsInPackage',
                sorter: (a, b) => a.totalItemsInPackage - b.totalItemsInPackage,
            },
            {
                className: 'w-170px',
                title: t('tableColumn.createdDate'),
                dataIndex: 'createdDate',
                sorter: (a, b) => moment(a.createdDate) - moment(b.createdDate),
                render: text => <div dangerouslySetInnerHTML={{ __html: moment.utc(text).locale(languageService.getLang()).format('hh:mm A <br> DD/MM/YYYY') }} />
            }
        ];
        return columns;
    }

    getSenderInfo(order) {
        let formattedAddress = this.getFormattedAddress(order?.addressSender) || this.props.t('text.noAddress');
        let senderInfo = [order.senderName, order.senderPhone, formattedAddress];
        return senderInfo.join('<br/>');
    }

    getFormattedAddress(address) {
        let arr = [];
        if (address && address.street) {
            arr.push(address.street);
        }
        if (address && address.ward) {
            let ward = address.ward.prefix + ' ' + address.ward.name;
            arr.push(ward);
        }
        if (address && address.district) {
            let district = address.district.prefix + ' ' + address.district.name;
            arr.push(district);
        }
        if (address && address.city) {
            arr.push(address.city.name);
        }
        return arr.join(', ');
    }

    onChange(pagination, filters, sorter, extra) {
        console.log('params', pagination, filters, sorter, extra);
    }

    handleClosePage() {
        this.props.history.push('/dashboard')
    }

    transform = (order) => {
        var result = {
            key: order.id,
            id: order.id,
            orderId: order.code,
            from: this.getSenderInfo(order),
            deliveryMethod: order.deliveryType?.languageKey,
            packageInfo: order.specialOption?.name,
            createdDate: order.createdTime,
            amount: order.amount,
            packageSizeWeight: order.packageSizeWeight,
            totalWeight: order.totalWeight,
            totalProductsInPackage: order.totalProductsInPackage,
            totalItemsInPackage: order.totalItemsInPackage,
        }

        if (order.status.subStatusName != null) {
            result.status = `${order.status.statusName}-${order.status.subStatusName}`
        }
        else {
            result.status = order.status.statusName;
        }

        return result
    }

    render() {
        const { t } = this.props;
        const { vehicle, code, createdTime } = this.props.pickupList || {};
        let rows = this.props.pickupList?.orders?.map(o => this.transform(o)) || [];
        let columns = this.getTableColumns(rows);

        return (
            <Layout className='bg-content'>
                <div className='bg-body'>
                    <TopBar title={t('title.viewDetailPickup')} />
                    <div className='bg-white clearfix'>
                        <Content className='content'>
                            <div className='list-pickup'>
                                <div className='review-vehicle'>
                                    <div className='pull-left'>
                                        <p><b>{t('text.selectedVehicle') }: </b>{vehicle?.code}</p>
                                        <p><b>{t('text.vehicleType')}: </b>{t(vehicle?.type?.name)}</p>
                                    </div>
                                    <div className='pull-right'>
                                        <p><b>{t('tableColumn.pickupListId')}: </b>{code}</p>
                                        <p><b>{t('tableColumn.createdDate')}: </b>{moment.utc(createdTime).locale(languageService.getLang()).format('hh:mm A DD/MM/YYYY')}</p>
                                    </div>
                                </div>
                                <Table
                                    bordered
                                    columns={columns}
                                    dataSource={rows}
                                    onchange={this.onChange} />
                            </div>
                        </Content>
                        <div className='redirect-page pull-right'>
                            <Button className='btn-action' onClick={this.handleClosePage.bind(this)}>{t('button.close')}</Button>
                            <Button className='btn-action btn-next'>{t('button.print')}</Button>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}