import { Layout } from "antd";
import React, { Component } from "react";
import { I18nextProvider } from "react-i18next";
import { connect } from "react-redux";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { LoadingComponent } from "./components/loading/loading.component";
import BeecowMenu from "./components/menu";
import { ErrorBoundary } from "./errorBoundary";
import PrivateRoute from "./router/auth.router";
import routes from "./router/routes";
import i18n from "./utils/i18n";

export class App extends Component {
  render() {
    return (
      <ErrorBoundary>
        <Router>
          <I18nextProvider i18n={i18n}>
            <div className="container-list">
              <LoadingComponent loading={this.props.loading} />
              <Layout className="ant-layout ant-layout-has-sider" style={{ minHeight: "100vh" }}>
                <BeecowMenu listMenu={routes} />
                <Switch>
                  {routes.map((route, index) =>
                    route.auth ? (
                      <PrivateRoute key={index} path={route.path} exact={route.exact} component={route.main} />
                    ) : (
                      <Route
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        component={route.main}
                        ii={this.props.i18n}
                      />
                    )
                  )}
                </Switch>
              </Layout>
            </div>
          </I18nextProvider>
        </Router>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.app.isDataServiceProcessing || false,
  };
};
export default connect(mapStateToProps)(App);
