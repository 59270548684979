
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Table, Checkbox, Select, Form } from 'antd';
import moment from 'moment';
import { FORMAT_TIME_DATE } from '../../../../common/constants';

export default class ReviewConfirmQuantityComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productExportReason: props.productExportReason,
            exportList: props.exportList,
            vehicle: props.vehicle,
            route: props.route,
        }

        this.formRef = React.createRef();
    }

    render() {
        const { t } = this.props;
        const { exportList, vehicle, route, } = this.state;
        const { orders } = exportList;
        var routeName = route?.code && route?.routeName ? `${route?.code} (${route?.routeName})` : '';
        var {
            remainingOrders,
            totalOrdersScanned,
            remainingProducts,
            totalProductsScanned
        } = this.getProductsScanned(orders);

        return (
            <div>
                <div>
                    <div>
                        <div className='row ml-5'>
                            <div className='col-4'>
                                <label>{t('text.selectedVehicle')}</label>
                                <p>{`${vehicle?.code}, ${vehicle?.type}`}</p>
                            </div>

                            <div className='col-4'>
                                <label>{t('tableColumn.routeId')} - {t('tableColumn.routeName')}</label>
                                <p>{routeName}</p>
                            </div>

                            <div className='col-4'>
                                <label>{t('tableColumn.routine')}</label>
                                <p>{route?.routine}</p>
                            </div>
                        </div>

                        <div className='row m-auto pl-5'>
                            <div className='col-4 m-auto'>
                                <p><b>{t('tableColumn.exportListId')}</b></p>
                                <Link className='text-decoration-underline' to={`/view-export-list/${exportList.id}`} target='_blank'> {exportList?.code} </Link>
                            </div>

                            <div className='col-4 m-auto'>
                                <div className='d-flex'>
                                    <p className='col-8'>{t('text.scannedOrders')}</p>
                                    <p className='col-4 text-primary'>{totalOrdersScanned}</p>
                                </div>
                                <div className='d-flex'>
                                    <p className='col-8'>{t('text.scannedProducts')}</p>
                                    <p className='col-4 text-primary'>{totalProductsScanned}</p>
                                </div>
                            </div>

                            <div className='col-4 m-auto'>
                                <div className='d-flex'>
                                    <p className='col-8'>{t('text.remainingOrders')}</p>
                                    <p className='col-4 text-danger'>{remainingOrders}</p>
                                </div>
                                <div className='d-flex'>
                                    <p className='col-8'>{t('text.remainingProducts')}</p>
                                    <p className='col-4 text-danger'>{remainingProducts}</p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='mt-5'>
                        <Form ref={this.formRef}>
                            {this.renderOrders(orders)}
                        </Form>
                    </div>

                </div>
                <div className='steps-action' className='float-right mr-5 mt-5 d-flex'>
                    <a className='pr-5 mt-2' onClick={this.props.prev}>
                        { t('button.back')}
                    </a>
                    <Button type='primary' onClick={this.props.finishExportList}>
                        {t('button.finishExportList')}
                    </Button>
                </div>
            </div>

        )
    }

    renderOrders = (orders) => {
        const { t } = this.props;
        const { productExportReason } = this.state;
        if (orders) {
            return orders?.map((o, index) => {
                var isFailAll = this.isFailAll(o.orderItems);

                return (
                    <>
                        <div className='row ml-5 header-table'>
                            <div className='col-md-3 d-flex'>
                                <p className='mr-3 font-weight-bold'>{t('tableColumn.orderCode')}: </p>
                                <Link className='text-decoration-underline' to={`/view-order/${o.id}`} target='_blank'> {o?.code} </Link>
                            </div>
                            <div className='col-md-3 d-flex'>
                                <p className='font-weight-bold mr-3'>{t('tableColumn.createdDate')}: </p>
                                <p>{moment.utc(o?.createdTime).format(FORMAT_TIME_DATE)}</p>
                            </div>
                            <div className='col-md-6 d-flex'>
                                {
                                    isFailAll &&
                                    <>
                                        <div className='col-md-6 text-reason-all'>
                                            {t('text.sameReasonForAllProducts')}
                                        </div>
                                        <div className='col-md-6'>
                                            <Form.Item name={['sameReason', index]}>
                                                <Select
                                                    onChange={(e) => this.onChangeSameReason(e, o.orderItems)}
                                                    optionFilterProp='children'
                                                    placeholder={t('placeholder.selectReason')}>
                                                    {
                                                        productExportReason?.map((name, index) => (
                                                            <Select.Option key={index} value={name}>{t(name)}</Select.Option>
                                                        ))
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>

                        <div className='mt-3'>
                            <Table
                                columns={this.orderTableColumns()}
                                dataSource={this.mappingToDataTable(o?.orderItems)}
                                pagination={false}
                                bordered
                            />
                        </div>
                        {
                            (index < orders.length) &&
                            <hr className='line' />
                        }
                    </>
                )
            });
        }
    }

    orderTableColumns = () => {
        const { t } = this.props;
        var { productExportReason } = this.props;
        return [
            {
                title: t('tableColumn.no'),
                dataIndex: 'index',
            },
            {
                title: t('tableColumn.productCode'),
                dataIndex: 'productCode',
            },
            {
                title: t('tableColumn.productName'),
                dataIndex: 'productName',
            },
            {
                title: t('tableColumn.productCategory'),
                dataIndex: 'productCategory',
            },
            {
                title: t('tableColumn.amount'),
                dataIndex: 'amount',
                render: (amount, record) => {
                    return (
                        <>
                            <span className='m-auto'>{`${record.amountScanned}/${record.amount}`}</span>
                        </>
                    )
                }
            },
            {
                title: t('tableColumn.scanned'),
                dataIndex: 'scanned',
                render: (scanned, record) => {
                    return <Checkbox checked={scanned} disabled={true} />
                }
            },
            {
                title: t('tableColumn.reason'),
                dataIndex: 'scanReasonFailed',
                render: (scanReasonFailed, record) => {
                    var scanned = record.scanned;
                    return (
                        <>
                            {
                                scanned && <span className='m-auto'>{record.scanReasonFailed}</span>
                            }

                            {
                                !scanned &&
                                <Form.Item
                                    name={['orderItems', record.productCode, 'scanReasonFailed']}
                                    rules={[{ required: true, message: t('messages.selectReason') }]}>
                                    <Select
                                        optionFilterProp='children'
                                        onChange={(value) => this.updateOrderItem(record.productCode, null, null, value)}
                                        placeholder={t('placeholder.selectReason') }>
                                        {
                                            productExportReason?.map((name, index) => (
                                                <Select.Option key={index} value={name}>{t(name)}</Select.Option>
                                            ))
                                        }
                                    </Select>

                                </Form.Item>
                            }
                        </>
                    )
                }
            },
        ];
    }

    isFailAll = (orderItems) => {
        var anyScanned = orderItems?.find((i) => i.scanned == true);
        if (anyScanned) {
            return false;
        }

        return true;
    }

    mappingToDataTable = (orderItems) => {
        return orderItems?.map((i, index) => {
            return {
                index: index + 1,
                productCode: i.product?.code,
                productName: i.product?.name,
                productCategory: i.product?.packageType?.name,
                amount: i.exportedAmount,
                amountScanned: i.amountScanned ?? 0,
                scanned: i.scanned ?? false,
                scanReasonFailed: i.scanReasonFailed ?? null,
            }
        });
    }

    updateOrderItem(productCode, scanned, amountScanned, scanReasonFailed, fromScan) {
        const { exportList } = this.state;
        const { orders } = exportList;

        var order = orders?.find((o) => {
            var orderItems = o.orderItems;
            let index = orderItems?.findIndex(i => i.product?.code === productCode);
            if (index !== -1) {
                return o;
            }
        });

        let indexOrder = orders?.findIndex(o => o.id === order.id);
        var orderItems = order.orderItems;
        let index = orderItems?.findIndex(i => i.product?.code === productCode);
        if (index !== -1) {
            var orderItem = orderItems[index];
            var exportedAmount = orderItem.exportedAmount

            if (fromScan && amountScanned >= exportedAmount) {
                amountScanned = exportedAmount;
            }
            if (scanned !== undefined && scanned !== null) {
                orderItem['scanned'] = scanned;
            }
            if (amountScanned !== undefined && amountScanned !== null) {
                orderItem['amountScanned'] = amountScanned;
            }
            if (scanReasonFailed !== undefined && scanReasonFailed !== null) {
                orderItem['scanReasonFailed'] = scanReasonFailed;
            }

            orderItems.splice(index, 1, orderItem);
            orders.splice(indexOrder, 1, order);

            this.setState({
                exportList: {
                    ...exportList,
                    orders: orders
                }
            });
        }

        if (indexOrder !== -1) {
            var dataFormRef = this.formRef.current.getFieldsValue();
            var { sameReason } = dataFormRef;
            if (sameReason) {
                sameReason[indexOrder] = null;
                this.formRef.current.setFieldsValue(dataFormRef);
            }
        }
    }

    onInput = (e, maxValue) => {
        var value = e.target.value;
        if (value > maxValue) {
            return e.target.value = maxValue;
        }
        return e;
    }

    onChangeSameReason = (event, listItem) => {
        var dataFormRef = this.formRef.current.getFieldsValue();

        var { orderItems } = dataFormRef;
        listItem?.forEach((item) => {
            if (orderItems[`${item.product?.code}`]) {
                orderItems[`${item.product?.code}`].scanReasonFailed = event;
            }

            this.updateOrderItem(item?.product?.code, null, null, event);
        });

        this.formRef.current.setFieldsValue(dataFormRef);
    }

    isScannedOrder = (order) => {
        var orderItems = order.orderItems;
        var itemsScanned = orderItems?.filter(x => x.scanned && x.scanned == true).length ?? 0;
        if (itemsScanned === orderItems.length) {
            return true;
        }

        return false;
    }

    getProductsScanned = (orders) => {
        var totalOrdersScanned = 0;
        var totalProductsScanned = 0;
        var totalProducts = 0;
        orders?.map(o => {
            if (this.isScannedOrder(o)) {
                totalOrdersScanned++;
            }

            var itemsScanned = o.orderItems?.filter(x => x.scanned && x.scanned == true).length;
            totalProductsScanned += itemsScanned;

            totalProducts += o.orderItems.length;
        });

        return {
            remainingOrders: orders.length - totalOrdersScanned,
            totalOrdersScanned: totalOrdersScanned,
            remainingProducts: totalProducts - totalProductsScanned,
            totalProductsScanned: totalProductsScanned
        }
    }

    validateFields = () => {
        return this.formRef.current.validateFields();
    }

    getFieldsValue = () => {
        const { exportList } = this.state;
        return exportList;
    }

}