import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import { LoginScreen } from './login.screen';
import { resetSession, setAuth, setWorkspace } from '../../../modules/session/session.actions';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state) => {
    return {
        isAuthenticated: (state.session?.auth?.token && state.session?.auth?.user?.role == 'STATION') ? true : false,        
        userId: state?.session?.auth?.user?.userId
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAuth: (auth) => dispatch(setAuth(auth)),
        setWorkspace: (workspaceId) => dispatch(setWorkspace(workspaceId)),
        signOut: (userId) => dispatch(resetSession(userId))
    }
}

export default compose(
    withTranslation('translations'),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(LoginScreen);


