import { DataService } from "../base/base-data.service";

class ImportDataService extends DataService {
    apiUrl = '/import';

    async getById(id) {
        return await this.get(`${this.apiUrl}/${id}`);
    }

    async executeImport(data) {
        return await this.post(`${this.apiUrl}/execute`, data);
    }

    async updateProductSpaceShipping(data) {
        return await this.post(`${this.apiUrl}/update-product-space-shipping`, data);
    }

    async getOrderImportList(stationId, page, pageSize) {
        return await this.get(`${this.apiUrl}/get-order-import-list-by-station-id?StationId=${stationId}&page=${page}&rows=${pageSize}`);
    }

    async getImportListFromVehicleById(id, stationId) {
        return await this.get(`${this.apiUrl}/view-import-from-vehicle?importId=${id}&stationId=${stationId}`);
    }

    async getImportListFromVehicle(stationId) {
        return await this.get(`${this.apiUrl}/get-list-import-from-vehicle?stationId=${stationId}`);
    }
}

let importDataService = new ImportDataService();

export default importDataService;