import { combineReducers } from "redux";
import sessionReducer from "./session/session.reducers";
import ordersReducer from "./orders/orders.reducers";
import profileReducer from "./profile/profile.reducers";
import addressReducer from "./address/address.reducers";
import appReducer from "./app/app.reducers";
import importReducer from "./import/import.reducers";

const rootReducer = combineReducers({
    app: appReducer,
    session: sessionReducer,
    order: ordersReducer,
    profile: profileReducer,
    address: addressReducer,
    import: importReducer,
});

export default rootReducer;