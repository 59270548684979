import { connect } from 'react-redux';
import { ImportPackagesScreen } from './import-packages.screen';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

 const mapStateToProps = ({ session }) => {
     return {
         userId: session?.auth.user.userid,
         currentStationId: session?.auth?.user?.currentStationId,
     };
 };


export default compose(
    withTranslation('translations'),
    connect(mapStateToProps, null),
    withRouter
)(ImportPackagesScreen)