import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import DeliveryListScreen from './delivery-list.screen';

const mapStateToProps = ({ session }) => {
  return {
    currentStationId: session?.auth?.user?.currentStationId
  }
}

export default compose(
    withTranslation('translations'),
    connect(mapStateToProps, null),
    withRouter
)(DeliveryListScreen)

