import { Option } from 'antd/lib/mentions';
import { Select, Form, Table, Button, Image, Empty } from 'antd';
import moment from 'moment';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';
import { getSpecialServiceIcon } from '../../../utils/order';
import iconWareHouse from '../../../assets/icons/warehouse.png';
import { deliveryHouseMethods } from '../../../constants/order-constant';
import _ from 'lodash';
import orderDataService from '../../../data-services/orders/orders-data.service';

const unitWeight = 'Kg';

export class ListOrderPickupScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedVehicleId: props?.selectedVehicle?.id ?? null,
            selectedVehicle: props?.selectedVehicle,
            selectedRowKeys: props?.selectedOrderList?.map((order) => order.id) ?? [],
            showSelectAll: true,
            orderList: [],
            vehicleList: [],
        };
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        if (this.props.currentStationId) {
            this.loadData();
        }
    }

    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });

        let orderList = this.state.orderList?.filter((order) =>
            selectedRowKeys?.find((key) => key === order.id)
        );
        this.props?.setSelectedOrdersForDelivery(orderList);
        this.props?.setSelectedOrderIds(selectedRowKeys);
    };
    
    onChangeVehicle = (vehicleId) => {
        let vehicle = this.state.vehicleList.find(
            (aVehicle) => aVehicle.id === vehicleId
        );
        this.props?.setSelectedVehicleForDelivery(vehicle);
        this.setState({
            selectedVehicleId: vehicleId,
        });
    };
    
    getRecipientInfo = (order) => {
        let formattedAddress =
            this.getFormattedAddress(order?.addressRecipient) || this.props.t('text.noAddress');
        let receiverInfo = [
            order.receiverName,
            order.receiverPhone,
            formattedAddress,
        ];
        return receiverInfo.join('<br/>');
    };
    
    getFormattedAddress(address) {
        let arr = [];
        if (address && address.street) {
            arr.push(address.street);
        }
        if (address && address.ward) {
            let ward = address.ward.prefix + ' ' + address.ward.name;
            arr.push(ward);
        }
        if (address && address.district) {
            let district = address.district.prefix + ' ' + address.district.name;
            arr.push(district);
        }
        if (address && address.city) {
            arr.push(address.city.name);
        }
        return arr.join(', ');
    }

    onChange(pagination, filters, sorter, extra) {
        console.log('params', pagination, filters, sorter, extra);
    }

    handleCheckAll() {
        const { t } = this.props;
        const { orderList } = this.state || [];
        const { showSelectAll } = this.state;
        let keys = orderList.map((p) => p.id);
        // If the button state is unselected, set the key as empty.
        if (!showSelectAll) {
            keys = [];
        } else {
            this.props?.setSelectedOrdersForDelivery(orderList);
        }
        this.setState({ selectedRowKeys: keys, showSelectAll: !showSelectAll });

        this.props.setSelectedOrderIds(keys);
    }

    getTableColumns(orders) {
        const { t } = this.props;
        let deliveries = orders?.map((d) => {
            return { text: d.deliveryMethod, value: d.deliveryMethod };
        });
        let deliveryOptions = deliveries?.filter(
            (d, i) => deliveries?.findIndex((o) => o.value === d.value) === i
        );
        let packages = orders?.map((d) => {
            return { text: d.packageInfo, value: d.packageInfo };
        });

        let packageInfoOptions = packages?.filter(
            (d, i) => packages?.findIndex((o) => o.value === d.value) === i
        );
        let columns = [
            {
                title: t('tableColumn.orderId'),
                dataIndex: 'orderId',
                fixed: 'left',
                render: (text, data) => (
                    <Link target='_blank' to={`/view-order/${data.id}`}>
                        {text}
                    </Link>
                ),
            },
            {
                className: 'w-270px',
                title: t('tableColumn.receiverAddress'),
                dataIndex: 'toRecipient',
                render: (text) => <span dangerouslySetInnerHTML={{ __html: text }} />,
            },
            {
                title: t('tableColumn.packageInfo'),
                dataIndex: 'packageInfo',
                render: (name) => (
                    <span>
                        {name}
                        {getSpecialServiceIcon(name)}
                    </span>
                ),
                filters: packageInfoOptions,
                onFilter: (value, record) => record.packageInfo.indexOf(value) === 0,
            },
            {
                title: t('tableColumn.deliveryMethod'),
                dataIndex: 'deliveryMethod',
                render: (text) => (
                    <span>
                        {text}
                        <br />
                        {deliveryHouseMethods.includes(text?.toLowerCase()) && (
                            <Image src={iconWareHouse} width={30} />
                        )}
                    </span>
                ),
                filters: deliveryOptions,
                onFilter: (value, record) => record.deliveryMethod.indexOf(value) === 0,
            },
            {
                title: t('tableColumn.totalWeight'),
                dataIndex: 'totalWeight',
                render: (text) => (
                    <span>
                        {text} {unitWeight}
                    </span>
                ),
            },
            {
                className: 'w-80px',
                title: t('tableColumn.totalProducts'),
                dataIndex: 'totalProductsInPackage',
            },
            {
                className: 'w-80px',
                title: t('tableColumn.totalItems'),
                dataIndex: 'totalItemsInPackage',
            },
            {
                title: t('tableColumn.createdDate'),
                dataIndex: 'createdDate',
                render: (text) => (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: moment
                                .utc(text)
                                .local()
                                .format('hh:mm A <br> DD/MM/YYYY'),
                        }}
                    />
                ),
            },
        ];
        return columns;
    }

    transform = (order) => {
        var result = {
            key: order.id,
            id: order.id,
            orderId: order.code,
            toRecipient: this.getRecipientInfo(order),
            deliveryMethod: order?.delivery?.name,
            deliveryMethodId: order?.delivery?.id,
            packageInfo: order?.specialOption?.name,
            packageInfoId: order?.specialOption?.id,
            createdDate: order.createdTime,
            amount: order.amount,
            packageSizeWeight: order.packageSizeWeight,
            totalWeight: order.totalWeight,
            totalProductsInPackage: order.totalProductsInPackage,
            totalItemsInPackage: order.totalItemsInPackage,
            isChecked: order.isChecked,
        };
        if (order?.status) {
            if (order.status.subStatusName != null) {
                result.status = `${order.status.statusName}-${order.status.subStatusName}`;
            } else {
                result.status = order.status.statusName;
            }
        }

        return result;
    };

    loadData() {
        orderDataService
            .getOrdersAndVehiclesForDelivery(
                this.props.currentStationId,
                0,
                0,
                '',
                '',
                '',
                false,
                [],
                null,
                null,
                this.props?.orderDeliveryId,
                true
            )
            .then((res) => {
                this.props?.setDeliveryCode(res.deliveryListCode);

                let selectedOrderIdList = [];
                let selectedRowKeys = this.state.selectedRowKeys;
                if (selectedRowKeys === null || selectedRowKeys?.length === 0) {
                    let selectedOrderList = res.orders
                        ?.filter((order) => order.isChecked)
                        ?.map((o) => this.transform(o));
                    selectedOrderIdList = selectedOrderList?.map((order) => order.id);
                    this.props?.setSelectedOrderIds(selectedOrderIdList);
                    this.props?.setSelectedOrdersForDelivery(selectedOrderList);
                } else {
                    selectedOrderIdList = selectedRowKeys;
                }

                if (this.state.selectedVehicleId) {
                } else {
                    let defaultSelectedVehicle = res.vehicles?.find(
                        (vehicle) => vehicle.isSelected
                    );
                    this.props?.setSelectedVehicleForDelivery(defaultSelectedVehicle);
                    this.props?.setFieldsValue({ vehicleId: defaultSelectedVehicle?.id });
                }

                this.setState({
                    selectedRowKeys: selectedOrderIdList,
                    orderList: res.orders?.map((o) => this.transform(o)),
                    vehicleList: res.vehicles,
                });
            });
    }

    render() {
        const { t } = this.props;
        const { orderList, vehicleList } = this.state;
        const { selectedRowKeys, showSelectAll } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange.bind(this),
        };

        let columns = this.getTableColumns(orderList);

        return (
            <Content className='content'>
                <div className='list-pickup'>
                    <div className='select-vehicle pull-left'>
                        <label for='html'>{t('text.pleaseSelectVehicle')}</label>
                        <Form.Item
                            name='vehicleId'
                            rules={[{ required: true, message: t('messages.pleaseSelectVehicle') }]}
                        >
                            <Select
                                showSearch
                                placeholder={t('placeholder.selectVehicle')}
                                optionFilterProp='children'
                                onChange={this.onChangeVehicle}
                            >
                                {vehicleList?.map(({ id, code, type }) => (
                                    <Option
                                        value={id}
                                        key={id}
                                    >{`${code} (${type?.name})`}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className='pull-right'>
                        <Button type='primary' onClick={this.handleCheckAll.bind(this)}>
                            {showSelectAll ? (
                                <span>{t('text.selectAllOrders')}</span>
                            ) : (
                                    <span>{t('text.unSelectAllOrders')}</span>
                            )}
                        </Button>
                        <p className='selected-order clearfix'>
                            {t('text.selected')} {selectedRowKeys.length + ` ${t('text.orders')}`}
                        </p>
                    </div>
                    <Table
                        columns={columns}
                        rowSelection={rowSelection}
                        bordered
                        dataSource={orderList}
                        onchange={this.onChange}
                        locale={{
                            emptyText: (
                                <Empty
                                    description={
                                        !this.state.selectedVehicleId
                                            ? t('text.pleaseSelectVehicle')
                                            : t('text.noOrders')
                                    }
                                />
                            ),
                        }}
                    />
                    <Form.Item
                        name='orderIds'
                        className='message'
                        rules={[{ required: true, message: t('messages.pleaseChooseOrders') }]}
                    />
                </div>
            </Content>
        );
    }
}
