import actionTypes from "./address.types";
import addressService from "../../data-services/address/address-data.service";

export const getCities = () => {
  return async (dispatch) => {
    const res = await addressService.getInitData()
    dispatch({
      type: actionTypes.GET_CITIES,
      payload: res?.cities
    })
  }
}
export const clearData = () => {
  return {
    type: actionTypes.CLEAR_ADDRESS,
    payload: []
  }
}

export const getDistrictByCityId = (cityId) => {
  return async (dispatch) => {
    const res = await addressService.getDistrictByCityId(cityId)
    dispatch({
      type: actionTypes.GET_DISTRICT_BY_CITYID,
      payload: res?.districts || []
    })
  }
}

export const getWardsByDistrictId = (districtId) => {
  return async (dispatch) => {
    const res = await addressService.getWardsByDistrictId(districtId)
    dispatch({
      type: actionTypes.GET_WARDS_BY_DISTRICTID,
      payload: res?.wards
    })
  }
}