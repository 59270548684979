import { connect } from 'react-redux';
import { ResetPasswordByEmailScreen } from './reset-password-by-email.screen';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { withTranslation, translate, Trans } from 'react-i18next';
import { userTypes } from '../../constants/user-constants';

const mapStateToProps = (state) => ({
    isAuthenticated: (state.session?.auth?.token && state.session?.auth?.user?.role == userTypes.END_USER) ? true : false
})

export default compose(
    withTranslation('translations'),
    connect(mapStateToProps, null),
    withRouter
)(ResetPasswordByEmailScreen);
