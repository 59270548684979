import React, { Component } from 'react';
import Layout from 'antd/lib/layout/layout';
import { Modal, Button, message, Steps } from 'antd';
import TopBar from '../../components/topbar';
import routeDataService from '../../data-services/routes/routes-data.service';

import ChoosePackagesComponent from './choose-packages/choose-packages.component';
import ScanPackagesComponent from './scan-packages/scan-packages.component';
import ConfirmExportListComponent from './confirm-export-list/confirm-export-list.component';
import SummazizeExportListComponent from './summazize-export-list/summazize-export-list.component';

import './edit-export-packages.component.scss';
import { ProductExportReason } from '../../components/products-info/products-info';
import orderDataService from '../../data-services/orders/orders-data.service';

const { Step } = Steps;

const SCREENS = {
    CHOOSE_PACKAGE: 0,
    SCAN_PACKAGE: 1,
    CONFIRM_REASON: 2,
    SUMMARIZE: 3,
};

export default class EditExportPackagesScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: 'title.editExportPackages',
            current: 0,
            initialData: {},
            routes: [],
            selectedRouteId: null,
            selectedVehicleId: null,
            visibleConfirmDialog: false,
            productExportReason: [
                ProductExportReason.CANT_FIND,
                ProductExportReason.PACKAGES_BROCKEN,
                ProductExportReason.VEHICLE_FULL,
                ProductExportReason.OTHER_REASONS,
            ],
            orderExportListId: null,
            orderExportListCode: null
        };

        this.choosePackagesForm = React.createRef();
        this.scanPackagesForm = React.createRef();
        this.confirmForm = React.createRef();
        this.summazizeForm = React.createRef();

        this.viewExportListDetailForm = React.createRef();
    }

    componentDidMount() {
        const { id } = this.props?.match?.params || {};
        const { currentStationId } = this.props;
        if (currentStationId) {
            routeDataService
                .getRouteFromStationToEditOrderExportList(currentStationId, id)
                .then((res) => {
                    this.setState({
                        routes: res.routeList,
                        selectedRouteId: res.selectedRouteId,
                        selectedVehicleId: res.selectedVehicleId,
                        orderExportListId: id,
                        orderExportListCode: res.orderExportListCode
                    });
                });
        }
    }

    render() {
        const { t } = this.props;
        const { current, routes, orderExportListCode } = this.state;

        if (routes == null || routes.length === 0) {
            return <></>;
        }

        const steps = this.createSteps();
        return (
            <Layout className='bg-content'>
                <div className='bg-body'>
                    <TopBar title={t(this.state.title)} />
                    <div className='bg-white clearfix beecow-leasing-contract'>
                        <div className='col-md-12'>
                            <span className='pull-right font-weight-bold'>
                                {t('tableColumn.exportId')}: {orderExportListCode}
                            </span>
                        </div>
                        <Steps
                            type='navigation'
                            current={current}
                            className='site-navigation-steps'
                        >
                            {steps.map((item) => (
                                <Step key={item.title} title={item.title} />
                            ))}
                        </Steps>
                        <div className='steps-content'>{steps[current].content}</div>
                        <div
                            className='steps-action'
                            className='float-right mr-5 mt-5 d-flex'
                        >
                            {current > 0 && (
                                <a className='pr-5' onClick={this.prev}>
                                    Back
                                </a>
                            )}

                            {current == 0 && (
                                <>
                                    <a className='pr-5 text-primary' onClick={this.cancel}>
                                        Cancel
                                    </a>
                                </>
                            )}

                            {current < steps.length - 1 && (
                                <>
                                    <Button type='primary' onClick={this.next}>
                                        Next
                                    </Button>
                                </>
                            )}

                            {current === steps.length - 1 && (
                                <>
                                    <Button type='primary' onClick={this.submit}>
                                        Done
                                    </Button>
                                </>
                            )}
                        </div>
                    </div>
                </div>

                <Modal
                    className='content-modal'
                    visible={this.state.visibleConfirmDialog}
                    onOk={this.onConfirmMissingPackages}
                    onCancel={this.onCancel}
                    cancelText={t('button.continueScan')}
                    okText={t('button.yesLost')}
                >
                    <p>{t('messages.confirmMissingProduct')}</p>
                    <p>{t('messages.sureMissingProduct')}</p>
                </Modal>
            </Layout>
        );
    }

    refresh() {
        const { initialData } = this.state;

        switch (this.state.current) {
            case SCREENS.CHOOSE_PACKAGE:
                if (
                    this.choosePackagesForm.current &&
                    this.choosePackagesForm.current.setFieldsValue
                ) {
                    this.choosePackagesForm.current.setFieldsValue(initialData);
                }
                break;
            case SCREENS.SCAN_PACKAGE:
                break;
            case SCREENS.CONFIRM_REASON:
                break;
            case SCREENS.SUMMARIZE:
                break;
            default:
                break;
        }
    }

    createSteps = () => {
        const { t } = this.props;
        var {
            routes,
            initialData,
            productExportReason,
            selectedVehicleId,
            selectedRouteId,
        } = this.state;
        const steps = [
            {
                title: t('title.choosePackages'),
                content: (
                    <ChoosePackagesComponent
                        t={ t }
                        ref={this.choosePackagesForm}
                        initialData={initialData}
                        selectedData={{ selectedRouteId, selectedVehicleId }}
                        routes={routes}
                        productExportReason={productExportReason}
                    />
                ),
            },
            {
                title: t('title.scanPackages'),
                content: (
                    <ScanPackagesComponent
                        t={t}
                        ref={this.scanPackagesForm}
                        initialData={initialData}
                        productExportReason={productExportReason}
                    />
                ),
            },
            {
                title: t('title.confirmReason'),
                content: (
                    <ConfirmExportListComponent
                        t={t}
                        ref={this.confirmForm}
                        initialData={initialData}
                        productExportReason={productExportReason}
                    />
                ),
            },
            {
                title: t('title.summazize'),
                content: (
                    <SummazizeExportListComponent
                        t={t}
                        ref={this.summazizeForm}
                        initialData={initialData}
                    />
                ),
            },
        ];

        return steps;
    };

    next = () => {
        const { t } = this.props;
        var currentFormRef;
        const { current } = this.state;
        switch (current) {
            case SCREENS.CHOOSE_PACKAGE:
                currentFormRef = this.choosePackagesForm;
                this.setState({
                    ...this.state,
                    initialData: {
                        ...this.state.initialData,
                        ...currentFormRef?.current?.getFieldsValue(),
                    },
                });

                break;
            case SCREENS.SCAN_PACKAGE:
                currentFormRef = this.scanPackagesForm;
                var values = currentFormRef?.current?.getFieldsValue();
                this.setState({
                    ...this.state,
                    initialData: {
                        ...this.state.initialData,
                        ...values,
                    },
                });
                break;
            case SCREENS.CONFIRM_REASON:
                currentFormRef = this.confirmForm;
                var values = currentFormRef?.current?.getFieldsValue();
                this.setState({
                    ...this.state,
                    initialData: {
                        ...this.state.initialData,
                        ...values,
                    },
                });
                break;
            case SCREENS.SUMMARIZE:
                break;
            default:
                break;
        }

        if (currentFormRef && currentFormRef.current) {
            currentFormRef.current
                .validateFields()
                .then(() => {
                    let nextStep = this.state.current + 1;
                    if (nextStep == SCREENS.SCAN_PACKAGE) {
                        var choosePackagesFrom = currentFormRef?.current?.getFieldsValue();
                        var { orders } = choosePackagesFrom;
                        if (!orders || orders.length === 0) {
                            message.warning(t('messages.warningOrders'));
                            return;
                        }
                    }

                    if (nextStep == SCREENS.CONFIRM_REASON) {
                        var { initialData } = this.state;
                        var { orders } = initialData;

                        var someThingsNotScanned = this.someThingsNotScanned();
                        if (someThingsNotScanned) {
                            this.setState({
                                visibleConfirmDialog: true,
                            });
                            return;
                        } else {
                            this.setState(
                                {
                                    current: SCREENS.SUMMARIZE,
                                },
                                () => {
                                    this.refresh();
                                }
                            );
                            return;
                        }
                    }

                    if (nextStep < SCREENS.SUMMARIZE) {
                        this.setState({ current: nextStep }, () => {
                            this.refresh();
                        });
                    }
                })
                .catch((ex) => console.log(ex));
        }
    };

    prev = () => {
        var someThingsNotScanned = this.someThingsNotScanned();
        if (
            this.state.current === SCREENS.SUMMARIZE &&
            someThingsNotScanned === false
        ) {
            this.setState(
                {
                    current: SCREENS.SCAN_PACKAGE,
                },
                () => {
                    this.refresh();
                }
            );
        } else {
            this.setState(
                {
                    current: this.state.current - 1,
                },
                () => {
                    this.refresh();
                }
            );
        }
    };

    cancel = () => {
        this.props.history.push('/dashboard');
    };

    submit = () => {
        var dataSendToServer = this.getRequestModel();
        dataSendToServer.orderExportListId = this.state.orderExportListId;
        orderDataService.updateExportList(dataSendToServer).then((res) => {
            this.props.history.push(`/view-export-list/${res}`);
        });
    };

    getRequestModel = () => {
        var { initialData } = this.state;
        var { route, vehicle, orders } = initialData;

        return {
            stationId: this.props.currentStationId,
            vehicleId: vehicle?.id,
            routeId: route?.id,
            orders: orders,
        };
    };

    onConfirmMissingPackages = () => {
        let nextStep = this.state.current + 1;
        this.setState(
            {
                visibleConfirmDialog: false,
                current: nextStep,
            },
            () => {
                this.refresh();
            }
        );
    };

    onCancel = () => {
        this.setState({
            visibleConfirmDialog: false,
        });
    };

    someThingsNotScanned = () => {
        var { initialData } = this.state;
        var { orders } = initialData;

        var someThingsNotScanned = false;
        if (orders.length > 0) {
            orders.forEach((o) => {
                let index = o.orderItems?.findIndex((i) => i.scanned === false);
                if (index !== -1) {
                    someThingsNotScanned = true;
                }
            });
        }

        return someThingsNotScanned;
    };
}
