import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ListProductComponent from "./list-product.component";
import { getOrderDetails } from "../../modules/orders/orders.actions"
import { withRouter } from "react-router";
import { compose } from "redux";
import { withTranslation } from 'react-i18next';

const mapStateToProps = ({ order }) => {
  return {
    order: order.orderDetails || {},
    initData: order.create.initData || {},
    amount: order.create?.amount || 1,
    deliverySelected: order.create?.deliverySelected
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getOrderDetails,
  }, dispatch)
}

export default compose(
    withTranslation("translations"),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(ListProductComponent)
