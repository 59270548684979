import { Component } from 'react';
import { Table, Input, Button, Space, Image, Row, Col, Modal } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
    deliveryHouseMethods,
    deliveryMethods,
    specialServices,
} from '../../../constants/order-constant';
import fragileIcon from '../../../assets/icons/icon-fragile.png';
import fastIcon from '../../../assets/icons/icon-fast.png';
import deliveryIcon from '../../../assets/icons/icon-delivery.png';
import deliveryIconLeasing from '../../../assets/icons/warehouse.png';
import languageService from '../../../services/language.service';

export default class TableOrdersComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchText: '',
            searchedColumn: '',
        };
        this.onChange = this.onChange.bind(this);
    }

    formatDateTime(dateTimeStr){
        let value = moment
        .utc(dateTimeStr)
        .locale(languageService.getLang())
        .format("hh:mm A, DD/MM/YYYY");
        return value;
    }

    render() {
        let rows =
            this.props.dataSource?.map((o) => {
                const { t } = this.props;
                var result = {
                    id: o.id,
                    orderId: o.code,
                    from: this.getSenderInfo(o),
                    to: this.getReceiverInfo(o),
                    deliveryMethod: o.deliveryType?.languageKey,
                    route: this.getRouteInfo(o),
                    packageInfo: o.othersCategory || o.packageType?.name,
                    contract: o.contract?.code,
                    createdDate: moment
                    .utc(o.createdTime)
                    .locale(languageService.getLang())
                    .format("hh:mm A, DD/MM/YYYY"),
                    showEditButton: o.showEditButton,
                    orderTrackingCode: o.orderTrackingCode
                };
                if (o.status.subStatusName != null) {
                    result.status = t(o.status.languageKey || o.status.statusName);
                } else {
                    result.status = t(o.status.languageKey || o.status.statusName);
                }
                return result;
            }) || [];

        let columns = this.getTableColumns(rows);
        return (
            <Table
                bordered
                columns={columns}
                dataSource={rows}
                onchange={this.onChange}
            />
        );
    }

    getTableColumns(orders) {
        const { t } = this.props;
        let deliveries = orders?.map((d) => {
            return { text: t(d.deliveryMethod), value: d.deliveryMethod };
        });
        let deliveryOptions = deliveries?.filter(
            (d, i) => deliveries?.findIndex((o) => o.value === d.value) === i
        );
        let routes = orders?.map((d) => {
            return { text: d.route?.replace('<br/>', ' - '), value: d.route };
        });
        let routeOptions = routes?.filter(
            (d, i) => routes?.findIndex((o) => o.value === d.value) === i
        );
        let status = orders?.map((d) => {
            return { text: d.status, value: d.status };
        });
        let statusOptions = status?.filter(
            (d, i) => status?.findIndex((o) => o.value === d.value) === i
        );

        let columns = [
            {
                title: t('tableColumn.orderId'),
                dataIndex: 'orderId',
                sorter: (a, b) => a.orderId.localeCompare(b.orderId),
                ...this.getColumnSearchProps('orderId'),
                render: (text, data) => (
                    <Link target='_blank' to={`/view-order/${data.id}`}>
                        {text}
                    </Link>
                ),
            },
            {
                title: t('tableColumn.from'),
                dataIndex: 'from',
                sorter: (a, b) => a.from.localeCompare(b.from),
                ...this.getColumnSearchProps('from'),
                render: (text) => <span dangerouslySetInnerHTML={{ __html: text }} />,
            },
            {
                title: t('tableColumn.to'),
                dataIndex: 'to',
                sorter: (a, b) => a.to.localeCompare(b.to),
                ...this.getColumnSearchProps('to'),
                render: (text) => <span dangerouslySetInnerHTML={{ __html: text }} />,
            },
            {
                title: t('tableColumn.deliveryMethod'),
                dataIndex: 'deliveryMethod',
                sorter: (a, b) => a.deliveryMethod.localeCompare(b.deliveryMethod),
                filters: deliveryOptions,
                onFilter: (value, record) => record.deliveryMethod.indexOf(value) === 0,
                render: (text, data) => {
                    const isShowDeliveryIcon = deliveryMethods?.includes(
                        data.deliveryMethod?.toLowerCase()
                    );
                    const isShowDeliveryIconLeasing = deliveryHouseMethods?.includes(
                        data.deliveryMethod?.toLowerCase()
                    );
                    return (
                        <div>
                            <Row>
                                <Col span={18}>{t(text)}</Col>
                                <Col span={6}>
                                    {isShowDeliveryIcon && (
                                        <Image src={deliveryIcon} width={30} />
                                    )}
                                    {isShowDeliveryIconLeasing && (
                                        <Image src={deliveryIconLeasing} width={30} />
                                    )}
                                </Col>
                            </Row>
                            <br />
                            <p>{data.contract}</p>
                        </div>
                    );
                },
            },
            {
                title: t('tableColumn.route'),
                dataIndex: 'route',
                sorter: (a, b) => a.route.localeCompare(b.route),
                filters: routeOptions,
                onFilter: (value, record) => record.route.indexOf(value) === 0,
                render: (text, data) => (
                    <Link to={`/view-order-history/${data.orderTrackingCode}`}>
                        <span dangerouslySetInnerHTML={{ __html: text }} />
                    </Link>
                ),
            },
            {
                title: t('tableColumn.createdDate'),
                dataIndex: 'createdDate',
                sorter: (a, b) => moment(a.createdDate) - moment(b.createdDate),
                ...this.getColumnSearchProps('createdDate'),
            },
            {
                title: t('tableColumn.status'),
                dataIndex: 'status',
                sorter: (a, b) => a.status.localeCompare(b.status),
                filters: statusOptions,
                onFilter: (value, record) => record.status.indexOf(value) === 0,
                render: (text, data) => (
                    <div>
                        <span>{data?.status}</span>
                        {data?.showEditButton && (
                            <div style={{ display: 'flex', justifyContent: 'center'}}>
                                <div className='text-center' style={{ marginRight: 5}}>
                                    <Link
                                        to={`/edit-order/${data?.id}`}
                                        className='ant-btn ant-btn-primary'
                                    >
                                        {t('button.edit')}
                                    </Link>
                                </div>
                                <div className='text-center'>
                                    <Button onClick={() => this.clickButtonDelete(data?.id)}
                                        className='ant-btn ant-btn-primary ant-btn-dangerous'
                                    >
                                        {t('button.delete')}
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                ),
            },
        ];
        return columns;
    };

    clickButtonDelete(orderId){
        const currentComponent = this;
        const { t } = this.props;
        Modal.confirm({
          title: `${t('confirm.removeOrder')}`,
          icon: <ExclamationCircleOutlined />,
          onOk() {
            currentComponent.props.handleDeleteOrder(orderId);
          },
          okType: 'danger',
          okText: `${t('button.ok')}`,
          cancelText: `${t('button.cancel')}`,
        });
    };

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            const { t } = this.props;
            return <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        this.handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type='primary'
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size='small'
                        style={{ width: 90 }}
                    >
                        {t('button.search')}
                    </Button>
                    <Button
                        onClick={() => this.handleReset(clearFilters)}
                        size='small'
                        style={{ width: 90 }}
                    >
                        {t('button.reset')}
                    </Button>
                </Space>
            </div>
        },
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: (text) =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    getSenderInfo(order) {
        let formattedAddress =
            this.getFormattedAddress(order?.addressSender) || this.props.t('text.noAddress');
        let senderInfo = [order.senderName, order.senderPhone, formattedAddress];
        return senderInfo.join('<br/>');
    }

    getReceiverInfo(order) {
        let formattedAddress =
            this.getFormattedAddress(order?.addressRecipient) || this.props.t('text.noAddress');
        let senderInfo = [
            order.receiverName,
            order.receiverPhone,
            formattedAddress,
        ];
        return senderInfo.join('<br/>');
    }

    getFormattedAddress(address) {
        let arr = [];
        if (address && address.street) {
            arr.push(address.street);
        }
        if (address && address.ward) {
            let ward = address.ward.prefix + ' ' + address.ward.name;
            arr.push(ward);
        }
        if (address && address.district) {
            let district = address.district.prefix + ' ' + address.district.name;
            arr.push(district);
        }
        if (address && address.city) {
            arr.push(address.city.name);
        }
        return arr.join(', ');
    }

    getRouteInfo(order) {
        let routeInfo = [order.route?.code, order.route?.routeName];
        return routeInfo.join('<br/>');
    }

    onChange(pagination, filters, sorter, extra) {
        console.log('params', pagination, filters, sorter, extra);
    }

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = (clearFilters) => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    getSpecialServiceIcon = (name) => {
        if (name === specialServices.fragile) {
            return <Image width={30} src={fragileIcon} />;
        } else if (name === specialServices.fastService) {
            return <Image width={30} src={fastIcon} />;
        }
        return <></>;
    };
}
