import _ from "lodash";

export class Utilities {
    
    static randomUuid() {
        var s = [];
        var hexDigits = "0123456789abcdef";
        for (var i = 0; i < 36; i++) {
          s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
        s[8] = s[13] = s[18] = s[23] = "-";
    
        var uuid = s.join("");
        return uuid;
    }

    static getFileType (path){
        var ext = /^.+\.([^.]+)$/.exec(path);
        return ext == null ? "" : ext[1];
    }

    static checkExistKeyInArray(list, compairBy) {
        if(!list || !compairBy) return false;
        return _.every(list, compairBy);
    }

    static mergeSameValueArray(list, compairBy) {
        if(!list) return [];
        if(!compairBy) return list;
        return _.unionBy(list, compairBy);
    }

    static keysToCamel(obj) {
        return _.mapKeys(obj, (v, k) => _.camelCase(k))
    }

    static isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    static randomValue (min, max)  {
        return Math.random() * (max - min) + min;
    };

    static isValidEmail (string) {
        const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!emailPattern.test(string)) {
            return false;
        }
        return true;
    }

    static isValidPhoneNumber (string) {
        const phonePattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
        if (!phonePattern.test(string)) {
            return false;
        }
        return true;
    }

    static isValidPassword (string) {
        const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})");
        if (!strongRegex.test(string)) {
            return false;
        }
        return true;
    }

    static getFileName(str) {
        if(!str) return '';
        return str.split('\\').pop().split('/').pop();
    }

    static formatAmount(value) {
        if (value) {
            return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
        return "";
    }
}