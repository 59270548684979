import React, { Component } from 'react';
import { Layout } from 'antd';
import { Card, Input } from 'antd';
import TableOrders from './table-orders';
import moment from 'moment';
import _ from 'lodash';
import TopBar from '../../components/topbar/index';
import './dashboard.screen.scss';
import { ChartStatisticComponent } from './chart-statistic-component/chart-statistic.component';
import languageService from '../../services/language.service';

const { Content } = Layout;
const { Search } = Input;

export class DashboardScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterBy: '',
        };
    }

    componentDidMount() {
        if (this.props.currentStationId) {
            this.props.listOrders(this.props.currentStationId);
        }
    }

    render() {
        const { t } = this.props;
        let orders = this.props.orders?.slice(0, 5).map(o => {
            return {
                id: o.id,
                orderId: o.code,
                from: this.getSenderInfo(o),
                to: this.getReceiverInfo(o),
                deliveryMethod: o.deliveryType?.languageKey,
                route: this.getRouteInfo(o),
                packageInfo: o.packageType?.name,
                createdDate: moment.utc(o.createdTime).locale(languageService.getLang()).format('hh:mm A DD/MM/YYYY'),
                status: t(o?.status.languageKey || o?.status.statusName),
                contract: o.contract?.code,
                orderTrackingCode: o.orderTrackingCode
            }
        });
        orders = _.sortBy(orders, 'createdTime');
        if (this.state.filterBy) {
            let value = this.state.filterBy?.toLowerCase();
            orders = orders.filter(o =>
                o.orderId?.toLowerCase().indexOf(value) >= 0 || o.from?.toLowerCase().indexOf(value) >= 0 || o.to?.toLowerCase().indexOf(value) >= 0
            );
        }

        return (
            <Layout className='bg-content beecow-dashboard'>
                <div className='bg-body'>
                    <TopBar title={t('title.dashboard')} />
                    <Content className='content'>
                        <Card className='mt-3'>
                            <ChartStatisticComponent />
                        </Card>
                        <Card className='mt-5'>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Search
                                    className='mr-3'
                                    placeholder={t('placeholder.searchDashboard')}
                                    allowClear
                                    enterButton={t('button.search')}
                                    size='large'
                                    onSearch={this.handleTextChanged} />
                            </div>
                        </Card>
                        <Card className='card-body mt-3'>
                            <TableOrders
                                t={t}
                                dataSource={orders} />
                        </Card>
                    </Content>
                </div>
            </Layout>
        );
    }

    handleTextChanged = (value) => {
        this.setState({
            filterBy: value,
        });
    }

    getSenderInfo = (order) => {
        const { t } = this.props;

        let formattedAddress = this.getFormattedAddress(order?.addressSender) || t('text.noAddress');
        let senderInfo = [order.senderName, order.senderPhone, formattedAddress];
        return senderInfo.join('<br/>');
    }

    getReceiverInfo = (order) => {
        const { t } = this.props;

        let formattedAddress = this.getFormattedAddress(order?.addressRecipient) || t('text.noAddress');
        let senderInfo = [order.receiverName, order.receiverPhone, formattedAddress];
        return senderInfo.join('<br/>');
    }

    getFormattedAddress = (address) => {
        let arr = [];
        if (address && address.street) {
            arr.push(address.street);
        }
        if (address && address.ward) {
            let ward = address.ward.prefix + ' ' + address.ward.name;
            arr.push(ward);
        }
        if (address && address.district) {
            let district = address.district.prefix + ' ' + address.district.name;
            arr.push(district);
        }
        if (address && address.city) {
            arr.push(address.city.name);
        }
        return arr.join(', ');
    }

    getRouteInfo = (order) => {
        let routeInfo = [order.route?.code, order.route?.routeName];
        return routeInfo.join('<br/>');
    }
}