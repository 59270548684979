import 'moment/locale/es';
import 'moment/locale/vi';
import i18n from "i18next";
import moment from "moment";


export function getDateTime(dateTimeUtc, formatPattern) {
    if (!formatPattern) {
        formatPattern = "yyyy-MM-dd HH:mm:ss";
    }
    
    let languageCode = i18n.language;
    let dateTimeResult = moment.utc(dateTimeUtc).locale(languageCode).local().format(formatPattern);
    return dateTimeResult;
}