import { DataService } from "../base/base-data.service";

class AddressService extends DataService {
    apiUrl = '/address'

    async getInitData() {
        return await this.get(`${this.apiUrl}/load-cities`);
    }

    async getWardsByDistrictId(districtId) {
        return await this.get(`${this.apiUrl}/get-wards-by-districtId?districtId=${districtId}`);
    }

    async getDistrictByCityId(cityId) {
        return await this.get(`${this.apiUrl}/get-district-by-cityId?cityId=${cityId}`);
    }

    async getOrderDetails(orderId) {
        return await this.get(`${this.apiUrl}/get-order-details?id=${orderId}`);
    }
}

let addressService = new AddressService();

export default addressService;