import { Table, Image } from 'antd';
import moment from 'moment';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';
import { getSpecialServiceIcon } from '../../../utils/order';
import { deliveryHouseMethods } from '../../../constants/order-constant';
import iconWareHouse from '../../../assets/icons/warehouse.png';

const unitWeight = 'Kg';

export class ReviewOrderPickupScreen extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.state = {
            selectedOrderList: props?.selectedOrderList,
            selectedVehicle: props?.selectedVehicle,
            selectedRowKeys: props?.selectedOrderList?.map(order => order.id),
        };
    }

    componentDidMount() { }
    
    getRecipientInfo = (order) => {
        let formattedAddress =
            this.getFormattedAddress(order?.addressRecipient) || this.props.t('text.noAddress');
        let receiverInfo = [
            order.receiverName,
            order.receiverPhone,
            formattedAddress,
        ];
        return receiverInfo.join('<br/>');
    };
    
    getFormattedAddress(address) {
        let arr = [];
        if (address && address.street) {
            arr.push(address.street);
        }
        if (address && address.ward) {
            let ward = address.ward.prefix + ' ' + address.ward.name;
            arr.push(ward);
        }
        if (address && address.district) {
            let district = address.district.prefix + ' ' + address.district.name;
            arr.push(district);
        }
        if (address && address.city) {
            arr.push(address.city.name);
        }
        return arr.join(', ');
    }

    getTableColumns(orders) {
        const { t } = this.props;
        let deliveries = orders?.map((d) => {
            return { text: d.deliveryMethod, value: d.deliveryMethod };
        });
        let deliveryOptions = deliveries?.filter(
            (d, i) => deliveries?.findIndex((o) => o.value === d.value) === i
        );
        let packages = orders?.map((d) => {
            return { text: d.packageInfo, value: d.packageInfo };
        });

        let packageInfoOptions = packages?.filter(
            (d, i) => packages?.findIndex((o) => o.value === d.value) === i
        );
        let columns = [
            {
                title: t('tableColumn.orderId'),
                dataIndex: 'orderId',
                fixed: 'left',
                render: (text, data) => (
                    <Link target='_blank' to={`/view-order/${data.id}`}>
                        {text}
                    </Link>
                ),
            },
            {
                className: 'w-270px',
                title: t('tableColumn.receiverAddress'),
                dataIndex: 'toRecipient',
                render: (text) => <span dangerouslySetInnerHTML={{ __html: text }} />,
            },
            {
                title: t('tableColumn.packageInfo'),
                dataIndex: 'packageInfo',
                render: (name) => (
                    <span>
                        {name}
                        {getSpecialServiceIcon(name)}
                    </span>
                ),
                filters: packageInfoOptions,
                onFilter: (value, record) => record.packageInfo.indexOf(value) === 0,
            },
            {
                title: t('tableColumn.deliveryMethod'),
                dataIndex: 'deliveryMethod',
                render: (text) => (
                    <span>
                        {text}
                        <br />
                        {deliveryHouseMethods.includes(text?.toLowerCase()) && (
                            <Image src={iconWareHouse} width={30} />
                        )}
                    </span>
                ),
                filters: deliveryOptions,
                onFilter: (value, record) => record.deliveryMethod.indexOf(value) === 0,
            },
            {
                title: t('tableColumn.totalWeight'),
                dataIndex: 'totalWeight',
                render: (text) => (
                    <span>
                        {text} {unitWeight}
                    </span>
                ),
            },
            {
                className: 'w-80px',
                title: t('tableColumn.totalProducts'),
                dataIndex: 'totalProductsInPackage',
            },
            {
                className: 'w-80px',
                title: t('tableColumn.totalItems'),
                dataIndex: 'totalItemsInPackage',
            },
            {
                title: t('tableColumn.createdDate'),
                dataIndex: 'createdDate',
                render: (text) => (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: moment
                                .utc(text)
                                .local()
                                .format('hh:mm A <br> DD/MM/YYYY'),
                        }}
                    />
                ),
            },
        ];
        return columns;
    }

    transform = (order) => {
        var result = {
            key: order.id,
            id: order.id,
            orderId: order.code,
            toRecipient: this.getRecipientInfo(order),
            deliveryMethod: order?.delivery?.name,
            deliveryMethodId: order?.delivery?.id,
            packageInfo: order?.specialOption?.name,
            packageInfoId: order?.specialOption?.id,
            createdDate: order.createdTime,
            amount: order.amount,
            packageSizeWeight: order.packageSizeWeight,
            totalWeight: order.totalWeight,
            totalProductsInPackage: order.totalProductsInPackage,
            totalItemsInPackage: order.totalItemsInPackage,
            isChecked: order.isChecked,
        };
        if (order?.status) {
            if (order.status.subStatusName != null) {
                result.status = `${order.status.statusName}-${order.status.subStatusName}`;
            } else {
                result.status = order.status.statusName;
            }
        }

        return result;
    };

    onChange(pagination, filters, sorter, extra) {
        console.log('params', pagination, filters, sorter, extra);
    }

    render() {
        const { t } = this.props;
        const { selectedOrderList, selectedVehicle } = this.state;
        let columns = this.getTableColumns(selectedOrderList);
        return (
            <Content className='content'>
                <div className='list-pickup'>
                    <div className='review-vehicle'>
                        <p>
                            <b>{t('text.selectedVehicle')}: </b>
                            {selectedVehicle?.code || ''}
                        </p>
                        <p>
                            <b>Vehicle Type: </b>
                            {selectedVehicle?.type?.name || ''}
                        </p>
                    </div>
                    <Table
                        bordered
                        columns={columns}
                        dataSource={selectedOrderList}
                        onchange={this.onChange}
                    />
                </div>
            </Content>
        );
    }
}
