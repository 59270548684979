import React, { Component } from 'react'
import Layout from 'antd/lib/layout/layout';
import { Button, message, Steps } from 'antd';
import TopBar from '../../components/topbar';

import ShippingInfoComponent from './shipping-info/shipping-info.component'
import ProductInfoComponent from './product-info/product-info.component'
import DeliveryComponent from './delivery/delivery.component'
import PaymentMethodComponent from './payment/payment.component'
import SummaryComponent from './summarize/summarize.component'

import { DeliveryConstants } from './../../constants/delivery.constants'
import { createOrderTypes } from './../../constants/order-constant'
import orderDataService from './../../data-services/orders/orders-data.service';
import userDataService from '../../data-services/user/user-data.service';

const { Step } = Steps;
export class CreateOrderScreen extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            title: 'title.createOrder',
            current: 0,
            productCategories: [],
            deliveryOptions: [],
            shippingServices: [],
            locations: [],
            paymentTypes: [],
            paymentByOptions: [],
            stationInfo: null,
            initialData: {
                shippingInfo: {
                    senderInfo: {
                        fullName: null,
                        phoneNumber: null,
                        address: {
                            street: null,
                            city: {
                                id: null,
                                name: null
                            },
                            district: {
                                id: null,
                                name: null
                            },
                            ward: {
                                id: null,
                                name: null
                            }
                        },
                    },
                    receiverInfo: {
                        fullName: null,
                        phoneNumber: null,
                        address: {
                            street: null,
                            city: {
                                id: null,
                                name: null
                            },
                            district: {
                                id: null,
                                name: null
                            },
                            ward: {
                                id: null,
                                name: null
                            }
                        },
                    },
                },
                productInfo: {
                    totalProduduct: null,
                    description: null,
                    products: []
                },
                deliveryInfo: {
                    shippingService: {
                        id: null,
                        name: null,
                    },
                    deliveryOption: {
                        id: null,
                        name: null,
                    },
                    route: {
                        id: null,
                        name: null,
                        code: null,
                        provider: null
                    }
                },
                paymentInfo: {
                    payBy: null,
                    paymentType: null,
                    isCod: null,
                    codAmount: null,
                }
            },
        }

        this.shippingInfoForm = React.createRef();
        this.productInfoForm = React.createRef();
        this.deliveryInfoForm = React.createRef();
        this.paymentInfoForm = React.createRef();
    }

    async componentDidMount() {
        orderDataService.getInitData(DeliveryConstants.DELIVERY_STAFF).then((res) => {
            var productCategories = res.packageCategory?.map((c) => {
                return {
                    id: c.id,
                    name: c.name,
                    isNew: false,
                    languageKey: c.languageKey
                }
            });

            this.setState({
                ...this.state,
                productCategories: productCategories,
                deliveryOptions: res.deliveries,
                shippingServices: res.specialServices,
                locations: res.locations,
                paymentByOptions: res.paymentBy,
                paymentTypes: res.paymentTypes
            }, () => {
                userDataService.getUserInfoById(this.props.userId).then((res) => {
                    this.getSenderInfo(res);
                    this.setState({
                        stationInfo: {
                            id: res?.stationIds[0],
                        }
                    });
                });

                this.refresh();
            });
        });
    };

    getSenderInfo = (res) => {
        var senderInfo = {
          fullName: res.fullName,
          phoneNumber: res.phone,
          address: res.address,
        };
    
        var { initialData } = this.state;
        var { shippingInfo } = initialData;
        shippingInfo.senderInfo = senderInfo;
    
        this.setState({
          initialData: {
            shippingInfo: shippingInfo,
          },
        });
    
        this.shippingInfoForm.current.setFieldsValue(shippingInfo);
    };

    render() {
        const { t } = this.props;
        const { current } = this.state;
        const steps = this.createSteps();
        return (
            <Layout className='bg-content'>
                <div className='bg-body'>
                    <TopBar title={t(this.state.title)} />
                    <div className='bg-white clearfix beecow-leasing-contract ml-4'>
                        <Steps
                            type='navigation'
                            current={current}
                            className='site-navigation-steps'
                        >
                            {
                                steps.map(item => <Step key={item.title} title={item.title} />)
                            }
                        </Steps>
                        <div className='steps-content mt-5'>{steps[current].content}</div>
                        <div className='steps-action float-right mr-5'>
                            {current > 0 && (
                                <a className='pr-5' onClick={this.prev}>
                                    {t('button.back')}
                                </a>
                            )}
                            {current < steps.length - 1 && (
                                <Button type='primary' onClick={this.next}>
                                    {t('button.next')}
                                </Button>
                            )}
                            {current === steps.length - 1 && (
                                <Button type='primary' onClick={this.submit}>
                                    {t('button.done')}
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }

    createSteps = () => {
        const { t } = this.props;
        var { initialData, deliveryOptions, shippingServices, productCategories, paymentByOptions, locations, paymentTypes, stationInfo } = this.state;
        var { shippingInfo, productInfo, deliveryInfo, paymentInfo } = initialData;

        const steps = [
            {
                title: t('order.shippingInformation'),
                content: <ShippingInfoComponent
                    ref={this.shippingInfoForm}
                    t={t}
                    initialData={shippingInfo} />,
            },
            {
                title: t('order.productInformation'),
                content: <ProductInfoComponent
                    ref={this.productInfoForm}
                    t={t}
                    initialData={productInfo}
                    productCategories={productCategories} />,
            },
            {
                title: t('order.deliveryMethod'),
                content: <DeliveryComponent
                    ref={this.deliveryInfoForm}
                    t={t}
                    initialData={deliveryInfo}
                    deliveryOptions={deliveryOptions}
                    stationInfo={stationInfo}
                    shippingServices={shippingServices}
                    shippingInfo={shippingInfo}
                    productInfo={productInfo}
                    locations={locations}
                />,
            },
            {
                title: t('order.paymentMethod'),
                content: <PaymentMethodComponent
                    ref={this.paymentInfoForm}
                    t={t}
                    initialData={paymentInfo}
                    paymentByOptions={paymentByOptions}
                    paymentTypes={paymentTypes}
                    deliveryInfo={deliveryInfo}
                    shippingInfo={shippingInfo}
                />,
            },
            {
                title: t('order.summarize'),
                content: <SummaryComponent
                    t={t}
                    stationInfo={stationInfo}
                    shippingInfo={shippingInfo}
                    productInfo={productInfo}
                    deliveryInfo={deliveryInfo}
                    paymentInfo={paymentInfo}
                    deliveryOptions={deliveryOptions}
                    shippingServices={shippingServices}
                    productCategories={productCategories}
                />,
            },
        ];

        return steps;
    }

    next = () => {
        const { t } = this.props;
        var currentFormRef;
        switch (this.state.current) {
            case 0:
                currentFormRef = this.shippingInfoForm;
                this.setState({
                    ...this.state,
                    initialData: {
                        ...this.state.initialData,
                        shippingInfo: currentFormRef.current.getFieldsValue()
                    }
                });
                break;
            case 1:
                currentFormRef = this.productInfoForm;
                this.setState({
                    ...this.state,
                    initialData: {
                        ...this.state.initialData,
                        productInfo: currentFormRef.current.getFieldsValue()
                    }
                });
                break;
            case 2:
                currentFormRef = this.deliveryInfoForm;
                this.setState({
                    ...this.state,
                    initialData: {
                        ...this.state.initialData,
                        deliveryInfo: currentFormRef.current.getFieldsValue()
                    }
                });
                break;
            case 3:
                currentFormRef = this.paymentInfoForm;
                this.setState({
                    ...this.state,
                    initialData: {
                        ...this.state.initialData,
                        paymentInfo: currentFormRef.current.getFieldsValue()
                    }
                });
                break;
            case 4:
                break;
        }

        if (currentFormRef && currentFormRef.current) {
            currentFormRef.current.validateFields()
                .then(() => {
                    if(this.state.current == 1 && currentFormRef.current.props.initialData?.products?.length == 0){
                        message.warning(t('messages.warningSelectProduct'));
                        return;
                    }
                    let nextStep = this.state.current + 1;
                    if (nextStep < 5) {
                        this.setState({ current: nextStep }, () => {
                            this.refresh();
                        });
                    }
                })
                .catch(ex => console.log(ex));
        }
    }

    prev = () => {
        this.setState({
            current: this.state.current - 1
        }, () => {
            this.refresh();
        });
    }

    refresh = () => {
        switch (this.state.current) {
            case 0:
                this.shippingInfoForm.current.setFieldsValue(this.state.initialData.shippingInfo);
                break;
            case 1:
                this.productInfoForm.current.setFieldsValue(this.state.initialData.productInfo);
                break;
            case 2:
                //this.deliveryInfoForm.current.setFieldsValue(this.state.initialData.deliveryInfo);
                break;
            case 3:
                this.paymentInfoForm.current.setFieldsValue(this.state.initialData.deliveryInfo);
                break;
            default:
                break;
        }
    }

    submit = () => {
        const { t } = this.props;
        var { initialData, stationInfo } = this.state;
        var createOrderRequest = {
            senderInfo: initialData.shippingInfo.senderInfo,
            receiverInfo: initialData.shippingInfo.receiverInfo,
            productInfo: initialData.productInfo,
            deliveryInfo: {
                shippingServiceId: initialData.deliveryInfo.shippingService.id,
                deliveryTypeId: initialData.deliveryInfo.deliveryOption.id,
                route: {
                    routeId: initialData.deliveryInfo.route.id,
                    companyId: initialData.deliveryInfo.route.company?.id,
                    shippingFeeDetails: initialData.deliveryInfo.route.shippingFeeDetails,
                },
            },
            paymentInfo: {
                payById: initialData.paymentInfo.payBy.id,
                paymentTypeId: initialData.paymentInfo.paymentType.id,
                isCod: initialData.paymentInfo.isCod,
                codAmount: initialData.paymentInfo.codAmount,
            },
            createOrderType: createOrderTypes.STATION,
            toStationId: this.state.initialData?.deliveryInfo?.toStationId,
            fromStationId: stationInfo.id,
            createdByUserId: this.props.userId
        }

        orderDataService.createOrder(createOrderRequest).then(res => {
            if (res.success) {
                message.success(t('messages.createOrderSuccess'));
                this.props.history.push(`/view-order/${res.data?.id}`)
            }
            else {
                message.error(t('messages.createOrderFailed'));
            }
        });
    }
}
