import { notification } from "antd";
import moment from "moment";
import languageService from "../services/language.service";

export const leftMenu = [];
export const FORMAT_DATE = "DD/MM/YYYY";
export const FORMAT_YEAR_MONTH = "MM/YYYY";
export const FORMAT_DATETIME = "DD/MM/YYYY hh:mm A";
export const FORMAT_TIME_DATE = "hh:mm A DD/MM/YYYY";

export const EStatusContractLeasing = {
    Active: {
        id: 1,
        name: "Active",
        color: "#52c41a"
    },
    WaitingForVerify: {
        id: 2,
        name: " Waiting for Verify",
        color: "#faad14"
    },
    Renewing: {
        id: 3,
        name: "Renewing",
        color: "#52c41a"
    },
    Expired: {
        id: 4,
        name: "Expired",
        color: "#ff4d4f"
    }
}
export const EStatusProductLeasing = {
    WaitingForImport: {
        id: 1,
        name: "Waiting for Import",
        color: "#faad14"
    },
    OutOfStock: {
        id: 2,
        name: "Out of Stock",
        color: "#ff4d4f"
    },
    ReadyToSell: {
        id: 3,
        name: "Ready to Sell",
        color: "#52c41a"
    }
}

export const alertType = {
    success: 'success',
    warning: 'warning',
    error: 'error',
};

export const alert = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
}

export const showAlert = (message, description, isActive) => {
    alert(
        isActive ? alertType.success : alertType.warning,
        message,
        description
    );
};

export const formatDate = (date, format) => {
    if (format) {
        return moment.utc(date).local()
            .locale(languageService.getLang())
            .format(format);
    }
    return moment.utc(date).local()
        .locale(languageService.getLang())
        .format(FORMAT_DATETIME);
}
