import React, { Component } from 'react';
import Layout from 'antd/lib/layout/layout';
import { Button, message, Steps } from 'antd';
import TopBar from '../../components/topbar';

import ShippingInfoComponent from './shipping-info/shipping-info.component';
import ProductInfoComponent from './product-info/product-info.component';
import DeliveryComponent from './delivery/delivery.component';
import PaymentMethodComponent from './payment/payment.component';
import SummaryComponent from './summarize/summarize.component';

import { DeliveryConstants } from '../../constants/delivery.constants';
import { createOrderTypes } from '../../constants/order-constant';
import orderDataService from '../../data-services/orders/orders-data.service';

const { Step } = Steps;
export class EditOrderScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            title: 'title.editOrder',
            current: 0,
            productCategories: [],
            deliveryOptions: [],
            shippingServices: [],
            locations: [],
            paymentTypes: [],
            paymentByOptions: [],
            stationInfo: null,
            initialData: null,
            originalProducts: [],
            orderCode: null,
        };

        this.shippingInfoForm = React.createRef();
        this.productInfoForm = React.createRef();
        this.deliveryInfoForm = React.createRef();
        this.paymentInfoForm = React.createRef();
    }

    componentDidMount() {
        const { id } = this.props?.match?.params || {};
        if (!id) {
            this.props.history.push('/page-not-found');
            return;
        }
        orderDataService.getOrderDetails(id).then((result) => {
            let orderInfo = result.order;
            let data = this.buildOrderInfoFromJsonObject(orderInfo);
            let originalProducts = orderInfo?.orderItems?.map((anItem) =>
                this.buildProductInfo(anItem)
            );
            orderDataService
                .getInitData(DeliveryConstants.DELIVERY_STAFF)
                .then((res) => {
                    var productCategories = res.packageCategory?.map((c) => {
                        return {
                            id: c.id,
                            name: c.name,
                            isNew: false,
                        };
                    });

                    this.setState({
                        ...this.state,
                        initialData: data,
                        orderCode: data?.orderCode,
                        originalProducts: originalProducts,
                        stationInfo: { id: orderInfo?.station?.id },
                        productCategories: productCategories,
                        deliveryOptions: res.deliveries,
                        shippingServices: res.specialServices,
                        locations: res.locations,
                        paymentByOptions: res.paymentBy,
                        paymentTypes: res.paymentTypes,
                    });
                });
        });
    }

    buildProductInfo = (anItem) => {
        let product = {
            ...anItem.product,
            amount: anItem.amount,
            categoryId: anItem?.product?.packageType?.id,
        };

        return product;
    };

    buildOrderInfoFromJsonObject = (order) => {
        let senderAddress = order?.addressSender;
        let receiverAddress = order?.addressRecipient;
        let originalProducts = order?.orderItems?.map((anItem) =>
            this.buildProductInfo(anItem)
        );
        let data = {
            orderId: order?.id,
            orderCode: order?.code,
            shippingInfo: {
                senderInfo: {
                    fullName: order?.senderName,
                    phoneNumber: order?.senderPhone,
                    address: {
                        street: senderAddress?.street,
                        city: {
                            id: senderAddress?.city?.id,
                            name: senderAddress?.city?.name,
                        },
                        district: {
                            id: senderAddress?.district?.id,
                            name: senderAddress?.district?.name,
                        },
                        ward: {
                            id: senderAddress?.ward?.id,
                            name: senderAddress?.ward?.id,
                        },
                    },
                },
                receiverInfo: {
                    fullName: order?.receiverName,
                    phoneNumber: order?.receiverPhone,
                    address: {
                        street: receiverAddress?.street,
                        city: {
                            id: receiverAddress?.city?.id,
                            name: receiverAddress?.city?.name,
                        },
                        district: {
                            id: receiverAddress?.district?.id,
                            name: receiverAddress?.district?.name,
                        },
                        ward: {
                            id: receiverAddress?.ward?.id,
                            name: receiverAddress?.ward?.name,
                        },
                    },
                },
            },
            productInfo: {
                totalProducts: order?.orderItems?.length,
                description: order?.descriptions,
                products: originalProducts,
            },
            deliveryInfo: {
                shippingService: {
                    id: order?.specialOption?.id,
                    name: order?.specialOption?.name,
                },
                deliveryOption: {
                    id: order?.delivery?.id,
                    name: order?.delivery?.name,
                },
                route: {
                    id: order?.route?.id,
                    name: order?.route?.routeName,
                    code: order?.route?.code,
                    provider: order?.route?.companyID,
                },
                toStation: order?.toStation
            },
            paymentInfo: {
                payBy: { id: order?.payByIdType, name: order?.payBy },
                paymentType: order?.paymentType,
                isCod: order?.isCod,
                codAmount: order?.codamount,
            },
            deliveryList: order?.deliveryList,
        };
        return data;
    };

    render() {
        const { t } = this.props;
        const { current, initialData, orderCode } = this.state;

        if (initialData === null || initialData === undefined) {
            return <></>;
        } else {
            const steps = this.createSteps();
            return (
                <Layout className='bg-content'>
                    <div className='bg-body'>
                        <TopBar title={t(this.state.title)} />
                        <div className='bg-white clearfix beecow-leasing-contract'>
                            <div className='col-md-12'>
                                <span className='pull-right font-weight-bold'>
                                    {t('tableColumn.orderId')}: {orderCode}
                                </span>
                            </div>
                            <Steps
                                type='navigation'
                                current={current}
                                className='site-navigation-steps'
                            >
                                {steps.map((item) => (
                                    <Step key={item.title} title={item.title} />
                                ))}
                            </Steps>
                            <div className='steps-content mt-5'>{steps[current].content}</div>
                            <div className='steps-action float-right mr-5'>
                                {current > 0 && (
                                    <a className='pr-5' onClick={this.prev.bind(this)}>
                                        {t('button.back')}
                                    </a>
                                )}
                                {current < steps.length - 1 && (
                                    <Button type='primary' onClick={this.next.bind(this)}>
                                        {t('button.next')}
                                    </Button>
                                )}
                                {current === steps.length - 1 && (
                                    <Button type='primary' onClick={this.submit}>
                                        {t('button.done')}
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                </Layout>
            );
        }
    }

    createSteps = () => {
        const { t } = this.props;
        var {
            initialData,
            shippingServices,
            productCategories,
            paymentByOptions,
            locations,
            paymentTypes,
            stationInfo,
        } = this.state;
        var { shippingInfo, productInfo, deliveryInfo, paymentInfo, deliveryList } =
            initialData;

        const steps = [
            {
                title: t('order.shippingInformation'),
                content: (
                    <ShippingInfoComponent
                        t={t}
                        ref={this.shippingInfoForm}
                        initialData={shippingInfo}
                    />
                ),
            },
            {
                title: t('order.productInformation'),
                content: (
                    <ProductInfoComponent
                        t={t}
                        ref={this.productInfoForm}
                        initialData={productInfo}
                        productCategories={productCategories}
                    />
                ),
            },
            {
                title: t('order.deliveryMethod'),
                content: (
                    <DeliveryComponent
                        t={t}
                        ref={this.deliveryInfoForm}
                        initialData={deliveryInfo}
                        deliveryOptions={deliveryList}
                        stationInfo={stationInfo}
                        shippingServices={shippingServices}
                        shippingInfo={shippingInfo}
                        productInfo={productInfo}
                        locations={locations}
                        onUpdateMoreDeliveryInfo={this.onUpdateMoreDeliveryInfo.bind(this)}
                    />
                ),
            },
            {
                title: t('order.paymentMethod'),
                content: (
                    <PaymentMethodComponent
                        t={t}
                        ref={this.paymentInfoForm}
                        initialData={paymentInfo}
                        paymentByOptions={paymentByOptions}
                        paymentTypes={paymentTypes}
                        deliveryInfo={deliveryInfo}
                        shippingInfo={shippingInfo}
                        onUpdatePaymentMethod={this.onUpdatePaymentMethod.bind(this)}
                    />
                ),
            },
            {
                title: t('order.summarize'),
                content: (
                    <SummaryComponent
                        t={t}
                        stationInfo={stationInfo}
                        shippingInfo={shippingInfo}
                        productInfo={productInfo}
                        deliveryInfo={deliveryInfo}
                        paymentInfo={paymentInfo}
                        deliveryOptions={deliveryList}
                        shippingServices={shippingServices}
                        productCategories={productCategories}
                    />
                ),
            },
        ];

        return steps;
    };

    onUpdatePaymentMethod = (data) => {
        var { paymentByOptions } = this.state;

        let paymentType = paymentByOptions?.find(
            (item) => item.id === data.payByIdType
        );

        let deliveryInfo = {
            ...this.state.initialData.deliveryInfo,
        };

        if (paymentType) {
            deliveryInfo.payById = paymentType.id;
            if (paymentType.id === 0) {
                deliveryInfo.codAmount = 0;
                deliveryInfo.isCod = false;
            } else if (paymentType.id === 1) {
                deliveryInfo.codAmount = data.codAmount;
                deliveryInfo.isCod = data.isCod;
            }
        }

        let initialData = {
            ...this.state.initialData,
            deliveryInfo,
        };

        this.setState({
            initialData: initialData,
        });
    };

    onUpdateMoreDeliveryInfo(data) {
        if (data) {
            let deliveryInfo = {
                ...this.state.initialData.deliveryInfo,
            };
            deliveryInfo.selectedLocation = data.selectedLocation;
            deliveryInfo.selectedStation = data.selectedStation;

            let initialData = {
                ...this.state.initialData,
                deliveryInfo,
            };

            this.setState({
                initialData: initialData,
            });
        }
    }

    next() {
        const { t } = this.props;
        var currentFormRef;
        switch (this.state.current) {
            case 0:
                currentFormRef = this.shippingInfoForm;
                this.setState({
                    ...this.state,
                    initialData: {
                        ...this.state.initialData,
                        shippingInfo: currentFormRef.current.getFieldsValue(),
                    },
                });
                break;
            case 1:
                currentFormRef = this.productInfoForm;
                this.setState({
                    ...this.state,
                    initialData: {
                        ...this.state.initialData,
                        productInfo: currentFormRef.current.getFieldsValue(),
                    },
                });
                break;
            case 2:
                currentFormRef = this.deliveryInfoForm;
                let currentDeliveryInfo = this.state.initialData.deliveryInfo;
                let deliveryInfo = currentFormRef.current.getFieldsValue();
                deliveryInfo.payById = currentDeliveryInfo?.payById;
                deliveryInfo.codAmount = currentDeliveryInfo?.codAmount;
                deliveryInfo.isCod = currentDeliveryInfo?.isCod;
                deliveryInfo.selectedLocation = currentDeliveryInfo?.selectedLocation;
                deliveryInfo.selectedStation = currentDeliveryInfo?.selectedStation;
                this.setState({
                    ...this.state,
                    initialData: {
                        ...this.state.initialData,
                        deliveryInfo: deliveryInfo,
                    },
                });
                break;
            case 3:
                currentFormRef = this.paymentInfoForm;
                this.setState({
                    ...this.state,
                    initialData: {
                        ...this.state.initialData,
                        paymentInfo: currentFormRef.current.getFieldsValue(),
                    },
                });
                break;
            case 4:
                break;
            default:
                break;
        }

        if (currentFormRef && currentFormRef.current) {
            currentFormRef.current
                .validateFields()
                .then(() => {
                    if (
                        this.state.current == 1 &&
                        currentFormRef.current.props.initialData?.products?.length == 0
                    ) {
                        message.warning(t('messages.warningSelectProduct'));
                        return;
                    }
                    let nextStep = this.state.current + 1;
                    if (nextStep < 5) {
                        this.setState({ current: nextStep }, () => {
                            this.refresh();
                        });
                    }
                })
                .catch((ex) => console.log(ex));
        }
    }

    prev() {
        this.setState(
            {
                current: this.state.current - 1,
            },
            () => {
                this.refresh();
            }
        );
    }

    refresh() {
        switch (this.state.current) {
            case 0:
                this.shippingInfoForm.current.setFieldsValue(
                    this.state.initialData.shippingInfo
                );
                break;
            case 1:
                this.productInfoForm.current.setFieldsValue(
                    this.state.initialData.productInfo
                );
                break;
            case 2:
                //this.deliveryInfoForm.current.setFieldsValue(this.state.initialData.deliveryInfo);
                break;
            case 3:
                this.paymentInfoForm.current.setFieldsValue(
                    this.state.initialData.deliveryInfo
                );
                break;
            default:
                break;
        }
    }

    submit = () => {
        const { t } = this.props;
        var { initialData, stationInfo, originalProducts } = this.state;

        const productsToUpdate = initialData?.productInfo;
        const productsWillBeDeleted = [];
        for (let index = 0; index < originalProducts?.length; index++) {
            let aProduct = originalProducts[index];
            if (aProduct) {
                let isExist =
                    productsToUpdate?.products?.filter((item) => item.id === aProduct.id)
                        ?.length > 0;
                if (!isExist) {
                    aProduct.deleted = true;
                    productsWillBeDeleted?.push(aProduct);
                }
            }
        }

        var editOrderRequest = {
            orderId: initialData.orderId,
            senderInfo: initialData.shippingInfo.senderInfo,
            receiverInfo: initialData.shippingInfo.receiverInfo,
            productsWillBeDeleted,
            productInfo: productsToUpdate,
            deliveryInfo: {
                shippingServiceId: initialData.deliveryInfo.shippingService.id,
                deliveryId: initialData.deliveryInfo.deliveryOption.id,
                route: {
                    routeId: initialData.deliveryInfo.route.id,
                    companyId: initialData.deliveryInfo.route.company?.id,
                    shippingFeeDetails: initialData.deliveryInfo.route.shippingFeeDetails,
                },
            },
            paymentInfo: {
                payById: initialData.paymentInfo.payBy.id,
                paymentTypeId: initialData.paymentInfo.paymentType.id,
                isCod: initialData.paymentInfo.isCod,
                codAmount: initialData.paymentInfo.codAmount,
            },
            updateOrderFrom: createOrderTypes.STATION,
            toStationId: this.state.initialData?.deliveryInfo?.toStationId,
            fromStationId: stationInfo.id,
            editedByUserId: this.props.userId,
        };

        orderDataService.updateOrder(editOrderRequest).then((res) => {
            if (res.success) {
                message.success(t('messages.editOrderSuccess'));
                this.props.history.push(`/view-order/${res?.data?.id}`);
            } else {
                message.error(t('messages.updateOrderFailed'));
            }
        });
    };
}
