import actionTypes from "./address.types";

const ordersInitialState = {
    cities: [],
    districts: [],
    wards: []
};

const ordersReducer = (state = ordersInitialState, action) => {
    switch (action.type) {
        case actionTypes.GET_CITIES:
            return { ...state, cities: action.payload };
        case actionTypes.GET_DISTRICT_BY_CITYID:
            return { ...state, districts: action.payload };
        case actionTypes.GET_WARDS_BY_DISTRICTID:
            return { ...state, wards: action.payload };
        default:
            return state;
    }
};

export default ordersReducer;