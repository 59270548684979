import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { DoubleRightOutlined, DoubleLeftOutlined  } from '@ant-design/icons';

import 'bootstrap/dist/css/bootstrap.min.css';
import './menu.component.scss';

const { Sider } = Layout;
const { SubMenu } = Menu;

export class BeecowMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
            current: 'app.dashboard',
        };
    }

    render() {
        const { listMenu, signedInUser } = this.props;
        const { collapsed } = this.state;

        var selectedItem = listMenu?.filter(i => i.path === this.props?.location?.pathname && i.isMenu == true)[0];
        var pathName = this.props?.location?.pathname;
        selectedItem = selectedItem ? selectedItem : this.focusToSelected(selectedItem, pathName, listMenu);

        const selectedKey = selectedItem ? selectedItem.key : this.state.current;
        const menu = listMenu?.filter(i => !i.parent && i.key && i.isMenu);

        if (!signedInUser) {
            return <></>
        }

        return (
            <Sider
                style={{
                    overflowY:'auto',
                    overflowX: 'hidden',
                    height: '100vh',
                    position: 'sticky',
                    top: 0,
                    left: 0
                }}
                className='beecow-sider-menu no-print scrollbar-warning'
                collapsible
                collapsed={collapsed}
                onCollapse={() => this.toggle()}
            >
                <div className={'beecow-toggle-btn ' + (!collapsed ? 'beecow-flex-right mt-1' : 'beecow-flex-center mt-4 mb-4')} onClick={this.toggle}>
                    {this.state.collapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined className='mt-0 mr-1' />}
                </div>
                <Menu
                    mode='inline' theme='light' selectedKeys={[selectedKey]} onClick={this.handleClick}>
                    {!collapsed &&
                        <div className='beecow-logo mb-4 mt-3'>
                        <div className='logo'>
                            <div className='suffix d-flex justify-content-end'>
                               <span className='mb-1'>STATION</span>
                            </div>
                            <div className='name d-flex justify-content-center'>
                                <h1>BEECOW</h1>
                            </div>
                            </div>
                        </div>
                    }
                    {this.renderMenu(menu)}
                </Menu>
            </Sider>
        );
    }

    renderMenu(menu) {
        const { listMenu } = this.props;
        return menu?.map((item) =>
            item.abstract ?
                this.renderSubMenu(item, listMenu?.filter(i => i.parent === item.key)) :
                this.renderMenuItem(item)
        );
    }

    renderSubMenu(item, menu) {
        const { t } = this.props;
        return (
            <SubMenu
                key={item.key}
                icon={<><span className='ant-menu-item-icon'><i className={item.icon}></i></span></>}
                title={t(item.name)}>
                {this.renderMenu(menu)}
            </SubMenu>
        );
    }

    renderMenuItem(item) {
        const { t } = this.props;
        return (
            <Menu.Item
                key={item.key}
                icon={item.icon && <><span className='ant-menu-item-icon'><i className={item.icon}></i></span></>}>
                {item.path && <Link to={item.path} />}
                {t(item.name)}
            </Menu.Item>
        );
    }

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed
        });
    };

    handleClick = e => {
        this.setState({
            current: e.key,
        });
    };

    focusToSelected = (selectedItem, pathName, listMenu) => {
        if (pathName.includes('/view-order/'))
            selectedItem = listMenu?.filter(i => i.path === '/list-orders')[0];

        else if (pathName.includes('/view-pickup-list/'))
            selectedItem = listMenu?.filter(i => i.path === '/list-pickup')[0];

        else if (pathName.includes('/packages/view/'))
            selectedItem = listMenu?.filter(i => i.path === '/list-order-import')[0];

        else if (pathName.includes('/view-export-list/'))
            selectedItem = listMenu?.filter(i => i.path === '/list-order-export')[0];

        else if (pathName.includes('/view-import-from-vehicle/'))
            selectedItem = listMenu?.filter(i => i.path === '/list-import-from-vehicle')[0];

        else if (pathName.includes('/view-delivery-list-detail/'))
            selectedItem = listMenu?.filter(i => i.path === '/view-delivery-list')[0];

        else if (pathName.includes('/view-order-history/'))
            selectedItem = listMenu?.filter(i => i.path === '/view-order-history')[0];

        else if (pathName.includes('/view-lost-order-details/'))
            selectedItem = listMenu?.find(i => i.key === 'app.lost-order-list');

        return selectedItem;
    }
}