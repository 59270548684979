import React, { Component } from 'react'
import { Spin } from 'antd';
import './loading.component.scss'

export class LoadingComponent extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { loading } = this.props;
        return (
            <>
            {
                loading &&
                <div className="div-loader">
                    <div className="loader-item">
                        <Spin tip="Loading..."></Spin>
                    </div>
                </div>
            }
           </>
        )
    }
}
