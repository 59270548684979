import _ from 'lodash';
import moment from 'moment';
import { Layout } from 'antd';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import TopBar from '../../components/topbar';
import { Content } from 'antd/lib/layout/layout';
import { Form, Table, Button, Space } from 'antd';
import { getDateTime } from '../../utils/datetime';
import importDataService from '../../data-services/import/import-data.service';

export default class ListImportFromVehicleScreen extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataSource: [],
            paging: {
                pageSize: 20,
            }
        }
    }

    componentDidMount() {
        const { currentStationId } = this.props
        if (currentStationId) {
            importDataService
                .getImportListFromVehicle(currentStationId)
                .then((res) => {
                    const listImport = res.orderImportFromVehicle?.map((i, index) => this.transform(i, index));
                    // Update the state of the react.
                    this.setState({
                        dataSource: listImport,
                    });
                });
        }
    }

    getTableColumns(i) {
        const { t } = this.props;
        let routes = i?.map(d => { return { text: d.route, value: d.route } });
        let routeOptions = routes?.filter((d, i) => routes?.findIndex(o => o.value === d.value) === i);
        let columns = [
            {
                title: t('tableColumn.no'),
                dataIndex: 'no',
            },
            {
                title: t('tableColumn.importId'),
                dataIndex: 'importCode',
                render: (text, data) => <Link target='_blank' to={`/view-import-from-vehicle/${data?.id}`}>{text}</Link>,
                sorter: (a, b) => a.importCode.localeCompare(b.importCode)
            },
            {
                title: t('tableColumn.route'),
                dataIndex: 'route',
                sorter: (a, b) => a.route.localeCompare(b.route),
                filters: routeOptions,
                onFilter: (value, record) => record.route.indexOf(value) === 0,
            },
            {
                title: t('tableColumn.vehicle'),
                dataIndex: 'vehicleCode',
                sorter: (a, b) => a.vehicleCode.localeCompare(b.vehicleCode)
            },
            {
                title: t('tableColumn.orders'),
                dataIndex: 'getCountOrders',
                sorter: (a, b) => a.getCountOrders - b.getCountOrders
            },
            {
                title: t('tableColumn.products'),
                dataIndex: 'getCountProducts',
                sorter: (a, b) => a.getCountProducts - b.getCountProducts
            },
            {
                title: t('tableColumn.items'),
                dataIndex: 'getCountItems',
                sorter: (a, b) => a.getCountItems - b.getCountItems
            },
            {
                title: t('tableColumn.status'),
                dataIndex: 'status',
                sorter: (a, b) => a.status.localeCompare(b.status)
            },
            {
                title: t('tableColumn.createdDate'),
                dataIndex: 'createdTime',
                sorter: (a, b) => moment(a.createdTime) - moment(b.createdTime),
                render: text => <div dangerouslySetInnerHTML={{
                    __html: getDateTime(text, 'hh:mm A <br> DD/MM/YYYY')
                }} />
            },
            {
                title: t('tableColumn.action'),
                dataIndex: 'action',
                render: (_, record) => {
                    return <>
                        <Space>
                            <Button
                                type='link'
                                className='pl-0 pr-0 mr-2'
                                onClick={() => this.redirectTo(`/edit-import-from-vehicle/${record?.id}`)}
                            ><i className='fa fa-pencil-square-o beecow-icon beecow-icon-edit-color' aria-hidden='true'></i></Button>

                            <Button style={{ display: 'none' }} type='link' className='pl-0 pr-0'><i className='fa fa-trash-o beecow-icon beecow-icon-delete-color' aria-hidden='true'></i></Button>
                        </Space>

                    </>
                }
            }
        ];
        return columns;
    }

    transform = (i, index) => {
        const { t } = this.props;
        var result = {
            no: index + 1,
            id: i?.id,
            importCode: i?.code,
            route: i?.route,
            vehicleCode: i?.vehicleCode + " " + t(i?.vehicleType),
            getCountOrders: i?.getCountOrders,
            getCountProducts: i?.getCountProducts,
            getCountItems: i?.getCountItems,
            status: t(i?.status),
            createdTime: i?.createdTime
        }

        return result
    }

    render() {
        const { t } = this.props;
        const { dataSource } = this.state;
        let columns = this.getTableColumns(dataSource);

        return (
            <Layout className='bg-content'>
                <div className='bg-body'>
                    <TopBar
                        title={t('title.listOfImportFromVehicle')} />
                    <div className='bg-white clearfix'>
                        <Form ref={this.formRef}>
                            <Content className='content'>
                                <div className='list-pickup'>
                                    <Table
                                        dataSource={dataSource}
                                        columns={columns}
                                        pagination={this.state.paging}
                                        bordered
                                    />
                                </div>
                            </Content>
                        </Form>
                    </div>
                </div>
            </Layout>
        )
    }

    redirectTo = (url) => {
        this.props.history.push(url);
    }
}