import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage" // defaults to localStorage for web
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import persistStore from "redux-persist/es/persistStore";
import rootReducer from "../modules";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancer = composeEnhancers(
    applyMiddleware(thunk),
    //devToolsEnhancer({ realtime: true, hostname: 'localhost', port: 3000, name: 'BeeCow' }),
);

const configureStore = () => {
    const persistConfig = {
        key: 'root',
        storage,
        timeout: null,
        whitelist: ['session']
    };
    const persistedReducer = persistReducer(persistConfig, rootReducer);
    const store = createStore(
        persistedReducer,
        enhancer,
    );
    const persistor = persistStore(store);
    return { persistor, store };
}

export const { persistor, store } = configureStore();