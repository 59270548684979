
import React, { Component } from 'react'
import Layout from 'antd/lib/layout/layout';
import { Modal, Button, notification } from 'antd';
import TopBar from '../../components/topbar';
import './import-from-vehicle.screen.scss';
import { ProductExportReason } from '../../components/products-info/products-info';
import vehicleDataService from '../../data-services/vehicles/vehicles-data.service';

import SelectVehicleComponent from './select-vehicle/select-vehicle.component';
import ConfirmQuantityComponent from './confirm-quantity/confirm-quantity.component';
import SummarizeComponent from './summarize/summarize.component';
import orderDataService from '../../data-services/orders/orders-data.service';

const SCREENS = {
    SELECT_VEHICLE: 0,
    RECONFIRM: 1,
    SUMMARIZE: 2,
}

export default class ImportFromVehicleScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: 'title.importFromVehicle',
            visibleConfirmDialog: false,
            current: 0,
            productExportReason: [
                ProductExportReason.CANT_FIND,
                ProductExportReason.PACKAGES_BROCKEN,
                ProductExportReason.VEHICLE_FULL,
                ProductExportReason.OTHER_REASONS
            ],
            initialData: {
                orderImportFromVehicleList: null,
                vehicle: null,
                exportLists: [],
                route: null
            },
            vehicles: [],
            confirmExportList: null
        }

        this.selectVehicleForm = React.createRef();
        this.confirmForm = React.createRef();
        this.summazizeForm = React.createRef();
    }

    componentDidMount() {
        this.getVehicles();
    }

    render() {
        const { t } = this.props;
        const { current } = this.state;
        const steps = this.createSteps();

        return (
            <Layout className='bg-content'>
                <div className='bg-body'>
                    <TopBar title={t(this.state.title)} />
                    <div className='bg-white clearfix beecow-leasing-contract'>
                        <h3 className='text-center mb-3'>{steps[current].title}</h3>
                        <div className='steps-content mt-5'>{steps[current].content}</div>
                        <div className='steps-action float-right mr-5 mt-5 d-flex'>
                            {
                                current > 0 && current !== 1 && current < 2 && (
                                    <a className='pr-5' onClick={this.prev}>
                                        Back
                                    </a>
                                )
                            }

                            {
                                current === 0 && (
                                    <>
                                        <Button type='primary' onClick={this.checkAllExportList}>
                                            {t('button.next')}
                                        </Button>
                                    </>
                                )
                            }

                            {
                                current === 2 && (
                                    <>
                                        <a className='pr-5' onClick={this.closeSummarize}>
                                            {t('button.back')}
                                        </a>
                                    </>
                                )
                            }

                        </div>
                    </div>
                </div>

                <Modal
                    visible={this.state.visibleConfirmDialog}
                    onOk={() => {
                        this.setState({
                            visibleConfirmDialog: false,
                        });
                    }}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okText={t('button.ok')}
                    onCancel={() => {
                        this.setState({
                            visibleConfirmDialog: false,
                        });
                    }}
                >
                    <p>{t('messages.exportListNotImportYet')}</p>
                    <p>{t('messages.comfirmAllImport')}</p>
                </Modal>

            </Layout>
        )
    }

    refresh = () => {
        const { initialData, vehicles } = this.state;

        switch (this.state.current) {
            case SCREENS.SELECT_VEHICLE:
                if (this.selectVehicleForm.current && this.selectVehicleForm.current.setFieldsValue) {
                    this.selectVehicleForm.current.setFieldsValue({ vehicles, initialData });
                }
                break;
            case SCREENS.RECONFIRM:
                break;
            case SCREENS.SUMMARIZE:
                break;
        }
    }

    createSteps = () => {
        const { t } = this.props;
        var { productExportReason, initialData, vehicles, confirmExportList } = this.state;
        var { vehicle, route, exportLists } = initialData;

        const steps = [
            {
                title: t('title.selectVehicle'),
                content: <SelectVehicleComponent
                    t={t}
                    ref={this.selectVehicleForm}
                    vehicles={vehicles}
                    initialData={initialData}
                    reConfirmPage={this.reConfirmPage}
                    onChangeVehicle={this.onChangeVehicle}
                />,
            },
            {
                title: t('title.reconfirmQuantity'),
                content: <ConfirmQuantityComponent
                    t={t}
                    ref={this.confirmForm}
                    exportList={confirmExportList}
                    vehicle={vehicle}
                    route={route}
                    productExportReason={productExportReason}
                    prev={this.prev}
                    finishExportList={this.next}
                />,
            },
            {
                title: t('title.summazize'),
                content: <SummarizeComponent
                    t={t}
                    ref={this.summazizeForm}
                    vehicles={vehicles}
                    initialData={initialData}
                />,
            },
        ];

        return steps;
    }

    next = () => {
        const { current, initialData } = this.state;
        var { exportLists } = initialData;

        var currentFormRef;
        var nextStep = current + 1;
        switch (current) {
            case SCREENS.SELECT_VEHICLE:
                this.setState({ current: nextStep }, () => {
                    this.refresh();
                });

                break;
            case SCREENS.RECONFIRM:
                currentFormRef = this.confirmForm;
                var exportListConfirmed = currentFormRef?.current?.getFieldsValue();
                if (exportListConfirmed) {
                    var indexExportListExisted = exportLists?.findIndex(e => e.id === exportListConfirmed.id);
                    if (indexExportListExisted !== -1) {
                        exportLists.splice(indexExportListExisted, 1, exportListConfirmed);
                    }
                    else {
                        exportLists.push(exportListConfirmed)
                    }

                    this.setState({
                        initialData: {
                            ...initialData,
                            exportLists: exportLists
                        }
                    });

                    if (currentFormRef?.current && currentFormRef?.current?.validateFields) {
                        currentFormRef?.current?.validateFields().then(() => {
                            this.finishExportList(exportListConfirmed);
                        });
                    }
                }

                break;
            case SCREENS.SUMMARIZE:
                break;

        }

    }

    prev = () => {
        this.setState({
            current: this.state.current - 1
        }, () => {
            this.refresh();
        });
    }

    getVehicles = () => {
        var stationId = this.props.currentStationId;
        if (stationId) {
            vehicleDataService.getTransportVehiclesFinishedByStationId(stationId).then((res) => {
                if (res) {
                    this.setState({
                        vehicles: res
                    }, () => {
                        this.refresh();
                    });
                }
            });
        }
    }

    reConfirmPage = (exportList) => {
        var { initialData } = this.state;
        var { vehicle, route } = this.selectVehicleForm.current.getFieldsValue();
        this.setState({
            confirmExportList: exportList,
            initialData: {
                ...initialData,
                route: route,
                vehicle: vehicle
            }
        }, () => {
            this.setState({
                current: this.state.current + 1,
            });
        });
    }

    checkAllExportList = () => {
        var { current, initialData } = this.state;
        var { exportLists } = initialData;
        var someExportListStillImportedYet = false;

        if (exportLists) {
            var exportListImportedYet = exportLists.find(e => !e.finished);
            if (exportListImportedYet) {
                someExportListStillImportedYet = true;
            }
        }

        if (someExportListStillImportedYet) {
            this.setState({ visibleConfirmDialog: true });
        }
        else {
            var orderImportFromVehicleListId = initialData?.orderImportFromVehicleList?.id;
            if (orderImportFromVehicleListId) {
                this.props.history.push(`/view-import-from-vehicle/${orderImportFromVehicleListId}`);
            } else {
                console.log('Can not open view import from vehicle');
            }
        }
    }

    transferOrderItems = (orderItems) => {
        return orderItems?.map((i) => {
            return {
                id: i.id,
                scanned: i.scanned ?? false,
                amountScanned: i.amountScanned ?? 0,
                scanReasonFailed: i.scanReasonFailed ?? ''
            }
        });
    }

    transferOrders = (orders) => {
        return orders.map((o) => {
            return {
                id: o.id,
                orderItems: this.transferOrderItems(o.orderItems)
            }
        });
    }

    finishExportList = async (exportList) => {
        const { t } = this.props;
        var { current, initialData } = this.state;
        var stationId = this.props.currentStationId;
        var finishExportRequest = {
            stationId: stationId,
            exportListId: exportList.id,
            vehicleId: initialData?.vehicle?.id,
            routeId: initialData?.route?.id,
            orders: this.transferOrders(exportList.orders),
            orderImportFromVehicleListId: initialData?.orderImportFromVehicleList?.id,
        }

        console.log('request >> ', finishExportRequest);
        orderDataService.finishedExportList(finishExportRequest).then((response) => {
            if (response.success) {
                // Back to first page
                var nextStep = current - 1;
                var { orderExportList, orderImportFromVehicleList } = response.data;
                this.setState(
                    {
                        current: nextStep,
                        initialData: {
                            ...initialData,
                            orderImportFromVehicleList: orderImportFromVehicleList,
                        }
                    }, () => {
                        this.refresh();
                        notification.success({
                            message: `${t('text.theExportList')} ${exportList.code} ${t('text.importSuccess')}`,
                        });
                    });
            }
            else {
                notification.error({
                    message: response.message,
                });

                console.log(response);
            }

        });
    }

    closeSummarize = () => {
        
    }

    onChangeVehicle = ({ vehicle, route, exportLists, orderImportFromVehicleList }) => {
        var { initialData } = this.state;
        this.setState({
            initialData: {
                ...initialData,
                route: route,
                vehicle: vehicle,
                exportLists: exportLists,
                orderImportFromVehicleList: orderImportFromVehicleList
            }
        }, () => {

            console.log('updated ', this.state.initialData);
        });


    }
}