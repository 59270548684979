import { Layout, message } from 'antd';
import Form from 'antd/lib/form/Form';
import React, { Component } from 'react';
import { orderDeliveryStep } from '../../constants/order-constant';
import orderDataService from '../../data-services/orders/orders-data.service';
import NextPage from '../shared/components/next-page';
import TopBar from '../../components/topbar';
import OrderList from './order-list';
import ReviewOrders from './review-orders';

export class EditDeliveryOrderScreen extends Component {
    constructor(props) {
        super(props);
        this.state = this.getDefaultState();
        this.formRef = React.createRef();
    }

    renderDeliveryOrderList() {
        const { id } = this.props?.match?.params || {};
        const { currentPage, selectedOrderList, selectedVehicle } = this.state;
        switch (currentPage) {
            case orderDeliveryStep.reviewDeliveryOrderList:
                return (
                    <ReviewOrders
                        selectedOrderList={selectedOrderList}
                        selectedVehicle={selectedVehicle}
                    />
                );
            default:
                return (
                    <OrderList
                        orderDeliveryId={id}
                        selectedVehicle={selectedVehicle}
                        selectedOrderList={selectedOrderList}
                        setFieldsValue={this.setFieldsValue.bind(this)}
                        setDeliveryCode={this.setDeliveryCode.bind(this)}
                        setSelectedOrderIds={this.setSelectedOrderIds.bind(this)}
                        setSelectedOrdersForDelivery={this.setSelectedOrdersForDelivery.bind(
                            this
                        )}
                        setSelectedVehicleForDelivery={this.setSelectedVehicleForDelivery.bind(
                            this
                        )}
                    />
                );
        }
    }

    handleNextPage() {
        const { currentPage } = this.state;
        this.formRef?.current?.validateFields().then(() => {
            const values = this.formRef.current.getFieldsValue();
            this.setState({
                currentPage: currentPage + 1,
                data: { ...values },
            });
        });
    }

    handleBackHomePage() {
        this.props.history.push('/dashboard');
    }

    handleBackPage() {
        const { currentPage } = this.state;
        this.setState({ currentPage: currentPage - 1 });
    }

    handleFinish() {
        const { t } = this.props;
        const { id } = this.props?.match?.params || {};
        const { selectedOrderList, selectedVehicle } = this.state;
        this.formRef?.current?.validateFields().then(async () => {
            const orderIds = selectedOrderList.map((order) => order.id);
            const request = {
                orderIds,
                vehicleId: selectedVehicle.id,
                stationId: this.props.currentStationId,
                orderDeliveryListId: id,
            };

            orderDataService.updateDeliveryList(request).then((res) => {
                if (res?.dataSavedSuccessfully) {
                    this.formRef?.current?.resetFields();
                    this.resetState();
                    this.props.history.push(`/view-delivery-list-detail/${res?.orderDeliveryListId}`);
                } else {
                    message.error(t('messages.saveFailedTheOrderDeliveryList'));
                }
            });
        });
    }

    setFieldsValue = (values) => {
        if (values && this.formRef) {
            this.formRef?.current?.setFieldsValue(values);
        }
    };

    setSelectedOrderIds = (orderIds) => {
        this.formRef?.current?.setFieldsValue({ orderIds });
    };

    setDeliveryCode = (code) => {
        this.setState({ deliveryListCode: code });
    };

    setSelectedVehicleForDelivery(vehicle) {
        this.setState({ selectedVehicle: vehicle });
    }

    setSelectedOrdersForDelivery(orderList) {
        this.setState({ selectedOrderList: orderList });
    }

    resetState() {
        let defaultData = this.getDefaultState();
        this.setState(defaultData);
    }

    getDefaultState() {
        return {
            currentPage: orderDeliveryStep.orderList,
            selectedVehicle: {},
            selectedOrderList: [],
            deliveryListCode: null,
        };
    }

    render() {
        const { t } = this.props;
        const { currentPage, deliveryListCode } = this.state;
        return (
            <Layout className='bg-content'>
                <div className='bg-body'>
                    <TopBar
                        title={
                            currentPage === orderDeliveryStep.orderList
                                ? t('title.editDeliveryList')
                                : t('title.reviewDeliveryList')
                        }
                    />
                    <div className='bg-white clearfix'>
                        <div className='col-md-12'>
                            <span className='pull-right font-weight-bold'>
                                {t('tableColumn.deliveryId')}: {deliveryListCode}
                            </span>
                        </div>
                        <br />
                        <Form ref={this.formRef}>
                            {this.renderDeliveryOrderList()}
                            <NextPage
                                handleNext={this.handleNextPage.bind(this)}
                                handleBack={this.handleBackPage.bind(this)}
                                handleCancel={this.handleBackHomePage.bind(this)}
                                handleFinish={this.handleFinish.bind(this)}
                                showCancel={currentPage === orderDeliveryStep.orderList}
                                hideNext={
                                    currentPage === orderDeliveryStep.reviewDeliveryOrderList ||
                                    currentPage === orderDeliveryStep.viewDeliveryOrderList
                                }
                                hideBack={
                                    currentPage === orderDeliveryStep.orderList ||
                                    currentPage === orderDeliveryStep.viewDeliveryOrderList
                                }
                                hideClose={
                                    currentPage === orderDeliveryStep.orderList ||
                                    currentPage === orderDeliveryStep.reviewDeliveryOrderList
                                }
                                hideFinish={currentPage === orderDeliveryStep.orderList}
                                textFinish={t('button.done')}
                            />
                        </Form>
                    </div>
                </div>
            </Layout>
        );
    }
}
