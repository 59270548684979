import { Component } from 'react';
import { Table, Input, Button, Space, Row, Col, Image } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import deliveryIcon from '../../../assets/icons/icon-delivery.png';
import deliveryIconLeasing from '../../../assets/icons/warehouse.png';
import { deliveryHouseMethods, deliveryMethods } from '../../../constants/order-constant';

export class TableOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            searchedColumn: '',
        };
    }

    render() {
        let columns = this.getTableColumns();
        return <Table
            bordered
            pagination={{position: []}}
            columns={columns}
            dataSource={this.props.dataSource}
            onchange={this.onChange} />
    }

    getTableColumns() {
        const { t } = this.props;
        let orders = this.props.dataSource;

        let deliveries = orders.map(d => { return {text: t(d.deliveryMethod), value: d.deliveryMethod} });
        let deliveryOptions = deliveries.filter((d, i) => deliveries.findIndex(o => o.value === d.value) === i);
        let routes = orders.map(d => { return {text: d.route.replace('<br/>', ' - '), value: d.route} });
        let routeOptions = routes.filter((d, i) => routes.findIndex(o => o.value === d.value) === i);
        let status = orders.map(d => { return {text: d.status, value: d.status} });
        let statusOptions = status.filter((d, i) => status.findIndex(o => o.value === d.value) === i);
        let columns = [
            {
                title: t('tableColumn.orderId'),
                dataIndex: 'orderId',
                sorter: (a, b) => a.orderId.localeCompare(b.orderId),
                ...this.getColumnSearchProps('orderId'),
                render:  (text, data) => <Link to={`/view-order/${data.id}`}>{text}</Link>
            },
            {
                title: t('tableColumn.from'),
                dataIndex: 'from',
                sorter: (a, b) => a.from.localeCompare(b.from),
                ...this.getColumnSearchProps('from'),
                render: text => <span dangerouslySetInnerHTML={{__html: text}} />
            },
            {
                title: t('tableColumn.to'),
                dataIndex: 'to',
                sorter: (a, b) => a.to.localeCompare(b.to),
                ...this.getColumnSearchProps('to'),
                render: text => <span dangerouslySetInnerHTML={{__html: text}} />
            },
            {
                title: t('tableColumn.deliveryMethod'),
                dataIndex: 'deliveryMethod',
                sorter: (a, b) => a.deliveryMethod.localeCompare(b.deliveryMethod),
                filters: deliveryOptions,
                onFilter: (value, record) => record.deliveryMethod.indexOf(value) === 0,
                render: (text, data) => {
                    const isShowDeliveryIcon = deliveryMethods.includes(data.deliveryMethod?.toLowerCase())
                    const isShowDeliveryIconLeasing = deliveryHouseMethods.includes(data.deliveryMethod?.toLowerCase())
                    return < div >
                        <Row>
                            <Col span={18}>{t(text)}</Col>
                            <Col span={6}>
                                {isShowDeliveryIcon && <Image src={deliveryIcon} width={30} />}
                                {isShowDeliveryIconLeasing && <Image src={deliveryIconLeasing} width={30} />}
                            </Col>
                        </Row>
                        <br />
                        <p>{data.contract}</p>
                    </div >
                }
            },
            {
                title: t('tableColumn.route'),
                dataIndex: 'route',
                sorter: (a, b) => a.route.localeCompare(b.route),
                filters: routeOptions,
                onFilter: (value, record) => record.route.indexOf(value) === 0,
                render: (text, data) => (
                    <Link to={`/view-order-history/${data.orderTrackingCode}`}>
                        <span dangerouslySetInnerHTML={{ __html: text }} />
                    </Link>
                ),
            },
            {
                title: t('tableColumn.createdDate'),
                dataIndex: 'createdDate',
                sorter: (a, b) => a.createdDate.localeCompare(b.createdDate),
                ...this.getColumnSearchProps('createdDate'),
            },
            {
                title: t('tableColumn.status'),
                dataIndex: 'status',
                sorter: (a, b) => a.status.localeCompare(b.status),
                filters: statusOptions,
                onFilter: (value, record) => record.status.indexOf(value) === 0,
            },
        ];
        return columns;
    }

    onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`${this.props.t('button.search')} ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type='primary'
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size='small'
                        style={{ width: 90 }}
                    >
                        {this.props.t('button.search')}
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size='small' style={{ width: 90 }}>
                        {this.props.t('button.reset')}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''} />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
    };
    
    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
}