import React, { Component } from 'react';
import { Form } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import './shipping-info.scss';

import FormInfoComponent from '../../../components/form-info/form-info.component';
import userDataService from '../../../data-services/user/user-data.service';
import { Utilities } from '../../../common/utilities';

export default class ShippingInfoComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isValidSender: false,
            isValidReceiver: false,
            initialData: props.initialData ?? this.getDefaultShippingInfo()
        }

        this.senderFormRef = React.createRef();
        this.receiverFormRef = React.createRef();
    }

    getDefaultShippingInfo = () =>{
        let defaultData = {
            senderInfo: {
                fullName: null,
                phoneNumber: null,
                address: {
                    street: null,
                    city: {
                        id: null,
                        name: null
                    },
                    district: {
                        id: null,
                        name: null
                    },
                    ward: {
                        id: null,
                        name: null
                    }
                },
            },
            receiverInfo: {
                fullName: null,
                phoneNumber: null,
                address: {
                    street: null,
                    city: {
                        id: null,
                        name: null
                    },
                    district: {
                        id: null,
                        name: null
                    },
                    ward: {
                        id: null,
                        name: null
                    }
                },
            }
        };
        return defaultData;
    }

    render() {
        const { t } = this.props;
        var { initialData } = this.state;
        return (
            <Content className='beecow-shipping-info'>
                <div className='row'>
                    <Form.Item name='senderInfo' className='col-sm-5 col-lg-5 m-auto'>
                        <FormInfoComponent
                            t={t}
                            ref={this.senderFormRef}
                            initialData={initialData.senderInfo}
                            onChange={this.onChangeSenderPhone}
                            title={t('order.senderInfo')} />
                    </Form.Item>
                    <Form.Item name='receiverInfo' className='col-sm-5 col-lg-5 m-auto'>
                        <FormInfoComponent
                            t={t}
                            initialData={initialData.receiverInfo}
                            ref={this.receiverFormRef}
                            title={t('order.receiverInfo')} />
                    </Form.Item>
                </div>
            </Content>
        )
    }

    validateFields = () => {
        let promises = [];

        if (this.senderFormRef && this.senderFormRef.current) {
            let senderFormValidation = this.senderFormRef.current.validateFields();
            promises.push(senderFormValidation);
        }

        if (this.receiverFormRef && this.receiverFormRef.current) {
            let receiverFormValidation = this.receiverFormRef.current.validateFields();
            promises.push(receiverFormValidation);
        }

        return Promise.all(promises);
    }

    getFieldsValue = () => {
        let shippingInfo = {};

        if (this.senderFormRef && this.senderFormRef.current) {
            let senderInfo = this.senderFormRef.current.getFieldsValue();
            shippingInfo['senderInfo'] = senderInfo;
        }

        if (this.receiverFormRef && this.receiverFormRef.current) {
            let receiverInfo = this.receiverFormRef.current.getFieldsValue();
            shippingInfo['receiverInfo'] = receiverInfo;
        }

        return shippingInfo;
    }

    setFieldsValue = (values) => {
        if (this.senderFormRef && this.senderFormRef.current) {
            this.senderFormRef.current.setFieldsValue(values.senderInfo);
        }
        if (this.receiverFormRef && this.receiverFormRef.current) {
            this.receiverFormRef.current.setFieldsValue(values.receiverInfo);
        }

        this.setState({
            initialData: values 
        });
    }

    onChangeSenderPhone = (values) => {
        var { phoneNumber } = values;
        if (Utilities.isValidPhoneNumber(phoneNumber)) {
            if (phoneNumber) {
                userDataService.getUserByPhone(phoneNumber).then((res) => {
                    if (res && res?.id) {
                        this.resetSenderForm().then(() => {
                            this.senderFormRef.current.setFieldsValue(res);
                        });
                    }
                });
            }
        }
    }

    resetSenderForm = async () => {
        var senderInfo = {
            fullName: null,
            address: {
                street: null,
                city: {
                    id: null,
                    name: null
                },
                district: {
                    id: null,
                    name: null
                },
                ward: {
                    id: null,
                    name: null
                }
            }
        }
        this.senderFormRef.current.setFieldsValue(senderInfo);
    }
}