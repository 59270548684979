import React, { Component } from 'react';
import { Content } from 'antd/lib/layout/layout';
import { Radio, Form, Tabs, Checkbox, InputNumber, Space, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { formatAmount } from '../../../utils/order';
import { paymentConstants } from '../../../constants/order-constant';
import { TEXT_VND } from '../../../constants/common';
import orderDataService from '../../../data-services/orders/orders-data.service'
import { DeliveryConstants } from './../../../constants/delivery.constants'
import './payment.scss';
import { images } from '../../../constants/imagesConstant';

const { TabPane } = Tabs;
export default class PaymentMethodComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCODCondition: paymentConstants.SHOW_COD_CONDITION,
            loading: false,
            showShippingFeeDetail: false,
            paymentTypes: props.paymentTypes ?? [],
            paymentByOptions: props.paymentByOptions ?? [],
            deliveryInfo: props.deliveryInfo ?? {},
            shippingInfo: props.shippingInfo ?? {},
            initialData: props.initialData ?? {},
            paymentDetail: {},
            tooltipDetail: props.t('messages.paymentMethodTooltipDetail')
        }

        this.formRef = React.createRef();
    }

    componentDidMount() {
        this.formRef?.current?.setFieldsValue(this.state.initialData);
        this.getPaymentCalculator();
    }

    render() {
        const { t } = this.props;
        var { paymentByOptions, paymentTypes, initialData, tooltipDetail } = this.state;
        return (
            <Content>
                <Form ref={this.formRef}>
                    <div className='create-order clearfix beecow-payment-method mx-auto'>
                        <div className='title d-flex'>
                            <i className='fa fa-credit-card' aria-hidden='true'></i>
                            <p className='m-0 ml-2'>{t('order.paymentMethod')}</p>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-4 col-sm-4 col-lg-4'>
                                <div className='action'>
                                    <Form.Item name={['payBy', 'id']}
                                        rules={[{ required: true, message: t('messages.choosePaymentBy') }]}>
                                        <Radio.Group onChange={this.paymentByOptions}>
                                            <Space direction='vertical'>
                                                {
                                                    paymentByOptions?.map(({ id, languageKey }) => <Radio key={id} value={id}>{t(languageKey)}</Radio>)
                                                }
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>
                                    {
                                        (initialData?.payById === this.state.showCODCondition) && <div className='check-cod'>
                                            <Form.Item name='isCod' valuePropName='checked'>
                                                <Checkbox onChange={this.onChangeCODCheckBox}>COD
                                                    <Tooltip title={tooltipDetail} overlayStyle={{ whiteSpace: 'pre-line' }} placement='bottomLeft' color={'gold'}>
                                                        <QuestionCircleOutlined style={{ position: 'absolute', paddingLeft: '5px' }} />
                                                    </Tooltip>
                                                </Checkbox>
                                            </Form.Item>
                                            {
                                                initialData?.isCod &&
                                                <div>
                                                    <Form.Item className='cod-amount'
                                                        name='codAmount'
                                                        rules={[{ required: true, message: t('messages.codAmount') }]}>
                                                        <InputNumber
                                                            onChange={this.onChangeCODAmount}
                                                            className='cod-amount'
                                                            min={0}
                                                            formatter={value => formatAmount(value)}
                                                            placeholder={t('placeholder.codAmount')}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className='col-6 col-sm-6 col-lg-6'>
                                <div className='tab-payment'>
                                    <Form.Item name={['paymentType', 'id']}>
                                        <Tabs defaultActiveKey={initialData?.paymentType?.id} onChange={this.handleChangeTab}>
                                            {
                                                paymentTypes?.map(({ id, name, languageKey }, index) => {
                                                    if (name === 'Cash') return <TabPane tab={t(languageKey)} key={id} className='cash'>
                                                        {
                                                            this.renderPayment()
                                                        }
                                                    </TabPane>
                                                    if (name === 'Bank') return <TabPane tab={t(languageKey)} key={id}>
                                                        <div className='bank-payment'>
                                                            <p>{t('messages.bankAccount')}</p>
                                                            <div className='info-bank'>
                                                                <p>{t('text.bankName')}: <span className='ml-2'>{t('text.bankNameInfo')}</span></p>
                                                                <p>{t('text.accountNumber')}: <span className='ml-2'>{t('text.accountNumberInfo')}</span></p>
                                                                <p>{t('text.accountNumber')}: <span className='ml-2'>{t('text.accountNameInfo')}</span></p>
                                                            </div>
                                                            <div className='des-bank'>
                                                                <p>{t('text.bankDescription')}</p>
                                                            </div>
                                                            {
                                                                this.renderPayment()
                                                            }
                                                        </div>
                                                    </TabPane>
                                                    return <TabPane tab={t(languageKey)} key={id} className='e-wallet'>
                                                        <div className='img-qr-code'>
                                                            <img src={images.qrCode} width='100' />
                                                        </div>
                                                        {
                                                            this.renderPayment()
                                                        }
                                                    </TabPane>
                                                })
                                            }
                                        </Tabs>
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </Content>
        )
    }

    isDeliveryToStation = (deliveryOption) => {
        if (deliveryOption) {
            return deliveryOption?.name.toLowerCase().includes(DeliveryConstants.TO_STATION) ? true : false;
        }

        return false;
    }

    renderPayment() {
        const { t } = this.props;
        var { initialData, paymentDetail } = this.state;
        var codAmountFormated = formatAmount(initialData.codAmount) == 'null' ? 0 + TEXT_VND : formatAmount(initialData.codAmount) + TEXT_VND;
        var CODFeeFormated = formatAmount(paymentDetail.codFee) + TEXT_VND;
        var pickupFromDoorFeeFormated = formatAmount(paymentDetail.pickupFromDoorFee) + TEXT_VND;
        var deliveryToDoorFeeFormated = formatAmount(paymentDetail.deliveryToDoorFee) + TEXT_VND;
        var interProvinceFeeFormated = formatAmount(paymentDetail.interProvinceFee) + TEXT_VND;
        var totalFeeFormated = formatAmount(paymentDetail.totalFee) + TEXT_VND;
        return (
            <>
                {
                    initialData.isCod && paymentDetail?.codFee > 0 &&
                    <>
                        <div className='row'>
                            <div className='col-sm-8'>
                                <p className='m-0'><b>{t('text.codAmount')}</b></p>
                                <p className='m-0 pl-4 text-detail font-italic'>{t('text.codAmountDeltail-1')}</p>
                                <p className='m-0 pl-4 text-detail font-italic'>{t('text.codAmountDeltail-2')}</p>
                            </div>
                            <div className='col-sm-4'>
                                <p className='float-right'>{codAmountFormated}</p>
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <p className='col-sm-8'><b>{t('text.codFee')}</b></p>
                            <div className='col-sm-4'>
                                <p className='float-right'>{CODFeeFormated}</p>
                            </div>
                        </div>
                    </>
                }

                <div>
                    {
                        this.isDeliveryToStation(initialData?.deliveryOption) ||
                        <div className='row mt-1'>
                            <p className='col-sm-8 m-0'><b>{t('text.deliveryFee')}:</b></p>
                            <div className='col-sm-4 m-0 '>
                                <p className='float-right'>{deliveryToDoorFeeFormated}</p>
                            </div>
                        </div>
                    }

                    {
                        paymentDetail?.interProvinceFee > 0 &&
                        <div className='row mt-1'>
                            <p className='col-sm-8 m-0'><b>{t('text.interProvinceFee')}:</b></p>
                            <div className='col-sm-4 m-0 '>
                                <p className='float-right'>{interProvinceFeeFormated}</p>
                            </div>
                        </div>
                    }

                    <div className='row mt-1'>
                        <p className='col-sm-8 m-0'><b>{t('text.totalShippingFee')}:</b></p>
                        <div className='col-sm-4 m-0 '>
                            <p className='text-danger float-right'><b>{totalFeeFormated}</b></p>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    toggleCollapse() {
        this.setState({
            showShippingFeeDetail: !this.state.showShippingFeeDetail,
        });
    }

    paymentByOptions = e => {
        var initialData = this.state.initialData;
        initialData.payById = e.target.value;
        this.setState({
            initialData: initialData
        }, () => {
            this.getPaymentCalculator();
        });
    }

    onChangeCODCheckBox = (e) => {
        var initialData = this.state.initialData;
        initialData.isCod = e.target.checked;
        this.setState({
            initialData: initialData
        }, () => {
            this.getPaymentCalculator();
        });
    }

    onChangeCODAmount = (value) => {
        var { initialData } = this.state;
        this.setState({
            ...this.state,
            initialData: {
                ...initialData,
                codAmount: value
            }
        });
    }

    getPaymentCalculator = () => {
        var { shippingInfo, deliveryInfo } = this.state;
        var paymentInfo = this.getFieldsValue();

        var paymentCalculatorRequest = {
            senderCityId: shippingInfo?.senderInfo?.address?.city?.id,
            receiverCityId: shippingInfo?.receiverInfo?.address?.city?.id,
            isCOD: paymentInfo.isCod ?? false,
            deliveryTypeId: deliveryInfo?.deliveryOption?.id,
            companyId: deliveryInfo?.route?.company?.id,
            shippingFeeDetails: deliveryInfo?.route?.shippingFeeDetails,
        }

        orderDataService.paymentCalculator(paymentCalculatorRequest).then((res) => {
            this.setState({
                ...this.state,
                paymentDetail: res
            });
        });
    }

    getFieldsValue = () => {
        var { paymentByOptions, paymentTypes, paymentDetail } = this.state;
        var paymentInfo = this.formRef.current.getFieldsValue() ?? {};

        if (isNaN(paymentInfo.payBy.id)) {
            paymentInfo.payBy.id = null;
        }
        if (!paymentInfo.isCod) {
            paymentInfo.isCod = false;
        }
        if (!paymentInfo.codAmount) {
            paymentInfo.codAmount = null;
        }
        if (!paymentInfo.paymentType.id) {
            paymentInfo.paymentType = paymentTypes ? paymentTypes[0] : null;
        }

        var paymentType = paymentTypes?.find((i) => i.id === paymentInfo.paymentType.id) ?? null;
        paymentInfo.paymentType = paymentType;

        var payBy = paymentByOptions?.find((i) => i.id === paymentInfo.payBy.id) ?? null;
        paymentInfo.payBy = payBy;
        paymentInfo.paymentDetail = paymentDetail;

        return paymentInfo;
    }

    validateFields = () => {
        return this.formRef.current.validateFields();
    }

    setFieldsValue = (values) => {
        this.setState({
            ...this.state,
            initialData: values
        });

        this.formRef?.current?.setFieldsValue(values);
        this.getPaymentCalculator();
    }
}