export const USER_ID = "5DE4C30E-D277-4989-859A-368245647EDC";
export const STATION_ID = "dff7e52c-3a7f-4ce5-87a9-37cd415a5bc2";
export const TEXT_VND = " VND";

export const listOptionPrint = [
  {
      id: 0,
      name: 'Print this Order sheet with Order'
  },
  {
      id: 1,
      name: 'Print only Order barcode'
  },
  {
      id: 2,
      name: 'Select to Print Product barcodes'
  }
]

export const StationToWarehouser = 'station to warehouse';