import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router';
import { ViewLostOrderDetailsScreen } from "./view-lost-order-details.screen";

const mapStateToProps = (state) => {
  return {
    currentStationId: state.session?.auth?.user?.currentStationId,
  };
};

export default compose(
  withTranslation("translations"),
  connect(mapStateToProps, null),
  withRouter
)(ViewLostOrderDetailsScreen);
