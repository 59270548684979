import importDataService from "../../data-services/import/import-data.service";
import actionTypes from "./import.type";

export function getListImportOrders(stationId, page, pageSize) {
    return async (dispatch) => {
        let data = await importDataService.getOrderImportList(stationId, page, pageSize);
        const { records, rows } = data
        dispatch({
            type: actionTypes.GET_LIST_IMPORT_ORDER,
            total: records, pageSize: pageSize, current: page, import: rows
        });
    }
}