import { DataService } from "../base/base-data.service";

class DeliveryDataService extends DataService{
    apiUrl = '/delivery'

    async deleteDeliveryById(deliveryListId) {
        return await this.delete(`${this.apiUrl}/delete/${deliveryListId}`);
    }
}

let deliveryDataService = new DeliveryDataService();

export default deliveryDataService;