import _ from 'lodash';
import moment from 'moment';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import TopBar from '../../components/topbar';
import { Content } from 'antd/lib/layout/layout';
import { getDateTime } from '../../utils/datetime';
import { showAlert } from '../../common/constants';
import { Table, Button, Layout, Modal } from 'antd';
import { exportStatus } from '../../constants/order-constant';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import exportDataService from '../../data-services/export/export-data.service';

export default class ListOrderExportScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listExportOrder: [],
        };
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.InitDataTable();
    };

    InitDataTable() {
        const { currentStationId, current, pageSize } = this.props;
        if (currentStationId) {
            this.props.listExport(currentStationId, current, pageSize);
        }
    };

    onChange(pagination, filters, sorter, extra) {
        console.log('params', pagination, filters, sorter, extra);
    }

    getTableColumns(e) {
        const { t } = this.props;
        let routes = e?.map((d) => {
            return { text: d.route, value: d.route };
        });
        let routeOptions = routes?.filter(
            (d, i) => routes?.findIndex((o) => o.value === d.value) === i
        );
        let vehicles = e?.map((d) => {
            return { text: d.vehicle, value: d.vehicle };
        });
        let vehicleOptions = vehicles?.filter(
            (d, i) => vehicles?.findIndex((o) => o.value === d.value) === i
        );
        let status = e?.map((d) => {
            return { text: t(d.status), value: t(d.status) };
        });
        let statusOptions = status?.filter(
            (d, i) => status?.findIndex((o) => o.value === d.value) === i
        );
        let columns = [
            {
                title: t('tableColumn.no'),
                dataIndex: 'key',
            },
            {
                title: t('tableColumn.exportId'),
                dataIndex: 'exportId',
                render: (text, data) => (
                    <Link
                        target='_blank'
                        className='text-primary text-underline'
                        to={`/view-export-list/${data.id}`}
                    >
                        {text}
                    </Link>
                ),
                sorter: (a, b) => a.exportId.localeCompare(b.exportId),
            },
            {
                title: t('tableColumn.route'),
                dataIndex: 'route',
                render: (text, data) => (
                    <>
                        {text}({data.routeName})
                    </>
                ),
                sorter: (a, b) => a.route.localeCompare(b.route),
                filters: routeOptions,
                onFilter: (value, record) => record.route.indexOf(value) === 0,
            },
            {
                title: t('tableColumn.vehicle'),
                dataIndex: 'vehicle',
                render: (text, data) => (
                    <>
                        {text} ({t(data.vehicleType)})
                    </>
                ),
                sorter: (a, b) => a.vehicle.localeCompare(b.vehicle),
                filters: vehicleOptions,
                onFilter: (value, record) => record.vehicle.indexOf(value) === 0,
            },
            {
                title: t('tableColumn.orders'),
                dataIndex: 'orders',
                sorter: (a, b) => a.orders - b.orders,
            },
            {
                title: t('tableColumn.products'),
                dataIndex: 'products',
                sorter: (a, b) => a.products - b.products,
            },
            {
                title: t('tableColumn.items'),
                dataIndex: 'items',
                sorter: (a, b) => a.items - b.items,
            },
            {
                title: t('tableColumn.status'),
                dataIndex: 'status',
                sorter: (a, b) => a.status.localeCompare(b.status),
                filters: statusOptions,
                onFilter: (value, record) => record.status.indexOf(value) === 0,
                render: (text, record) => (
                    <>
                        {record.status}
                    </>
                ),
            },
            {
                title: t('tableColumn.createdDate'),
                dataIndex: 'createdDate',
                sorter: (a, b) => moment(a.createdDate) - moment(b.createdDate),
                render: (text) => (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: getDateTime(text, 'hh:mm A <br> DD/MM/YYYY')
                        }}
                    />
                ),
            },
            {
                title: t('tableColumn.action'),
                dataIndex: 'action',
                render: (text, record) => {
                    if (record.statusId === exportStatus.new) {
                        return <>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div className='text-center' style={{ marginRight: 5 }}>
                                    <Link
                                        to={`/edit-export-packages/${record.id}`}
                                        className='ant-btn ant-btn-primary'
                                    >
                                        {t('button.edit')}
                                    </Link>
                                </div>
                                <div className='text-center' style={{ marginRight: 5 }}>
                                    <Button
                                        onClick={() => this.clickButtonDelete(record?.id)}
                                        className='ant-btn ant-btn-primary ant-btn-dangerous'
                                    >
                                        {t('button.delete')}
                                    </Button>
                                </div>
                            </div>
                        </>
                    }
                },
            },
        ];
        return columns;
    };

    clickButtonDelete(exportId) {
        const currentComponent = this;
        const { t } = this.props;
        Modal.confirm({
            title: `${t('confirm.removeOrder')}`,
            icon: <ExclamationCircleOutlined />,
            onOk() {
                currentComponent.handleDeleteExport(exportId);
            },
            okType: 'danger',
            okText: `${t('button.ok')}`,
            cancelText: `${t('button.cancel')}`,
        });
    };

    handleDeleteExport = (exportId) => {
        const { t } = this.props;
        let message = '';
        let description = '';
        exportDataService.deleteExportById(exportId).then((res) => {
            if (res) {
                message = t('confirm.success');
                description = t('confirm.deleteSuccess');
                showAlert(message, description, true);
                this.InitDataTable();
            } else {
                message = t('confirm.warning');
                description = t('confirm.deleteFail');
                showAlert(message, description, false);
            }
        });
    };

    transform = (list, index) => {
        const { t } = this.props;
        var result = {
            id: list.id,
            key: index + 1,
            exportId: list.code,
            route: list?.route?.code,
            routeName: list?.route?.routeName,
            vehicle: list?.vehicle?.code,
            vehicleType: list?.vehicle?.type?.name,
            createdDate: list.createdTime,
            orders: list.orders,
            products: list.products,
            items: list.items,
            status: t(list.status),
            statusId: list.statusId,
        };

        return result;
    };

    render() {
        const { t } = this.props;
        const dataSource = this.props.export.map((x, i) => this.transform(x, i));
        let columns = this.getTableColumns(dataSource);
        const paging = {
            current: this.props.current,
            pageSize: this.props.pageSize,
            total: this.props.total,
            onChange: this.onPageChanged,
        };
        return (
            <Layout className='bg-content'>
                <div className='bg-body'>
                    <TopBar title={t('title.listOfExport')} />
                    <div className='bg-white clearfix'>
                        <Content className='content'>
                            <div className='list-pickup'>
                                <Table
                                    columns={columns}
                                    bordered
                                    dataSource={dataSource}
                                    onchange={this.onChange}
                                    pagination={paging}
                                />
                            </div>
                        </Content>
                    </div>
                </div>
            </Layout>
        );
    }

    onPageChanged = (page, pageSize) => {
        const { currentStationId } = this.props;
        this.props.listExport(currentStationId, page, pageSize);
    }
}
