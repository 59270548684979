import { Table, Image } from 'antd';
import moment from 'moment';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';
import { getSpecialServiceIcon } from '../../../utils/order';
import { deliveryHouseMethods } from '../../../constants/order-constant';
import iconWareHouse from '../../../assets/icons/warehouse.png';
import orderDataService from '../../../data-services/orders/orders-data.service';
import './../deliver-order.scss';
const unitWeight = 'Kg';

export class ReviewDeliveryOrderListScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderListForDelivery: [],
            pagination: {
                current: 1,
                pageSize: 20,
                total: 0,
            },
        };
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        const { isSelectedAllOrders, selectedOrders, unselectedOrders } = this.props;
        if (this.props.currentStationId) {
            if (isSelectedAllOrders) {
                this.loadData(true, this.state.pagination, unselectedOrders);
            } else {
                let dataList = selectedOrders?.map((anOrder) =>
                    this.transform(anOrder)
                );
                this.setState({
                    orderListForDelivery: dataList?.slice(0, this.state.pagination.pageSize),
                    pagination: {
                        ...this.state.pagination,
                        total: dataList?.length,
                    },
                });
            }
        }
    }

    loadData = (
        nextPage,
        pagination,
        unselectedOrders,
        sorter = null,
        filters = null
    ) => {

        let sortOrder = 'asc';
        let sortField = 'Id';
        if (sorter) {
            sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';
            sortField = sorter?.field || 'Id';
        }

        let inPackages = [];
        let inDeliveryMethods = [];


        if (filters) {
            if (filters.deliveryMethod && filters.deliveryMethod?.length > 0) {
                // For example: ['Door To Door', 'Warehouse To Door', 'Station To Door']
                inDeliveryMethods.push(...filters.deliveryMethod);
            }

            if (filters.packageInfo && filters.packageInfo?.length > 0) {
                // For example: ['Fast Service', 'Fragile', 'Normal Service']
                inPackages.push(...filters.packageInfo);
            }
        }

        orderDataService
            .getOrdersAndVehiclesForDelivery(
                this.props.currentStationId,
                pagination.current,
                pagination.pageSize,
                sortField,
                sortOrder,
                '',
                nextPage,
                unselectedOrders,
                inPackages,
                inDeliveryMethods
            )
            .then((res) => {
                this.setState({
                    pagination: {
                        ...pagination,
                        total: res.totalOrders,
                    },
                    orderListForDelivery: res.orders?.map((o) => this.transform(o)),
                });
            });
    };

    getRecipientInfo(order) {
        let formattedAddress =
            this.getFormattedAddress(order?.addressRecipient) || this.props.t('text.noAddress');
        let senderInfo = [
            order.receiverName,
            order.receiverPhone,
            formattedAddress,
        ];
        return senderInfo.join('<br/>');
    }

    getReceiverInfo(order) {
        let formattedAddress =
            this.getFormattedAddress(order?.addressRecipient) || this.props.t('text.noAddress');
        let senderInfo = [
            order.receiverName,
            order.receiverPhone,
            formattedAddress,
        ];
        return senderInfo.join('<br/>');
    }

    getFormattedAddress(address) {
        let arr = [];
        if (address && address.street) {
            arr.push(address.street);
        }
        if (address && address.ward) {
            let ward = address.ward.prefix + ' ' + address.ward.name;
            arr.push(ward);
        }
        if (address && address.district) {
            let district = address.district.prefix + ' ' + address.district.name;
            arr.push(district);
        }
        if (address && address.city) {
            arr.push(address.city.name);
        }
        return arr.join(', ');
    }

    onChange(pagination, filters, sorter, extra) {
        const { isSelectedAllOrders, selectedOrders, unselectedOrders } = this.props;
        if (isSelectedAllOrders) {
            this.loadData(true, pagination, unselectedOrders, sorter, filters);
        } else {
            let skip = (pagination.current - 1) * pagination.pageSize;
            let take = skip + pagination.pageSize;
            let newList = [...selectedOrders];
            newList = newList.slice(skip, take)?.map(anOrder => this.transform(anOrder));
            this.setState({
                orderListForDelivery: newList,
                pagination: { ...pagination }
            });
        }
    }

    getTableColumns(orders) {
        const { t } = this.props;
        let deliveries = orders?.map((d) => {
            return { text: d.deliveryMethod, value: d.deliveryMethod };
        });
        let deliveryOptions = deliveries?.filter(
            (d, i) => deliveries?.findIndex((o) => o.value === d.value) === i
        );
        let packages = orders?.map((d) => {
            return { text: d.packageInfo, value: d.packageInfo };
        });
        let packageInfoOptions = packages?.filter(
            (d, i) => packages?.findIndex((o) => o.value === d.value) === i
        );

        let columns = [
            {
                title: t('tableColumn.orderId'),
                dataIndex: 'orderId',
                render: (text, data) => (
                    <Link target='_blank' to={`/view-order/${data.id}`}>
                        {text}
                    </Link>
                ),
            },
            {
                title: t('tableColumn.receiverAddress'),
                className: 'w-270px',
                dataIndex: 'toRecipient',
                render: (text) => <span dangerouslySetInnerHTML={{ __html: text }} />,
            },
            {
                title: t('tableColumn.packageInfo'),
                dataIndex: 'packageInfo',
                render: (name) => (
                    <span>
                        {t(name)}
                        {getSpecialServiceIcon(name)}
                    </span>
                ),
                filters: packageInfoOptions,
                onFilter: (value, record) => record.packageInfo.indexOf(value) === 0,
            },
            {
                title: t('tableColumn.deliveryMethod'),
                dataIndex: 'deliveryMethod',
                render: (text) => (
                    <span>
                        {text}
                        <br />
                        {deliveryHouseMethods.includes(text?.toLowerCase()) && (
                            <Image src={iconWareHouse} width={30} />
                        )}
                    </span>
                ),
                filters: deliveryOptions,
                onFilter: (value, record) => record.deliveryMethod.indexOf(value) === 0,
            },
            {
                title: t('tableColumn.totalWeight'),
                dataIndex: 'totalWeight',
                render: (text) => (
                    <span>
                        {text} {unitWeight}
                    </span>
                ),
            },
            {
                title: t('tableColumn.totalProducts'),
                className: 'w-80px',
                dataIndex: 'totalProductsInPackage',
            },
            {
                title: t('tableColumn.totalItems'),
                className: 'w-80px',
                dataIndex: 'totalItemsInPackage',
            },
            {
                title: t('tableColumn.createdDate'),
                dataIndex: 'createdDate',
                render: (text) => (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: moment
                                .utc(text)
                                .local()
                                .format('hh:mm A <br> DD/MM/YYYY'),
                        }}
                    />
                ),
            },
        ];
        return columns;
    }

    transform = (order) => {
        var result = {
            key: `review-delivery-list-${order.id}`,
            id: order.id,
            orderId: order.code,
            toRecipient: this.getRecipientInfo(order),
            deliveryMethod: order.delivery?.name,
            packageInfo: order.specialOption?.languageKey,
            createdDate: order.createdTime,
            amount: order.amount,
            packageSizeWeight: order.packageSizeWeight,
            totalWeight: order.totalWeight,
            totalProductsInPackage: order.totalProductsInPackage,
            totalItemsInPackage: order.totalItemsInPackage,
        };

        if (order?.status) {
            if (order.status.subStatusName != null) {
                result.status = `${order.status.statusName}-${order.status.subStatusName}`;
            } else {
                result.status = order.status.statusName;
            }
        }
        return result;
    };

    render() {
        const { t } = this.props;
        const { vehicleId } = this.props.data || {};
        const { vehicles } = this.props;
        const { orderListForDelivery } = this.state;
        const selectedVehicle = vehicles.find((x) => x.id === vehicleId);
        let columns = this.getTableColumns(orderListForDelivery);
        return (
            <Content className='content'>
                <div className='list-pickup'>
                    <div className='review-vehicle'>
                        <p>
                            <b>{t('text.selectedVehicle')}: </b>
                            {selectedVehicle?.code || ''}
                        </p>
                        <p>
                            <b>{t('text.vehicleType')}: </b>
                            {t(selectedVehicle?.type?.name) || ''}
                        </p>
                    </div>
                    <Table
                        key={`review-delivery-list`}
                        bordered
                        columns={columns}
                        dataSource={orderListForDelivery}
                        pagination={this.state.pagination}
                        onChange={this.onChange}
                        locale={{
                            emptyText: <span>{t('text.emptyText')}</span>,
                        }}
                    />
                </div>
            </Content>
        );
    }
}
