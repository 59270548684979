
import React, { Component } from 'react';
import Layout, { Header } from 'antd/lib/layout/layout';
import { EditOutlined } from '@ant-design/icons';
import TopBar from '../../../components/topbar';
import { Link } from 'react-router-dom';
import { Modal, Button, Table, Checkbox } from 'antd';
import moment from 'moment';
import { FORMAT_TIME_DATE } from '../../../common/constants';
import importDataService from '../../../data-services/import/import-data.service';
import './edit-import-vehicle.screen.scss'
import _ from 'lodash';
import areaStationDataService from '../../../data-services/area-stations/area-stations-data.service';

import EditSpacesComponent from '../../../components/edit-spaces/edit-spaces.component';

const unitWeight = 'kg';
export default class EditImportFromVehicleScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            initialData: {
                code: null,
                exportedOrders: null,
                exportedProducts: null,
                exportedItems: null,
                exportedWeight: null,
                confirmedOrders: null,
                confirmedProducts: null,
                confirmedItems: null,
                confirmedWeight: null,
                orderExportLists: [],
                vehicle: null,
                route: null,

                areas: [],
                total: 0
            },
            editSpaceDialog: false,
            editingProduct: null,
            editingOrder: null,
            areaList: [],
        }

        this.formRef = React.createRef();
    }

    componentDidMount() {
        var params = this.props?.match?.params ?? {};
        var { currentStationId } = this.props;

        if (params.id) {
            importDataService.getImportListFromVehicleById(params.id, currentStationId ).then((res) => {
                if (res) {
                    this.setState({ initialData: res})
                }
            })
        }
    }

    render() {
        const { t } = this.props;
        return (
            <Layout className='bg-content'>
                <div className='bg-body'>
                    <TopBar title={t('title.modifyImportListFromVehicle')} />
                    <div className='bg-white clearfix beecow-leasing-contract'>
                        <div className='steps-content mt-5'>
                            {this.renderContent()}
                            {this.renderEditSpacesDialog()}
                        </div>
                        <div className='steps-action' className='float-right mr-5 mt-5 d-flex'>
                            <Button type='primary' onClick={() => this.props.history?.goBack()}>
                                { t('button.back')}
                            </Button>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }

    renderContent = () => {
        const { t } = this.props;
        const { initialData } = this.state;
        const {
            code,
            exportedOrders,
            exportedProducts,
            exportedItems,
            exportedWeight,
            confirmedOrders,
            confirmedProducts,
            confirmedItems,
            confirmedWeight,
            orderExportLists,
            vehicle,
            route
        } = initialData;

        return (
            <div>
                <div className='row ml-5'>
                    <div className='col-2'>
                        <p><b>{t('tableColumn.id')}</b></p>
                        <p>{code ?? ''}</p>
                    </div>

                    <div className='col-2'>
                        <p><b>{t('tableColumn.vehicle')}</b></p>
                        <p>{`${vehicle?.code}, ${vehicle?.type}`}</p>
                    </div>

                    <div className='col-5'>
                        <p><b>{t('tableColumn.routeId')} - {t('tableColumn.routeName')}</b></p>
                        <p>{`${route?.code} (${route?.routeName})`}</p>
                    </div>

                </div>

                <div className='row ml-5'>
                    <div className='col-2'>
                        <p><b>{t('text.totalExportList')}</b></p>
                        <p>{ orderExportLists?.length ?? 0}</p>
                    </div>

                    <div className='col-2'>
                        <p><b>{t('tableColumn.totalOrders') }</b></p>
                        <p>{exportedOrders}</p>
                    </div>

                    <div className='col-2'>
                        <p><b>{t('tableColumn.totalProducts')}</b></p>
                        <p>{exportedProducts}</p>
                    </div>

                    <div className='col-2'>
                        <p><b>{t('tableColumn.totalItems')}</b></p>
                        <p>{exportedItems}</p>
                    </div>

                    <div className='col-2'>
                        <p><b>{t('tableColumn.totalWeight')}</b></p>
                        <p>{`${exportedWeight} ${unitWeight}`}</p>
                    </div>
                </div>
                <div className='row ml-5'>
                    <div className='col-2'>
                    </div>

                    <div className='col-2'>
                        <p><b>{t('tableColumn.newOrderQty')}</b></p>
                        <p className=' text-primary'>{confirmedOrders}</p>
                    </div>

                    <div className='col-2'>
                        <p><b>{t('tableColumn.newProductQty')}</b></p>
                        <p className=' text-primary'>{confirmedProducts}</p>
                    </div>

                    <div className='col-2'>
                        <p className='text-left'><b>{t('tableColumn.newItemQty')}</b></p>
                        <p className='text-primary'>{confirmedItems}</p>
                    </div>

                    <div className='col-2'>
                        <p><b>{t('tableColumn.newWeights')}</b></p>
                        <p className=' text-primary'>{`${confirmedWeight} ${unitWeight}`}</p>
                    </div>
                </div>

                <div className='mt-5'>
                    {this.renderExportList(orderExportLists)}
                </div>

            </div>
        )
    }

    mappingToDataTable = (orderItems, order) => {
        return orderItems?.map((i, index) => {
            return {
                index: index + 1,
                productCode: i.product?.code,
                productName: i.product?.name,
                productCategory: i.product?.packageType?.name,
                amount: i.exportedAmount,
                amountScanned: i.confirmedAmount ?? 0,
                scanned: i.confirmed ?? false,
                scanReasonFailed: i.confirmFailedReason ?? null,
                product: i.product ?? null,
                order: order,
                orderItem: i
            }
        });
    }

    renderExportList = (orderExportLists) => {
        const { t } = this.props;
        if (orderExportLists) {
            return orderExportLists?.map((exportList, index) => {
                const {
                    id,
                    code,
                    exportedOrders,
                    exportedProducts,
                    exportedItems,
                    confirmedOrders,
                    confirmedProducts,
                    confirmedItems,
                    orders
                } = exportList;

                return (
                    <>
                        <div className='ml-3 mr-3 mt-4 mb-5'>
                            <div className='pt-4 bg-export-list'>
                                <div className='row m-auto pl-5'>
                                    <div className='col-4 m-auto text-center'>
                                        <p><b>{t('tableColumn.exportListId')}</b></p>
                                        <Link className='text-decoration-underline' to={`/view-export-list/${id}`} target='_blank'> {code} </Link>
                                    </div>

                                    <div className='col-4 m-auto'>
                                        <div className='d-flex'>
                                            <p className='col-6'>{t('tableColumn.exportOrders')}</p>
                                            <p className='col-4'>{exportedOrders}</p>
                                        </div>
                                        <div className='d-flex'>
                                            <p className='col-6'>{t('tableColumn.exportProducts')}</p>
                                            <p className='col-4'>{exportedProducts}</p>
                                        </div>
                                        <div className='d-flex'>
                                            <p className='col-6'>{t('tableColumn.exportItems')}</p>
                                            <p className='col-4'>{exportedItems}</p>
                                        </div>
                                    </div>

                                    <div className='col-4 m-auto'>
                                        <div className='d-flex'>
                                            <p className='col-6'>{t('tableColumn.newOrderQty')}</p>
                                            <p className='col-4 text-primary'>{confirmedOrders}</p>
                                        </div>
                                        <div className='d-flex'>
                                            <p className='col-6'>{t('tableColumn.newProductQty')}</p>
                                            <p className='col-4 text-primary'>{confirmedProducts}</p>
                                        </div>
                                        <div className='d-flex'>
                                            <p className='col-6'>{t('tableColumn.newItemQty')}</p>
                                            <p className='col-4 text-primary'>{confirmedItems}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='mt-3 mb-3 pb-1'>
                                    {this.renderOrders(orders)}
                                </div>
                            </div>
                        </div>
                    </>
                )
            });
        }

    }

    renderOrders = (orders) => {
        const { t } = this.props;
        if (orders) {
            return orders?.map((o, index) => {
                var colums = this.orderTableColumns();
                var dataSource = this.mappingToDataTable(o?.orderItems, o);
                return (
                    <>
                        <div className='bg-order rounded-0 mt-0 mb-0 border ml-3 mr-3 mb-3 pt-2'>
                            <div className='row ml-5 header-table'>
                                <div className='col-md-3 d-flex'>
                                    <p className='mr-4 font-weight-bold'>{t('tableColumn.orderCode')}: </p>
                                    <Link className='text-decoration-underline' to={`/view-order/${o.id}`} target='_blank'> {o?.code} </Link>
                                </div>
                                <div className='col-md-3 d-flex'>
                                    <p className='font-weight-bold mr-3'>{t('tableColumn.createdDate')}: </p>
                                    <p>{moment.utc(o?.createdTime).format(FORMAT_TIME_DATE)}</p>
                                </div>
                            </div>

                            <div className='mt-0'>
                                <Table
                                    className='ml-3 mr-3 bg-import-summarize'
                                    columns={colums}
                                    dataSource={dataSource}
                                    pagination={false}
                                />
                            </div>
                        </div>
                    </>
                )
            });
        }
    }

    displayAssignedSpaces = (product, order, orderItem) => {
        let spaces = product?.productSpaceShippings?.map(ps => ps.space);
        let areas = [];
        spaces?.forEach(space => {
            let index = areas.findIndex(a => a.id === space.area?.id);
            if (index === -1) {
                let area = space.area;
                area.spaces = [space];
                areas.push(area);
            }
            else {
                let area = areas[index];
                area.spaces.push(space);
                areas[index] = area;
            }
        });
        if (areas.length > 0) {
            var area = areas[0];
            var areaHtml = areas.map((area, index) => {
                return (
                    <>
                        <p>{area.code + ': ' + _.join(area.spaces?.map(space => space.code), ', ')}</p>
                    </>
                )
            });

            return <>
                <div className='beecow-spaces'>
                    <div>{areaHtml}</div>
                    <Button className='beecow-spaces-action-edit'
                        icon={<EditOutlined />}
                        type='text'
                        onClick={() => this.editSpaces(order, product, area, orderItem)}>
                    </Button>
                </div>

            </>
        } else {
            return <>  <div className='beecow-spaces'>
                <div></div>
                <Button className='beecow-spaces-action-edit'
                    icon={<EditOutlined />}
                    type='text'
                    onClick={() => this.editSpaces(order, product, area, orderItem)}>
                </Button>
            </div>
            </>
        }
    }

    orderTableColumns = () => {
        const { t } = this.props;
        return [
            {
                title: t('tableColumn.no'),
                dataIndex: 'index',
            },
            {
                title: t('tableColumn.productCode'),
                dataIndex: 'productCode',
            },
            {
                title: t('tableColumn.productName'),
                dataIndex: 'productName',
            },
            {
                title: t('tableColumn.productCategory'),
                dataIndex: 'productCategory',
            },
            {
                title: t('tableColumn.assignedSpaces'),
                dataIndex: 'product',
                render: (data, record) => {
                    return (
                        <>
                            {
                                this.displayAssignedSpaces(data, record?.order, record?.orderItem)
                            }
                        </>
                    )
                }
            },
            {
                title: t('tableColumn.amount'),
                dataIndex: 'amount',
                render: (amount, record) => {
                    return (
                        <>
                            <span className='m-auto'>{`${record.amountScanned}/${record.amount}`}</span>
                        </>
                    )
                }
            },
            {
                title: t('tableColumn.scanned'),
                dataIndex: 'scanned',
                render: (scanned, record) => {
                    return (
                        <Checkbox
                            checked={scanned}
                            disabled={true}
                        />
                    )
                }
            },
            //{
            //    title: 'Reason',
            //    dataIndex: 'scanReasonFailed',
            //    render: (scanReasonFailed, record) => {
            //        return (
            //            <>
            //                <span className='m-auto'>{record.scanReasonFailed}</span>
            //            </>
            //        )
            //    }
            //},
        ];
    }

    renderEditSpacesDialog = () => {
        const { t } = this.props;
        var { editingOrder, editingProduct, areaList } = this.state;
        var values = this.getInitialEditData()

        return <Modal
            title={t('title.editAssignedSpaces') }
            centered
            visible={this.state.editSpaceDialog}
            maskClosable={false}
            onOk={this.onSaveAssignedSpace}
            onCancel={this.cancelEditSpaces}
            okText={t('button.save')}
            cancelText={t('button.cancel')}
            width={680}
        >
            <div className='row col-12 m-0 p-0'>
                <div className='col-12'>
                    <p><b>{ t('text.order')}:</b> {editingOrder?.code}</p>
                </div>
                <div className='row col-12'>
                    <p className='col-6 pr-3'><b>{t('text.product')}:</b> {editingProduct?.name} ({editingProduct?.code})</p>
                    <p className='col-3 pr-3'><b>{t('text.amount')}:</b> {editingProduct?.total ?? 0}</p>
                    <p className='col-3 pr-3'><b>{t('text.remaining')}:</b> <span class='text-danger'>{editingProduct?.remainingAmount ?? 0}</span></p>
                </div>
                <div className='row col-12'>
                    <EditSpacesComponent
                        t={t}
                        ref={this.formRef}
                        areas={areaList}
                        initialData={values}
                        onChangeRemainingAmount={this.onChangeRemainingAmount}
                    />
                </div>
            </div>
        </Modal>
    }

    async editSpaces(order, product, area, orderItem) {
        var { currentStationId } = this.props;
        product.remainingAmount = product.total;
        let promises = [];
        promises.push(areaStationDataService.getAreasForShippingByStationId(currentStationId));
        promises.push(areaStationDataService.getSpacesByAreaId(area?.id, 1, 20, true, false));
        let [areas, pagingSpaces] = await Promise.all(promises);
        product.total = orderItem.confirmedAmount;
        this.setState({
            editSpaceDialog: true,
            areaList: areas,
            editingOrder: order,
            editingProduct: product,
        }, () => {
            var values = this.getInitialEditData();
            if (this.formRef && this.formRef.current && this.formRef.current.setFieldsValue) {
                this.formRef.current.setFieldsValue(values);
            }

            if (this.formRef && this.formRef.current && this.formRef.current.setRemainingAmount) {
                this.formRef.current.setRemainingAmount();
            }
        });
    }

    cancelEditSpaces = () => {
        this.setState({
            editSpaceDialog: false,
            initialData: {
                ...this.state.initialData,
                areas: [],
                total: 0
            }
        });

        var values = this.getInitialEditData()
        this.formRef.current.clearCache();
    }

    onChangeRemainingAmount = (remainingAmount) => {
        var { editingProduct } = this.state;
        editingProduct.remainingAmount = remainingAmount;
        this.setState({
            editingProduct: editingProduct
        });
    }

    getInitialEditData = () => {
        var { editingProduct } = this.state;

        var spaces = editingProduct?.productSpaceShippings?.map(ps => {
                return {
                    space: ps.space,
                    total: ps.total ?? 0
                }
            });
        var areas = spaces?.map((i) => {
            return {
                areaId: i?.space?.area?.id,
                area: i?.space?.area,
                spaceId: i?.space?.id,
                space: i?.space,
                amount: i?.total
            }
        });

        return {
            areas: areas,
            total: editingProduct?.total
        }
    }

    onSaveAssignedSpace = () => {
        var { currentStationId } = this.props;
        var { editingProduct } = this.state;
        
        if (this.formRef) {
            var remainingAmount = this.formRef.current.getRemainingAmount();
            if (remainingAmount < 0 || remainingAmount > 0) {

                console.log('Can not save assigned space');
                return;
            }

            var editSpacesData = this.formRef.current.getFieldsValue();
            var { areas } = editSpacesData;
            var spaceShippings = areas?.map((a) => {
                return {
                    spaceId: a.spaceId,
                    total: a.amount
                }
            });
            var spaceNewIds = areas?.map((a) => a.spaceId);

            let request = {
                stationId: currentStationId,
                productId: editingProduct?.id,
                spaceShippings: spaceShippings,
                spaceNewIds: spaceNewIds,
                isImportedFromVehicle: true
            };

            importDataService.updateProductSpaceShipping(request)
                .then((res) => {
                    this.refresh();
                    if (this.formRef && this.formRef.current && this.formRef.current.clearCache) {
                        this.formRef.current.clearCache();
                        var values = this.getInitialEditData()
                        this.formRef.current.setFieldsValue(values);
                    }
                })
                .finally(() => {
                    this.cancelEditSpaces();
                });
        }
    }

    refresh() {
        let importListId = this.props.match?.params?.id;
        var { currentStationId } = this.props;
        if (importListId) {
            importDataService.getImportListFromVehicleById(importListId, currentStationId).then((res) => {
                if (res) {
                    this.setState({ initialData: res }, () => {
                        if (this.formRef && this.formRef.current && this.formRef.current.clearCache) {
                            this.formRef.current.clearCache();
                        }
                    });
                }
            })

        }
    }
}