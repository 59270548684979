import React, { Component } from 'react'
import { Option } from 'antd/lib/mentions';
import { Select, Form, Button, Row, Col, Image, Table, } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import './choose-packages.component.scss'
import moment from 'moment'
import iconWareHouse from '../../../assets/icons/warehouse.png'
import { getSpecialServiceIcon } from '../../../utils/order'
import { Link } from 'react-router-dom'
import { deliveryHouseMethods } from '../../../constants/order-constant';

const unitWeight = 'Kg';
export default class ChoosePackagesComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            routes: props.routes,
            initialData: props.initialData,
            routeSelected: {},
            selectedData: props.selectedData
        }

        this.formRef = React.createRef()
    }

    componentDidMount() {
        let data = this.props.selectedData;
        this.onChangeRoute(data.selectedRouteId, true);
    }

    render() {
        const { t } = this.props;
        var { routes } = this.props;
        var { initialData, routeSelected, showSelectAll } = this.state;
        if (!initialData || !routes) {
            return <></>
        }

        var { vehicleRoutes } = routes?.find((r) => r.id === routeSelected.id) ?? [];

        return (
            <div>
                <div className='export-list mT50'>
                    <Form ref={this.formRef}>
                        <Row>
                            <Col span={6}>
                                <div className='select-route mL30'>
                                    <label for='html'>{t('messages.warningSelectRoute')}</label>
                                    <Form.Item name={['route', 'id']}
                                        rules={[{
                                            required: true,
                                            message: t('messages.warningSelectRoute')
                                        }]}>
                                        <Select
                                            showSearch
                                            placeholder={t('placeholder.selectRoute')}
                                            optionFilterProp='children'
                                            onChange={this.onChangeRoute}
                                        >
                                            {routes?.map(({ id, routeName }) => <Option value={id} key={id}>{routeName}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </div>
                            </Col>
                            <Col span={6} offset={1}>
                                <div className='select-route'>
                                    <label for='html'>{t('text.pleaseSelectVehicle')}</label>
                                    <Form.Item name={['vehicle', 'id']} rules={[{
                                        required: true,
                                        message: t('messages.pleaseSelectVehicle')
                                    }]}>
                                        <Select
                                            showSearch
                                            placeholder={t('placeholder.selectVehicle')}
                                            optionFilterProp='children'
                                            onChange={this.onChangeVehicle}
                                        >
                                            {vehicleRoutes?.map(({ vehicle }) => <Option value={vehicle.id} key={vehicle.id}>{`${vehicle.code}, ${vehicle?.type?.name}`}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </div>
                            </Col>
                            <Col span={4} offset={5}>
                                <div className='pull-right'>
                                    <Button type='primary' onClick={this.handleCheckAll.bind(this)}>
                                        {!showSelectAll ? <span>{t('text.selectAllOrders')}</span> : <span>{t('text.unSelectAllOrders')}</span>}
                                    </Button>
                                    <p className='selected-order clearfix'>{t('text.selected')} {` ${t('text.orders')}`}</p>
                                </div>
                            </Col>
                        </Row>
                        {this.renderTableOrders()}
                    </Form>
                </div>
            </div>
        );
    }

    renderTableOrders() {
        var { routes } = this.props;
        const { initialData, routeSelected } = this.state;
        var { orders } = routes?.find((r) => r.id === routeSelected.id) ?? [];
        if (!orders || orders.length === 0) {
            return;
        }

        let columns = this.getTableColumns(orders);
        var orderIdListSelected = initialData?.orders?.map((o) => o.id);
        const rowSelection = {
            selectedRowKeys: orderIdListSelected,
            onChange: this.onSelectOrder,
        };
        var dataSource = this.getData(orders);

        return (<Content className='content'>
            <div>
                <Table
                    columns={columns}
                    rowSelection={rowSelection}
                    bordered
                    dataSource={dataSource}
                    onchange={this.onChange}
                />
            </div>
        </Content>
        )
    }

    onChangeRoute = (routeId, setFormValue) => {
        const { routes, selectedData } = this.props;
        const routeSelected = routes?.find(r => r.id === routeId);
        var route = {
            id: routeSelected.id,
            code: routeSelected.code,
            routeName: routeSelected.routeName

        }

        const { vehicleRoutes } = routeSelected;
        var vehicleRoute = vehicleRoutes?.find((v) => v.vehicle.id === selectedData?.selectedVehicleId);

        let initialData = {
            route: route,
            vehicle: vehicleRoute?.vehicle
        };

        if (setFormValue) {
            initialData.orders = routeSelected?.orders?.filter(order => order.isChecked);
            this.formRef.current.setFieldsValue(initialData);
        }

        this.setState({
            routeSelected: routeSelected,
            initialData: initialData
        });
    }

    onChangeVehicle = (vehicleId) => {
        const { routeSelected, initialData } = this.state;
        const { vehicleRoutes } = routeSelected;
        var vehicleRoute = vehicleRoutes?.find((v) => v.vehicle.id === vehicleId);
        this.setState({
            initialData: {
                ...initialData,
                vehicle: vehicleRoute.vehicle
            }
        });
    }

    getSenderInfo = (orders) => {
        let formattedAddress = this.getFormattedAddress(orders?.addressRecipient) || this.props.t('text.noAddress');
        let senderInfo = [orders.senderName, orders.senderPhone, formattedAddress];
        return senderInfo.join('<br/>');
    }

    getFormattedAddress(address) {
        let arr = [];
        if (address && address.street) {
            arr.push(address.street);
        }
        if (address && address.ward) {
            let ward = address.ward.prefix + ' ' + address.ward.name;
            arr.push(ward);
        }
        if (address && address.district) {
            let district = address.district.prefix + ' ' + address.district.name;
            arr.push(district);
        }
        if (address && address.city) {
            arr.push(address.city.name);
        }
        return arr.join(', ');
    }

    onChange(pagination, filters, sorter, extra) {
        console.log('params', pagination, filters, sorter, extra);
    }

    handleCheckAll() {
        // const { orders } = this.props || []
        // const { showSelectAll } = this.state
        // let keys = orders.map((p) => p.id)
        // if (!showSelectAll) keys = []
        // this.setState({ selectedRowKeys: keys, showSelectAll: !showSelectAll })

        // this.props.updateSelectedOrder(keys)
        // this.props.setSelectedOrderIds(keys)
    }

    onSelectOrder = orderIds => {

        var { initialData, routeSelected } = this.state;
        var { orders } = routeSelected;
        var ordersSelected = orders.filter((o) => orderIds.includes(o.id));

        this.setState({
            initialData: {
                ...initialData,
                orders: ordersSelected
            }
        });
    }

    getTableColumns() {
        const { t } = this.props;
        var { routes } = this.props;
        const { selectedRoute } = this.state;

        const orders = routes?.find(x => x.id === selectedRoute)?.orders
        let deliveries = orders?.map(d => { return { text: d.deliveryMethod, value: d.deliveryMethod } });
        let deliveryOptions = deliveries?.filter((d, i) => deliveries?.findIndex(o => o.value === d.value) === i);
        let packages = orders?.map(d => { return { text: d.packageInfo, value: d.packageInfo } });
        let packageInfoOptions = packages?.filter((d, i) => packages?.findIndex(o => o.value === d.value) === i);
        let columns = [
            {
                title: t('tableColumn.orderId'),
                dataIndex: 'orderId',
                sorter: (a, b) => a.orderId.localeCompare(b.orderId),
                render: (text, data) => <Link target='_blank' to={`/view-order/${data.id}`}>{text}</Link>
            },
            {
                title: t('tableColumn.receiverAddress'),
                dataIndex: 'from',
                render: text => <span dangerouslySetInnerHTML={{ __html: text }} />
            },
            {
                title: t('tableColumn.packageInfo'),
                dataIndex: 'packageInfo',
                sorter: (a, b) => a.packageInfo.localeCompare(b.packageInfo),
                render: (name) => <span>{name}{getSpecialServiceIcon(name)}</span>,
                filters: packageInfoOptions,
                onFilter: (value, record) => record.packageInfo.indexOf(value) === 0,
            },
            {
                title: t('tableColumn.deliveryMethod'),
                dataIndex: 'deliveryMethod',
                sorter: (a, b) => a.deliveryMethod.localeCompare(b.deliveryMethod),
                render: (text) => <span>{text}<br />{deliveryHouseMethods.includes(text?.toLowerCase()) && <Image src={iconWareHouse} width={30} />}</span>,
                filters: deliveryOptions,
                onFilter: (value, record) => record.deliveryMethod.indexOf(value) === 0,
            },
            {
                title: t('tableColumn.totalWeight'),
                dataIndex: 'totalWeight',
                sorter: (a, b) => a.totalWeight - b.totalWeight,
                render: text => <span>{text} {unitWeight}</span>
            },
            {
                title: t('tableColumn.totalProductsInPackage'),
                dataIndex: 'totalProductsInPackage',
                sorter: (a, b) => a.totalProductsInPackage - b.totalProductsInPackage,
            },
            {
                title: t('tableColumn.totalItemsInPackage'),
                dataIndex: 'totalItemsInPackage',
                sorter: (a, b) => a.totalItemsInPackage - b.totalItemsInPackage,
            },
            {
                title: t('tableColumn.createdDate'),
                dataIndex: 'createdDate',
                sorter: (a, b) => moment(a.createdDate) - moment(b.createdDate),
                render: text => <div dangerouslySetInnerHTML={{ __html: moment.utc(text).local().format('hh:mm A <br> DD/MM/YYYY') }} />
            }
        ];
        return columns;
    }

    getData = (orders) => {
        return orders?.map((o) => {
            let totalWeight = o.orderItems?.map(x => x.product?.weight * x.importedAmount).reduce((a, b) => a + b, 0)
            let totalProduct = o.orderItems.length
            let totalItemsInPackage = o.orderItems?.map(x => x.importedAmount).reduce((a, b) => a + b, 0)
            const result = {
                key: o.id,
                id: o.id,
                orderId: o.code,
                from: this.getSenderInfo(o),
                deliveryMethod: o.delivery?.name,
                packageInfo: o.specialOption?.name,
                createdDate: o.createdTime,
                amount: o.importedAmount,
                packageSizeWeight: o.packageSizeWeight,
                totalWeight: totalWeight,
                totalProductsInPackage: totalProduct,
                totalItemsInPackage: totalItemsInPackage,
            }

            if (o.status?.subStatusName) {
                result.status = `${o.status?.statusName}-${o.status?.subStatusName}`
            }
            else {
                result.status = o.status?.statusName;
            }

            return result
        }) ?? []
    }

    getFieldsValue = () => {
        var { initialData } = this.state;
        return initialData;
    }

    validateFields = () => {
        return this.formRef.current.validateFields();
    }

    setFieldsValue = (data) => {
        const { routes } = this.props;
        const { route } = data;
        var routeSelected = routes?.find((r) => r.id === route.id) ?? [];
        this.setState({
            initialData: data,
            routeSelected: routeSelected,
        }, () => {
            this.formRef.current.setFieldsValue(data)
        });
    }
}
