import React, { Component } from 'react';
import {  } from 'antd';
import { Pie, Line } from '@ant-design/charts';
import orderDataService from '../../../data-services/orders/orders-data.service';
import moment from 'moment';
import { FORMAT_YEAR_MONTH } from '../../../common/constants';
import { formatDate } from '../../../utils/common';

var today = moment().add('month', 1);
let formatDataGraph = [];
for(let i = 0; i < 12; i ++){
    let test = {
        month: formatDate(today.add('month', -1), FORMAT_YEAR_MONTH),
        amount: 0
    };
    formatDataGraph.push(test);
};
formatDataGraph = formatDataGraph.reverse();
export class ChartStatisticComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSourcePie: [],
            dataSourceGraph: [],
            textRouteNameSelected: '',
        listRoutes: [],
        };
    }

    handleDrawChartPie() {
        const config = {
        data: this.state.dataSourcePie,
        angleField: 'value',
        colorField: 'type',
        radius: 0.5,
        legend: false,
        label: {
            type: 'spider',
            labelHeight: 19,
            content: '{percentage}\n{name}'
        },
        pieStyle: {
            lineWidth: 0.5,
        },
        interactions: [
            {
                type: 'element-active',
            },
            {
                type: 'element-single-selected',
            },
        ],
    };
    return config;
};

    handleDrawChartGraph() {
        const config = {
            data: this.state.dataSourceGraph,
            xField: 'month',
            yField: 'amount',
            point: {
                size: 5,
                shape: 'diamond',
                style: {
                    fill: 'white',
                    stroke: '#5B8FF9',
                    lineWidth: 2,
                },
            },
        };
        return config;
    }

    componentDidMount() {
        orderDataService.getOrderAssignedOnStationAsync().then((res) => {
            if(res.records > 0){
                this.setDataSourcePie(res.rows);
            }
        })
    }

    setDataSourcePie(routes) {    
        let dataPie = [];
        routes.map((i) => {
            let item = {
                type: i.routeName,
                value: i.amount,
                code: i.code
            };
            dataPie.push(item)
        });
        this.setState({ 
            dataSourcePie: dataPie,
            listRoutes: routes
        });
        var maxValue = routes.reduce((max, obj) => (max.amount > obj.amount) ? max : obj);
        this.handleDataChartGraph(maxValue);
    };

    handleDataChartGraph(maxValue){
        const { listRoutes } = this.state;
        if(listRoutes){
            formatDataGraph.map((i) => {
                let isCheck = maxValue.amountOrderInMonth?.find(a => a.month === i.month)
                if(isCheck){
                    i.amount = isCheck.amount
                };
            });
            this.setState({ 
                dataSourceGraph: formatDataGraph,
                textRouteNameSelected: maxValue.routeName
            });
        }
    };

    clickAreaChartPie(item) {
        const { listRoutes } = this.state;
        if(item[0]?.name){
            let getData = listRoutes.find((e) => e.code == item[0].data.code);
            this.setState({ textRouteNameSelected: item[0]?.name})
            this.handleDataChartGraph(getData);
        }
    }

    render() {
        const { textRouteNameSelected } = this.state;
        let configPie = this.handleDrawChartPie();
        let configGraph = this.handleDrawChartGraph();
        return (
            <div style={{display: 'flex'}}>
                <div style={{width:'50%'}}>
                    <Pie
                        onReady={(plot) => {
                            plot.on('plot:click', (evt) => {
                                const { x, y } = evt;
                                const { xField } = plot.options;
                                const tooltipData = plot.chart.getTooltipItems({ x, y });
                                this.clickAreaChartPie(tooltipData)
                            });
                        }}
                        {...configPie}
                    />
                </div>
                <div style={{width:'50%'}}>
                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: 10}}>
                        <p>{textRouteNameSelected}</p>  
                    </div>
                    <Line {...configGraph} />
                </div>
            </div>
        );
    };
}