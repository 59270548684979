
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ViewImportFromVehicleDetailScreen from './view-detail.screen';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
    return {
        currentStationId: state.session?.auth?.user?.currentStationId,
    };
};

export default compose(
    withTranslation('translations'),
    connect(mapStateToProps, null),
    withRouter
)(ViewImportFromVehicleDetailScreen)
