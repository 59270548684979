import { connect } from "react-redux";
import { TopBar } from "./topbar.component";
import { resetSession } from "../../modules/session/session.actions";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { withTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
    return {
        signedInUser: state.session?.auth?.user,
        companyLogoUrl: state.session?.workspace?.info?.logoPath,
        companyName: state.session?.workspace?.info?.name,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: (request) => dispatch(resetSession(request))
    }
}

export default compose(
    withTranslation("translations"),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(TopBar)
