import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getListExportOrders } from '../../modules/orders/orders.actions';
import ListOrderExportScreen from './list-order-export.screen';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
    return {
        currentStationId: state.session?.auth?.user?.currentStationId,
        current: state.order?.current || 1,
        pageSize: state.order?.pageSize || 20,
        total: state.order?.total,
        export: state.order?.listExport || [],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        listExport: (stationId, page, pageSize) => dispatch(getListExportOrders(stationId, page, pageSize)),
    }
}

export default compose(
    withTranslation('translations'),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(ListOrderExportScreen)

