import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PickupScreen } from './pickup.screen';
import { bindActionCreators } from 'redux'
import { clearData } from '../../../modules/orders/orders.actions'
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    clearData
  }, dispatch)
}

const mapStateToProps = ({ order, session }) => {
  return {
    selectedOrders: order?.selectedOrders || [],
    currentStationId: session?.auth?.user?.currentStationId,
  }
}

export default compose(
    withTranslation('translations'),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(PickupScreen)