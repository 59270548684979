import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { getDeliveryListDetailById } from '../../modules/orders/orders.actions';
import { ViewDeliveryListDetailScreen } from './view-delivery-list-detail.screen';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
  let deliveryListDetail =  state.order?.dataForDeliveryListDetail?.deliveryListDetail;
  return {
    currentStationId: state.session?.auth?.user?.currentStationId,
    vehicle: deliveryListDetail?.vehicle,
    deliveryCode: deliveryListDetail?.code,
    createdTime: deliveryListDetail?.createdTime,
    orders: deliveryListDetail?.orders
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getDeliveryListDetailById
  }, dispatch)
}


export default compose(
    withTranslation('translations'),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(ViewDeliveryListDetailScreen)
