
import React, { Component } from 'react';
import ScanProductComponent from './scan-product/scan-product.component';
import ReviewConfirmQuantityComponent from './review-confirm-quantity/review-confirm-quantity.component';

export default class ConfirmQuantityComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            productExportReason: props.productExportReason,
            exportList: props.exportList,
            vehicle: props.vehicle,
            route: props.route,
        }

        this.scanProductForm = React.createRef();
        this.reviewConfirmForm = React.createRef();
    }

    render() {
        const { current } = this.state;
        const steps = this.createSteps();
        return (
            <div className='ml-3 mr-3'>
                {steps[current].content}
            </div>
        )
    }

    createSteps = () => {
        const { t } = this.props;
        const { exportList, vehicle, route, productExportReason } = this.state;
        const steps = [
            {
                content: <ScanProductComponent
                    t={t}
                    ref={this.scanProductForm}
                    prev={this.props.prev}
                    next={this.nextToReviewPage}
                    route={route}
                    vehicle={vehicle}
                    exportList={exportList}
                    productExportReason={productExportReason}
                />,
            },
            {
                content: <ReviewConfirmQuantityComponent
                    t={t}
                    ref={this.reviewConfirmForm}
                    prev={this.prev}
                    finishExportList={this.finishExportList}
                    route={route}
                    vehicle={vehicle}
                    exportList={exportList}
                    productExportReason={productExportReason}
                />,
            },
        ];

        return steps;
    }

    nextToReviewPage = () => {
        var exportList = this.scanProductForm.current.getFieldsValue();

        this.setState({
            ...this.state,
            exportList: exportList
        }, () => {
            this.setState({
                current: this.state.current + 1
            });
        })
    }

    prev = () => {
        this.setState({
            current: this.state.current - 1
        });
    }

    finishExportList = () => {
        var exportList = this.reviewConfirmForm.current.getFieldsValue();
        this.setState({
            ...this.state,
            exportList: exportList,

        }, () => {
            this.props.finishExportList();
        });

    }

    getFieldsValue = () => {
        var { exportList } = this.state;

        // Add checked flag
        exportList.finished = true;

        return exportList;
    }

    validateFields = () => {
        return this.reviewConfirmForm.current.validateFields();
    }
}