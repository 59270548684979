import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getListImportOrders } from '../../modules/import/import.action';
import ListOrderImportScreen from './list-order-import.screen';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
    return {
        currentStationId: state.session?.auth?.user?.currentStationId,
        current: state.import?.current || 1,
        pageSize: state.import?.pageSize || 20,
        total: state.import?.total,
        import: state.import?.listImport || [],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        listImport: (stationId, page, pageSize) => dispatch(getListImportOrders(stationId, page, pageSize)),
    }
}

export default compose(
    withTranslation('translations'),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(ListOrderImportScreen)

