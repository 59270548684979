import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { setSelectedVehicleForDelivery, setVehiclesForDelivery } from '../../../modules/orders/orders.actions';
import actionTypes from '../../../modules/orders/orders.types';
import { DeliveryOrderListScreen } from './delivery-order-list.screen';

const mapStateToProps = (state) => {
  return {
    currentStationId: state.session?.auth?.user?.currentStationId,
    isSelectedAllOrders: state?.order?.isSelectedAllOrders,
    selectedOrdersForDelivery: state?.order?.selectedOrdersForDelivery,
    unselectedOrdersForDelivery:state?.order?.unselectedOrdersForDelivery,
    allOrdersHaveBeenSelected: state?.order?.allOrdersHaveBeenSelected
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSelectedOrderList: (
      isSelectedAllOrders,
      selectedOrdersForDelivery,
      unselectedOrdersForDelivery
    ) =>
      dispatch({
        type: actionTypes.UPDATE_SELECTED_DELIVERY_ORDER_LIST,
        payload: {
          isSelectedAllOrders,
          selectedOrdersForDelivery,
          unselectedOrdersForDelivery,
        },
      }),
    setVehiclesForDelivery: vehicles => dispatch(setVehiclesForDelivery(vehicles)),
    setSelectedVehicleForDelivery: vehicle=> dispatch(setSelectedVehicleForDelivery(vehicle))
  };
};

export default compose(
    withTranslation('translations'),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(DeliveryOrderListScreen)