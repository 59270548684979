import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { fetchListOrders } from '../../modules/orders/orders.actions';
import { DashboardScreen } from './dashboard.screen';

const mapStateToProps = (state) => {
    return {
        orders: state.order?.list,
        currentStationId: state.session?.auth?.user?.currentStationId,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        listOrders: (stationId) => dispatch(fetchListOrders(stationId))
    }
}

export default compose(
    withTranslation('translations'),
    connect(mapStateToProps, mapDispatchToProps),
)(DashboardScreen)
