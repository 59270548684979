import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { EditDeliveryOrderScreen } from './edit-delivery-order.screen';

import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

const mapStateToProps = ({ session }) => {
  return {
    currentStationId: session?.auth?.user?.currentStationId
  }
}

export default compose(
    withTranslation('translations'),
    connect(mapStateToProps, null),
    withRouter
)(EditDeliveryOrderScreen)
