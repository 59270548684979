import { connect } from 'react-redux';
import { fetchListOrders } from '../../modules/orders/orders.actions';
import { ListOrdersScreen } from './list-order.screen';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
    return {
        orders: state.order?.list,
        currentStationId: state.session?.auth?.user?.currentStationId,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        listOrders: (stationId) => dispatch(fetchListOrders(stationId))
    }
}
export default compose(
    withTranslation('translations'),
    connect(mapStateToProps, mapDispatchToProps),
)(ListOrdersScreen)