import { Layout, notification } from 'antd';
import Form from 'antd/lib/form/Form';
import React, { Component } from 'react'
import { stepListPickup } from '../../../constants/order-constant';
import orderDataService from '../../../data-services/orders/orders-data.service';
import NextPage from '../../shared/components/next-page';
import TopBar from '../../../components/topbar';
import EditOrderPickup from './edit-pickup-order';
import ReviewOrderPickup from './../review-orders-pickup';

const alertType = {
    success: 'success',
    warning: 'warning',
    error: 'error',
};
export class PickupScreen extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentPage: stepListPickup.editPickup,
            data: {}
        }
        this.formRef = React.createRef()
    }

    renderListPickup() {
        const { currentPage, data } = this.state;
        switch (currentPage) {
            case (stepListPickup.reviewPickup):
                return <ReviewOrderPickup data={data} />
            default: return <EditOrderPickup data={data} setSelectedOrderIds={this.setSelectedOrderIds.bind(this)} setSelectedVehicleId={this.setSelectedVehicleId.bind(this)} />
        }
    }

    handleNextPage() {
        const { currentPage, data } = this.state
        this.formRef?.current?.validateFields().then(() => {
            const values = this.formRef.current.getFieldsValue()
            this.setState({ currentPage: currentPage - 2, data: { ...data, ...values } })
        })
    }

    handleBackHomePage() {
        this.props.history.push('/list-pickup')
    }

    handleBackPage() {
        const { currentPage } = this.state
        this.setState({ currentPage: currentPage + 2 })
    }

    handleFinish() {
        const { data } = this.state
        this.formRef?.current?.validateFields().then(async () => {
            const orderIds = this.props.selectedOrders.map((x) => x.id)
            var orderPickupId = this.props?.match?.params?.id;
            const request = { orderPickupId, orderIds, vehicleId: data.vehicleId }
            orderDataService.updateOrderPickup(request).then((res) => {
                if (res.success) {
                    this.formRef.current.resetFields()
                    this.props.clearData()
                    this.props.history.push(`/view-pickup-list/${res.data.id}`)
                    this.alertSuccess();
                } else {
                    this.alertFailupdate();
                }
            })
        })
    }

    setSelectedOrderIds = (orderIds) => {
        this.formRef.current.setFieldsValue({ orderIds });
    }

    setSelectedVehicleId = (vehicleId) => {
        this.formRef.current.setFieldsValue({ vehicleId });
    }

    render() {
        const { t } = this.props;
        const { currentPage } = this.state
        return (
            <Layout className='bg-content'>
                <div className='bg-body'>
                    <TopBar title={currentPage === stepListPickup.listPickup ? t('title.editPickupList') : t('title.reviewPickupList')} />
                    <div className='bg-white clearfix'>
                        <Form ref={this.formRef} >
                            {this.renderListPickup()}
                            <NextPage
                                handleNext={this.handleNextPage.bind(this)}
                                handleBack={this.handleBackPage.bind(this)}
                                handleCancel={this.handleBackHomePage.bind(this)}
                                handleFinish={this.handleFinish.bind(this)}
                                showCancel={currentPage === stepListPickup.editPickup}
                                hideNext={currentPage === stepListPickup.reviewPickup || currentPage === stepListPickup.viewPickupList}
                                hideBack={currentPage === stepListPickup.editPickup || currentPage === stepListPickup.viewPickupList}
                                hideClose={currentPage === stepListPickup.editPickup || currentPage === stepListPickup.reviewPickup}
                                hideFinish={currentPage === stepListPickup.editPickup}
                                textFinish={t('button.updatePickupList')}
                            />
                        </Form>
                    </div>
                </div>
            </Layout>
        )
    }

    alert = (type, message, description) => {
        notification[type]({
            message: message,
            description: description,
        });
    };

    alertSuccess = () => {
        this.alert(
            alertType.success,
            'Success',
            'Update successful'
        );
        return;
    }

    alertFailupdate = () => {
        this.alert(
            alertType.error,
            'error',
            'Update failed!'
        );
        return;
    }

}
