import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SelectAdress } from './select-adress-component'
import { getDistrictByCityId, getWardsByDistrictId } from '../../../../modules/orders/orders.actions'
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

const mapStateToProps = ({ order }) => {
    return {
        initData: order.create?.initData || {},
        receiverWards: order.create?.receiverWards || [],
        receiverDistricts: order.create?.receiverDistricts || [],
        senderWards: order.create?.senderWards || [],
        senderDistricts: order.create?.senderDistricts || []
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getDistrictByCityId,
        getWardsByDistrictId
    }, dispatch)
}


export default compose(
    withTranslation('translations'),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(SelectAdress)
