import React, { Component } from 'react'
import { Form, Input, Button, Row, Col, DatePicker, Select, message, Modal } from 'antd';
import Layout, { Content, Header } from 'antd/lib/layout/layout';
import SelectAddress from '../shared/components/select-address/select-adress.container';
import { Option } from 'antd/lib/mentions';
import userDataService from '../../data-services/user/user-data.service';
import { station, driverInnerCity, driverInnerProvince } from '../../constants/user-constants';
import TopBar from '../../components/topbar';
import moment from 'moment';
import 'moment/locale/vi';
import 'moment/locale/es';
import languageService from '../../services/language.service';

export class ProfileScreen extends Component {
    constructor(props) {
        super(props)
        this.state = {
            password: '',
            showStation: false,
            ModalConfirmChangePassword:
            {
                visible: false,
                modalText: props.t('messages.changePassword'),
                confirmLoading: false
            }
        }
        this.formRef = React.createRef()
        this.changeUserType = this.changeUserType.bind(this)
        this.backPage = this.backPage.bind(this)
    }

    componentDidMount() {
        const { userId } = this.props?.match?.params || {}
        this.props.getInitUser()
        this.props.getCities()
        userDataService.getUserId(userId).then((res) => {
            const { address, userTypeId } = res || {}
            const { cityId, districtId, wardId } = address || {}
            res.doB = moment(res.doB ?? '')
            this.phone = res.phone
            this.email = res.email
            this.passwordHash = res.passwordHash
            this.setFieldToEmpty({ ...res })

            if (districtId) this.props.getDistrictByCityId(cityId)
            if (wardId) this.props.getWardsByDistrictId(districtId)
            this.setState({
                showStation: this.props.initUser.roles?.some((x) => x.id === userTypeId && (x.code === station || x.code === driverInnerCity || x.code === driverInnerProvince))
            })
        })
    }

    setFieldToEmpty(data) {
        this.formRef.current?.setFieldsValue(data)
    }

    getCity(city) {
        return this.props.cities?.cities.find(x => x.id === city)?.name || ''
    }

    updateUser(values) {
        const { t } = this.props;
        userDataService.update(values).then(() => {
            message.success(t('messages.updateUserSuccess'))
            this.props.updateUserName(values.fullName)
            this.backPage()
        }).catch(({ data }) => {
            message.error(data.message)
        })
    }

    resetPassword = () => {
        const { t } = this.props;
        this.setState({
            ModalConfirmChangePassword:
            {
                ...this.state.ModalConfirmChangePassword,
                confirmLoading: true

            }
        })
        const { userId } = this.props?.match?.params || {}
        if (userId) {
            userDataService.resetPassword({
                id: userId
            }).then(() => {
                this.setState({
                    ModalConfirmChangePassword:
                    {
                        ...this.state.ModalConfirmChangePassword,
                        confirmLoading: false,
                        visible: false
                    }
                })
                message.success(t('messages.resetPasswordSuccess'))
                try{
                    this.props.signOut({userId});
                }catch(e){
                    console.log(e);
                }
            })
        }
    }

    showModal = () => {
        this.setState({
            ModalConfirmChangePassword:
            {
                ...this.state.ModalConfirmChangePassword,
                visible: true,

            }
        })
    };

    handleCancel = () => {
        this.setState({
            ModalConfirmChangePassword:
            {
                ...this.state.ModalConfirmChangePassword,
                visible: false

            }
        })
    };

    changeUserType(value) {
        const { roles } = this.props.initUser
        const showStation = roles.some((x) => x.id === value && (x.code === station || x.code === driverInnerCity || x.code === driverInnerProvince))
        this.setState({ showStation })
    }

    backPage() {
        this.props.history.push('/create-order')
    }

    onChangeReceiverCity(id) {
        this.props.getDistrictByCityId(id, false)
        this.setFieldToEmpty({
            address: {
                districtId: null,
                wardId: null
            }
        })
    }

    onChangeReceiverDistrict(id) {
        this.props.getWardsByDistrictId(id, false)
        this.setFieldToEmpty({
            address: {
                wardId: null
            }
        })
    }

    render() {
        const { t } = this.props;
        const { initUser, address } = this.props
        const { roles, status, stations } = initUser
        const { cities, districts, wards } = address

        return (
            <Layout className='bg-content'>
                <div className='bg-body'>
                    <TopBar title={t('title.updateUser')} />
                    <div className='bg-white add-edit-user clearfix'>
                        <Content>
                            <Form
                                ref={this.formRef}
                                layout='vertical'
                                requiredMark={false}
                                onFinish={this.updateUser.bind(this)}
                            >
                                <Row>
                                    <Form.Item name='id' hidden />
                                    <Form.Item name='addressId' hidden />
                                    <Col span={8} offset={2}>
                                        <Form.Item
                                            label='Full Name'
                                            name='fullName'
                                            rules={[{
                                                required: true,
                                                message: t('messages.pleaseInputName')
                                            }]}>
                                            <Input placeholder={t('placeholder.inputName')} />
                                        </Form.Item>
                                        <Form.Item
                                            label='Phone'
                                            name='phone'
                                            rules={[{
                                                required: true,
                                                message: t('messages.pleaseInputSenderPhone')
                                            },
                                            {
                                                pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
                                                message: t('messages.pleaseInputValidPhone')
                                            }]}
                                        >
                                            <Input type='tel' placeholder={t('placeholder.inputPhone')} disabled={this.phone?.length > 0} />
                                        </Form.Item>
                                        <Form.Item
                                            name='doB'
                                            label={t('text.dob')}
                                            rules={[{
                                                required: true,
                                                message: t('messages.pleaseInputDOB'),
                                            }]}>
                                            <DatePicker locale={languageService.getLocale()} />
                                        </Form.Item>
                                        <Form.Item
                                            name='email'
                                            label={t('text.email')}
                                            rules={[
                                                {
                                                    type: 'email',
                                                    message: t('messages.notValidEmail'),
                                                },
                                                {
                                                    required: true,
                                                    message: t('messages.inputEmail'),
                                                },
                                            ]}
                                        >
                                            <Input disabled={this.email?.length > 0} />
                                        </Form.Item>
                                        <div className='select-address'>
                                            <SelectAddress
                                                address='address'
                                                onChangeCity={this.onChangeReceiverCity.bind(this)}
                                                onChangeDistrict={this.onChangeReceiverDistrict.bind(this)}
                                                cities={cities}
                                                wards={wards}
                                                districts={districts}
                                            />
                                        </div>
                                    </Col>
                                    <Col span={8} offset={2}>
                                        <div className='generator generator-edit'>
                                            <label for='html'>{t('text.password')}</label>
                                            <h4 className='generator-pass'>
                                                <Input type='password' value={this.passwordHash ? '******' : ''} disabled />
                                            </h4>
                                        </div>
                                        <Form.Item name='userTypeId'
                                            label={t('text.userType')}
                                            rules={[{
                                                required: true,
                                                message: t('messages.selectUserType')
                                            }]}>
                                            <Select disabled placeholder={t('messages.selectUserType')} onChange={this.changeUserType}>
                                                {
                                                    roles?.map(({ id, name }) => <Option value={id} key={id}>{name}</Option>)
                                                }
                                            </Select>
                                        </Form.Item>

                                        <Form.Item
                                            label={t('text.station')}
                                            name='stationIds' rules={[{
                                                required: true,
                                                message: ('messages.selectStation')
                                            }]}>
                                            <Select placeholder={t('placeholder.selectStation')} disabled>
                                                {
                                                    stations?.map(({ id, name }) => <Option value={id} key={id}>{name}</Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            name='statusId'
                                            label={t('text.status')}
                                            rules={[{
                                                required: true,
                                                message: t('messages.pleaseSelectStatus')
                                            }]}>
                                            <Select placeholder={t('messages.selectStatus')}>
                                                {
                                                    status?.map(({ id, name }) => <Option value={id} key={id}>{t(name)}</Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item className='actions'>
                                    <Button className='btn-action btn-back' onClick={this.backPage}>{t('button.back')}</Button>
                                    <Button className='btn-action btn-reset' onClick={this.showModal}>{t('button.resetPassword')}</Button>
                                    <Button className='btn-action btn-insert' htmlType='submit' >{t('button.update')}</Button>
                                </Form.Item>
                            </Form>
                            <Modal
                                visible={this.state.ModalConfirmChangePassword.visible}
                                onOk={this.resetPassword}
                                confirmLoading={this.state.ModalConfirmChangePassword.confirmLoading}
                                onCancel={this.handleCancel}
                            >
                                <p>{this.state.ModalConfirmChangePassword.modalText}</p>
                            </Modal>
                        </Content>
                    </div>
                </div>
            </Layout>
        )
    }
}
