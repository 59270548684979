import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DetailPackages } from './detail-packages.component';
import { getOrderDetails, getInitData } from '../../../../../modules/orders/orders.actions'
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

const mapStateToProps = ({ order }) => {
    return {
        order: order.orderDetails || {},
        initData: order.create.initData || {}
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getOrderDetails,
        getInitData
    }, dispatch)
}

export default compose(
    withTranslation('translations'),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(DetailPackages)
