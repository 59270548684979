import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getOrderPickup, updateSelectedOrder } from '../../../../modules/orders/orders.actions';
import { EditOrderPickupScreen } from './edit-order-pickup.screen';
import { bindActionCreators } from 'redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
  return {
    orders: state.order?.listOrderPickup,
    currentStationId: state.session?.auth?.user?.currentStationId,
    vehicles: state.order?.vehicles || [],
    selectedOrders: state.order?.selectedOrders
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getOrderPickup,
    updateSelectedOrder
  }, dispatch)
}


export default compose(
    withTranslation('translations'),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(EditOrderPickupScreen)
