
import React, { Component } from 'react';
import Layout, { Header } from 'antd/lib/layout/layout';
import TopBar from '../../../components/topbar';
import { Link } from 'react-router-dom';
import { Modal, Button, Table, Checkbox, Select, Form, InputNumber, notification } from 'antd';
import moment from 'moment';
import { FORMAT_TIME_DATE, formatDate } from '../../../common/constants';
import importDataService from '../../../data-services/import/import-data.service';
import './view-detail.screen.scss'

const unitWeight = 'kg';
export default class ViewImportFromVehicleDetailScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: 'title.viewImportListFromVehicle',
            initialData: {
                code: null,
                exportedOrders: null,
                exportedProducts: null,
                exportedItems: null,
                exportedWeight: null,
                confirmedOrders: null,
                confirmedProducts: null,
                confirmedItems: null,
                confirmedWeight: null,
                orderExportLists: [],
                vehicle: null,
                route: null
            },
        }

        this.formRef = React.createRef();
    }

    componentDidMount() {
        var params = this.props?.match?.params ?? {};
        var { currentStationId } = this.props;

        if (params.id) {
            importDataService.getImportListFromVehicleById(params.id, currentStationId).then((res) => {
                if (res) {
                    this.setState({ initialData: res })
                }
            })
        }
    }

    render() {
        const { t } = this.props;
        return (
            <Layout className='bg-content'>
                <div className='bg-body'>
                    <TopBar title={t(this.state.title)} />
                    <div className='bg-white clearfix beecow-leasing-contract'>
                        <div className='steps-content mt-5'>
                            {this.renderContent()}
                        </div>
                        <div className='steps-action' className='float-right mr-5 mt-5 d-flex'>
                            <Button type='primary' onClick={() => this.props.history.push(`/dashboard`)}>
                                {t('button.close')}
                            </Button>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }

    renderContent = () => {
        const { t } = this.props;
        const { initialData } = this.state;
        const {
            code,
            exportedOrders,
            exportedProducts,
            exportedItems,
            exportedWeight,
            confirmedOrders,
            confirmedProducts,
            confirmedItems,
            confirmedWeight,
            orderExportLists,
            vehicle,
            route
        } = initialData;

        return (
            <div>
                <div className='row ml-5'>
                    <div className='col-2'>
                        <p><b>{t('tableColumn.id')}</b></p>
                        <p>{code ?? ''}</p>
                    </div>

                    <div className='col-2'>
                        <p><b>{t('tableColumn.vehicle')}</b></p>
                        <p>{`${vehicle?.code}, ${t(vehicle?.type)}`}</p>
                    </div>

                    <div className='col-5'>
                        <p><b>{t('tableColumn.routeId')} - {t('tableColumn.routeName')}</b></p>
                        <p>{`${route?.code} (${route?.routeName})`}</p>
                    </div>

                </div>

                <div className='row ml-5'>
                    <div className='col-2'>
                        <p><b>{t('tableColumn.totalExportList')}</b></p>
                        <p>{orderExportLists?.length ?? 0}</p>
                    </div>

                    <div className='col-2'>
                        <p><b>{t('tableColumn.totalOrders')}</b></p>
                        <p>{exportedOrders}</p>
                    </div>

                    <div className='col-2'>
                        <p><b>{t('tableColumn.totalProducts')}</b></p>
                        <p>{exportedProducts}</p>
                    </div>

                    <div className='col-2'>
                        <p><b>{t('tableColumn.totalItems')}</b></p>
                        <p>{exportedItems}</p>
                    </div>

                    <div className='col-2'>
                        <p><b>{t('tableColumn.totalWeight')}</b></p>
                        <p>{`${exportedWeight} ${unitWeight}`}</p>
                    </div>
                </div>
                <div className='row ml-5'>
                    <div className='col-2'>
                    </div>

                    <div className='col-2'>
                        <p><b>{t('tableColumn.newOrderQty')}</b></p>
                        <p className=' text-primary'>{confirmedOrders}</p>
                    </div>

                    <div className='col-2'>
                        <p><b>{t('tableColumn.newProductQty')}</b></p>
                        <p className=' text-primary'>{confirmedProducts}</p>
                    </div>

                    <div className='col-2'>
                        <p className='text-left'><b>{t('tableColumn.newItemQty')}</b></p>
                        <p className='text-primary'>{confirmedItems}</p>
                    </div>

                    <div className='col-2'>
                        <p><b>{t('tableColumn.newWeights')}</b></p>
                        <p className=' text-primary'>{`${confirmedWeight} ${unitWeight}`}</p>
                    </div>
                </div>

                <div className='mt-5'>
                    {this.renderExportList(orderExportLists)}
                </div>

            </div>
        )
    }

    mappingToDataTable = (orderItems) => {
        const { t } = this.props;
        return orderItems?.map((i, index) => {
            return {
                index: index + 1,
                productCode: i.product?.code,
                productName: i.product?.name,
                productCategory: i.product?.packageType?.name,
                amount: i.exportedAmount,
                amountScanned: i.confirmedAmount ?? 0,
                scanned: i.confirmed ?? false,
                scanReasonFailed: t(i.confirmFailedReason) ?? null,
            }
        });
    }

    renderExportList = (orderExportLists) => {
        const { t } = this.props;
        if (orderExportLists) {
            return orderExportLists?.map((exportList, index) => {
                const {
                    id,
                    code,
                    exportedOrders,
                    exportedProducts,
                    exportedItems,
                    confirmedOrders,
                    confirmedProducts,
                    confirmedItems,
                    orders
                } = exportList;

                return (
                    <>
                        <div className='ml-3 mr-3 mt-4 mb-5'>
                            <div className='pt-4 bg-export-list'>
                                <div className='row m-auto pl-5'>
                                    <div className='col-4 m-auto text-center'>
                                        <p><b>{t('tableColumn.exportListId')}</b></p>
                                        <Link className='text-decoration-underline' to={`/view-export-list/${id}`} target='_blank'> {code} </Link>
                                    </div>

                                    <div className='col-4 m-auto'>
                                        <div className='d-flex'>
                                            <p className='col-6'>{t('tableColumn.exportOrders')}</p>
                                            <p className='col-4'>{exportedOrders}</p>
                                        </div>
                                        <div className='d-flex'>
                                            <p className='col-6'>{t('tableColumn.exportProducts')}</p>
                                            <p className='col-4'>{exportedProducts}</p>
                                        </div>
                                        <div className='d-flex'>
                                            <p className='col-6'>{t('tableColumn.exportItems')}</p>
                                            <p className='col-4'>{exportedItems}</p>
                                        </div>
                                    </div>

                                    <div className='col-4 m-auto'>
                                        <div className='d-flex'>
                                            <p className='col-6'>{t('tableColumn.newOrderQty')}</p>
                                            <p className='col-4 text-primary'>{confirmedOrders}</p>
                                        </div>
                                        <div className='d-flex'>
                                            <p className='col-6'>{t('tableColumn.newProductQty')}</p>
                                            <p className='col-4 text-primary'>{confirmedProducts}</p>
                                        </div>
                                        <div className='d-flex'>
                                            <p className='col-6'>{t('tableColumn.newItemQty')}</p>
                                            <p className='col-4 text-primary'>{confirmedItems}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='mt-3 mb-3 pb-1'>
                                    {this.renderOrders(orders)}
                                </div>
                            </div>
                        </div>
                    </>
                )
            });
        }

    }

    renderOrders = (orders) => {
        const { t } = this.props;
        if (orders) {
            return orders?.map((o, index) => {
                var colums = this.orderTableColumns();
                var dataSource = this.mappingToDataTable(o?.orderItems);
                return (
                    <>
                        <div className='bg-order rounded-0 mt-0 mb-0 border ml-3 mr-3 mb-3 pt-2'>
                            <div className='row ml-5 header-table'>
                                <div className='col-md-3 d-flex'>
                                    <p className='mr-4 font-weight-bold'>{t('tableColumn.orderCode')}: </p>
                                    <Link className='text-decoration-underline' to={`/view-order/${o.id}`} target='_blank'> {o?.code} </Link>
                                </div>
                                <div className='col-md-3 d-flex'>
                                    <p className='font-weight-bold mr-3'>{t('tableColumn.createdDate')}: </p>
                                    <p>{formatDate(o?.createdTime, FORMAT_TIME_DATE)}</p>
                                </div>
                            </div>

                            <div className='mt-0'>
                                <Table
                                    className='ml-3 mr-3 bg-import-summarize'
                                    columns={colums}
                                    dataSource={dataSource}
                                    pagination={false}
                                />
                            </div>
                        </div>
                    </>
                )
            });
        }
    }

    orderTableColumns = () => {
        const { t } = this.props;
        return [
            {
                title: t('tableColumn.no'),
                dataIndex: 'index',
            },
            {
                title: t('tableColumn.productCode'),
                dataIndex: 'productCode',
            },
            {
                title: t('tableColumn.productName'),
                dataIndex: 'productName',
            },
            {
                title: t('tableColumn.productCategory'),
                dataIndex: 'productCategory',
            },
            //{
            //    title: 'Assigned Spaces',
            //    dataIndex: 'productCategory',
            //},
            {
                title: t('tableColumn.amount'),
                dataIndex: 'amount',
                render: (amount, record) => {
                    return (
                        <>
                            <span className='m-auto'>{`${record.amountScanned}/${record.amount}`}</span>
                        </>
                    )
                }
            },
            {
                title: t('tableColumn.scanned'),
                dataIndex: 'scanned',
                render: (scanned, record) => {
                    return (
                        <Checkbox
                            checked={scanned}
                            disabled={true}
                        />
                    )
                }
            },
            {
                title: t('tableColumn.reason'),
                dataIndex: 'scanReasonFailed',
                render: (scanReasonFailed, record) => {
                    return (
                        <>
                            <span className='m-auto'>{record.scanReasonFailed}</span>
                        </>
                    )
                }
            },
        ];
    }

}