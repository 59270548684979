import { Option } from 'antd/lib/mentions';
import { Select, Form, Table, Button, Image, Empty, Input } from 'antd';
import moment from 'moment';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';
import order, { getSpecialServiceIcon } from '../../../utils/order';
import iconWareHouse from '../../../assets/icons/warehouse.png'
import { deliveryHouseMethods } from '../../../constants/order-constant';
import _ from 'lodash';
import './list-order-pickup.scss';
import languageService from '../../../services/language.service';

const unitWeight = 'Kg';

export class ListOrderPickupScreen extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedVehicleId: props.data?.vehicleId || '',
            searchText: '',
            searchedColumn: '',
            selectedRowKeys: props.selectedOrders?.map(x => x.id) || [],
            showSelectAll: true,
            count: 0,
            listOrder: []
        }
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        if (this.props.currentStationId) {
            this.props.listOrders(this.props.currentStationId);
        }
    }

    componentWillReceiveProps() {
        this.getActivedOrders();
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
        this.props.updateSelectedOrder(selectedRowKeys);
        this.props.setSelectedOrderIds(selectedRowKeys);
    }

    getActivedOrders() {
        const { orders } = this.props;
        const { selectedVehicleId } = this.state;
        return selectedVehicleId ? orders : []
    }

    onChangeVehicle = (vehicleId) => {
        const { orders, vehicles } = this.props;
        let listOrder = orders?.map((o) => this.transform(o));
        let vehicleItem = vehicles?.filter(a => a.id === vehicleId);
        this.props.setVolumeOfVehicle(vehicleItem[0]?.volume);
        this.setState({
            selectedVehicleId: vehicleId,
            listOrder: listOrder
        });
    }

    getSenderInfo = (order) => {
        let formattedAddress = this.getFormattedAddress(order?.addressSender) || this.props.t('text.noAddress');
        let senderInfo = [order.senderName, order.senderPhone, formattedAddress];
        return senderInfo.join('<br/>');
    }

    getFormattedAddress(address) {
        let arr = [];
        if (address && address.street) {
            arr.push(address.street);
        }
        if (address && address.ward) {
            let ward = address.ward.prefix + ' ' + address.ward.name;
            arr.push(ward);
        }
        if (address && address.district) {
            let district = address.district.prefix + ' ' + address.district.name;
            arr.push(district);
        }
        if (address && address.city) {
            arr.push(address.city.name);
        }
        return arr.join(', ');
    }

    onChange(pagination, filters, sorter, extra) {
        console.log('params', pagination, filters, sorter, extra);
    }

    handleCheckAll() {
        const { orders } = this.props || [];
        const { showSelectAll } = this.state;
        let keys = orders.map((p) => p.id);
        if (!showSelectAll) keys = [];
        this.setState({ selectedRowKeys: keys, showSelectAll: !showSelectAll });
        this.props.updateSelectedOrder(keys);
        this.props.setSelectedOrderIds(keys);
    }

    getTableColumns(orders) {
        const { t } = this.props;
        let deliveries = orders?.map(d => { return { text: d.deliveryMethod, value: d.deliveryMethod } });
        let delivieryOptions = deliveries?.filter((d, i) => deliveries?.findIndex(o => o.value === d.value) === i);
        let packages = orders?.map(d => { return { text: d.packageInfo, value: d.packageInfo } });
        let packageInfoOptions = packages?.filter((d, i) => packages?.findIndex(o => o.value === d.value) === i);
        let columns = [
            {
                title: t('tableColumn.orderId'),
                dataIndex: 'orderId',
                sorter: (a, b) => a.orderId.localeCompare(b.orderId),
                render: (text, data) => <Link target='_blank' to={`/view-order/${data.id}`}>{text}</Link>
            },
            {
                title: t('tableColumn.pickupAddress'),
                dataIndex: 'from',
                render: text => <span dangerouslySetInnerHTML={{ __html: text }} />
            },
            {
                title: t('tableColumn.packageInfo'),
                dataIndex: 'packageInfo',
                sorter: (a, b) => a.packageInfo.localeCompare(b.packageInfo),
                render: (name) => <span>{name}{getSpecialServiceIcon(name)}</span>,
                filters: packageInfoOptions,
                onFilter: (value, record) => record.packageInfo.indexOf(value) === 0,
            },
            {
                title: t('tableColumn.deliveryMethod'),
                dataIndex: 'deliveryMethod',
                sorter: (a, b) => a.deliveryMethod.localeCompare(b.deliveryMethod),
                render: (text) => <span>{text}<br />{deliveryHouseMethods.includes(text?.toLowerCase()) && <Image src={iconWareHouse} width={30} />}</span>,
                filters: delivieryOptions,
                onFilter: (value, record) => record.deliveryMethod.indexOf(value) === 0,
            },
            {
                title: t('tableColumn.totalWeight'),
                dataIndex: 'totalWeight',
                sorter: (a, b) => a.totalWeight - b.totalWeight,
                render: text => <span>{text} {unitWeight}</span>
            },
            {
                title: t('tableColumn.totalProductsInPackage'),
                dataIndex: 'totalProductsInPackage',
                sorter: (a, b) => a.totalProductsInPackage - b.totalProductsInPackage,
            },
            {
                title: t('tableColumn.totalItemsInPackage'),
                dataIndex: 'totalItemsInPackage',
                sorter: (a, b) => a.totalItemsInPackage - b.totalItemsInPackage,
            },
            {
                title: t('tableColumn.createdDate'),
                dataIndex: 'createdDate',
                sorter: (a, b) => moment(a.createdDate) - moment(b.createdDate),
                render: text => <div dangerouslySetInnerHTML={{ __html: moment.utc(text).local().format('hh:mm A <br> DD/MM/YYYY') }} />
            }
        ];
        return columns;
    }

    transform = (order) => {
        var result = {
            key: order.id,
            id: order.id,
            orderId: order.code,
            from: this.getSenderInfo(order),
            deliveryMethod: order.delivery?.name,
            packageInfo: order.specialOption?.name,
            createdDate: order.createdTime,
            amount: order.amount,
            packageSizeWeight: order.packageSizeWeight,
            totalWeight: order.totalWeight,
            totalProductsInPackage: order.totalProductsInPackage,
            totalItemsInPackage: order.totalItemsInPackage,
        }

        if (order.status.subStatusName != null) {
            result.status = `${order.status.statusName}-${order.status.subStatusName}`
        }
        else {
            result.status = order.status.statusName;
        }

        return result
    }

    render() {
        const { t, data, orders } = this.props;
        const { vehicles } = this.props;
        const { listOrder } = this.state;
        const { selectedRowKeys, showSelectAll } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange.bind(this),
        };

        let columns = null;
        let orderDataSource = [];
        if (data?.vehicleId) {
            let orderList = orders?.map((o) => this.transform(o));
            columns = this.getTableColumns(orderList);
            orderDataSource = orderList;
        }
        else {
            columns = this.getTableColumns(listOrder);
            orderDataSource = listOrder
        }
        let localLanguage = languageService.getLang();

        return <Content className='content'>
            <div className='list-pickup'>
                <div className='select-vehicle pull-left' style={{ display: 'inline-grid' }}>
                    <label for='html'>{t('text.pleaseSelectVehicle')}</label>
                    <div style={{ display: 'inline-flex' }}>
                        <Form.Item
                            name='vehicleId'
                            rules={[{
                                required: true,
                                message: t('messages.pleaseSelectVehicle')
                            }]}
                        >
                            <Select
                                showSearch
                                placeholder={t('placeholder.selectVehicle')}
                                optionFilterProp='children'
                                onChange={this.onChangeVehicle}
                                style={{ minWidth: 220 }}
                            >
                                {
                                    vehicles?.map(({ id, code, type, volume }) =>
                                        <Option
                                            value={id}
                                            key={id}
                                        >
                                            {`${code} (${t(type?.name)}) - ${volume.toLocaleString(localLanguage)} ${unitWeight}`}
                                        </Option>)
                                }
                            </Select>
                        </Form.Item>
                        <div className='show-volume-of-vehicle'>
                            <p className='line-current-weight'>{t('text.currentWeight')}/{t('text.volumeOfVehicle')}</p>
                            <p id='volume-selected' className='line-current-weight'>
                                <span
                                    id='current-weight'
                                    className={data?.currentWeight > data?.volumeOfVehicle ? 'volume-selected-limted' : ''}
                                >
                                    {data?.currentWeight.toLocaleString(localLanguage)}
                                </span>/{data?.volumeOfVehicle.toLocaleString(localLanguage) + " " + unitWeight}
                            </p>
                        </div>
                    </div>
                </div>
                <div className='pull-right'>
                    <Button type='primary' onClick={this.handleCheckAll.bind(this)}>
                        {showSelectAll ? <span>{t('text.selectAllOrders')}</span> : <span>{t('text.unSelectAllOrders')}</span>}
                    </Button>
                    <p className='selected-order clearfix'>{t('text.selected')} {selectedRowKeys.length + ` ${t('text.orders')}`}</p>
                </div>
                <Table
                    columns={columns}
                    rowSelection={rowSelection}
                    bordered
                    dataSource={orderDataSource}
                    onchange={this.onChange}
                    locale={{ emptyText: <Empty description={!this.state.selectedVehicleId ? t('messages.pleaseSelectVehicle') : t('messages.noOrders')} /> }} />
                <Form.Item name='orderIds' className='message' rules={[{ required: true, message: t('messages.pleaseChooseOrderPickup') }]} ></Form.Item>
            </div>
        </Content>
    }
}