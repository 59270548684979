import React, { Component } from 'react';
import { Content } from 'antd/lib/layout/layout';
import { Form } from "antd";
import moment from 'moment';
import BarcodeReader from 'react-barcode-reader';
import { FORMAT_DATETIME } from '../../common/constants';
import { Link } from 'react-router-dom';

export class OrderInfo extends Component {
    constructor(props) {
        super(props);
        this.barcode = '';

        this.formRef = React.createRef();
    }

    render() {
        const { t } = this.props;
        var { initialData } = this.props;
        return (
            <Content className='beecow-order-info'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <p>{t('messages.scanOrder')}:</p>
                        <p><small>&emsp;{t('messages.scanOrderDescription')}</small></p>
                        <BarcodeReader timeBeforeScanTest={1000} onReceive={this.onReceiveScan.bind(this)}  />
                    </div>
                </div>
                <Form ref={this.formRef}>
                    <Form.Item
                        name={["id"]}
                        rules={[{ required: true, message: t('messages.pleaseScanOrder') }]}>
                    </Form.Item>
                </Form>
                {initialData && initialData.id && (<>
                    <div className='row mt-5'>
                        <div className='col-sm-6'>
                            <p><b>{t('text.scanned')}: <Link style={{ textDecoration: 'underline' }} to={`/view-order/${initialData?.id}`}>{initialData?.code}</Link></b></p>
                        </div>
                        <div className='col-sm-6'>
                            <p className='text-right'><b>{t('text.status')}: {t('status.waitingImport')}</b></p>
                        </div>
                    </div>
                    <div className='row mt-5'>
                        <div className='col-sm-6'>
                            <h2>{t('order.senderInfo')}</h2>
                            <p>&emsp;{t('order.senderPhone')}: {initialData?.senderPhone}</p>
                            <p>&emsp;{t('order.senderName')}: {initialData?.senderName}</p>
                            <p>&emsp;{t('order.senderAddress')}: {this.formatAddress(initialData?.addressSender)}</p>
                            <p><b>{t('tableColumn.pickedListId')}: <Link style={{ textDecoration: 'underline' }} to={`/view-pickup-list/${initialData?.orderPickup?.id}`}>{initialData?.orderPickup?.code}</Link></b></p>
                            <p><b>{t('tableColumn.assignedVehicle')}:</b> {initialData?.orderPickup?.vehicle?.code}</p>
                            <p><b>{t('tableColumn.assignedDriver')}:</b> {initialData?.orderPickup?.driver?.fullName}</p>
                        </div>
                        <div className="col-sm-6">
                            <h2>{t('order.receiverInfo')}</h2>
                            <p>&emsp;{t('order.receiverPhone')}: {initialData?.receiverPhone}</p>
                            <p>&emsp;{t('order.receiverName')}: {initialData?.receiverName}</p>
                            <p>&emsp;{t('order.receiverAddress')}: {this.formatAddress(initialData?.addressRecipient)}</p>
                            <p><b>{t('tableColumn.orderCreatedDate')}:</b> {moment.utc(initialData?.createdTime).local().format(FORMAT_DATETIME)}</p>
                            <p><b>{t('tableColumn.pickedDate')}:</b> {moment.utc(initialData?.pickedDate).local().format(FORMAT_DATETIME)}</p>
                        </div>
                    </div>
                </>)}
            </Content>
        )
    }

    onReceiveScan(event) {
        if (event && event.charCode === 13) {
            this.props.onChange(this.barcode);
            this.barcode = '';
        } else {
            this.barcode += event.key;
        }
    }

    formatAddress(address) {
        let arr = [];
        if (address && address.street) {
            arr.push(address.street);
        }
        if (address && address.ward) {
            let ward = (address.ward.prefix || "") + ' ' + address.ward.name;
            arr.push(ward);
        }
        if (address && address.district) {
            let district = (address.ward.prefix || "") + ' ' + address.district.name;
            arr.push(district);
        }
        if (address && address.city) {
            arr.push(address.city.name);
        }
        return arr.join(', ');
    }

    validateFields = async () => {
       return this.formRef.current.validateFields();
    }

    setFieldsValue = (values) => {
        this.formRef.current.setFieldsValue(values);
    }

}