import React, { Component } from 'react';
import { Form, Button, Select, Input, Modal } from 'antd';
import './product-table.scss';
import { othersCategoryId } from '../../../../constants/order-constant';
import { Utilities } from '../../../../common/utilities';

export default class ProductTableComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            products: props.initialData,
            productCategories: props.productCategories,
            modalVisible: false,
            newProductCategory: {
                id: null,
                name: null,
                isNew: true
            },
            changeAtIndex: null
        }
    }

    render() {
        const { t } = this.props;
        return (
            <table className='table table-borderless product-table ml-0' scroll={{ y: 240 }}>
                <thead>
                    <tr>
                        <th className='action-button-area'></th>
                        <th className='table-product-name'>{t('tableColumn.productName') }</th>
                        <th className='table-product-category'>{t('tableColumn.productCategory')}</th>
                        <th className='table-product-size'>{t('tableColumn.length')} (m)</th>
                        <th className='table-product-size'>{t('tableColumn.width')} (m)</th>
                        <th className='table-product-size'>{t('tableColumn.height')} (m)</th>
                        <th className='table-product-size'>{t('tableColumn.weight')} (kg)</th>
                        <th className='table-product-size'>{t('tableColumn.amount')}</th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderListProduct()}
                </tbody>
            </table>
        )
    }

    onAdd = (callBack, params) => {
        if (params && params >= 0) {
            callBack(null, params);
        }
        else {
            callBack();
        }

        if (this.props.onChange) {
            this.props.onChange();
        }
    }

    onRemove = (callBack, params) => {
        callBack(params);
        if (this.props.onChange) {
            this.props.onChange();
        }
    }

    onChangeAmount = () => {
        if (this.props.onChange) {
            this.props.onChange();
        }
    }

    onChangeCategory = (obj, index) => {
        var { productCategories, products } = this.state;
        if (othersCategoryId == obj.value) {
            this.setState({
                newProductCategory: {
                    id: null,
                    name: null,
                    isNew: true
                },
                changeAtIndex: index,
                modalVisible: true
            });
        }
    }

    onChangeNewCategory = (event) => {
        var { newProductCategory } = this.state;
        newProductCategory.name = event.target.value;
        this.setState({
            newProductCategory: newProductCategory
        });
    }

    onOk = (values) => {
        var { newProductCategory, productCategories, changeAtIndex, products } = this.state;
        newProductCategory.id = Utilities.randomUuid();
        productCategories.push(newProductCategory);
        this.setState({
            modalVisible: false,
            newProductCategory: null,
            productCategories: productCategories
        });
    };

    renderListProduct = () => {
        const { t } = this.props;
        const { products, productCategories, newProductCategory } = this.state;
        return <>
            <Form.List name='products' initialValue={products}>
                {(fields, { add, remove }) => {
                    return (
                        fields.length == 0 ? (
                            <tr>
                                <td>
                                    <Button onClick={() => this.onAdd(add, [])} type='text'><i className='fa fa-plus text-success' aria-hidden='true'></i></Button>
                                </td>
                            </tr>
                        ) :
                            fields.map((field, index) => (
                                <tr key={field.key}>
                                    <td style={{ display: 'inline-flex' }} className='action-button-area pl-0'>
                                        <Button type='text' onClick={() => this.onAdd(add, index + 1)}><i className='fa fa-plus text-success' aria-hidden='true' ></i></Button>
                                        <Button type='text' onClick={() => this.onRemove(remove, field.name)}><i className='fa fa-close text-danger' aria-hidden='true' ></i></Button>
                                    </td>
                                    <td className='mt-0 table-product-name pl-0'>
                                        <Form.Item
                                            className='mt-0'
                                            name={[index, 'name']}
                                            rules={[{ required: true, message: t('messages.productName')}]}
                                        >
                                            <Input placeholder={t('placeholder.productName')}/>
                                        </Form.Item>
                                    </td>
                                    <td className='mt-0 table-product-category'>
                                        <Form.Item
                                            name={[index, 'categoryId']}
                                            rules={[{ required: true, message: t('messages.category')}]}
                                        >
                                            <Select placeholder={t('placeholder.category')}>
                                                {productCategories.map(({ id, name, languageKey }) => <Select.Option key={id} value={id}>{t(languageKey)}</Select.Option>)}
                                            </Select>
                                        </Form.Item>
                                    </td>
                                    <td className='pr-0'>
                                        <Form.Item
                                            name={[index, 'length']}
                                            rules={[{ required: true, message: t('messages.length') }]}
                                        >
                                            <Input placeholder={t('placeholder.length')}
                                                type='number'
                                                min='0'
                                                onKeyDown={e => /[\+\-\,]$/.test(e.key) && e.preventDefault()}
                                            />
                                        </Form.Item>
                                    </td>
                                    <td className='pr-0'>
                                        <Form.Item
                                            name={[index, 'width']}
                                            rules={[{ required: true, message: t('messages.width') }]}
                                        >
                                            <Input placeholder={t('placeholder.width')}
                                                type='number'
                                                min='0'
                                                onKeyDown={e => /[\+\-\,]$/.test(e.key) && e.preventDefault()}
                                            />
                                        </Form.Item>
                                    </td>
                                    <td className='pr-0'>
                                        <Form.Item
                                            name={[index, 'height']}
                                            rules={[{ required: true, message: t('messages.height') }]}
                                        >
                                            <Input placeholder={t('placeholder.height')}
                                                type='number'
                                                min='0'
                                                onKeyDown={e => /[\+\-\,]$/.test(e.key) && e.preventDefault()}
                                            />
                                        </Form.Item>
                                    </td>
                                    <td className='pr-0'>
                                        <Form.Item
                                            name={[index, 'weight']}
                                            rules={[{ required: true, message: t('messages.weight') }]}
                                        >
                                            <Input placeholder={t('placeholder.weight')}
                                                type='number'
                                                min='0'
                                                onKeyDown={e => /[\+\-\,]$/.test(e.key) && e.preventDefault()}
                                            />
                                        </Form.Item>
                                    </td>
                                    <td className='pr-0'>
                                        <Form.Item
                                            name={[index, 'amount']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('messages.amount'),
                                                },
                                                () => ({
                                                    validator(_, amount) {
                                                        if (parseInt(amount) <= 0) {
                                                            return Promise.reject(new Error(t('messages.amountValidate')));
                                                        }

                                                        return Promise.resolve();
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input
                                                onChange={this.onChangeAmount}
                                                placeholder={t('placeholder.amount')}
                                                type='number'
                                                min='1'
                                                onKeyDown={e => /[\+\-\.\,]$/.test(e.key) && e.preventDefault()}
                                            />
                                        </Form.Item>
                                    </td>
                                </tr>
                            ))
                    );
                }}
            </Form.List>

            <Modal
                title={t('title.addProductCategory')}
                centered
                visible={this.state.modalVisible}
                maskClosable={false}
                onOk={this.onOk}
                onCancel={() => {
                    this.setState({
                        modalVisible: false,
                    });
                }}
                okText={t('button.add')}
                cancelText={t('button.cancel')}
                width={300}
            >
                <div className='row col-12 m-0'>
                    <Input placeholder={t('placeholder.productCategory')} value={newProductCategory?.name } onChange={ this.onChangeNewCategory }/>
                </div>
            </Modal>
        </>
    }
}
