const actionTypes = {
    SET_LIST_ORDERS: 'SET_LIST_ORDERS',
    GET_ORDER_INIT_DATA: 'GET_ORDER_INIT_DATA',
    GET_STATIONS: 'GET_STATIONS',
    GET_ROUTING: 'GET_ROUTING',
    SELECT_ROUTING: 'SELECT_ROUTING',
    SET_ROUTE: 'SET_ROUTE',
    CLEAR_ORDER_DATA: 'CLEAR_ORDER_DATA',
    GET_DISTRICT_BY_CITYID: 'GET_DISTRICT_BY_CITYID',
    GET_WARDS_BY_DISTRICTID: 'GET_WARDS_BY_DISTRICTID',
    GET_ORDER_DETAILS: 'GET_ORDER_DETAILS',
    GET_LIST_PICKUP_ORDER: 'GET_LIST_PICKUP_ORDER',
    SET_PAYMENT: 'SET_PAYMENT',
    UPDATE_SELECTED_ORDER: 'UPDATE_SELECTED_ORDER',
    GET_PICKUP_LIST_BY_ID: 'GET_PICKUP_LIST_BY_ID',
    GET_ORDERS_FOR_DELIVERY: 'GET_ORDERS_FOR_DELIVERY',
    CLEAR_DATA_FOR_DELIVERY: 'CLEAR_DATA_FOR_DELIVERY',
    UPDATE_SELECTED_DELIVERY_ORDER_LIST: 'UPDATE_SELECTED_DELIVERY_ORDER_LIST',
    SET_VEHICLES_FOR_DELIVERY: 'SET_VEHICLES_FOR_DELIVERY',
    SET_SELECTED_VEHICLE_FOR_DELIVERY: 'SET_SELECTED_VEHICLE_FOR_DELIVERY',
    GET_DELIVERY_LIST_DETAIL_BY_ID: 'GET_DELIVERY_LIST_DETAIL_BY_ID',
    GET_LIST_EXPORT_ORDER: 'GET_LIST_EXPORT_ORDER',
    UPDATE_LIST_PICKUP_ORDER_AND_VEHICLE: 'UPDATE_LIST_PICKUP_ORDER_AND_VEHICLE'
}

export default actionTypes;