export const ProductsInfoModes = Object.freeze({
    SCAN: 'SCAN',
    CONFIRM_REASON: 'CONFIRM_REASON',
});

export const ProductNotEnoughReasons = Object.freeze({
    SENDER_SEND_NOT_ENOUGH_ITEMS_OF_PRODUCTS: 'productReasons.senderSendNotEnoughItemsOfProducts',
    DRIVER_LOST_ITEMS_OF_PRODUCT_DURING_PICKUP_PROCESS: 'productReasons.driverLostItemsOfProductDuringPickupProcess',
    OTHER_REASONS: 'productReasons.otherReasons',
});

export const ProductNotFoundReasons = Object.freeze({
    SENDER_SEND_NOT_ENOUGH_PRODUCTS: 'productReasons.senderSendNotEnoughProducts',
    DRIVER_LOST_THE_PRODUCT_DURING_PICKUP_PROCESS: 'productReasons.driverLostTheProductDuringPickupProcess',
    OTHER_REASONS: 'productReasons.otherReasons',
});

export const ProductExportReason = Object.freeze({
    VEHICLE_FULL: "export.vehicleFull",
    PACKAGES_BROCKEN: "export.packagesBroken",
    CANT_FIND: "export.cannotFindLostPackage",
    OTHER_REASONS: 'export.otherReasons',
});