import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { LostOrderListScreen } from "./lost-order-list.screen";
const mapStateToProps = (state) => {
  return {
    currentStationId: state.session?.auth?.user?.currentStationId,
  };
};

export default compose(
  withTranslation("translations"),
  connect(mapStateToProps, null)
)(LostOrderListScreen);
