import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ReviewDeliveryOrderListScreen } from './review-delivery-order-list.screen';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
  return {
      currentStationId: state.session?.auth?.user?.currentStationId,
      isSelectedAllOrders: state.order?.isSelectedAllOrders,
      selectedOrders: state.order?.selectedOrdersForDelivery,
      unselectedOrders: state.order?.unselectedOrdersForDelivery,
      vehicles: state.order?.vehiclesForDelivery || [],
  };
};


export default compose(
    withTranslation('translations'),
    connect(mapStateToProps, null),
    withRouter
)(ReviewDeliveryOrderListScreen)

