import { DataService } from "../base/base-data.service";

class VehicleDataService extends DataService {
    apiUrl = '/vehicle';

    async getListVehicle() {
        return await this.get(`${this.apiUrl}/loadData`);
    }

    async getVehicles() {
        return await this.get(`${this.apiUrl}/vehicles`);
    }

    async getVehicleTypes() {
        return await this.get(`${this.apiUrl}/vehicleTypes`);
    }

    async addVehicle(data) {
        return await this.post(`${this.apiUrl}/insert`, data);
    }

    async updateVehicle(data) {
        return await this.post(`${this.apiUrl}/update`, data);
    }

    async deleteVehicles(data) {
        return await this.post(`${this.apiUrl}/delete`, data);
    }

    async getWorkingDays() {
        return await this.get(`${this.apiUrl}/workingDays`);
    }

    async importVehicle(data) {
        return await this.post(`${this.apiUrl}/import`, data);
    }

    async getTransportVehiclesFinishedByStationId(id) {
        return await this.get(`${this.apiUrl}/get-vehicles-last-station-by-station-id?stationId=${id}`);
    }
}

let vehicleDataService = new VehicleDataService();

export default vehicleDataService;