import React, { Component } from 'react';
import { Button, message, Modal, Steps } from 'antd';
import Layout from 'antd/lib/layout/layout';
import TopBar from '../../components/topbar';
import { OrderInfo } from '../../components/order-info/order-info.component';
import { ProductsInfo } from '../../components/products-info/products-info.component';
import './import-packages.scss';
import orderDataService from '../../data-services/orders/orders-data.service';
import {
    ProductNotEnoughReasons,
    ProductNotFoundReasons,
    ProductsInfoModes,
} from '../../components/products-info/products-info';
import importDataService from '../../data-services/import/import-data.service';
import { ImportListSummary } from '../../components/import-list-summary/import-list-summary.component';

const { Step } = Steps;
const SCREENS = {
    SCAN_ORDER: 0,
    SCAN_PRODUCT: 1,
    CONFIRM_REASON: 2,
    SUMMARIZE: 3,
};

export class ImportPackagesScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            orders: [],
            initialData: this.getInitialData(),
            productNotEnoughReasons: [
                ProductNotEnoughReasons.SENDER_SEND_NOT_ENOUGH_ITEMS_OF_PRODUCTS,
                ProductNotEnoughReasons.DRIVER_LOST_ITEMS_OF_PRODUCT_DURING_PICKUP_PROCESS,
                ProductNotEnoughReasons.OTHER_REASONS,
            ],
            productNotFoundReasons: [
                ProductNotFoundReasons.SENDER_SEND_NOT_ENOUGH_PRODUCTS,
                ProductNotFoundReasons.DRIVER_LOST_THE_PRODUCT_DURING_PICKUP_PROCESS,
                ProductNotFoundReasons.OTHER_REASONS,
            ],
            isRemoveThisOrder: false
        };

        this.orderFormRef = React.createRef();
        this.productsFormRef = React.createRef();
    }

    render() {
        const { t } = this.props;
        let {
            current,
            initialData,
            productNotEnoughReasons,
            productNotFoundReasons,
            orders,
        } = this.state;
        let { orderInfo } = initialData;
        let steps = [
            {
                title: t('title.scanOrderBarcode'),
                content: (
                    <OrderInfo
                        t={t}
                        ref={this.orderFormRef}
                        initialData={orderInfo}
                        onChange={this.onChangeOrder.bind(this)}
                    />
                ),
            },
            {
                title: t('title.scanProductBarcode'),
                content: (
                    <ProductsInfo
                        t={t}
                        ref={this.productsFormRef}
                        mode={ProductsInfoModes.SCAN}
                        initialData={orderInfo}
                    />
                ),
            },
            {
                title: t('title.confirmReason'),
                content: (
                    <ProductsInfo
                        t={t}
                        ref={this.productsFormRef}
                        mode={ProductsInfoModes.CONFIRM_REASON}
                        initialData={orderInfo}
                        productNotEnoughReasons={productNotEnoughReasons}
                        productNotFoundReasons={productNotFoundReasons}
                    />
                ),
            },
            {
                title: t('title.summazize'),
                content: (
                    <ImportListSummary
                        t={t}
                        initialData={orders}
                        onEdit={this.editOrder.bind(this)}
                        onRemove={this.removeOrder.bind(this)}
                    />
                ),
            },
        ];
        return (
            <Layout className='bg-content'>
                <div className='bg-body'>
                    <TopBar title={t('title.importPackages')} />
                    <div className='bg-white clearfix beecow-import-packages'>
                        <Steps
                            type='navigation'
                            current={current}
                            className='site-navigation-steps'
                        >
                            {steps.map((item) => (
                                <Step key={item.title} title={item.title} />
                            ))}
                        </Steps>
                        <div className='steps-content'>{steps[current].content}</div>
                        <div className='steps-action'>
                            {current > 0 && !this.state.isRemoveThisOrder && (
                                <Button type='primary' onClick={this.prev.bind(this)}>
                                    {t('button.back')}
                                </Button>
                            )}
                            {current < steps.length - 2 && (
                                <Button type='primary' onClick={this.next.bind(this)}>
                                    {t('button.next')}
                                </Button>
                            )}
                            {current === steps.length - 2 && (
                                <>
                                    <Button type='primary' onClick={this.goToLastStep.bind(this)}>
                                        {t('button.goToLastStep')}
                                    </Button>
                                </>
                            )}
                            {current === steps.length - 1 && (
                                <>
                                    <Button type='primary' onClick={this.repeat.bind(this)}>
                                        {t('button.scanAnotherOrder')}
                                    </Button>
                                    {
                                        !this.state.isRemoveThisOrder &&
                                        <Button type='primary' onClick={this.finish.bind(this)}>
                                            {t('button.done')}
                                        </Button>
                                    }
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }

    getInitialData() {
        let orderInfo = {
            sender: {
                fullName: '',
                phone: '',
                address: {},
            },
            receiver: {
                fullName: '',
                phone: '',
                address: {},
            },
            pickedList: {
                code: '',
            },
            assignedVehicle: {
                code: '',
            },
            assignedDriver: {
                fullName: '',
            },
            orderCreatedDate: '',
            pickedDate: '',
            orderItems: [],
        };
        return {
            orderInfo: orderInfo,
        };
    }

    async onChangeOrder(code) {
        let order = await orderDataService.getPickedOrder(code);
        if (order) {
            /// Set values order info component
            if (this.orderFormRef && this.orderFormRef.current) {
                this.orderFormRef.current.setFieldsValue(order);
            }

            this.setState({
                initialData: { ...this.state.initialData, orderInfo: order },
            });
        } else {
            this.informInvalidOrder(code);
        }
    }

    informInvalidOrder(code) {
        const { t } = this.props;
        Modal.info({
            content: (
                <div>
                    <br />
                    <br />
                    <p>{t('messages.cannotImport')}</p>
                    <p>{t('messages.tryAgain')}</p>
                </div>
            ),
            onOk() { },
        });
    }

    next() {
        const { t } = this.props;
        let { initialData } = this.state;
        let { orderInfo } = initialData;
        let currentFormRef;
        switch (this.state.current) {
            case SCREENS.SCAN_ORDER:
                currentFormRef = this.orderFormRef;
                break;
            case SCREENS.SCAN_PRODUCT:
            case SCREENS.CONFIRM_REASON:
                currentFormRef = this.productsFormRef;
                this.addOrUpdateOrders();
                break;
            default:
                break;
        }

        if (currentFormRef && currentFormRef.current) {
            if (this.state.current === 1) {
                var { orderItems } = currentFormRef.current.getFieldsValue();
                var allScanned = this.allScanned(orderItems);
                if (allScanned === true) {
                    // If the variable 'orderItems' is not null and has a value in the step 'Scan Product Barcode', the Next button is available.
                    if (orderItems) {
                        let totalNumberLostQuantity = orderItems.filter(
                            (orderItem) => orderItem.amount - orderItem.amountScanned > 0
                        );

                        if (
                            totalNumberLostQuantity &&
                            totalNumberLostQuantity?.length > 0
                        ) {
                            this.setState({
                                current: SCREENS.CONFIRM_REASON,
                            });
                        } else {
                            this.setState({
                                current: SCREENS.SUMMARIZE,
                            });
                        }
                        return;
                    } else {
                        message.error(`${t('messages.checkTheStep')} ${t('messages.scanProductBarcode')}`);
                        return;
                    }
                }
            }

            if (orderInfo?.id && this.state.current == 0) {
                currentFormRef.current.setFieldsValue(orderInfo);
            }

            currentFormRef.current
                .validateFields()
                .then(() => {
                    this.setState({
                        current: this.state.current + 1,
                    });
                })
                .catch((ex) => console.log(ex));
        } else {
            this.setState({
                current: this.state.current + 1,
            });
        }
    }

    prev() {
        let prevStep = 1;
        if (this.state.current == SCREENS.SUMMARIZE) {
            const { orders } = this.state;
            orders?.forEach((order) => {
                let orderItems = order.orderInfo.orderItems;
                let totalNumberLostQuantity = orderItems.filter(
                    (orderItem) => orderItem.amount - orderItem.amountScanned > 0
                );
                if (totalNumberLostQuantity?.length === 0) {
                    prevStep = prevStep + 1;
                    return;
                }
            })
        }

        this.setState(
            {
                current: this.state.current - prevStep,
            },
            () => {
                this.refresh();
            }
        );
    }

    refresh = () => {
        let { initialData } = this.state;
        let { orderInfo } = initialData;
        let currentFormRef;
        switch (this.state.current) {
            case 0:
                currentFormRef = this.orderFormRef;
                if (currentFormRef && currentFormRef.current) {
                    currentFormRef.current.setFieldsValue(orderInfo);
                }
                break;
            case 1:
            case 2:
                currentFormRef = this.productsFormRef;
                break;
            default:
                break;
        }
    };

    repeat() {
        const { isRemoveThisOrder } = this.state;
        if (isRemoveThisOrder) {
            this.setState({
                ...this.state,
                isRemoveThisOrder: false
            })
        }
        this.addOrUpdateOrders();
        this.setState({
            initialData: this.getInitialData(),
            current: 0,
        });
    }

    goToLastStep() {
        this.productsFormRef.current
            .validateFields()
            .then(() => {
                this.addOrUpdateOrders();
                this.setState({
                    current: this.state.current + 1,
                });
            })
            .catch((ex) => console.log(ex));
    }

    addOrUpdateOrders() {
        let { orders, initialData } = this.state;
        let index = orders?.findIndex(
            (o) => o.orderInfo?.id === initialData?.orderInfo?.id
        );
        if (index === -1) {
            orders.push(initialData);
        } else {
            orders.splice(index, 1, initialData);
        }

        this.setState({
            orders: orders,
        });
    }

    editOrder(index) {
        let { orders } = this.state;
        if (index >= 0 && index < orders.length) {
            this.setState({
                initialData: orders[index],
                current: 0,
            });
        }
    }

    removeOrder(index) {
        let { orders } = this.state;
        if (index >= 0 && index < orders.length) {
            orders.splice(index, 1);
            this.setState({
                orders: orders,
            });
        }
        if (orders.length === 0) {
            this.setState({
                ...this.state,
                isRemoveThisOrder: true
            });
        }
    }

    finish() {
        const { t } = this.props;
        var { currentStationId } = this.props;
        let importedOrders = this.state.orders?.map((order) => {
            return {
                id: order?.orderInfo?.id,
                orderItems: order?.orderInfo?.orderItems,
            };
        });
        let importData = {
            stationId: currentStationId,
            orders: importedOrders,
        };

        importDataService.executeImport(importData).then((importListId) => {
            if (importListId) {
                this.props.history.push(`/packages/view/${importListId}`);
                message.success(t('messages.packageImportedSuccess'));
            }
            else {
                message.error('messages.cannotCreateImportList');
            }
        });
    }

    allScanned = (orderItems) => {
        var allScanned = true;
        if (orderItems.length > 0) {
            let index = orderItems.findIndex(
                (i) => !i?.scanned || i?.scanned === false
            );
            if (index !== -1) {
                allScanned = false;
            }
        }

        return allScanned;
    };
}
