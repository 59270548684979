import { Layout, Modal } from 'antd';
import Form from 'antd/lib/form/Form';
import React, { Component } from 'react';
import { orderDeliveryStep } from '../../constants/order-constant';
import orderDataService from '../../data-services/orders/orders-data.service';
import NextPage from '../shared/components/next-page';
import TopBar from '../../components/topbar';
import DeliveryOrderList from './delivery-order-list';
import ReviewDeliveryOrderList from './review-delivery-order-list';

export class DeliverOrderScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: orderDeliveryStep.orderList,
            data: {
                currentWeight: 0,
                volumeOfVehicle: 0
            },
            selectedAllOrders: false,
            orderIds: []
        };
        this.formRef = React.createRef();
    }

    renderDeliveryOrderList() {
        const { currentPage, data } = this.state;
        switch (currentPage) {
            case orderDeliveryStep.reviewDeliveryOrderList:
                return <ReviewDeliveryOrderList data={data} />;
            default:
                return (
                    <DeliveryOrderList
                        data={data}
                        setDataForDelivery={this.setDataForDelivery.bind(this)}
                        setSelectedOrderIds={this.setSelectedOrderIds.bind(this)}
                        setVolumeOfVehicle={this.setVolumeOfVehicle.bind(this)}
                    />
                );
        }
    }

    handleNextPage() {
        const { t } = this.props
        const { currentPage, data } = this.state;
        this.formRef?.current?.validateFields().then(() => {
            const values = this.formRef.current.getFieldsValue();
            if (this.state.data.currentWeight > this.state.data.volumeOfVehicle) {
                Modal.warning({
                    title: t('title.modalWarning'),
                    content: t('messages.deliveryGreaterThanVolumeOfVehicle'),
                    okText: t('button.ok')
                });
            }
            else {
                this.setState({
                    currentPage: currentPage + 1,
                    data: { ...data, ...values },
                });
            }
        });
    }

    handleBackHomePage() {
        this.props.history.push('/dashboard');
    }

    handleBackPage() {
        const { currentPage } = this.state;
        this.setState({ currentPage: currentPage - 1 });
    }

    handleFinish() {
        const { data, selectedAllOrders, orderIds } =
            this.state;

        this.formRef?.current?.validateFields().then(async () => {
            const request = {
                selectedAllOrders: selectedAllOrders,
                orderIds: orderIds,
                vehicleId: data.vehicleId,
                stationId: this.props.currentStationId
            };
            orderDataService.createDeliveryList(request).then((res) => {
                this.formRef.current.resetFields();
                this.props.clearDataForDelivery();
                this.props.history.push(`/view-delivery-list-detail/${res}`);
            });
        });
    }

    setVolumeOfVehicle = (volumeOfVehicle) => {
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                volumeOfVehicle: volumeOfVehicle
            }
        });
        this.formRef.current.setFieldsValue({ volumeOfVehicle: volumeOfVehicle })
    }

    setSelectedOrderIds = (orderIds, currentWeight) => {
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                currentWeight: currentWeight
            }
        });
        this.formRef.current.setFieldsValue({ orderIds });
    };

    setDataForDelivery = (selectedAllOrders, orderIds) => {
        this.setState({
            selectedAllOrders: selectedAllOrders,
            orderIds: orderIds,
        });
    };

    componentWillUnmount() {
        this.props.clearDataForDelivery();
    }

    render() {
        const { t } = this.props;
        const { currentPage } = this.state;
        return (
            <Layout className='bg-content'>
                <div className='bg-body'>
                    <TopBar
                        title={
                            currentPage === orderDeliveryStep.orderList
                                ? t('title.createDeliveryList')
                                : t('title.reviewDeliveryList')
                        }
                    />
                    <div className='bg-white clearfix'>
                        <Form ref={this.formRef}>
                            {this.renderDeliveryOrderList()}
                            <NextPage
                                handleNext={this.handleNextPage.bind(this)}
                                handleBack={this.handleBackPage.bind(this)}
                                handleCancel={this.handleBackHomePage.bind(this)}
                                handleFinish={this.handleFinish.bind(this)}
                                showCancel={currentPage === orderDeliveryStep.orderList}
                                hideNext={
                                    currentPage === orderDeliveryStep.reviewDeliveryOrderList ||
                                    currentPage === orderDeliveryStep.viewDeliveryOrderList
                                }
                                hideBack={
                                    currentPage === orderDeliveryStep.orderList ||
                                    currentPage === orderDeliveryStep.viewDeliveryOrderList
                                }
                                hideClose={
                                    currentPage === orderDeliveryStep.orderList ||
                                    currentPage === orderDeliveryStep.reviewDeliveryOrderList
                                }
                                hideFinish={currentPage === orderDeliveryStep.orderList}
                                textFinish={t('title.createDeliveryList')}
                            />
                        </Form>
                    </div>
                </div>
            </Layout>
        );
    }
}
