import React, { Component } from 'react';
import { Content } from 'antd/lib/layout/layout';
import { Table, Form, Select, Radio, Col, Row, Space } from 'antd';

import RouteTableComponent from './route-table/route-table.component';
import routeDataService from '../../../data-services/routes/routes-data.service'
import { DeliveryConstants } from './../../../constants/delivery.constants'
//import { OrderMessages } from './../../../messages/order.message'

export default class DeliveryComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMoreOptions: false,
            deliveryOptions: props.deliveryOptions ?? [],
            shippingServices: props.shippingServices ?? [],
            shippingInfo: props.shippingInfo ?? [],
            productInfo: props.productInfo ?? [],
            locations: [],
            stations: [],
            routes: [],
            cachedRoutes: [],
            stationInfo: props.stationInfo,
            initialData: null
        }

        this.deliveryFormRef = React.createRef();
        this.routeTableRef = React.createRef();
    }

    render() {
        const { t } = this.props;
        const { initialData, deliveryOptions, shippingServices, locations, routes, showMoreOptions, stations} = this.state;
        return (
            <Content>
                <Form ref={this.deliveryFormRef}>
                    <div className='row mx-auto'>
                        <div className='col-3 mx-auto'>
                            <label>{t("text.shippingService")}</label>
                            <Form.Item name={['shippingService', 'id']}
                                rules={[{ required: true, message: t('messages.shippingService')}]}>
                                <Select
                                    onChange={this.onChangeShippingService}
                                    optionFilterProp='children'
                                    placeholder={t('placeholder.shippingService')}>
                                    {
                                        shippingServices?.map(({ id, name, languageKey }) => <Select.Option key={id} value={id}>{t(languageKey)}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                        <div className='col-6 offset-2'>
                            <div className='title'>
                                <label> <i className='fa fa-truck' aria-hidden='true'></i>{t('order.deliveryOptions') }</label>
                            </div>
                            <div className='action'>
                                <Form.Item name={['deliveryOption', 'id']}
                                    rules={[{
                                        required: true,
                                        message: t('messages.deliveryOption')
                                    }]}
                                >
                                    <Space direction='vertical'>
                                        {
                                            deliveryOptions?.map(({ id, name, languageKey }) => {
                                                const selected = initialData?.deliveryOption ?? null;
                                                return (
                                                    <Radio className='row' key={id} value={id} onChange={() => this.onChangeDeliveryOption(id)} checked={id === selected?.id}>
                                                        {t(languageKey)}
                                                    </Radio>
                                                );
                                            })}
                                    </Space>
                                </Form.Item>
                                {
                                    showMoreOptions && <div className='choose-delivery'>
                                        <div className='mt-3 title-sub'>
                                            <span><i className='fa fa-snowflake-o' aria-hidden='true'></i>
                                                {t('messages.deliveryDescription')}
                                            </span>
                                        </div>
                                        <Row className='mt-3'>
                                            <Col span={7} className='mr-4'>
                                                <Form.Item rules={[{
                                                    required: true,
                                                    message: t('messages.selectLocation')
                                                }]}>
                                                    <Select
                                                        onChange={this.onChangeLocation}
                                                        placeholder={t('placeholder.selectLocation')}>
                                                        {
                                                            locations?.map(({ id, name, languageKey }) => <Select.Option key={id} value={id}>{t(languageKey)}</Select.Option>)
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={7}>
                                                <Form.Item
                                                    name={['station', 'id']}
                                                    rules={[{
                                                        required: true,
                                                        message: t('messages.selectStation')
                                                    }]}>
                                                    <Select
                                                        value={initialData.station?.id}
                                                        onChange={value => this.onChangeStation(value)}
                                                        placeholder={t('placeholder.selectStation')}>
                                                        {
                                                            stations?.map(({ id, name }) => <Select.Option key={id} value={id}>{name}</Select.Option>)
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    <Form.Item>
                        <RouteTableComponent
                            ref={this.routeTableRef}
                            t={t}
                            initialData={routes}
                            onChange={this.onSelectRoute}
                        />
                    </Form.Item>
                </Form>
            </Content>
        )
    }

    getFieldsValue = () => {
        var { deliveryOptions, shippingServices, cachedRoutes } = this.state;
        var deliveryInfo = {};
        if (this.routeTableRef && this.routeTableRef.current) {
            var route = this.routeTableRef.current.getFieldsValue();
            var routeInfo = cachedRoutes?.find((i) => i.key === route.id) ?? null;
            deliveryInfo.route = routeInfo;
            this.setState({ ...this.state, route: route });
        }

        if (this.deliveryFormRef && this.deliveryFormRef.current) {
            var deliveryMethod = this.deliveryFormRef.current.getFieldsValue();
            var delivery = deliveryOptions?.find((i) => i.id === deliveryMethod.deliveryOption.id) ?? null;
            var shippingService = shippingServices?.find((i) => i.id === deliveryMethod.shippingService.id) ?? null;
            deliveryInfo.shippingService = shippingService;
            deliveryInfo.deliveryOption = delivery;
            deliveryInfo.toStationId = deliveryMethod?.station?.id ?? null;
            this.setState({ ...this.state, route: route });
        }

        return deliveryInfo;
    }

    setFieldsValue = (values) => {
        if (this.deliveryFormRef && this.deliveryFormRef.current) {
            return this.deliveryFormRef.current.setFieldsValue(values);
        }

        this.setState({ ...this.state, initialData: { ...this.state.initialData, ...values } });
    }

    validateFields = () => {
        if (this.deliveryFormRef && this.deliveryFormRef.current) {
            return this.deliveryFormRef.current.validateFields();
        }
    }

    onChangeShippingService = (value) => {
        const { shippingServices, initialData } = this.state;
        if (shippingServices) {
            const shippingService = shippingServices.find((i) => i.id === value);
            if (shippingService) {
                this.setState({
                    ...this.state,
                    initialData: {
                        ...initialData,
                        shippingService: shippingService
                    }
                }, () => {
                    this.refresh();
                });
            }
        }
    }

    onChangeDeliveryOption = (value) => {
        var { deliveryOptions, initialData } = this.state;
        if (deliveryOptions) {
            const deliveryOption = deliveryOptions?.find((i) => i.id === value);
            if (deliveryOption) {
                var showMoreOptions = false;
                if (this.isShowMoreOption(deliveryOption)) {
                    showMoreOptions = true;
                }
                this.setState({
                    ...this.state,
                    showMoreOptions: showMoreOptions,
                    initialData: {
                        ...initialData,
                        deliveryOption: deliveryOption,
                        station: null
                    },
                }, () => {
                    this.refresh();
                });
            }
        }
    }

    refresh = (values) => {
        const { initialData, shippingInfo, productInfo, station, stationInfo} = this.state;
        var { shippingService, deliveryOption } = initialData;
        if (!shippingService || !deliveryOption || !shippingInfo || !productInfo) {
            console.log('cannot refresh');
            return;
        }

        var units = this.state.productInfo?.products?.map((i, index) => {
            return {
                key: index + 1,
                length: i.length,
                width: i.width,
                height: i.height,
                weight: i.weight,
                amount: i.amount,
            }
        });
        var routesByDeliveryRequest = {
            deliveryTypeId: deliveryOption?.id,
            shippingServiceId: shippingService?.id,
            units: units ?? [],
            senderAddress: this.state.shippingInfo?.senderInfo?.address,
            receiverAddress: this.state.shippingInfo?.receiverInfo?.address,
            fromStationId: stationInfo.id ?? null
        }

        if (this.isValidModel(routesByDeliveryRequest)) {
            if (this.routeTableRef?.current?.onUpdate) {
                this.routeTableRef?.current?.onUpdate([], true);
                routeDataService.getRouteByDelivery(routesByDeliveryRequest).then((res) => {
                    var routes = res.map((r) => this.transfom(r));
                    this.setState({
                        cachedRoutes: routes
                    });

                    this.filterRoutesToReceiverAddress(routes);
                    var toStationOption = deliveryOption?.name?.toLowerCase().includes(DeliveryConstants.TO_STATION);
                    if (!toStationOption) {
                        this.routeTableRef?.current?.onUpdate(routes, false);
                    }

                }).catch((error) => {
                    this.routeTableRef?.current?.onUpdate([], false);
                    console.error(error);
                });
            }
        }
    }

    isValidModel = (routesByDeliveryRequest) => {
        if (!routesByDeliveryRequest.deliveryTypeId ||
            !routesByDeliveryRequest.shippingServiceId ||
            !routesByDeliveryRequest.units ||
            !routesByDeliveryRequest.senderAddress ||
            !routesByDeliveryRequest.receiverAddress ||
            !routesByDeliveryRequest.fromStationId

        ) {
            console.log('Invalid request model')
            console.log(routesByDeliveryRequest)
            return false;
        }
        return true;
    }

    isShowMoreOption = (deliveryOption) => {
        if (deliveryOption) {
            return deliveryOption?.name.toLowerCase().includes(DeliveryConstants.TO_STATION) ? true : false;
        }

        return false;
    }

    onChangeLocation = (locationId) => {
        this.routeTableRef?.current?.onUpdate([], false);
        var { initialData, locations } = this.state;
        if (locations) {
            var location = locations.find((i) => i.id === locationId);
            this.setState({
                stations: location.stations ?? [],
                initialData: {
                    ...initialData,
                    station: null,
                    location: location
                }
            });

            this.deliveryFormRef?.current?.setFieldsValue(this.state.initialData)
        }
    }

    onChangeStation = (stationId) => {
        var { cachedRoutes } = this.state;
        var routes = [];
        cachedRoutes?.forEach((route) => {
            var subrouteDetails = route.subRoutesDetails;
            subrouteDetails.forEach((s) => {
                if (s.toStationNavigation.id === stationId) {
                    routes.push(route);
                    return;
                }
            });
        });

        this.routeTableRef?.current?.onUpdate(routes, false);
    }

    onSelectRoute = (routeId) => {
        var { cachedRoutes } = this.state;
        if (this.routeTableRef && this.routeTableRef.current) {
            var routeInfo = cachedRoutes?.find((i) => i.key === routeId) ?? null;
            this.setState({ ...this.state, route: routeInfo });
            if (this.deliveryFormRef && this.deliveryFormRef.current) {
                var deliveryInfo = this.getFieldsValue();
                deliveryInfo.route = routeInfo;

                this.deliveryFormRef.current.setFieldsValue(deliveryInfo);
            }
        }
    }

    filterRoutesToReceiverAddress = (list) => {
        var { shippingInfo } = this.state;
        var routes = [];
        var stations = [];
        var receiverAddress = shippingInfo.receiverInfo?.address;
        list?.forEach((route) => {
            var subrouteDetails = route.subRoutesDetails;
            subrouteDetails.forEach((s) => {
                if (s.toStationNavigation.address) {
                    var toStation = s.toStationNavigation.address.city.id;
                    if (toStation == receiverAddress.city.id) {
                        routes.push(route);
                        stations.push(s.toStationNavigation);
                        return;
                    }
                }
            });
        });

        // Get locations and group stations by location
        var uniqueStationsById = [...new Map(stations.map(item => [item['id'], item])).values()];
        var groups = uniqueStationsById?.reduce((r, a) => {
            r[a.location] = [...r[a.location] || [], a];
            return r;
        }, {});
        var locationNames = Object.getOwnPropertyNames(groups);
        var location = locationNames.map((locationName, index) => {
            return {
                id: index + 1,
                name: locationName,
                stations: groups[locationName],
                languageKey: groups[locationName]?.find(g => g.location === locationName)?.languageKey
            }
        })

        this.setState({
            ...this.state,
            locations: location,
        });
    }

    transfom = (route) => {
        const { t } = this.props;
        return {
            key: route.id,
            id: route.id,
            routeId: route.code,
            routeName: route.routeName,
            subRoutes: this.formatSubRoutes(route.subRoutes),
            location: route.fromStationDetail?.location,
            eta: route.estimate,
            price: this.formatAmount(route.price) + t('order.priceFormat'),
            company: route.company,
            subRoutesDetails: route.subRoutes,
            shippingFeeDetails: route.shippingFeeDetails
        }
    }

    formatSubRoutes = (subRoutes) => {
        var toStations = subRoutes?.map((r) => `${r.toStationNavigation?.name}`)?.join(' - ') || '';
        if (toStations.length > 1) {
            return `${subRoutes[0].fromStationNavigation?.name} - ${toStations}`;
        }
        return '';
    }

    formatAmount = (value) => {
        if (isNaN(value) || value == null) {
            return '0';
        }

        return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
}
