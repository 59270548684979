import React, { Component } from 'react';
import Layout, { Content } from 'antd/lib/layout/layout';
import './view-detail-order.scss';
import TopBar from '../../components/topbar';
import { deliveryMethods, deliveryStaff, doorToStation, iconPayment, specialServices } from '../../constants/order-constant';
import iconMomo from '../../assets/icons/momo.png';
import iconMoney from '../../assets/icons/money.jpg';
import iconBank from '../../assets/icons/bank.jpg';
import deliveryIcon from '../../assets/icons/icon-delivery.png';
import fragileIcon from '../../assets/icons/icon-fragile.png';
import fastIcon from '../../assets/icons/icon-fast.png';
import { formatAmount } from '../../utils/order';
import { StationToWarehouser, TEXT_VND } from '../../constants/common';
import PrintModal from '../../components/modal-print/modal-print.component'
import Barcode from 'react-barcode';
import { Button, message } from 'antd';
import { Link } from 'react-router-dom';
import { orderStatus, orderDeliveryMethod } from '../../constants/order-constant';
import orderDataService from './../../data-services/orders/orders-data.service';
import { DeliveryConstants } from '../../constants/delivery.constants';

export default class ViewOrderDetailScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            sumAmount: 0,
            totalWeight: 0,
            showBarcode: false,
            onlyPrintBarcode: false
        }
        this.choosePrintRef = React.createRef();
    }

    componentDidMount() {
        const { orderId } = this.props?.match?.params || {}
        this.props.getOrderDetails(orderId)
        this.props.getInitData(DeliveryConstants.DELIVERY_STAFF);
        window.onafterprint = () => {
            this.setState({ showBarcode: false, onlyPrintBarcode: false })
        }
    }

    render() {
        const { t } = this.props;
        const { showBarcode, onlyPrintBarcode } = this.state
        const { code } = this.props.order
        return (
            <Layout className='bg-content'>
                <div className='bg-body'>
                    <TopBar title={t('title.orderDetail')} />
                    <div className='bg-white clearfix'>
                        <Content className='beecow-summarize-info'>
                            {
                                showBarcode && <div className='row'>
                                    <Barcode
                                        def
                                        value={code}
                                        format='code128'
                                    />
                                </div>
                            }
                            {
                                !onlyPrintBarcode && <>
                                    {this.renderBookingInfo()}
                                    {this.renderProductsInfo()}
                                    <div className='row'>
                                        <div className='col-sm-6'>
                                            {this.renderDelivery()}
                                            {this.renderRoute()}
                                        </div>
                                        <div className='col-sm-6'>
                                            {this.renderPayment()}
                                        </div>
                                    </div>
                                    {this.renderAction()}
                                </>
                            }
                        </Content>
                    </div>
                </div>
            </Layout>
        )
    }

    renderBookingInfo = () => {
        const { t } = this.props;
        const { code, status, senderPhone, senderName, receiverPhone, receiverName, addressRecipient, addressSender, orderTrackingCode } = this.props.order
        //address receiver
        const wardReceiver = `${addressRecipient?.ward?.prefix} ${addressRecipient?.ward?.name}`
        const districtReceiver = `${addressRecipient?.district?.prefix} ${addressRecipient?.district?.name}`
        const cityReceiver = addressRecipient?.city?.name
        //address sender
        const wardSender = `${addressSender?.ward?.prefix} ${addressSender?.ward?.name}`
        const districtSender = `${addressSender?.district?.prefix} ${addressSender?.district?.name}`
        const citySender = addressSender?.city?.name
        return (
            <div className='detail-page'>
                <div className='row'>
                    <div className='col-sm-6'>
                        <h4>{t('tableColumn.orderCode')}: {code}</h4>
                        <h4>{t('order.trackingOrderId')}: {orderTrackingCode}</h4>
                    </div>
                    <div className='col-sm-4'>
                        <h3>{t('text.status')}: {t(status?.languageKey || status?.statusName)}</h3>
                        <div><Link target='_blank' to={`/view-order-history/${orderTrackingCode?.toLowerCase()}`}>{t('text.viewTrackingOrder')}</Link></div>
                    </div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-sm-6'>
                        <h3>{t('order.senderInfo')}</h3>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <div className='row pl-4 mx-auto'>
                                    <p className='mr-5'>{t('order.senderPhone')}: </p>
                                    <p> {senderPhone}</p>
                                </div>
                                <div className='row pl-4 mx-auto'>
                                    <p className='mr-5'>{t('order.senderName')}:</p>
                                    <p> {senderName}</p>
                                </div>
                                <div className='row pl-4 mx-auto'>
                                    <p className='mr-5'>{t('order.senderAddress')}: <span>{addressSender?.street}, {wardSender}, {districtSender}, {citySender}</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <h3>{t('order.receiverInfo')}</h3>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <div className='row pl-4 mx-auto'>
                                    <p className='mr-5'>{t('order.receiverPhone')}:</p>
                                    <p> {receiverPhone}</p>
                                </div>
                                <div className='row pl-4 mx-auto'>
                                    <p className='mr-5'>{t('order.receiverName')}:</p>
                                    <p> {receiverName}</p>
                                </div>
                                <div className='row pl-4 mx-auto'>
                                    <p className='mr-5'>{t('order.receiverAddress')}: <span>{addressRecipient?.street}, {wardReceiver}, {districtReceiver}, {cityReceiver}</span> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderProductsInfo = () => {
        const { t } = this.props;
        const { orderItems } = this.props.order || {}
        const sumAmount = orderItems?.map(x => x.amount).reduce((a, b) => a + b, 0)
        const totalWeight = orderItems?.map(x => x.amount * x.product?.weight).reduce((a, b) => a + b, 0)

        return <>
            <div className='row mt-4 mb-5'>
                <div className='col-sm-12'>
                    <h3>{t('order.productInformation')}</h3>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>{t('tableColumn.no')}</th>
                                <th>{t('tableColumn.productCode')}</th>
                                <th>{t('tableColumn.productName')}</th>
                                <th>{t('tableColumn.productCategory')}</th>
                                <th className='text-center'>{t('tableColumn.sizeInLenthWidthHeight')} (m)</th>
                                <th className='text-center'>{t('tableColumn.weight')} (Kg)</th>
                                <th className='text-center'>{t('tableColumn.amount')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                orderItems?.map((p, index) => {
                                    const { code, name, length, height, width, weight, packageType } = p.product || {}
                                    return <tr key={p.id}>
                                        <td className='pl-3'>{index + 1}</td>
                                        <td className='pl-3'>{code}</td>
                                        <td className='pl-3'>{name}</td>
                                        <td className='pl-3'>{t(packageType?.languageKey)}</td>
                                        <td className='text-center'>{length} x {width} x {height}</td>
                                        <td className='text-center'>{weight} Kg</td>
                                        <td className='text-center'>{p.amount}</td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                    <hr className='line' />
                    <div className='total-card float-right'>
                        <div className='row mx-auto'>
                            <p className='col-sm-8'>{t('tableColumn.totalProductsInPackage')}:</p>
                            <p className='col-sm-3 text-right'>{sumAmount}</p>
                        </div>
                        <div className='row mx-auto'>
                            <p className='col-sm-8'>{t('tableColumn.totalWeight')}:</p>
                            <p className='col-sm-3 text-right'>{totalWeight} Kg</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }

    renderDelivery = () => {
        const { t } = this.props;
        const { deliveryType, contract, station, toStation, specialOption } = this.props.order || {}
        const isShowDeliveryIcon = deliveryMethods.includes(deliveryType?.name)
        const { name, code } = toStation || {}
        const receiverStation = name && `${code} (${name})` || ''
        return <>
            <h3>{t('tableColumn.deliveryMethod')}</h3>
            <div className='row'>
                <div className='col-sm-12'>
                    <p className='pl-4 mr-5'>{t(deliveryType?.languageKey)} {isShowDeliveryIcon && <img src={deliveryIcon} alt={deliveryType?.name} width={30} />}</p>
                    <p className='pl-4 mr-5'>{t('tableColumn.shippingService')}: <span>{t(specialOption?.languageKey)}  {this.getSpecialServiceIcon(specialOption?.name)}</span></p>
                    <p className='pl-4 mr-5'>{t('tableColumn.contractId')}:  {contract && <span className='click-contract'><a>{contract?.code}</a></span>}</p>

                    <p className='pl-4 mr-5'>{t('text.assignedStation')}: <span>{`${station?.code} (${station?.name})`}</span></p>
                </div>
                <div className='row pl-4'>
                    <div className='col-sm-7'>
                        <p className='pl-4 mr-5'>{t('text.stationWhereSenderWillDelivery')}:</p>
                    </div>
                    <div className='col-sm-4'>
                        {deliveryType?.name?.toLowerCase() === StationToWarehouser?.toLowerCase() && <span>{receiverStation}</span>}
                    </div>
                </div>
                <div className='row pl-4'>
                    <div className='col-sm-7'>
                        <p className='pl-4 mr-5'>{t('text.stationWhereReceiverPickUpPackage')}: </p>
                    </div>
                    <div className='col-sm-4'>
                        {deliveryType?.name?.toLowerCase() === doorToStation?.toLowerCase() && <span>{receiverStation}</span>}
                    </div>
                </div>
            </div>
        </>
    }

    renderRoute = () => {
        const { t } = this.props;
        const { route } = this.props.order || {}
        return <>
            <div className='mt-3'>
                <h3>{t('order.routing')}</h3>
                <div className='row'>
                    <div className='col-sm-12'>
                        <div className='row mx-auto'>
                            <p className='col-sm-4'>{t('tableColumn.provider')}:</p>
                            <p className='col-sm-8'>{route?.company?.name} <span><img src={route?.company?.logoPath} width={111} alt={route?.company?.name} /></span></p>
                        </div>
                        <div className='row mx-auto'>
                            <p className='col-sm-4'>{t('tableColumn.routeId')}:</p>
                            <p className='col-sm-8'>{route?.code}</p>
                        </div>
                        <div className='row mx-auto'>
                            <p className='col-sm-4'>{t('tableColumn.routeName')}:</p>
                            <p className='col-sm-8'>{route?.routeName}</p>
                        </div>
                    </div>
                </div>

            </div>
        </>
    }

    renderPayment = () => {
        const { t } = this.props;
        const { paymentType, payByLanguageKey, codamount, shippingFee, totalFee, deliveryToDoorFee, pickupFromDoorFee, codFee, delivery } = this.props.order || {}
        return <>
            <h3>{t('text.payment')}</h3>
            <div className='row'>
                <div className='col-sm-4 pl-5'>
                    <p className='mr-5'>  {this.getPaymentTypeIcon(paymentType?.name)} {t(paymentType?.languageKey)}</p>
                </div>
                <div className='col-sm-4 pl-5'>
                    <p className='mr-5'>{t(payByLanguageKey)}</p>
                </div>
            </div>
            <div className='row'>
                <div className='col-sm-8 pl-5'>
                    <div className='total-card'>
                        {codamount > 0 && <div className='row'>
                            <div className='col-sm-6'>
                                <p className='m-0'>{t('text.codAmount')}:</p>
                                <p className='m-0 pl-4 text-detail font-italic'>{t('text.codAmountDeltail-1')}</p>
                                <p className='m-0 pl-4 text-detail font-italic'>{t('text.codAmountDeltail-2')}</p>
                            </div>
                            <p className='col-sm-6 text-right'>{formatAmount(codamount) + TEXT_VND}</p>
                        </div>}
                        {
                            codFee > 0 && <div className='row mt-2'>
                                <p className='col-sm-6'>{t('text.codFee')}:</p>
                                <p className='col-sm-6 text-right'>{formatAmount(codFee) + TEXT_VND}</p>
                            </div>
                        }
                        {
                            pickupFromDoorFee > 0 && <div className='row mt-2'>
                                <p className='col-sm-6'>{t('text.pickupFee')}:</p>
                                <p className='col-sm-6 text-right'>{formatAmount(pickupFromDoorFee) + TEXT_VND}</p>
                            </div>
                        }
                        <div className='row'>
                            <p className='col-sm-6'>{t('tableColumn.shippingFee')}:</p>
                            <p className='col-sm-6 text-right'>{formatAmount(shippingFee) + TEXT_VND}</p>
                        </div>
                        {
                            delivery?.name.toLowerCase() !== StationToWarehouser.toLowerCase() &&
                            <div className='row'>
                                <p className='col-sm-6'>{t('text.additionalDoorServiceFee')}:</p>
                                <p className='col-sm-6 text-right'>{formatAmount(deliveryToDoorFee) + TEXT_VND}</p>
                            </div>
                        }
                        <div className='row'>
                            <p className='col-sm-6'>{t('text.total')}:</p>
                            <p className='text-danger col-sm-6 text-right'>{formatAmount(totalFee) + TEXT_VND}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
    renderAction = () => {
        const { t } = this.props;
        const { toStation, status, delivery } = this.props.order || {}
        const showButton = (toStation?.id === this.props.stationId
            && status?.statusName.toLowerCase() === orderStatus['IN_WAREHOUSE']
            && delivery?.name.toLowerCase().includes(orderDeliveryMethod['TO_STATION']))
        return (
            <>
                <div className='row'>
                    <div className='col-sm-12 offset-sm-8 action-detail'>
                        <div className='click-close no-print' onClick={this.onBackHome.bind(this)}>{t('button.close')}</div>
                        {
                            showButton && <Button className='btn-primary text-white' id='btn-receipient-picked' onClick={this.submit}>{t('button.recipientPicked')}</Button>
                        }
                        <div className='content-print'>
                            <PrintModal
                                t={t}
                                handlePrintContent={this.handlePrintContent}
                                handlePrintBarcode={this.handlePrintBarcode}
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    }

    handlePrintContent = () => {
        this.setState({ showBarcode: true }, () => {
            window.print()
        })
    }

    handlePrintBarcode = () => {
        this.setState({ showBarcode: true, onlyPrintBarcode: true }, () => {
            window.print()
        })
    }

    getSpecialServiceIcon = (name) => {
        if (name === specialServices.fragile) {
            return <img width={30} src={fragileIcon} alt="Fragile Service" />
        } else if (name === specialServices.fastService) {
            return <img width={30} src={fastIcon} alt="Fast Service" />
        }
        return <></>
    }

    getPaymentTypeIcon(name) {
        if (name === iconPayment.cash) {
            return <img width={30} src={iconMoney} alt={iconPayment.cash} />
        }
        else if (name === iconPayment.bank) {
            return <img width={30} src={iconBank} alt={iconPayment.bank} />
        }
        else if (name === iconPayment.ewallet) {
            return <img width={30} src={iconMomo} alt={iconPayment.ewallet} />
        }
    }

    onBackHome() {
        this.props.history.push(`/list-orders`);
    }

    submit = () => {
        const { t } = this.props;
        var { id } = this.props.order;

        orderDataService.updateOrderToDelivered(id).then(res => {
            if (res.success) {
                message.success('The order is delivered successfully!');
                document.getElementById('btn-receipient-picked').style.display = 'none';
            } else {
                message.error('The order is delivered failed!');
            }
        });
    }
}

