import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, Input, Button, Switch, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import jwt_decode from 'jwt-decode';
import { Redirect } from 'react-router'
import loginDataService from '../../../data-services/login/login-data.service';
import TopBar from './../../../components/topbar';
import './login.screen.scss';
import ForgotPasswordForm from '../../../components/forgot-password/forgot-password.component';
import userDataService from '../../../data-services/user/user-data.service';

const CLAIMS_SID = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid';
const CLAIMS_NAME = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'
const CLAIMS_EMAIL = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'
const CLAIMS_ROLE = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
const CLAIMS_CURRENT_STATION_ID = 'currentStationId'
const CLAIMS_COMPANY_IDENTITY = 'COMPANY_IDENTITY';
const CLAIMS_CHANGED_PASSWORD = 'CHANGED_PASSWORD';

export class LoginScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn: false,
            loginByPhone: false,
            forgotPasswordVisible: false,
        }
    }

    componentDidMount() {
        const { userId } = this.props.match?.params;
        if (userId) {
            this.props?.signOut(userId);
            userDataService.getUserId(userId)
                .then(res => {
                    this.setState({ userName: res?.email });
                })
        }
        this.setState({
            isLoggedIn: this.props.isAuthenticated,
        });
    }

    render() {
        const { t } = this.props;
        if (this.state.isLoggedIn) {
            return <Redirect to='/dashboard' />;
        }
        return (
            <div className='w-100'>
                <TopBar />
                <div className='m-auto beecow-login-bg'>
                    {
                        this.state.forgotPasswordVisible ? <>
                            <ForgotPasswordForm
                                t={t}
                                callBack={() => this.setState({ forgotPasswordVisible: false })}
                            />
                        </>
                            : <>
                                <Form
                                    name='normal_login'
                                    className="m-auto centered shadow p-4 pt-5 mb-5 bg-white rounded login-form"
                                    initialValues={{ rememberMe: true }}
                                    onFinish={this.onFinish}
                                >
                                    <div className='mb-5 text-center'>
                                        <div className='logo'>
                                            <div className='name d-flex justify-content-center'>
                                                <h1>BEECOW</h1>
                                            </div>
                                            <span className='mb-1'>STATION</span>
                                        </div>
                                    </div>
                                    <Form.Item name='loginByPhone'>
                                        <Switch onChange={this.onChange} />
                                        <span className='ml-3' id='loginByPhone'>
                                            {this.props.i18n.t('signIn.signInByPhone')}
                                        </span>
                                    </Form.Item>
                                    {!this.state.loginByPhone ?
                                        (
                                            <div>
                                                <Form.Item
                                                    name='userName'
                                                    rules={[{
                                                        required: true,
                                                        message: t('messages.pleaseInputUsername')
                                                    }]}
                                                >
                                                    <Input
                                                        prefix={<UserOutlined className='site-form-item-icon' />}
                                                        placeholder={t('placeholder.username')} />
                                                </Form.Item>
                                                <Form.Item
                                                    name='password'
                                                    rules={[{
                                                        required: true,
                                                        message: t('messages.pleaseInputPassword')
                                                    }]}
                                                >
                                                    <Input
                                                        prefix={<LockOutlined className='site-form-item-icon' />}
                                                        type='password'
                                                        placeholder={t('placeholder.password')}
                                                    />
                                                </Form.Item>
                                            </div>
                                        ) :
                                        (
                                            <Form.Item
                                                name='phoneNumber'
                                                rules={[{
                                                    required: true,
                                                    message: t('messages.pleaseInputPhoneNumber')
                                                }]}
                                            >
                                                <Input prefix={<UserOutlined className='site-form-item-icon' />} placeholder={t('placeholder.phoneNumber')} />
                                            </Form.Item>
                                        )
                                    }
                                    <Form.Item className='mt-2'>
                                        <a className='login-form-forgot float-right'
                                            onClick={() => this.setState({ forgotPasswordVisible: true })}
                                        >
                                            {t('signIn.forgotPassword')}
                                        </a>
                                        <Button type='primary' htmlType='submit' className='login-form-button col-12' id='loginButton'>
                                            {this.props.i18n.t('signIn.signInButton')}
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </>
                    }
                </div>
            </div>
        )
    }

    onChange = (checked) => {
        this.setState({
            loginByPhone: !this.state.loginByPhone,
        });
    }

    onFinish = (values) => {
        let self = this;
        const { t } = this.props;

        loginDataService.authenticate(values)
            .then((data) => {
                let token = data.token || '';
                let refreshToken = data.refreshToken || '';
                let exprire = data.exprire;
                let userInfo = self.getUserInfo(token);
                let workspaceId = self.getWorkspaceId(token);
                self.setupWorkspace(token, refreshToken, exprire, userInfo, workspaceId);
            })
            .catch((err) => {
                message.error(t('messages.loginFail'));
            });
    }

    getUserInfo(token) {
        let claims = jwt_decode(token);
        let user = {
            'userid': claims[CLAIMS_SID],
            'username': claims[CLAIMS_NAME],
            'emailaddress': claims[CLAIMS_EMAIL],
            'role': claims[CLAIMS_ROLE],
            'currentStationId': claims[CLAIMS_CURRENT_STATION_ID],
            'changedPassWord': claims[CLAIMS_CHANGED_PASSWORD]
        };
        return user;
    }

    getWorkspaceId(token) {
        let claims = jwt_decode(token);
        const { t } = this.props;

        return claims[CLAIMS_COMPANY_IDENTITY];
    }

    setupWorkspace(token, refreshToken, exprire, userInfo, workspaceId) {
        const { t } = this.props;

        if (userInfo.role == 'STATION') {
            let auth = { token: token, refreshToken: refreshToken, exprire: exprire, user: userInfo };
            this.props.setAuth(auth);
            this.props.setWorkspace(workspaceId);
            message.success(t('messages.loggedSuccess'));
            this.setState({ isLoggedIn: true });
        } else {
            message.error(t('messages.cannotPermissionAccess'));
        }
    }
}