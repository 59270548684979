import actionTypes from "./profile.types";
import userDataService from "../../data-services/user/user-data.service";

export const getInitUser = () => {
    return async (dispatch) => {
      const res = await userDataService.getInitUser()
      dispatch({
        type: actionTypes.GET_INIT_USER,
        payload: res
      })
    }
  }

  export const getUserId = (id) => {
    return async (dispatch) => {
      const res = await userDataService.getUserId(id)
      dispatch({
        type: actionTypes.GET_USER_ID,
        payload: res.users || {}
      })
    }
  }