import { Component } from 'react';
import { Button, Tabs, Card } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import { Route } from 'react-router-dom';
import TopBar from '../../components/topbar';
import TableOrdersComponent from './table-orders/table-orders.component';
import { showAlert } from '../../common/constants';
import orderDataService from '../../data-services/orders/orders-data.service';

const { TabPane } = Tabs;

export class ListOrdersScreen extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.InitDataTable();
    };

    InitDataTable() {
        if (this.props.currentStationId) {
            this.props.listOrders(this.props.currentStationId);
        }
    };

    handleDeleteOrder = (orderId) => {
        const { t } = this.props;
        let message = '';
        let description = '';
        orderDataService.deleteOrder(orderId).then((res) => {
            if (res) {
                message = t('confirm.success');
                description = t('confirm.deleteSuccess');
                showAlert(message, description, true);
                this.InitDataTable();
            } else {
                message = t('confirm.warning');
                description = t('confirm.deleteFail');
                showAlert(message, description, false);
            }
        });
    };

    render() {
        const { t } = this.props;
        let orderList = this.props.orders;
        return (
            <Layout className='bg-content'>
                <div className='bg-body'>
                    <TopBar title={t('title.listOfOrder')} />
                    <Content className='content'>
                        <Card>
                            <Tabs defaultActiveKey='active' tabBarExtraContent={this.createOrderBtn}>
                                <TabPane tab={t('text.activeOrders')} key='active'>
                                    <TableOrdersComponent t={t} dataSource={orderList} handleDeleteOrder={this.handleDeleteOrder} />
                                </TabPane>
                            </Tabs>
                        </Card>
                    </Content>
                </div>
            </Layout>
        );
    }

    createOrderBtn = () => {
        const { t } = this.props;
        return <Route render={({ history }) => (
            <Button className='add-new' onClick={() => history.push('/create-order')}>
                <i className='fas fa-scroll'></i>
                <p>{t('title.createOrder')}</p>
            </Button>
        )} />
    }
}