export const orderTrackingPhaseType = {
  // Created Order <Order ID> by <Sender's Name or Station User's Name> . Ex: Created Order Order-001 by User_1
  createdOrEditOrder: 1,

  // Created Pickup List <Pickup ID> by <Station user's name>
  createOrEditPickupList: 2,

  // Order assigned to <Vehicle's ID> - <Vehicle's type> - <Driver's name>. Ex: Order assigned to VEH-01 - Bike - Diver
  orderAssignedToDriver: 3,

  // <Driver's name> going to pick the Order
  driverGoingToPickOrder: 4,

  // <Driver's name> picked the Order
  driverPickedOrder: 5,

  // <Driver's name> can't pick the Order with reason: <Reason>
  driverCanNotPickOrderWithReason: 6,

  // Order finished picking process and waiting for import to the <Station ID + Name>
  orderFinishedPickingProcessAndWaitingForImport: 7,

  // Order imported to the <Station ID + Name> by <Station user's name>. [There are some product(s) missing.] Please see more detail at <Import ID>.
  createOrEditOrderImportedToStation: 8,

  // Created Export List by <Station user's name>. [There are some product(s) missing.] Please see more detail at <Export ID>
  createOrEditExportListByStation: 9,

  // <Driver's name> picked this Export list to transport with route <Route ID + Name> and vehicle <Vehicle ID + Type>
  driverPickedThisExportListToTransportWithRoute: 10,

  // The Order exported to the <Vehicle ID>
  orderExportedToVehicle: 11,

  // The Order is in transit to the <Next Station ID + Name>
  orderIsInTransitToStation: 12,

 // The Order is in transit to the <Last Station ID + Name>
  orderIsInTransitToLastStation: 13,

  // <Driver's name> arrived and importing to last station <Last station ID + Name>
  driverArrivedAndImportingToLastStation: 14,

  // <Station user's name> imported to last station <station ID + name>. [There are some product(s) missing]. Please see more detail at <Import from Vehicle ID>
  createOrEditImportedToLastStation: 15,

  // Created Delivery List by <Station user's name>
  createOrEditDeliveryListByStation: 16,

  // Order assigned to <Vehicle's ID> - <Vehicle's type> - <Driver's name>
  orderAssignedToDriverToDeliver: 17,

  // <Driver's name> going to deliver the Order
  driverGoingToDeliverOrder: 18,

  // <Driver's name> delivered the Order to <recipient's name>
  driverDeliveredOrderToRecipient: 19,

  // <Driver's name> can't deliver the Order with reason: <Reason>
  driverCanNotDeliverOrderWithReason: 20,

  // Order finished Delivery process.
  orderFinishedDeliveryProcess: 21,

  // The <recipient's name> received the Order at the station <Station ID + Name>
  recipientOrderAtStation: 22,
};

export const orderTrackingStatusType = {
    orderBeingProcessed: 1,
    inTransit: 2,
    delivered: 3
}