import _ from 'lodash'
import { specialServices} from '../constants/order-constant'
import orderDataService from '../data-services/orders/orders-data.service'
import fragileIcon from "../assets/icons/icon-fragile.png"
import fastIcon from "../assets/icons/icon-fast.png"
import { Image } from 'antd';
import { formatSubRoutes } from './routing';
import { TEXT_VND }  from '../constants/common';

const getData = (routes, props) => {
  const { selectedRoute } = props
  const { specialServices } = props.initData
  const { packageHeight, packageLength, packageWeight, packageWidth, SpecialOptionId } = props.data || {}
  const additional = specialServices.find((x) => x.id === SpecialOptionId)?.name
  return routes.map((x) => {
    const { code, routeName, estimate, subRoutes } = x
    const isSelected = x.id === selectedRoute?.id
    const price = isSelected && selectedRoute?.price || x.shippingFee?.shippingFeeDetails.find((x) => x.height === packageHeight
      && x.weight === packageWeight
      && x.length === packageLength
      && x.width === packageWidth
      && x.additional?.toLowerCase() === additional?.toLowerCase())?.price
    const fromSubs = _.sortBy(subRoutes, (x) => x.position)?.map(x => { return x.fromStationNavigation?.name })
    const toSubs = _.sortBy(subRoutes, (x) => x.position)?.map(x => { return x.toStationNavigation?.name })

    return {
      key: x.id,
      routeId: code,
      routeName: routeName,
      from: getSubRouteHtml(fromSubs),
      to: getSubRouteHtml(toSubs),
      subRoutes: formatSubRoutes(subRoutes),
      eta: estimate,
      price: `${price}${TEXT_VND}`,
    }
  })
}

const getSubRouteHtml = (subRoutes) => {
  return subRoutes?.map((x) => `<span>${x}</span> <br> `)?.join('') || ''
}

export const formatAmount = (value) => {
  if (value === undefined || value === "" || isNaN(value)) {
    return "";
  }
  return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const validatePhoneNumber = (phoneNUmber) => {
  const validatePhone = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  return validatePhone.test(phoneNUmber)
}

export const getPrice = async (props, valueCOD, data) => {
  const { selectedRoute } = props
  const { specialServices, deliveries } = props.initData
  const { packageHeight, packageLength, packageWeight, packageWidth, SpecialOptionId, deliveryId } = data || props.data
  const additional = specialServices.find((x) => x.id === SpecialOptionId)?.name
  let shippingFeeDetails = selectedRoute.shippingFee?.shippingFeeDetails.find((x) => x.height === packageHeight
    && x.weight === packageWeight
    && x.length === packageLength
    && x.width === packageWidth
    && x.additional?.toLowerCase() === additional?.toLowerCase())

  const deliverySelected = deliveries.find(d => d?.id === deliveryId)
  const request =
  {
    ShippingFeeDetailId: shippingFeeDetails.id,
    deliverySelected,
    isCOD: valueCOD ? true : false,
    customPrice: selectedRoute.price
  }

  var res = await orderDataService.CaclFeeCreateOrderWareHouse(request)
  return res
}

//icon
export const getSpecialServiceIcon = (name) => {
  if (name === specialServices.fragile) {
    return <Image width={30} src={fragileIcon} />
  } else if (name === specialServices.fastService) {
    return <Image width={30} src={fastIcon} />
  }
  return <></>
}

export default { getData, formatAmount, getSpecialServiceIcon }