import actionTypes from "./orders.types";

const ordersInitialState = {
    list: [],
    create: {
        initData: {},
        stations: [],
        routes: [],
        selectedRoute: {},
        senderWards: [],
        senderDistricts: [],
        receiverWards: [],
        receiverDistricts: [],
    },
    orderDetails: {},
    listOrderPickup: [],
    vehicles: [],
    selectedOrders: [],
    pickupList: {},
    // Create delivery order list
    isSelectedAllOrders: false,
    selectedOrdersForDelivery: [],
    unselectedOrdersForDelivery: [],
    allOrdersHaveBeenSelected: [],
    vehiclesForDelivery: [],
    selectedVehicleForDelivery: {},
    // End

    // Delivery list detail
    dataForDeliveryListDetail: {},
    // End

    // export list
    listExport: [],
    // paging
    current: 0,
    pageSize: 0,
    total: 0,
};

const ordersReducer = (state = ordersInitialState, action) => {
    switch (action.type) {
        case actionTypes.SET_LIST_ORDERS:
            return { ...state, list: action.orders };
        case actionTypes.GET_ORDER_INIT_DATA:
            return {
                ...state,
                create: { ...state.create, initData: action.payload },
            };
        case actionTypes.GET_STATIONS:
            return {
                ...state,
                create: { ...state.create, stations: action.payload },
            };
        case actionTypes.GET_ROUTING:
            return { ...state, create: { ...state.create, routes: action.payload } };
        case actionTypes.SELECT_ROUTING:
            return {
                ...state,
                create: { ...state.create, selectedRoute: action.payload },
            };
        case actionTypes.SET_ROUTE:
            return { ...state, create: { ...state.create, routes: [] } };
        case actionTypes.CLEAR_ORDER_DATA:
            return {
                ...state,
                create: {
                    ...state.create,
                    selectedRoute: {},
                    routes: [],
                    districts: [],
                    wards: [],
                },
            };
        case actionTypes.GET_DISTRICT_BY_CITYID:
            if (action.payload?.isSender) {
                return {
                    ...state,
                    create: {
                        ...state.create,
                        senderDistricts: action.payload?.data,
                        senderWards: [],
                    },
                };
            }
            return {
                ...state,
                create: {
                    ...state.create,
                    receiverDistricts: action.payload?.data,
                    receiverWards: [],
                },
            };
        case actionTypes.GET_WARDS_BY_DISTRICTID:
            if (action.payload?.isSender) {
                return {
                    ...state,
                    create: { ...state.create, senderWards: action.payload?.data },
                };
            }
            return {
                ...state,
                create: { ...state.create, receiverWards: action.payload?.data },
            };
        case actionTypes.GET_ORDER_DETAILS:
            return { ...state, orderDetails: action.payload };
        case actionTypes.GET_LIST_PICKUP_ORDER:
            return {
                ...state,
                listOrderPickup: action.payload.orders,
                vehicles: action.payload.vehicles,
            };
        case actionTypes.SET_PAYMENT:
            return { ...state, create: { ...state.create, payment: action.payload } };

        case actionTypes.UPDATE_SELECTED_ORDER:
            const selectedOrders = state.listOrderPickup?.filter((x) =>
                action.payload?.includes(x.id)
            );
            return { ...state, selectedOrders: selectedOrders };
        case actionTypes.GET_PICKUP_LIST_BY_ID:
            return { ...state, pickupList: action.payload };

        case actionTypes.CLEAR_DATA_FOR_DELIVERY:
            return {
                ...state,
                isSelectedAllOrders: false,
                selectedOrdersForDelivery: [],
                unselectedOrdersForDelivery: [],
                allOrdersHaveBeenSelected: [],
                vehiclesForDelivery: [],
                selectedVehicleForDelivery: {},
            };

        case actionTypes.UPDATE_SELECTED_DELIVERY_ORDER_LIST:
            let data = action.payload;
            return {
                ...state,
                isSelectedAllOrders: data.isSelectedAllOrders,
                selectedOrdersForDelivery: data.selectedOrdersForDelivery,
                unselectedOrdersForDelivery: data.unselectedOrdersForDelivery,
            };
        case actionTypes.SET_VEHICLES_FOR_DELIVERY:
            return {
                ...state,
                vehiclesForDelivery: action.payload,
            };
        case actionTypes.SET_SELECTED_VEHICLE_FOR_DELIVERY:
            return {
                ...state,
                selectedVehicleForDelivery: action.payload,
            };
        case actionTypes.GET_DELIVERY_LIST_DETAIL_BY_ID:
            return {
                ...state,
                dataForDeliveryListDetail: action.payload,
            };
        case actionTypes.GET_LIST_EXPORT_ORDER:
            return {
                ...state, total: action.total, pageSize: action.pageSize, current: action.current,
                listExport: action.order,
            };
        case actionTypes.UPDATE_LIST_PICKUP_ORDER_AND_VEHICLE:
            return {
                ...state,
                listOrderPickup: action.listOrderPickup,
                vehicles: action.vehicles,
                selectedOrders: action.selectedOrders
            };

        default:
            return state;
    }
};

export default ordersReducer;
