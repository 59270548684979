import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux'
import { clearDataForDelivery } from '../../modules/orders/orders.actions'
import { DeliverOrderScreen } from './deliver-order.screen';

const mapStateToProps = ({ order, session }) => {
  return {
    currentStationId: session?.auth?.user?.currentStationId
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    clearDataForDelivery
  }, dispatch)
}

export default compose(
    withTranslation('translations'),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(DeliverOrderScreen)

