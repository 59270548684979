import ViewOrderDetailScreen from './view-detail-order.screen';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getOrderDetails, getInitData, clearData } from '../../modules/orders/orders.actions'
import { withRouter } from 'react-router-dom';

const mapStateToProps = ({ order, session }) => {
    return {
        order: order.orderDetails || {},
        initData: order.create.initData || {},
        amount : order.create?.amount||1,
        deliverySelected:order.create?.deliverySelected,
        stationId: session.auth?.user?.currentStationId || '',
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getOrderDetails,
        getInitData,
        clearData
    }, dispatch)
}

export default compose(
    withTranslation('translations'),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(ViewOrderDetailScreen)
