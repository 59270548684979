import React, { Component } from 'react'
import { Col, Image, Row, Button } from 'antd';
import Layout, { Content, Header } from 'antd/lib/layout/layout';
import deliveryIcon from '../../../../../assets/icons/icon-delivery.png'
import fragileIcon from '../../../../../assets/icons/icon-fragile.png'
import fastIcon from '../../../../../assets/icons/icon-fast.png'
import moment from 'moment';
import iconLocal from '../../../../../assets/icons/icon-local.png'
import { formatAmount } from '../../../../../utils/order';
import { codFee, deliveryMethods, deliveryStaff, specialServices, iconPayment } from '../../../../../constants/order-constant';
import iconMomo from '../../../../../assets/icons/momo.png';
import iconMoney from '../../../../../assets/icons/money.jpg';
import iconBank from '../../../../../assets/icons/bank.jpg';
import Barcode from 'react-barcode';
import _ from 'lodash';
import { DeliveryConstants } from '../../../../../constants/delivery.constants';
export class DetailPackages extends Component {
  constructor(props) {
    super(props)
    this.state = {
      order: {}
    }
  }

  getSpecialServiceIcon = (name) => {
    if (name === specialServices.fragile) {
      return <Image width={30} src={fragileIcon} />
    } else if (name === specialServices.fastService) {
      return <Image width={30} src={fastIcon} />
    }
    return <></>
  }

  componentDidMount() {
    const { orderId } = this.props?.match?.params || {}
    this.props.getOrderDetails(orderId)
    this.props.getInitData(DeliveryConstants.DELIVERY_STAFF)
  }

  getPaymentTypeIcon(name) {
    if (name === iconPayment.cash) {
      return <Image width={30} src={iconMoney} />
    }
    else if (name === iconPayment.bank) {
      return <Image width={30} src={iconBank} />
    }
    return <Image width={30} src={iconMomo} />
  }

  render() {
    const { t } = this.props;
    if (_.isEmpty(this.props.order)) return null
    const today = moment().format('DD MMM YYYY HH:mm')
    const { packageSize, packageWeight } = this.props.initData
    const { tax, totalFee, specialOption, shippingFee, senderPhone, senderName, receiverPhone, receiverName, route,
      paymentType, payBy, packageType, isCod, delivery, codamount, addressSender, addressRecipient, descriptions,
      packageHeight, packageLength, packageWidth, othersCategory, code, additionalFee, status, contract, station, orderFailedOtherReasons, contractId, amount } = this.props.order
    const { city, district, ward, street } = addressSender || {}

    const isShowDeliveryIcon = deliveryMethods.includes(delivery?.name?.toLowerCase())

    let packageHeightText = packageSize?.find((x) => x.id === packageHeight)?.descriptions
    let packageLengthText = packageSize?.find((x) => x.id === packageLength)?.descriptions
    let packageWidthText = packageSize?.find((x) => x.id === packageWidth)?.descriptions
    // package weight
    let packageWeightText = packageWeight?.find((x) => x.id === this.props.order?.packageWeight)?.descriptions
    if (contractId != null) {
      packageHeightText = packageHeight
      packageLengthText = packageLength
      packageWidthText = packageWidth
      // package weight
      packageWeightText = this.props.order?.packageWeight
    }
    const packageSizeText = `${packageLengthText} x ${packageWidthText} x ${packageHeightText}`;
    let statusText = status?.statusName;
    if (status.subStatusName) {
      statusText = statusText + ` - ${status.subStatusName}`;
      if (status.subStatusName.includes('Other reasons')) {
        statusText = statusText + ` - ${orderFailedOtherReasons}`;
      }
    }

    return (
      <Content className='content'>
        <Row>
          <Col span={16}>
            <div className='create-order clearfix'>
              <div className='header-detail clearfix'>
                <div className='barcode pull-left'>
                  <Barcode
                    value={code}
                    format='code128'
                  />
                </div>
                <div className='info-beecow pull-right'>
                  <p>{today}</p>
                </div>
              </div>
              <div className='status'>
                <p><b>Status:</b> {statusText}</p>
              </div>
              <Row>
                <Col span={1}>
                  <span><i className='fa fa-user' aria-hidden='true'></i></span>
                </Col>
                <Col span={10}>
                  <div className='title-info-detail'>
                    <span>Sender's information</span>
                  </div>
                  <div className='info'>
                    <p>Phone: <span>{senderPhone}</span></p>
                    <p>Name: <span>{senderName}</span></p>
                    <p>Address: <span>{`${street}, ${ward?.name}, ${district?.name}, ${city?.name}`}</span></p>
                  </div>
                </Col>
                <Col span={1} offset={1}>
                  <span><i className='fa fa-address-book' aria-hidden='true'></i></span>
                </Col>
                <Col span={10}>
                  <div className='title-info-detail'>
                    <span>Receiver's information</span>
                  </div>
                  <div className='info'>
                    <p>Phone: <span>{receiverPhone}</span></p>
                    <p>Name: <span>{receiverName}</span></p>
                    <p>Address: <span>{`${addressRecipient?.street}, ${addressRecipient?.ward?.name}, ${addressRecipient?.district?.name}, ${addressRecipient?.city?.name}`}</span></p>
                  </div>
                </Col>
              </Row>
              <div className='clearfix'>
                <div className='title-info-detail'>
                  <span>Package information</span>
                </div>
                <div className='package-detail'>
                  <Row>
                    <Col span={8}>
                      <div className='choose-category clearfix'>
                        <Image
                          src={iconLocal}
                        />
                        <p className='text clearfix'>Category/ Package type:</p>
                      </div>
                      <div className='category clearfix'>
                        {othersCategory || packageType?.name}
                      </div>
                    </Col>
                    <Col span={8} className='package-size'>
                      <div className='choose-category clearfix'>
                        <Image
                          src={iconLocal}
                        />
                        <p className='text clearfix'>Package Size:</p>
                      </div>
                      <p>{packageSizeText}</p>
                      <div className='choose-category clearfix'>
                        <Image
                          src={iconLocal}
                        />
                        <p className='text clearfix'>Package Weight:</p>
                      </div>
                      <p><span>{packageWeightText}</span></p>
                    </Col>
                    <Col span={8}>
                      <div className='choose-category clearfix'>
                        <Image
                          src={iconLocal}
                        />
                        <p className='text clearfix'>Package descriptions:</p>
                      </div>
                      <div className='descriptions clearfix'>
                        <p>{descriptions}</p>
                      </div>
                      <br />
                      {contractId != null && (
                        <>
                          <div className='choose-category clearfix'>
                            <Image
                              src={iconLocal}
                            />
                            <p className='text clearfix'>Amount:</p>
                          </div>
                          <div className='amountSummary clearfix'>
                            <p>{amount}</p>
                          </div>
                        </>
                      )}
                    </Col>
                  </Row>
                  <div className='clearfix'>
                    <div className='choose-category clearfix'>
                      <Image
                        src={iconLocal}
                      />
                      <p className='text clearfix'>Specical Service</p>
                    </div>
                    <div className='specical-service'>
                      <p>
                        <span>{specialOption?.name}</span>
                        {this.getSpecialServiceIcon(specialOption?.name)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='content-route clearfix'>
                <Row>
                  <Col span={6}>
                    <div className='title-info-detail'>
                      <span>Delivery Options</span>
                    </div>
                    <div className='delivery-option'>
                      <p><i className='fa fa-dot-circle-o' aria-hidden='true'></i>
                        {delivery?.name}
                      </p>
                      {isShowDeliveryIcon && <Image src={deliveryIcon} width={30} />}
                      {contract && <p className='clearfix'>Contract ID: {contract?.code}</p>}
                    </div>
                  </Col>
                  <Col span={17} offset={1}>
                    <div className='title-info-detail'>
                      <span>Routing</span>
                    </div>
                    <div className='routing clearfix'>
                      <p>{t('tableColumn.routeId')}: <span className='content'>{route?.code}</span></p>
                      <p>{t('tableColumn.routeName')}: <span className='content'>{route?.routeName}</span></p>
                      <p>Estimate Time Arrival(ETA): <span className='content'>{route?.estimate}</span></p>
                    </div>
                    <div className='assign-station clearfix'>
                      <p className='title'>Order assigned for station:</p>
                      <p>Station ID:<span> {station?.code}</span> </p>
                      <p>Station name:<span className='name-station'> {station?.name}</span></p>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className='payment-methods clearfix'>
                <div className='title-info-detail'>
                  <span>Payment methods</span>
                </div>
                <Row>
                  <Col span={6}>
                    <div className='payment'>
                      <p><i className='fa fa-dot-circle-o' aria-hidden='true'></i>{payBy} </p>
                      <p className='methods'>
                        {this.getPaymentTypeIcon(paymentType?.name)}
                        <br />
                        {paymentType?.name}
                      </p>
                    </div>
                  </Col>
                  <Col span={16}>
                    <div className='shipping-fee'>
                      <ul>
                        {
                          Number(codamount) > 0 && <>
                            <li className='pull-left'>COD Amount:</li>
                            <li className='pull-right'>{`${formatAmount(codamount)} VND`}</li>
                            <br />
                            <li className='pull-left'>COD Fee:</li>
                            <li className='pull-right'>{formatAmount(codFee)} VND</li>
                            <br />
                          </>
                        }
                        {
                          Number(additionalFee) > 0 && <>
                            <li className='pull-left'>Additional fee for Door service:</li>
                            <li className='pull-right'>{formatAmount(additionalFee)} VND</li>
                            <br />
                          </>
                        }
                        <li className='pull-left'>Shipping Fee:</li>
                        <li className='pull-right'>{formatAmount(shippingFee)} VND</li>
                        <br />
                        <li className='pull-left'>Total: </li>
                        <li className='pull-right'>{formatAmount(totalFee)} VND</li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        <div className='action-print'>
          {isShowDeliveryIcon && <Button className='receiver'>Receiver Picked up</Button>}
          <Button className='btn-print'>Print Shipping Label</Button>
          <Button className='btn-print'>Print Package barcode</Button>
          <Button className='btn-print'>Print Order</Button>
        </div>
      </Content>

    )
  }
}