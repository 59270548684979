import { DataService } from "../base/base-data.service";

class PickupDataService extends DataService{
    apiUrl = '/pickup'

    async deletePickupById(orderPickupId) {
        return await this.delete(`${this.apiUrl}/delete/${orderPickupId}`);
    }
}

let pickupDataService = new PickupDataService();

export default pickupDataService;