import { Component } from "react";
import Layout, { Content } from "antd/lib/layout/layout";
import { Select, DatePicker, Space, Table, Button, Card, message } from "antd";
import TopBar from "../../components/topbar";
import orderDataService from "../../data-services/orders/orders-data.service";
import { selectDate } from "../../constants/order-constant";
import languageService from "../../services/language.service";
import moment from "moment";
import "moment/locale/vi";
import "moment/locale/es";
import { Link } from "react-router-dom";
import "./lost-order-list.screen.scss";

export class LostOrderListScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedValue: selectDate.all,
      orderList: [],
    };
    this.handleChangeSelectControl = this.handleChangeSelectControl.bind(this);
    this.handleChangeDatePickerControl =
      this.handleChangeDatePickerControl.bind(this);
  }

  componentDidMount() {
    const { fromDate, toDate } = this.state;
    this.loadData(fromDate, toDate);
  }

  handleChangeSelectControl(value) {
    if (value) {
      switch (value) {
        case selectDate.all: {
          this.loadData(null, null);
          break;
        }
        case selectDate.thisMonth: {
          let date = new Date();

          // Get the first day of this month.
          let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

          // Get the last day of this month.
          let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

          // format data
          let fromDate = moment(firstDay).format("YYYY-MM-DD 00:00:00");
          let toDate = moment(lastDay).format("YYYY-MM-DD 23:59:59");
          this.loadData(fromDate, toDate);
          break;
        }
        default:
          break;
      }
      this.setState({ selectedValue: value });
    }
  }

  handleChangeDatePickerControl(value) {
    if (value && value.length > 0) {
      let fromDate = value[0].format("YYYY-MM-DD 00:00:00");
      let toDate = value[1].format("YYYY-MM-DD 23:59:59");
      this.loadData(fromDate, toDate);
    }
  }

  loadData(fromDate, toDate) {
    const { currentStationId } = this.props;
    orderDataService
      .getLostOrderList(fromDate, toDate, currentStationId)
      .then((response) => {
        let orderList = response.orderList?.map((order) =>
          this.convertJsonObject(order)
        );
        this.setState({ orderList });
      });
  }

  handleResendEmail(orderId) {
    const { t } = this.props;
    let orderList = this.state.orderList;
    let order = orderList?.find((item) => item.orderId === orderId);
    if (order) {
      let stepList = order.lostSteps.map((item) => item.step);
      orderDataService
        .resendEmailToConfirmLostProductsInOrder(orderId, stepList)
        .then((response) => {
          if (response.isSuccess) {
            for (let index = 0; index < orderList.length; index++) {
              if (orderList[index].orderId === orderId) {
                orderList[index].allowResendEmail = false;
                break;
              }
            }
            this.setState({ orderList });
            message.success(t("messages.emailHasBeenSentSuccessfully"));
          } else {
            message.warn(t("messages.canNotSendEmailAtMoment"));
          }
        });
    }
  }

  convertJsonObject(object) {
    let newObject = {
      key: object.no,
      no: object.no,
      orderId: object.orderId,
      orderCode: object.orderCode,
      totalItemsInOrder: object.totalItemsInOrder,
      lostItems: object.lostItems,
      lostSteps: object.lostSteps,
      orderCreatedDate: object.orderCreatedDate,
      hideResendEmailButton: object.hideResendEmailButton,
      allowResendEmail: true,
    };
    return newObject;
  }

  getTableColumn() {
    const { t } = this.props;
    let columns = [
      {
        className: "w-80px",
        title: t("tableColumn.no"),
        dataIndex: "no",
        fixed: "left",
      },
      {
        className: "w-120px",
        title: t("tableColumn.orderId"),
        dataIndex: "orderId",
        render: (text, data) => (
          <Link target="_blank" to={`/view-lost-order-details/${data.orderId}`}>
            {data.orderCode}
          </Link>
        ),
        fixed: "left",
      },
      {
        className: "w-100px",
        title: t("tableColumn.totalItemsInOrder"),
        dataIndex: "totalItemsInOrder",
      },
      {
        className: "w-100px",
        title: t("tableColumn.lostItems"),
        dataIndex: "lostItems",
      },
      {
        className: "w-120px",
        title: t("tableColumn.lostSteps"),
        dataIndex: "lostSteps",
        render: (text, data) =>
          data?.lostSteps?.map((item, index) => (
            <p key={`idx_lostSteps_${index}_item`}>{t(item?.name)}</p>
          )),
      },
      {
        className: "w-180px",
        title: t("text.date"),
        dataIndex: "orderCreatedDate",
        render: (text, data) => (
          data?.lostSteps?.map((item, index) => (
            <p key={`idx_lostSteps_${index}_createdTime`}>{
              moment
                .utc(item.createdDate)
                .locale(languageService.getLang())
                .format("hh:mm A, DD/MM/YYYY")
            }</p>
          ))
        ),
      },
      {
        className: "w-80px",
        title: t("tableColumn.action"),
        dataIndex: "action",
        render: (text, data) =>
          data?.hideResendEmailButton === false && (
            <Button
              onClick={() => this.handleResendEmail(data.orderId)}
              disabled={!data.allowResendEmail}
            >
              {t("button.resendEmail")}
            </Button>
          ),
      },
    ];
    return columns;
  }

  render() {
    const { t } = this.props;
    const { selectedValue, orderList } = this.state;
    const { Option } = Select;
    const { RangePicker } = DatePicker;
    let columns = this.getTableColumn();
    return (
      <Layout className="bg-content">
        <div className="bg-body">
          <TopBar title={t("title.lostOrders")} />
          <Content>
            <Card>
              <Space direction="horizontal">
                <Select
                  defaultValue={selectDate.all}
                  className="w-170px"
                  onChange={this.handleChangeSelectControl}
                >
                  <Option value={selectDate.all}>{t("placeholder.all")}</Option>
                  <Option value={selectDate.thisMonth}>
                    {t("placeholder.thisMonth")}
                  </Option>
                  <Option value={selectDate.fromTo}>
                    {t("placeholder.fromTo")}
                  </Option>
                </Select>

                {selectedValue === selectDate.fromTo && (
                  <RangePicker
                    onChange={this.handleChangeDatePickerControl}
                    locale={languageService.getLocale()}
                  />
                )}
              </Space>
              <div className="div-separator"></div>
              <Table bordered columns={columns} dataSource={orderList} />
            </Card>
          </Content>
        </div>
      </Layout>
    );
  }
}
