export const doorToStation = "Door To Station";
export const doorToDoor = "Door To Door";
export const stationToStation = "Station to Station";
export const stationToDoor = "Station To Door";
export const deliveryEndUser = "DeliveryEndUser";
export const deliveryStaff = "DeliveryStaff";
export const othersCategory = "Other";
export const othersCategoryId = "4e30ab02-a084-4784-86d4-1aa77a0ed419";

export const orderStep = {
  infor: 0,
  delivery: 1,
  payment: 2,
  pakageDetails: 3,
};
export const codFee = 30000;
export const cash = "Cash";
export const deliveryMethods = [
  "door to station",
  "station to station",
  "wareHouse to station",
];

export const deliveryHouseMethods = [
  "door to warehouse",
  "station to warehouse",
  "warehouse to door",
];

export const specialServices = {
  fastService: "Fast Service",
  normalService: "Normal Service",
  fragile: "Fragile",
};

export const iconPayment = {
  cash: "Cash",
  bank: "Bank",
  ewallet: "E-Wallet",
};

export const payBySender = "Pay By Sender";
export const payByRecipient = "Pay by Recipient";

export const paymentConstants = {
  SHOW_COD_CONDITION: 1,
};

export const stepListPickup = {
  listPickup: 0,
  reviewPickup: 1,
  viewPickupList: 2,
  editPickup: 3,
};

export const createOrderTypes = {
  END_USER: "END USER",
  STATION: "STATION",
  WAREHOUSE: "WAREHOUSE",
};

export const orderDeliveryStep = {
  orderList: 0,
  reviewDeliveryOrderList: 1,
  viewDeliveryOrderList: 2,
};

export const orderStatus = {
  IN_WAREHOUSE: "in warehouse",
};

export const orderDeliveryMethod = {
  TO_STATION: "to station",
};

export const exportStatus = {
  new: 0,
  assignedDriver: 1,
  inTransit: 2,
  WaitingImportLastStation: 3,
  completed: 4,
};

export const deliveryStatus = {
  new: 0,
  assignedDriver: 1,
  completed: 2,
};

export const selectDate = {
  all: 'all',
  thisMonth: 'this-month',
  fromTo: 'from-to',
};

export const orderSteps = {
  import: 0,
  export: 1,
  importFromVehicle: 2
}