import actionTypes from "./session.types";
import companiesDataService from "../../data-services/companies/companies-data.service";
import userDataService from "../../data-services/user/user-data.service";

export function setAuth(auth) {
    return { type: actionTypes.SET_AUTH, auth };
}

export function setWorkspace(workspaceId, workspace) {
    return async (dispatch) => {
        if (workspaceId && !workspace) {
            workspace = await companiesDataService.getById(workspaceId);
        }
        dispatch({ type: actionTypes.SET_WORKSPACE, workspaceId, workspace });
    }
}

export function setCurrentUser(user) {
    return { type: actionTypes.SET_CURRENT_USER, user };
}

export function resetSession(userInfo) {   
    return async (dispatch) => {
        if (userInfo.userId)
        {
            await userDataService.logout(userInfo);
        }
        dispatch( { type: actionTypes.RESET_SESSION });    
    }  
}

export const updateUserName = (userName) => {
    return { type: actionTypes.UPDATE_USER_NAME, userName };
}