import _ from 'lodash';
import moment from 'moment';
import { Component } from 'react';
import './list-pickups.screen.scss';
import { Link } from 'react-router-dom';
import TopBar from '../../components/topbar';
import { Content } from 'antd/lib/layout/layout';
import { showAlert } from '../../common/constants';
import { getDateTime } from '../../utils/datetime';
import { Form, Table, Button, Modal, Layout } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import orderDataService from '../../data-services/orders/orders-data.service';
import pickupDataService from '../../data-services/pickups/pickups-data.service';

export default class ListPickupsScreen extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataSource: [],
            paging: {
                pageSize: 20,
            }
        }
    }

    componentDidMount() {
        this.InitDataTable();
    };

    InitDataTable() {
        const { currentStationId } = this.props
        if (currentStationId) {
            orderDataService
                .getListPickup(currentStationId)
                .then((res) => {
                    const listPickup = res.orderPickups?.map((o, index) => this.transform(o, index))
                    // Update the state of the react.
                    this.setState({
                        dataSource: listPickup,
                    });
                });
        }
    };

    getTableColumns(pickup) {
        const { t } = this.props;
        let vehicles = pickup?.map(d => { return { text: d.vehicleCode, value: d.vehicleCode } });
        let vehicleOptions = vehicles?.filter((d, i) => vehicles?.findIndex(o => o.value === d.value) === i);
        let status = pickup?.map(d => { return { text: t(d.status), value: t(d.status) } });
        let statusOptions = status?.filter((d, i) => status?.findIndex(o => o.value === d.value) === i);

        let columns = [
            {
                title: t('tableColumn.no'),
                dataIndex: 'no'
            },
            {
                title: t('tableColumn.pickupId'),
                dataIndex: 'code',
                render: (text, data) => {
                    return (
                        <Link target='_blank' to={`/view-pickup-list/${data?.id}`}>{text}</Link>
                    )
                },
                sorter: (a, b) => a.code.localeCompare(b.code)
            },
            {
                title: t('tableColumn.selectedVehicle'),
                dataIndex: 'vehicleCode',
                sorter: (a, b) => a.vehicleCode.localeCompare(b.vehicleCode),
                filters: vehicleOptions,
                onFilter: (value, record) => record.vehicleCode.indexOf(value) === 0,
            },
            {
                title: t('tableColumn.orders'),
                dataIndex: 'getCountOrders',
                sorter: (a, b) => a.getCountOrders - b.getCountOrders
            },
            {
                title: t('tableColumn.products'),
                dataIndex: 'getCountProducts',
                sorter: (a, b) => a.getCountProducts - b.getCountProducts
            },
            {
                title: t('tableColumn.items'),
                dataIndex: 'getCountItems',
                sorter: (a, b) => a.getCountItems - b.getCountItems
            },
            {
                title: t('tableColumn.status'),
                dataIndex: 'status',
                sorter: (a, b) => a.status.localeCompare(b.status),
                filters: statusOptions,
                onFilter: (value, record) => record.status.indexOf(value) === 0,
            },
            {
                title: t('tableColumn.createdDate'),
                dataIndex: 'createdTime',
                sorter: (a, b) => moment(a.createdTime) - moment(b.createdTime),
                render: text => <div dangerouslySetInnerHTML={{ 
                    __html: getDateTime(text, 'hh:mm A <br> DD/MM/YYYY')
                    }} />
            },
            {
                title: t('tableColumn.action'),
                width: '14%',
                render: (_, record) => {
                    if(record?.status?.includes('New')){
                        return <>
                            <div style={{ display: 'flex', justifyContent: 'center'}}>
                                <div className='text-center' style={{ marginRight: 5}}>
                                    <Link
                                        to={`/orders-pickup/${record?.id}/edit`}
                                        className='ant-btn ant-btn-primary'
                                    >
                                        {t('button.edit')}
                                    </Link>
                                </div>
                                <div className='text-center' style={{ marginRight: 5}}>
                                    <Button
                                        onClick={() => this.clickButtonDelete(record?.id)}
                                        className='ant-btn ant-btn-primary ant-btn-dangerous'
                                    >
                                        {t('button.delete')}
                                    </Button>
                                </div>
                            </div>
                        </>
                    }else{
                        return null;
                    }
                }
            }
        ];
        return columns;
    };

    clickButtonDelete(pickupId){
        const currentComponent = this;
        const { t } = this.props;
        Modal.confirm({
          title: `${t('confirm.removeOrder')}`,
          icon: <ExclamationCircleOutlined />,
          onOk() {
            currentComponent.handleDeletePickup(pickupId);
          },
          okType: 'danger',
          okText: `${t('button.ok')}`,
          cancelText: `${t('button.cancel')}`,
        });
    };

    handleDeletePickup = (pickupId) => {
        const { t } = this.props;
        let message = '';
        let description = '';
        pickupDataService.deletePickupById(pickupId).then((res) =>{
            if(res){
                message = t('confirm.success');
                description = t('confirm.deleteSuccess');
                showAlert(message, description, true);
                this.InitDataTable();
            }else{
                message = t('confirm.warning');
                description = t('confirm.deleteFail');
                showAlert(message, description, false);
            }
        });
    };

    transform = (o, index) => {
        const { t } = this.props;
        var result = {
            no: index + 1,
            id: o?.id,
            code: o?.code,
            vehicleCode: o?.vehicleCode + " " + '(' + t(o?.vehicleType) + ')',
            getCountOrders: o?.getCountOrders,
            getCountProducts: o?.getCountProducts,
            getCountItems: o?.getCountItems,
            status: t(o?.statusId),
            createdTime: o?.createdTime
        }

        return result
    }

    render() {
        const { t } = this.props;
        const { dataSource } = this.state;
        let columns = this.getTableColumns(dataSource);

        return (
            <Layout className='bg-content'>
                <div className='bg-body'>
                    <TopBar
                        title={t('title.listOfPickup')} />
                    <div className='bg-white clearfix'>
                        <Form ref={this.formRef}>
                            <Content className='content'>
                                <div className='list-pickup'>
                                    <Table
                                        columns={columns}
                                        dataSource={dataSource}
                                        pagination={this.state.paging}
                                        bordered
                                    />
                                </div>
                            </Content>
                        </Form>
                    </div>
                </div>
            </Layout>
        )
    }    
}