import { DataService } from "../base/base-data.service";

class AreaStationDataService extends DataService {
    apiUrl = '/AreaStation';

    async getListAreaStation(id) {
        return await this.get(`${this.apiUrl}/${id}`);
    }

    async getAreasForShippingByStationId(id) {
        return await this.get(`${this.apiUrl}/${id}/shipping`);
    }

    async addAreaStation(data) {
        return await this.post(`${this.apiUrl}/addAreaStation`, data);
    }

    async deleteAreaStation(data) {
        return await this.post(`${this.apiUrl}/delete`, data);
    }

    async getAreaDetailSpace(areaId) {
        return await this.get(`${this.apiUrl}/getAreaById?areaId=${areaId}`);
    }

    async getSpacesByAreaId(areaId, page, pageSize, isDropdownList, paged) {
        return await this.get(`${this.apiUrl}/get-spaces-by-areaid?areaId=${areaId}&page=${page}&rows=${pageSize}&isDropdownList=${isDropdownList ?? false}&paged=${paged}`);
    }

    async updateAreaStation(data) {
        return await this.post(`${this.apiUrl}/update`, data);
    }

    async getSpaceDetail(data) {
        return await this.get(`${this.apiUrl}/spaceDetail?spaceId=${data}`)
    }
}

let areaStationDataService = new AreaStationDataService();

export default areaStationDataService;