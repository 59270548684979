import { DataService } from "../base/base-data.service";

class OrderDataService extends DataService {
    apiUrl = '/order'

    async getInitData() {
        return await this.get(`${this.apiUrl}/loadData`);
    }

    async getInitData(deliveryType) {
        return await this.get(`${this.apiUrl}/loadData?deliveryType=${deliveryType}`);
    }

    async listStationOrders(stationId) {
        return await this.get(`${this.apiUrl}/list-station-orders?stationId=${stationId}`);
    }

    async listUserOrders(userId) {
        return await this.get(`${this.apiUrl}/list-user-orders?userId=${userId}`);
    }

    async getPickedOrder(code) {
        return await this.get(`${this.apiUrl}/get-picked-order?code=${code}`);
    }

    async getStation(cityId) {
        return await this.get(`${this.apiUrl}/get-station?cityId=${cityId}`);
    }

    async getRouting(toStation, fromStation) {
        return await this.get(`${this.apiUrl}/get-routing?toStation=${toStation}&fromStation=${fromStation || ''}`);
    }

    async getRoutingToDoor(receiverCity, fromStation, senderCity) {
        return await this.get(`${this.apiUrl}/get-routing-to-door?receiverCity=${receiverCity}&fromStation=${fromStation || ''}&senderCity=${senderCity || ''}`);
    }

    async submit(data) {
        return await this.post(`${this.apiUrl}/submit`, data);
    }

    async getWardsByDistrictId(districtId) {
        return await this.get(`${this.apiUrl}/get-wards-by-districtId?districtId=${districtId}`);
    }

    async getDistrictByCityId(cityId) {
        return await this.get(`${this.apiUrl}/get-district-by-cityId?cityId=${cityId}`);
    }

    async getOrderDetails(orderId) {
        return await this.get(`${this.apiUrl}/get-order-details?id=${orderId}`);
    }

    async listStationOrdersPickup(stationId) {
        return await this.get(`${this.apiUrl}/get-orders-pickup?stationId=${stationId}`);
    }

    async CaclFeeCreateOrderWareHouse(data) {
        return await this.post(`${this.apiUrl}/caclfee-create-order-warehouse`, data);
    }

    async createOrderPickup(data) {
        return await this.post(`${this.apiUrl}/create-pickup`, data)
    }

    async getPickupListById(id) {
        return await this.get(`${this.apiUrl}/get-pickup-list-by-id?id=${id}`);
    }

    async paymentCalculator(data) {
        return await this.post(`${this.apiUrl}/payment-calculator`, data);
    }

    async createOrder(data) {
        return await this.post(`${this.apiUrl}/create-order`, data);
    }

    async createExportList(data) {
        return await this.post(`${this.apiUrl}/create-export-list`, data);
    }

    async getExportListById(id) {
        return await this.get(`${this.apiUrl}/get-export-list-by-id?id=${id}`);
    }

    async getOrdersAndVehiclesForDelivery(
        stationId,
        currentPageNumber,
        totalNumberOfItemsOnPage,
        sortField,
        sortOrder,
        keywords,
        nextPage,
        unselectedOrderList,
        inPackages,
        inDeliveryMethods,
        orderDeliveryId = null,
        getAllRecords = false
    ) {

        let formData = {
            stationId,
            currentPageNumber,
            totalNumberOfItemsOnPage,
            sortField,
            sortOrder,
            keywords,
            nextPage,
            unselectedOrderList,
            inPackages,
            inDeliveryMethods,
            orderDeliveryId,
            getAllRecords
        };

        return await this.post(`${this.apiUrl}/get-orders-for-delivery`, formData);
    }

    async createDeliveryList(data) {
        return await this.post(`${this.apiUrl}/create-delivery-list`, data)
    }

    async getDeliveryListDetailById(id) {
        return await this.get(`${this.apiUrl}/get-delivery-list-detail/${id}`);
    }

    async getExportListByVehicleId(id) {
        return await this.get(`${this.apiUrl}/get-export-list-by-vehicle-id?vehicleId=${id}`);
    }

    async finishedExportList(data) {
        return await this.post(`${this.apiUrl}/finished-export-list`, data)
    }

    async getDeliveryList(data) {
        return await this.post(`${this.apiUrl}/get-delivery-list`, data)
    }


    async getListExportOrders(stationId, page, pageSize) {
        return await this.get(`${this.apiUrl}/list-export-orders?StationId=${stationId}&page=${page}&rows=${pageSize}`);
    }

    async getListPickup(stationId) {
        return await this.get(`${this.apiUrl}/list-pickup-orders?StationId=${stationId}`);
    }

    async getOrderTrackingByOrderCode(orderCode) {
        return await this.get(`${this.apiUrl}/get-order-tracking-by-order-code/${orderCode}`);
    }

    async updateOrderToDelivered(orderId) {
        return await this.post(`${this.apiUrl}/update-order-status-delivered/${orderId}`);
    }

    async updateOrder(data) {
        return await this.post(`${this.apiUrl}/update-order`, data);
    }

    async updateExportList(data) {
        return await this.post(`${this.apiUrl}/update-export-list`, data);
    }


    async updateOrderPickup(data) {
        return await this.post(`${this.apiUrl}/update-pickup-by-id`, data)
    }

    async updateDeliveryList(data) {
        return await this.post(`${this.apiUrl}/update-delivery-list`, data)
    }

    async getAllOrderToEditPickup(pickupId, stationId) {
        return await this.get(`${this.apiUrl}/get-all-order-to-edit-pickup?pickupId=${pickupId}&stationId=${stationId}`);
    }

    async getOrderAssignedOnStationAsync() {
        return await this.get(`${this.apiUrl}/get-order-assigned-station-for-a-year`);
    }

    async deleteOrder(orderId) {
        return await this.delete(`${this.apiUrl}/delete/${orderId}`);
    }

    async getLostOrderList(fromDate, toDate, stationId, userId){
        return await this.post(`${this.apiUrl}/get-lost-order-list`, {fromDate, toDate, stationId, userId});
    }

    async getLostOrderDetails(orderId){
        return await this.post(`${this.apiUrl}/get-lost-order-details`, {orderId});
    }

    async resendEmailToConfirmLostProductsInOrder(orderId, stepList){
        return await this.post(`${this.apiUrl}/resend-email-to-confirm-lost-products-in-order`, {orderId, stepList});
    }

}

let orderDataService = new OrderDataService();

export default orderDataService;