import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Layout, { Header } from 'antd/lib/layout/layout';
import { Table, Checkbox, Button } from 'antd';
import moment from 'moment';
import ReactToPrint from 'react-to-print';

import orderDataService from '../../../data-services/orders/orders-data.service';
import TopBar from '../../../components/topbar';
import { FORMAT_TIME_DATE } from '../../../common/constants';
import './view-export-list-detail.screen.scss'
import languageService from '../../../services/language.service';

const Unit = 'Kg';
export default class ViewExportListDetailScreen extends Component {
    constructor(props) {
        super(props)
        this.state = {
            title: 'title.exportListDetail',
            initialData: {}
        }
    }

    componentDidMount() {
        this.refresh();
    }

    render() {
        const { t } = this.props;
        var { initialData } = this.state;
        var exportListCreatedTime = initialData?.createdTime;

        return (
            <Layout className='bg-content'>
                <div className='bg-body'>
                    <TopBar title={t(this.state.title)} />
                    <div className='bg-white clearfix beecow-leasing-contract'>
                        <div ref={el => (this.componentRef = el)} className='steps-content'>
                            <div className='mt-5'>
                                <div className='col-12 col-sm-12 col-lg-12'>
                                    <div className='d-flex'>
                                        <div className='m-auto'>
                                            <lable className='font-weight-bold'>{t('tableColumn.exportListId')}</lable>
                                            <p>{initialData?.code}</p>
                                        </div>

                                        <div className='m-auto'>
                                            <lable className='font-weight-bold'>{t('text.date')}</lable>
                                            <p>{moment.utc(exportListCreatedTime).locale(languageService.getLang()).format(FORMAT_TIME_DATE)}</p>
                                        </div>
                                        <div className='m-auto'>
                                            <lable className='font-weight-bold'>{t('text.selectedRoute')}</lable>
                                            <p>{initialData?.route?.routeName}</p>
                                        </div>
                                        <div className='m-auto'>
                                            <lable className='font-weight-bold'>{t('text.selectedVehicle')}</lable>
                                            <p>{initialData?.vehicle?.code}</p>
                                        </div>
                                        <div className='m-auto'>
                                            <lable className='font-weight-bold'>{t('text.vehicleType')}</lable>
                                            <p>{t(initialData?.vehicle?.type?.name)}</p>
                                        </div>
                                    </div>

                                    <div className='mt-5'>
                                        {this.renderOrders(initialData.orders)}
                                        {this.renderSummarize(initialData.orders)}
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className='steps-action' className='float-right mr-5 mt-5 d-flex'>
                                <a className='pr-5' onClick={this.onCancle }>
                                    {t('button.back')}
                                </a>
                               
                                <ReactToPrint
                                trigger={() => <Button type='primary'>{t('button.print')}</Button>}
                                    content={() => this.componentRef}
                                />
                               
                        </div>
                    </div>
                </div>
            </Layout>

        )
    }

    refresh = () => {
        let exportListId = this.props.match?.params?.id;
        if (exportListId) {
            orderDataService.getExportListById(exportListId).then(data => {
                this.setState({ initialData: data });
            });
        }
    }

    renderOrders = (orders) => {
        const { t } = this.props;
        if (orders) {
            return orders?.map((o, index) => {
                return (
                    <>
                        <div className='col-12 mt-4'>
                            <div className='d-flex'>
                                <div className='d-flex mr-5'>
                                    <lable className='font-weight-bold mr-3'>{t('tableColumn.orderCode')}:</lable>
                                    <Link className='text-decoration-underline' to={`/view-order/${o.id}`} target='_blank'> {o?.code}</Link>
                                </div>
                                <div className='d-flex ml-5'>
                                    <lable className='font-weight-bold  mr-3'>{t('tableColumn.createdDate')}:</lable>
                                    <p>{moment.utc(o?.createdTime).locale(languageService.getLang()).format(FORMAT_TIME_DATE)}</p>
                                </div>
                            </div>
                            <div>
                                <Table
                                    columns={this.columns()}
                                    dataSource={this.mappingToDataTable(o?.orderItems)}
                                    pagination={false}
                                    bordered
                                />
                            </div>
                        </div>
                        {
                            (index < orders.length) &&
                            <hr className='line' />
                        }
                    </>
                )
            });
        }
    }

    renderSummarize = (orders) => {
        const { t } = this.props;
        if (orders) {
            var totalOrders = orders?.length;
            var totalProducts = orders.reduce((total, o) => o.orderItems ? total += o.orderItems.length : total, 0);
            var totalItems = orders.reduce((total, o) => {
                var _total = 0;
                if (o.orderItems) {
                    _total = o.orderItems?.reduce((result, i) => result += (i?.importedAmount), 0)
                }
                return total += _total
            }, 0);

            var totalWeight = orders.reduce((total, o) => {
                var _total = 0;
                if (o.orderItems) {
                    _total = o.orderItems?.reduce((result, i) => result += (i?.product?.weight * i?.importedAmount) , 0)
                }
                return total += _total
            }, 0);

            var exportOrders = orders.reduce((total, o) => {
                var _total = 0;
                if (o.orderItems) {
                    var scannedItems = o.orderItems.filter(i => i.exported === true);
                    _total = scannedItems.length;
                }
                return total += _total > 0 ? 1 : 0;
            }, 0);

            var exportProducts = orders.reduce((total, o) => {
                var _total = 0;
                if (o.orderItems) {
                    var scannedItems = o.orderItems.filter(i => i.exported === true);
                    _total = scannedItems.length;
                }
                return total += _total;
            }, 0);

            var totalItemsInExportList = orders.reduce((total, o) => {
                var _total = 0;
                if (o.orderItems) {
                    _total = o.orderItems?.reduce((result, i) => result += (i?.exportedAmount), 0)
                }
                return total += _total
            }, 0);

            var totalWeightInExportList = orders.reduce((total, o) => {
                var _total = 0;
                if (o.orderItems) {
                    _total = o.orderItems?.reduce((result, i) => result += (i?.product?.weight * i?.exportedAmount), 0)
                }
                return total += _total
            }, 0);

            var missingOrder = totalOrders - exportOrders;
            var missingProduct = totalProducts - exportProducts;

            return (
                <div className='d-flex ml-5'>
                    <div className='col-sm-4 col-lg-4'>
                        <div className='d-flex'>
                            <lable className='mr-3 col-sm-7'>{t('text.totalOrder')}:</lable>
                            <p className='col-sm-6'>{totalOrders}</p>
                        </div>
                        <div className='d-flex'>
                            <lable className='mr-3 col-sm-7'>{t('text.totalProduct')}:</lable>
                            <p className='col-sm-4'>{totalProducts}</p>
                        </div>
                        <div className='d-flex'>
                            <lable className='mr-3 col-sm-7'>{t('text.totalItems')}:</lable>
                            <p className='col-sm-4'>{totalItems}</p>
                        </div>
                        <div className='d-flex'>
                            <lable className='mr-3 col-sm-7'>{t('text.totalWeight')}:</lable>
                            <p className='col-sm-4'>{`${totalWeight} ${Unit}`}</p>
                        </div>
                    </div>

                    <div className='col-sm-4 col-lg-4'>
                        <div className='d-flex'>
                            <lable className='mr-3 col-sm-7'>{t('text.exportOrder')}:</lable>
                            <p className='col-sm-4 exported'>{exportOrders}</p>
                        </div>
                        <div className='d-flex'>
                            <lable className='mr-3 col-sm-7'>{t('text.exportProduct')}:</lable>
                            <p className='col-sm-4 exported'>{exportProducts}</p>
                        </div>
                        <div className='d-flex'>
                            <lable className='mr-3 col-sm-7'>{t('text.totalItemsExportList')}:</lable>
                            <p className='col-sm-4 exported'>{totalItemsInExportList}</p>
                        </div>
                        <div className='d-flex'>
                            <lable className='mr-3 col-sm-7'>{t('text.totalWeightExportList')}:</lable>
                            <p className='col-sm-4 exported'>{`${totalWeightInExportList} ${Unit}`}</p>
                        </div>
                    </div>

                    <div className='col-sm-4 col-lg-4'>
                        <div className='d-flex'>
                            <lable className='mr-3 col-sm-7'>{t('text.missingOrder')}:</lable>
                            <p className='col-sm-4 missed'>{missingOrder}</p>
                        </div>
                        <div className='d-flex'>
                            <lable className='mr-3 col-sm-7'>{t('text.missingProduct')}:</lable>
                            <p className='col-sm-4 missed'>{missingProduct}</p>
                        </div>
                    </div>
                </div>
            )
        }
    }

    columns = () => {
        const { t } = this.props;
        return [
            {
                title: t('tableColumn.no'),
                dataIndex: 'index',
            },
            {
                title: t('tableColumn.productCode'),
                dataIndex: 'productCode',
            },
            {
                title: t('tableColumn.productName'),
                dataIndex: 'productName',
            },
            {
                title: t('tableColumn.productCategory'),
                dataIndex: 'productCategory',
                render: (productCategory, data) => {
                    return <>{t(productCategory)}</>
                }
            },
            {
                title: t('tableColumn.assignedSpaces'),
                dataIndex: 'assignedSpaces',
                render: (spaces) => {
                    return <>
                        {
                            spaces?.map((s) => {
                                return (
                                    <div className='d-flex'>
                                        <p className='pr-3'>{s.area}</p>
                                        <p>
                                            {
                                                s.spaces.map((i) => {
                                                    return i.code
                                                }).join(', ')
                                            }
                                        </p>
                                    </div>
                                )
                            })
                        }
                    </>;
                }
            },
            {
                title: t('tableColumn.amount'),
                dataIndex: 'amount',
            },
            {
                title: t('tableColumn.scanned'),
                dataIndex: 'scanned',
                render: (scanned) => {
                    return (
                        <Checkbox
                            checked={scanned}
                            disabled={true}
                        />
                    )
                }
            },
            {
                title: t('tableColumn.reason'),
                dataIndex: 'reason',
            },
        ];
    }

    mappingToDataTable = (orderItems) => {
        return orderItems?.map((i, index) => {
            return {
                index: index + 1,
                productCode: i.product?.code,
                productName: i.product?.name,
                productCategory: i.product?.packageType?.languageKey,
                assignedSpaces: this.assignedSpaceInfoFormatted(i.product?.spaceAreas),
                amount: `${i.exportedAmount} / ${i.importedAmount}`,
                scanned: i.exported,
                reason: i.exportFailedReason,
            }
        });
    }

    assignedSpaceInfoFormatted = (spaceAreas) => {
        let groupsByArea = spaceAreas.reduce((r, a) => {
            r[a.area.code] = [...r[a.area.code] || [], a];
            return r;
        }, {});
        var areas = Object.keys(groupsByArea);

        return areas.map((area) => {
            return {
                area: area,
                spaces: groupsByArea[area]
            }
        })
    }

    onCancle = () => {
        this.props.history.push(`/dashboard`)
    }
}
