import { Layout, Modal } from 'antd';
import Form from 'antd/lib/form/Form';
import React, { Component } from 'react'
import TopBar from '../../components/topbar';
import ListOrderPickup from './list-order-pickup';
import NextPage from '../shared/components/next-page';
import ReviewOrderPickup from './review-orders-pickup';
import { stepListPickup } from '../../constants/order-constant';
import orderDataService from '../../data-services/orders/orders-data.service';

export class PickupOrderScreen extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentPage: stepListPickup.listPickup,
            data: {
                currentWeight: 0,
                volumeOfVehicle: 0
            }
        }
        this.formRef = React.createRef()
    }

    componentWillUnmount() {
        this.props.updateSelectedOrder([]);
    }

    renderListPickup() {
        const { currentPage, data } = this.state;
        switch (currentPage) {
            case (stepListPickup.reviewPickup):
                return <ReviewOrderPickup data={data} />
            default: return <ListOrderPickup
                data={data}
                setSelectedOrderIds={this.setSelectedOrderIds.bind(this)}
                setVolumeOfVehicle={this.setVolumeOfVehicle.bind(this)}
            />
        }
    }

    handleNextPage() {
        const { currentPage, data } = this.state;
        const { t } = this.props;
        this.formRef?.current?.validateFields().then(() => {
            const values = this.formRef.current.getFieldsValue();
            if (this.state.data.currentWeight > this.state.data.volumeOfVehicle) {
                Modal.warning({
                    title: t('title.modalWarning'),
                    content: t('messages.pickupGreaterThanVolumeOfVehicle'),
                    okText: t('button.ok')
                });
            }
            else
                this.setState({ currentPage: currentPage + 1, data: { ...data, ...values } })
        })
    }

    handleBackHomePage() {
        this.props.history.push('/dashboard')
    }

    handleBackPage() {
        const { currentPage, data } = this.state;
        this.setState({ currentPage: currentPage - 1, data: { ...data } })
    }

    handleFinish() {
        const { data } = this.state;
        this.formRef?.current?.validateFields().then(async () => {
            const orderIds = this.props.selectedOrders.map((x) => x.id)
            const request = { orderIds, vehicleId: data.vehicleId, stationId: this.props.currentStationId }
            orderDataService.createOrderPickup(request).then(({ id }) => {
                this.formRef.current.resetFields()
                this.props.clearData()
                this.props.history.push(`/view-pickup-list/${id}`)
            })
        })
    }

    setVolumeOfVehicle = (volumeOfVehicle) => {
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                volumeOfVehicle: volumeOfVehicle
            }
        });
        this.formRef.current.setFieldsValue({ volumeOfVehicle: volumeOfVehicle })
    }

    setSelectedOrderIds = (orderIds) => {
        const { orders } = this.props;
        if (orderIds.length > 0) {
            let totalWeight = 0
            orderIds.forEach(element => {
                let order = orders?.filter(a => a.id === element)
                if (order)
                    totalWeight = totalWeight + order[0]?.totalWeight;
            });
            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    currentWeight: totalWeight
                }
            });
        }
        else {
            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    currentWeight: 0
                }
            });
        }
        this.formRef.current.setFieldsValue({ orderIds })
    }

    render() {
        const { t } = this.props;
        const { currentPage } = this.state;
        return (
            <Layout className='bg-content'>
                <div className='bg-body'>
                    <TopBar title={currentPage === stepListPickup.listPickup ? t('title.createPickupList') : t('title.reviewPickupList')} />
                    <div className='bg-white clearfix'>
                        <Form ref={this.formRef} >
                            {this.renderListPickup()}
                            <NextPage
                                handleNext={this.handleNextPage.bind(this)}
                                handleBack={this.handleBackPage.bind(this)}
                                handleCancel={this.handleBackHomePage.bind(this)}
                                handleFinish={this.handleFinish.bind(this)}
                                showCancel={currentPage === stepListPickup.listPickup}
                                hideNext={currentPage === stepListPickup.reviewPickup || currentPage === stepListPickup.viewPickupList}
                                hideBack={currentPage === stepListPickup.listPickup || currentPage === stepListPickup.viewPickupList}
                                hideClose={currentPage === stepListPickup.listPickup || currentPage === stepListPickup.reviewPickup}
                                hideFinish={currentPage === stepListPickup.listPickup}
                                textFinish={t('button.createPickupList')}
                            />
                        </Form>
                    </div>
                </div>
            </Layout>
        )
    }
}
