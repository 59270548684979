import actionTypes from "./orders.types";
import orderDataService from "../../data-services/orders/orders-data.service";

export function setListOrders(orders) {
    return { type: actionTypes.SET_LIST_ORDERS, orders };
}

export function fetchListOrders(stationId) {
    return async (dispatch) => {
        let data = await orderDataService.listStationOrders(stationId);
        dispatch(setListOrders(data.orders));
    }
}

export const getInitData = (deliveryType) => {
    return async (dispatch) => {
        const res = await orderDataService.getInitData(deliveryType)
        dispatch({
            type: actionTypes.GET_ORDER_INIT_DATA,
            payload: res
        })
    }
}

export const getStation = (cityId) => {
    return async (dispatch) => {
        const res = await orderDataService.getStation(cityId)
        dispatch({
            type: actionTypes.GET_STATIONS,
            payload: res?.stations
        })
    }
}

export const getRouting = (req) => {
    return async (dispatch) => {
        const { toStation, fromStation } = req || {}
        const res = await orderDataService.getRouting(toStation, fromStation || '')
        dispatch({
            type: actionTypes.GET_ROUTING,
            payload: res?.data || []
        })
    }
}

export const getRoutingToDoor = (receiverCity, fromStation, senderCity) => {
    return async (dispatch) => {
        const res = await orderDataService.getRoutingToDoor(receiverCity, fromStation, senderCity)
        dispatch({
            type: actionTypes.GET_ROUTING,
            payload: res?.data || []
        })
    }
}

export const clearData = () => {
    return {
        type: actionTypes.CLEAR_ORDER_DATA,
        payload: []
    }
}

export const getDistrictByCityId = (cityId, isSender) => {
    return async (dispatch) => {
        const res = await orderDataService.getDistrictByCityId(cityId)
        dispatch({
            type: actionTypes.GET_DISTRICT_BY_CITYID,
            payload: { data: res?.districts || [], isSender }
        })
    }
}

export const getWardsByDistrictId = (districtId, isSender) => {
    return async (dispatch) => {
        const res = await orderDataService.getWardsByDistrictId(districtId)
        dispatch({
            type: actionTypes.GET_WARDS_BY_DISTRICTID,
            payload: { data: res?.wards || [], isSender }
        })
    }
}

export const getOrderDetails = (orderId) => {
    return async (dispatch) => {
        const res = await orderDataService.getOrderDetails(orderId)
        dispatch({
            type: actionTypes.GET_ORDER_DETAILS,
            payload: res.order || {}
        })
    }
}

export function getOrderPickup(stationId) {
    return async (dispatch) => {
        let data = await orderDataService.listStationOrdersPickup(stationId);
        dispatch({
            type: actionTypes.GET_LIST_PICKUP_ORDER,
            payload: data
        });
    }
}

export function updateSelectedOrder(keys) {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.UPDATE_SELECTED_ORDER,
            payload: keys
        });
    }
}

export const SetPayment = (payment) => {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.SET_PAYMENT,
            payload: payment
        })
    }
}

export function getPickupListById(id) {
    return async (dispatch) => {
        let data = await orderDataService.getPickupListById(id);
        dispatch({
            type: actionTypes.GET_PICKUP_LIST_BY_ID,
            payload: data?.orderPickup
        })
    }
}

export function setVehiclesForDelivery(vehicles) {
    return {
        type: actionTypes.SET_VEHICLES_FOR_DELIVERY,
        payload: vehicles
    }
}

export function setSelectedVehicleForDelivery(aVehicle) {
    return {
        type: actionTypes.SET_SELECTED_VEHICLE_FOR_DELIVERY,
        payload: aVehicle
    }
}

export const clearDataForDelivery = () => {
    return {
        type: actionTypes.CLEAR_DATA_FOR_DELIVERY,
        payload: []
    }
}

export function getDeliveryListDetailById(id) {
    return async (dispatch) => {
        let data = await orderDataService.getDeliveryListDetailById(id);
        dispatch({
            type: actionTypes.GET_DELIVERY_LIST_DETAIL_BY_ID,
            payload: data
        })
    }
}

export function getListExportOrders(stationId, page, pageSize) {
    return async (dispatch) => {
        let data = await orderDataService.getListExportOrders(stationId, page, pageSize);
        const { records, rows } = data.exportList
        dispatch({
            type: actionTypes.GET_LIST_EXPORT_ORDER,
            total: records, pageSize: pageSize, current: page, order: rows
        });
    }
}

export function updateOrderPickupAndVehicle(listOrderPickup, vehicles, selectedOrders) {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.UPDATE_LIST_PICKUP_ORDER_AND_VEHICLE,
            listOrderPickup: listOrderPickup,
            vehicles: vehicles,
            selectedOrders: selectedOrders
        });
    }
}