import { Option } from 'antd/lib/mentions';
import { Select, Form, Table, Button, Image, Empty } from 'antd';
import moment from 'moment';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';
import { getSpecialServiceIcon } from '../../../utils/order';
import iconWareHouse from '../../../assets/icons/warehouse.png';
import { deliveryHouseMethods } from '../../../constants/order-constant';
import _ from 'lodash';
import orderDataService from '../../../data-services/orders/orders-data.service';
import './../deliver-order.scss';
import languageService from '../../../services/language.service';
import './delivery-order-list.scss';

const unitWeight = 'Kg';

export class DeliveryOrderListScreen extends Component {
    constructor(props) {
        super(props);
        const {
            isSelectedAllOrders,
            allOrdersHaveBeenSelected,
            selectedOrdersForDelivery,
            unselectedOrdersForDelivery,
        } = this.props;

        this.state = {
            selectedVehicleId: props.data?.vehicleId || '',
            searchText: '',
            searchedColumn: '',
            count: 0,
            pagination: {
                current: 1,
                pageSize: 20,
                total: 0,
            },
            showSelectAll: isSelectedAllOrders,
            allOrdersHaveBeenSelected: allOrdersHaveBeenSelected?.map(anOrder => anOrder.id) ?? [],
            selectedOrderIds: selectedOrdersForDelivery?.map(anOrder => anOrder.id) ?? [],
            unselectedOrderIds: unselectedOrdersForDelivery?.map(anOrder => anOrder.id) ?? [],
            orderListForDelivery: [],
            vehicleListForDelivery: [],
            selectedOrdersForDelivery: [],
            unselectedOrdersForDelivery: [],
            baseDataList: [],
        };
    }

    componentDidMount() {
        const { currentStationId } = this.props;
        if (currentStationId) {
            this.loadData(false, this.state.pagination);
        }
    }

    onChangeVehicle = (vehicleId) => {
        let vehicle = this.state.vehicleListForDelivery.filter(
            (aVehicle) => aVehicle.id === vehicleId
        )[0];
        this.props.setSelectedVehicleForDelivery(vehicle);
        this.props.setVolumeOfVehicle(vehicle.volume);
        this.setState({
            selectedVehicleId: vehicleId,
        });
    };

    getRecipientInfo = (order) => {
        const { t } = this.props;
        let formattedAddress =
            this.getFormattedAddress(order?.addressRecipient) || t('text.noAddress');
        let receiverInfo = [
            order.receiverName,
            order.receiverPhone,
            formattedAddress,
        ];
        return receiverInfo.join('<br/>');
    };

    getFormattedAddress(address) {
        let arr = [];
        if (address && address.street) {
            arr.push(address.street);
        }
        if (address && address.ward) {
            let ward = address.ward.prefix + ' ' + address.ward.name;
            arr.push(ward);
        }
        if (address && address.district) {
            let district = address.district.prefix + ' ' + address.district.name;
            arr.push(district);
        }
        if (address && address.city) {
            arr.push(address.city.name);
        }
        return arr.join(', ');
    }

    onChange = (pagination, filters, sorter, extra) => {
        this.loadData(true, pagination, sorter, filters);
    }

    loadData = (nextPage, pagination, sorter = null, filters = null) => {
        let sortOrder = 'asc';
        let sortField = 'Id';
        if (sorter) {
            sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';
            sortField = sorter?.field || 'Id';
        }

        let inPackages = [];
        let inDeliveryMethods = [];

        if (filters) {
            if (filters.deliveryMethod && filters.deliveryMethod?.length > 0) {
                // For example: ['Door To Door', 'Warehouse To Door', 'Station To Door']
                inDeliveryMethods.push(...filters.deliveryMethod);
            }

            if (filters.packageInfo && filters.packageInfo?.length > 0) {
                // For example: ['Fast Service', 'Fragile', 'Normal Service']
                inPackages.push(...filters.packageInfo);
            }
        }

        // If the user click on the button 'Select all orders', the checkbox will be checked on the Table component.
        let isChecked = this.state.showSelectAll;

        //  If the user clicks on the button 'Select all orders', uncheck the checkbox control on the Table component.
        let unselectedOrders = this.state.unselectedOrderIds;

        orderDataService
            .getOrdersAndVehiclesForDelivery(
                this.props.currentStationId,
                pagination.current,
                pagination.pageSize,
                sortField,
                sortOrder,
                '',
                nextPage,
                [],
                inPackages,
                inDeliveryMethods
            )
            .then((res) => {
                var orders = [];
                var orderIds = [];
                if (res.orders) {
                    orders = res.orders?.map((o) => this.transform(o));
                    orderIds = orders.map((anOrder) => anOrder.id);
                    if (unselectedOrders && unselectedOrders.length > 0) {
                        for (let index = 0; index < orderIds.length; index++) {
                            orderIds = orderIds.filter(
                                (anId) => anId !== unselectedOrders[index]
                            );
                        }
                    }
                }
                let tmpSelectedOrderId = isChecked
                    ? orderIds
                    : this.state.selectedOrderIds?.length > 0
                        ? this.state.selectedOrderIds
                        : [];

                // Update the state of the react.
                this.setState({
                    pagination: {
                        ...pagination,
                        total: res.totalOrders,
                    },
                    baseDataList: res.orders,
                    orderListForDelivery: orders,
                    vehicleListForDelivery: nextPage
                        ? this.state.vehicleListForDelivery
                        : res.vehicles,
                    selectedOrderIds: tmpSelectedOrderId,
                });

                if (!nextPage) {
                    this.props.setVehiclesForDelivery(res.vehicles);
                }
            });
    };

    handleCheckAll() {
        const { orderListForDelivery } = this.state || [];
        const { showSelectAll } = this.state;

        let currentWeight = 0;
        // The status is 'selected all order'
        if (!showSelectAll) {
            // Get all ids from the order list and set value for checkbox control on Table component.
            let keys = orderListForDelivery.map((anOrder) => anOrder.id);

            keys.forEach(element => {
                let order = orderListForDelivery.filter(a => a.id === element);
                currentWeight = currentWeight + order[0]?.totalWeight;
            });

            // Update the state of the React.
            this.setState({ selectedOrderIds: keys, showSelectAll: !showSelectAll });

            // Update the value of properties, you can find it in the file name 'deliver-order.screen.js'.
            this.props.setSelectedOrderIds(keys, currentWeight);
            this.props.setDataForDelivery(true, []);

            // Set state in Redux state manager, you can find it in the file name 'delivery-order-list.container.js'.
            this.props.updateSelectedOrderList(true, [], []);
        } else {
            //  The status is 'unselected all order'.
            // Update state of the React.
            this.setState({ selectedOrderIds: [], showSelectAll: !showSelectAll });

            // Set state in Redux state manager, you can find it in the file name 'delivery-order-list.container.js'.
            this.props.updateSelectedOrderList(false, [], []);

            // // Update the value of properties, you can find it in the file name 'deliver-order.screen.js'.
            this.props.setSelectedOrderIds([], currentWeight);
            this.props.setDataForDelivery(false, []);
        }
    }

    onSelectChange = (selectedRowKeys = []) => {
        const {
            showSelectAll,
            baseDataList,
            unselectedOrderIds,
            allOrdersHaveBeenSelected,
            selectedOrderIds,
        } = this.state;
        
        let currentWeight = 0;
        // Match when the user clicks on the button 'Select all orders'.
        if (showSelectAll) {
            // If the selectedRowKeys is null or empty, reset unselectedAllOrders state.
            // The 'unselectedAllOrders' status contains orders that will not be selected for delivering.
            // It is useful when you frequently switch between pages.
            // It will unselect orders (unselect in checkbox control) that were previously deselected.
            if (selectedRowKeys === null || selectedRowKeys.length === 0) {
                let orderIds = baseDataList.map((anOrder) => anOrder.id);
                this.setState({ unselectedOrderIds: orderIds, selectedOrderIds: [] });
                this.handleCheckAll();
                this.props.setDataForDelivery(true, orderIds);
            } else {
                // Filter unselect orders (un-tick in the checkbox control) and update Redux status.
                var newUnselectedOrderIds = [...unselectedOrderIds];
                let newSelectedOrderIds = [...selectedOrderIds];

                // Reset the unselected list, remove all order id of the current page in the unselected list.
                for (let index = 0; index < baseDataList.length; index++) {
                    let anOrder = baseDataList[index];
                    newUnselectedOrderIds = newUnselectedOrderIds.filter(
                        (unselectedOrderId) => anOrder.id !== unselectedOrderId
                    );
                }

                // Set data for the unselected list and selected list.
                for (let index = 0; index < baseDataList.length; index++) {
                    let anOrder = baseDataList[index];

                    // If the id in the selectedRowKeys variable matches the order id of the current page, add it to the selected list.
                    let anOrderId = selectedRowKeys.filter(
                        (anId) => anOrder.id === anId
                    )[0];
                    if (anOrderId) {
                        newSelectedOrderIds.push(anOrderId);
                    } else {
                        // Add order id to the newUnselectedOrderIds variable.
                        newUnselectedOrderIds.push(anOrder.id);

                        // Remove order id in the newSelectedOrderIds variable.
                        newSelectedOrderIds = newSelectedOrderIds.filter(
                            (selectedOrderId) => anOrder.id !== selectedOrderId
                        );
                    }
                }

                // Remove duplicate values in array.
                newUnselectedOrderIds = newUnselectedOrderIds?.filter(
                    (value, index, array) => array.indexOf(value) === index
                );
                newSelectedOrderIds = newSelectedOrderIds?.filter(
                    (value, index, array) => {
                        return array.indexOf(value) === index;
                    }
                );
                let orderSelected = [];
                let orderUnSelected = [];
                newSelectedOrderIds.forEach((orderId) => {
                    let order = baseDataList.filter(a => a.id == orderId);
                    currentWeight = currentWeight + order[0].totalWeight;
                    orderSelected.push(order[0]);
                });
                newUnselectedOrderIds.forEach((orderId) => {
                    let orderUnSelect = baseDataList.filter(a => a.id == orderId);
                    orderUnSelected.push(orderUnSelect[0]);
                });

                let selectAll = showSelectAll;
                if(baseDataList.length > 0 &&
                    newSelectedOrderIds.length < baseDataList.length)
                    selectAll = false;

                // Update state on this page.
                this.setState({
                    unselectedOrderIds: newUnselectedOrderIds,
                    selectedOrderIds: newSelectedOrderIds,
                    showSelectAll: selectAll
                });
                
                // Update state of the Redux.
                this.props.updateSelectedOrderList(
                    selectAll,
                    orderSelected,
                    orderUnSelected
                );
                this.props.setDataForDelivery(selectAll, newSelectedOrderIds);
                this.props.setSelectedOrderIds(newSelectedOrderIds, currentWeight);
            }
        } else {
            // If the user doesn't click the button 'Select all orders', filter the selected orders to create a delivery list.
            var newAllSelectedOrders = [...allOrdersHaveBeenSelected];

            // If the user doesn't select any order, reset React state and Redux state.
            if (selectedRowKeys === null || selectedRowKeys.length === 0) {
                for (let index = 0; index < baseDataList.length; index++) {
                    let anOrder = baseDataList[index];
                    newAllSelectedOrders = newAllSelectedOrders.filter(
                        (filterOrder) => filterOrder.id !== anOrder.id
                    );
                }
                // Update the React state.
                this.setState({ allOrdersHaveBeenSelected: newAllSelectedOrders });
            } else {
                // If the user has selected orders on the checkbox control.
                for (let index = 0; index < baseDataList.length; index++) {
                    let anOrder = baseDataList[index];
                    newAllSelectedOrders = newAllSelectedOrders.filter(
                        (filterOrder) => filterOrder.id !== anOrder.id
                    );
                }

                for (let index = 0; index < selectedRowKeys.length; index++) {
                    let anOrder = baseDataList.filter(
                        (anOrder) => anOrder.id === selectedRowKeys[index]
                    )[0];
                    if (anOrder) {
                        newAllSelectedOrders.push(anOrder);
                    }
                }

                // Update the selected orders.
                newAllSelectedOrders = newAllSelectedOrders.filter(
                    (value, index, array) => {
                        currentWeight = currentWeight + value.totalWeight;
                        return array.indexOf(value) === index;
                    }
                );
                this.setState({ allOrdersHaveBeenSelected: newAllSelectedOrders });
            }

            if (this.state.orderListForDelivery.length > 0 &&
                newAllSelectedOrders.length === this.state.orderListForDelivery.length)
                this.handleCheckAll();
            else {
                this.props.updateSelectedOrderList(
                    showSelectAll,
                    newAllSelectedOrders,
                    []
                );
            }
            let orderIds = newAllSelectedOrders.map((anOrder) => anOrder.id);
            this.setState({ selectedOrderIds: orderIds });
            this.props.setSelectedOrderIds(orderIds, currentWeight);
            this.props.setDataForDelivery(false, orderIds);
        }
    };

    getTableColumns(orders) {
        const { t } = this.props;
        let deliveries = orders?.map((d) => {
            return { text: d.deliveryMethod, value: d.deliveryMethod };
        });
        let delivieryOptions = deliveries?.filter(
            (d, i) => deliveries?.findIndex((o) => o.value === d.value) === i
        );
        let packages = orders?.map((d) => {
            return { text: d.packageInfo, value: d.packageInfo };
        });

        let packageInfoOptions = packages?.filter(
            (d, i) => packages?.findIndex((o) => o.value === d.value) === i
        );
        let columns = [
            {
                title: t('tableColumn.orderId'),
                dataIndex: 'orderId',
                fixed: 'left',
                render: (text, data) => (
                    <Link target='_blank' to={`/view-order/${data.id}`}>
                        {text}
                    </Link>
                ),
            },
            {
                title: t('tableColumn.receiverAddress'),
                className: 'w-270px',
                dataIndex: 'toRecipient',
                render: (text) => <span dangerouslySetInnerHTML={{ __html: text }} />,
            },
            {
                title: t('tableColumn.packageInfo'),
                dataIndex: 'packageInfo',
                render: (name) => (
                    <span>
                        {t(name)}
                        {getSpecialServiceIcon(name)}
                    </span>
                ),
                filters: packageInfoOptions,
                onFilter: (value, record) => record.packageInfo.indexOf(value) === 0,
            },
            {
                title: t('tableColumn.deliveryMethod'),
                dataIndex: 'deliveryMethod',
                render: (text) => (
                    <span>
                        {text}
                        <br />
                        {deliveryHouseMethods.includes(text?.toLowerCase()) && (
                            <Image src={iconWareHouse} width={30} />
                        )}
                    </span>
                ),
                filters: delivieryOptions,
                onFilter: (value, record) => record.deliveryMethod.indexOf(value) === 0,
            },
            {
                title: t('tableColumn.totalWeight'),
                dataIndex: 'totalWeight',
                render: (text) => (
                    <span>
                        {text} {unitWeight}
                    </span>
                ),
            },
            {
                title: t('tableColumn.totalProducts'),
                className: 'w-80px',
                dataIndex: 'totalProductsInPackage',
            },
            {
                title: t('tableColumn.totalItems'),
                className: 'w-80px',
                dataIndex: 'totalItemsInPackage',
            },
            {
                title: t('tableColumn.createdDate'),
                dataIndex: 'createdDate',
                render: (text) => (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: moment
                                .utc(text)
                                .local()
                                .format('hh:mm A <br> DD/MM/YYYY'),
                        }}
                    />
                ),
            },
        ];
        return columns;
    }

    transform = (order) => {
        var result = {
            key: order.id,
            id: order.id,
            orderId: order.code,
            toRecipient: this.getRecipientInfo(order),
            deliveryMethod: order?.delivery?.name,
            deliveryMethodId: order?.delivery?.id,
            packageInfo: order?.specialOption?.languageKey,
            packageInfoId: order?.specialOption?.id,
            createdDate: order.createdTime,
            amount: order.amount,
            packageSizeWeight: order.packageSizeWeight,
            totalWeight: order.totalWeight,
            totalProductsInPackage: order.totalProductsInPackage,
            totalItemsInPackage: order.totalItemsInPackage,
        };
        if (order?.status) {
            if (order.status.subStatusName != null) {
                result.status = `${order.status.statusName}-${order.status.subStatusName}`;
            } else {
                result.status = order.status.statusName;
            }
        }

        return result;
    };

    render() {
        const { t, data } = this.props;
        const { vehicleListForDelivery } = this.state;
        const { orderListForDelivery } = this.state;
        const { selectedOrderIds, showSelectAll } = this.state;
        const rowSelection = {
            selectedRowKeys: selectedOrderIds,
            onChange: this.onSelectChange.bind(this),
        };
        let localLanguage = languageService.getLang();
        let columns = this.getTableColumns(orderListForDelivery);
        return (
            <Content className='content'>
                <div className='list-pickup'>
                    <div className='select-vehicle pull-left' style={{ display: 'inline-grid' }}>
                        <label htmlFor='html'>{t('text.pleaseSelectVehicle')}</label>
                        <div style={{ display: 'inline-flex' }}>
                            <Form.Item
                                name='vehicleId'
                                rules={[{ required: true, message: t('messages.pleaseSelectVehicle') }]}
                            >
                                <Select
                                    showSearch
                                    placeholder={t('placeholder.selectVehicle')}
                                    optionFilterProp='children'
                                    onChange={this.onChangeVehicle}
                                    style={{ minWidth: 220 }}
                                >
                                    {vehicleListForDelivery?.map(({ id, code, type, volume }) => (
                                        <Option
                                            value={id}
                                            key={id}
                                        >{`${code} (${t(type?.name)}) - ${volume.toLocaleString(localLanguage)} ${unitWeight}`}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <div className='show-volume-of-vehicle'>
                                <p className='line-current-weight'>{t('text.currentWeight')}/{t('text.volumeOfVehicle')}</p>
                                <p id='volume-selected' className='line-current-weight'>
                                    <span
                                        id='current-weight'
                                        className={data?.currentWeight > data?.volumeOfVehicle ? 'volume-selected-limted' : ''}
                                    >
                                        {data?.currentWeight.toLocaleString(localLanguage)}
                                    </span>/{data?.volumeOfVehicle.toLocaleString(localLanguage) + " " + unitWeight}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='pull-right'>
                        <Button type='primary' onClick={this.handleCheckAll.bind(this)}>
                            {!showSelectAll ? (
                                <span>{t('text.selectAllOrders')}</span>
                            ) : (
                                <span>{t('text.unSelectAllOrders')}</span>
                            )}
                        </Button>
                        <p className='selected-order clearfix'>
                            {t('text.selected')} {selectedOrderIds.length + ` ${t('text.orders')}`}
                        </p>
                    </div>
                    <Table
                        bordered
                        columns={columns}
                        dataSource={orderListForDelivery}
                        pagination={this.state.pagination}
                        rowSelection={rowSelection}
                        onChange={this.onChange}
                        locale={{
                            emptyText: (
                                <Empty
                                    description={
                                        !this.state.selectedVehicleId
                                            ? t('messages.pleaseSelectVehicle')
                                            : t('messages.noOrders')
                                    }
                                />
                            ),
                        }}
                    />
                    <Form.Item
                        name='orderIds'
                        className='message'
                        rules={[{ required: true, message: t('messages.pleaseChooseOrders') }]}
                    />
                </div>
            </Content>
        );
    }
}
