import noRecordFound from '../assets/images/NoRecordFound.jpg';
import qrcode from '../assets/images/qr-code.png';
import iconBank from '../assets/icons/bank.jpg';
import iconMomo from '../assets/icons/momo.png';
import iconMoney from '../assets/icons/money.jpg';

export const images ={
    noRecordFound: noRecordFound,
    qrCode: qrcode,
    iconBank: iconBank,
    iconMomo: iconMomo,
    iconMoney: iconMoney
}