import actionTypes from "./import.type";

const importInitialState = {
    // import list
    listImport: [],
    // paging
    current: 0,
    pageSize: 0,
    total: 0,
};

const importReducer = (state = importInitialState, action) => {
    switch (action.type) {
        case actionTypes.GET_LIST_IMPORT_ORDER:
            return {
                ...state, total: action.total, pageSize: action.pageSize, current: action.current,
                listImport: action.import,
            };
        default:
            return state;
    }
};

export default importReducer;
