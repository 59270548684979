
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Table, Checkbox } from 'antd';
import moment from 'moment';
import { FORMAT_TIME_DATE } from '../../../common/constants';
import './summarize.component.scss'

const unitWeight = 'kg';
export default class SummarizeComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vehicles: props.vehicles,
            initialData: props.initialData ?? {
                vehicle: null,
                exportLists: [],
                route: null
            },
        }

        this.formRef = React.createRef();
    }

    render() {
        const { t } = this.props;
        const { initialData } = this.state;
        const { vehicle, route, exportLists } = initialData;

        var routeName = route?.code && route?.routeName ? `${route?.code} (${route?.routeName})` : '';

        var totalExportList = exportLists.length;
        var totalOrders = exportLists?.map(x => x.totalOrders).reduce((a, b) => a + b, 0);
        var totalProducts = exportLists?.map(x => x.totalProducts).reduce((a, b) => a + b, 0);
        var totalItems = exportLists?.map(x => x.totalItems).reduce((a, b) => a + b, 0);
        var totalWeight = exportLists?.map(x => x.totalWeight).reduce((a, b) => a + b, 0);

        return (
            <div>
                <div>
                    <div className='row ml-5'>
                        <div className='col-4'>
                            <label>{t('text.selectedVehicle')}</label>
                            <p>{`${vehicle?.code}, ${vehicle?.type}`}</p>
                        </div>

                        <div className='col-4'>
                            <label>{t('tableColumn.routeId')} - {t('tableColumn.routeName')}</label>
                            <p>{routeName}</p>
                        </div>

                        <div className='col-4'>
                            <label>{t('tableColumn.routine')}</label>
                            <p>{route?.routine}</p>
                        </div>
                    </div>

                    <div className='row m-auto pl-5'>
                        <div className='col-2 m-auto'>
                            <p className='text-center'><b>{t('tableColumn.totalExportList')}</b></p>
                            <p className='text-center'>{totalExportList}</p>
                        </div>

                        <div className='col-2 m-auto'>
                            <p className='text-center'><b>{t('tableColumn.totalOrders')}</b></p>
                            <p className='text-center'>{totalOrders}</p>
                        </div>

                        <div className='col-2 m-auto'>
                            <p className='text-center'><b>{t('tableColumn.totalProducts')}</b></p>
                            <p className='text-center'>{totalProducts}</p>
                        </div>

                        <div className='col-2 m-auto'>
                            <p className='text-center'><b>{t('tableColumn.totalItems')}</b></p>
                            <p className='text-center'>{totalItems}</p>
                        </div>

                        <div className='col-2 m-auto'>
                            <p className='text-center'><b>{t('tableColumn.totalWeight')}</b></p>
                            <p className='text-center'>{`${totalWeight} ${unitWeight}`}</p>
                        </div>
                    </div>
                    <div className='row m-auto pl-5'>
                        <div className='col-2 m-auto'>
                        </div>

                        <div className='col-2 m-auto'>
                            <p className='text-center'><b>{t('tableColumn.newOrderQty')}</b></p>
                            <p className='text-center text-primary'>{totalOrders}</p>
                        </div>

                        <div className='col-2 m-auto'>
                            <p className='text-center'><b>{t('tableColumn.newProductQty')}</b></p>
                            <p className='text-center text-primary'>{totalProducts}</p>
                        </div>

                        <div className='col-2 m-auto'>
                            <p className='text-center'><b>{t('tableColumn.newItemQty')}</b></p>
                            <p className='text-center text-primary'>{totalItems}</p>
                        </div>

                        <div className='col-2 m-auto'>
                            <p className='text-center'><b>{t('tableColumn.newWeights')}</b></p>
                            <p className='text-center text-primary'>{`${totalWeight} ${unitWeight}`}</p>
                        </div>
                    </div>

                    <div className='mt-5'>
                        { this.renderExportList() }
                    </div>

                </div>
            </div>
        )
    }


    mappingToDataTable = (orderItems) => {
        return orderItems?.map((i, index) => {
            return {
                index: index + 1,
                productCode: i.product?.code,
                productName: i.product?.name,
                productCategory: i.product?.packageType?.name,
                amount: i.exportedAmount,
                amountScanned: i.amountScanned ?? 0,
                scanned: i.scanned ?? false,
                scanReasonFailed: i.scanReasonFailed ?? null,
            }
        });
    }

    renderExportList = () => {
        const { t } = this.props;
        const { initialData } = this.state;
        const { exportLists } = initialData;

        if (exportLists) {
            return exportLists?.map((exportList, index) => {
                const { orders } = exportList;
                var {
                    exportOrders,
                    exportProducts,
                    exportItems,
                    newOrderQty,
                    newProductQty,
                    newItemsQty
                } = this.getOrderInfo(orders);

                return (
                    <>
                        <div className='ml-3 mr-3 mt-4 mb-5'>
                            <div className='pt-4 bg-export-list'>
                                <div className='row m-auto pl-5'>
                                    <div className='col-4 m-auto text-center'>
                                        <p><b>{t('tableColumn.exportListId')}</b></p>
                                        <a>{exportList.code}</a>
                                    </div>

                                    <div className='col-4 m-auto'>
                                        <div className='d-flex'>
                                            <p className='col-6'>{t('tableColumn.exportOrders')}</p>
                                            <p className='col-4'>{exportOrders }</p>
                                        </div>
                                        <div className='d-flex'>
                                            <p className='col-6'>{t('tableColumn.exportProducts')}</p>
                                            <p className='col-4'>{exportProducts }</p>
                                        </div>
                                        <div className='d-flex'>
                                            <p className='col-6'>{t('tableColumn.exportItems')}</p>
                                            <p className='col-4'>{exportItems}</p>
                                        </div>
                                    </div>

                                    <div className='col-4 m-auto'>
                                        <div className='d-flex'>
                                            <p className='col-6'>{t('tableColumn.newOrderQty')}</p>
                                            <p className='col-4 text-primary'>{newOrderQty}</p>
                                        </div>
                                        <div className='d-flex'>
                                            <p className='col-6'>{t('tableColumn.newProductQty')}</p>
                                            <p className='col-4 text-primary'>{newProductQty}</p>
                                        </div>
                                        <div className='d-flex'>
                                            <p className='col-6'>{t('tableColumn.newItemQty')}</p>
                                            <p className='col-4 text-primary'>{newItemsQty}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='mt-3 mb-3 pb-1'>
                                    {this.renderOrders(orders)}
                                </div>
                            </div>
                        </div>
                    </>
                )
            });
        }

    }

    renderOrders = (orders) => {
        const { t } = this.props;
        if (orders) {
            return orders?.map((o, index) => {
                return (
                    <>
                        <div className='bg-order rounded-0 mt-0 mb-0 border ml-3 mr-3 mb-3 pt-2'>
                            <div className='row ml-5 header-table'>
                                <div className='col-md-3 d-flex'>
                                    <p className='mr-3 font-weight-bold'>{t('tableColumn.orderCode')}: </p>
                                    <Link to={`view-order/${o.id}`} target='_blank'> {o?.code} </Link>
                                </div>
                                <div className='col-md-3 d-flex'>
                                    <p className='font-weight-bold mr-3'>{t('tableColumn.createdDate')}: </p>
                                    <p>{moment.utc(o?.createdTime).format(FORMAT_TIME_DATE)}</p>
                                </div>
                            </div>

                            <div className='mt-0'>
                                <Table
                                    className='ml-3 mr-3 bg-import-summarize'
                                    columns={this.orderTableColumns()}
                                    dataSource={this.mappingToDataTable(o?.orderItems)}
                                    pagination={false}
                                />
                            </div>
                        </div>
                    </>
                )
            });
        }
    }

    orderTableColumns = () => {
        const { t } = this.props;
        return [
            {
                title: t('tableColumn.no'),
                dataIndex: 'index',
            },
            {
                title: t('tableColumn.productCode'),
                dataIndex: 'productCode',
            },
            {
                title: t('tableColumn.productName'),
                dataIndex: 'productName',
            },
            {
                title: t('tableColumn.productCategory'),
                dataIndex: 'productCategory',
            },
            {
                title: t('tableColumn.amount'),
                dataIndex: 'amount',
                render: (amount, record) => {
                    return (
                        <>
                            <span className='m-auto'>{`${record.amountScanned}/${record.amount}`}</span>
                        </>
                    )
                }
            },
            {
                title: t('tableColumn.scanned'),
                dataIndex: 'scanned',
                render: (scanned, record) => {
                    return (
                        <Checkbox
                            checked={scanned}
                            disabled={true}
                        />
                    )
                }
            },
            {
                title: t('tableColumn.reason'),
                dataIndex: 'scanReasonFailed',
                render: (scanReasonFailed, record) => {
                    return (
                        <>
                            <span className='m-auto'>{record.scanReasonFailed}</span>
                        </>
                    )
                }
            },
        ];
    }

    isScannedOrder = (order) => {
        var orderItems = order.orderItems;
        var itemsScanned = orderItems?.filter(x => x.scanned && x.scanned == true).length ?? 0;
        if (itemsScanned === orderItems.length) {
            return true;
        }

        return false;

    }
    getOrderInfo = (orders) => {
        var exportOrders = 0;
        var exportProducts = 0;
        var exportItems = 0;

        var newOrderQty = 0;
        var newProductQty = 0;
        var newItemsQty = 0;
        if (orders) {
            exportOrders = orders.length;
            orders?.map(o => {
                if (this.isScannedOrder(o)) {
                    newOrderQty++;
                }

                exportProducts += o.orderItems.length;
                exportItems += o.orderItems?.map((i) => i.exportedAmount).reduce((a, b) => a + b, 0);

                var itemsScanned = o.orderItems?.filter(x => x.scanned && x.scanned == true);
                newProductQty += itemsScanned.length;

                itemsScanned.forEach((i) => {
                    newItemsQty += i.amountScanned;
                })
            });
        }

        return {
            exportOrders: exportOrders,
            exportProducts: exportProducts,
            exportItems: exportItems,

            newOrderQty: newOrderQty,
            newProductQty: newProductQty,
            newItemsQty: newItemsQty
        }
    }

}