import React, { Component } from 'react';
import { Checkbox, InputNumber, Form, Select } from 'antd';
import { Link } from 'react-router-dom';
import BarcodeReader from 'react-barcode-reader';
import _ from 'lodash';
import { ProductsInfoModes } from './products-info';
import './products-info.scss';

export class ProductsInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            initialData: this.props.initialData,
        }
        this.formRef = React.createRef();
        this.barcode = '';
    }

    render() {
        const { t } = this.props;
        let { mode, productNotEnoughReasons, productNotFoundReasons } = this.props;
        let { initialData } = this.state;
        return (
            <Form
                ref={this.formRef}
                initialValues={initialData}
                className='beecow-products-info'
                style={{ padding: 20 }}>
                <p><b>{t('tableColumn.orderCode')}: <Link style={{ textDecoration: 'underline' }} to={`/view-order/${initialData?.id}`}>{initialData?.code}</Link></b></p>
                <h2>{t('order.productInformation')}</h2>
                <BarcodeReader timeBeforeScanTest={1000} onReceive={this.onReceiveScan.bind(this)} />
                <table className="table table-borderless">
                    <thead>
                        <tr>
                            <th>{t('tableColumn.no')}</th>
                            <th>{t('tableColumn.productCode')}</th>
                            <th>{t('tableColumn.productName')}</th>
                            <th>{t('tableColumn.productCategory')}</th>
                            <th>{t('tableColumn.size')} (m)</th>
                            <th>{t('tableColumn.weight')} (kg)</th>
                            <th>{t('tableColumn.amount')}</th>
                            <th>{t('tableColumn.scanned')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {initialData?.orderItems?.map((orderItem, index) => {
                            return (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{orderItem?.product?.code}</td>
                                    <td>{orderItem?.product?.name}</td>
                                    <td>{orderItem?.product?.packageType?.name}</td>
                                    <td>{orderItem?.product?.length}m - {orderItem?.product?.width}m - {orderItem?.product?.height}m</td>
                                    <td>{orderItem?.product?.weight}kg</td>
                                    <td>
                                        {(mode === ProductsInfoModes.SCAN) && !orderItem?.scanned && (
                                            <span>{orderItem.amount}</span>
                                        )}
                                        {(mode === ProductsInfoModes.SCAN) && orderItem?.scanned && (
                                            <Form.Item
                                                name={["orderItems", index, "amountScanned"]}
                                                rules={[{
                                                    required: true,
                                                    message: t('messages.invalidAmount')
                                                }]}>
                                                <InputNumber
                                                    pattern="^\d+$"
                                                    onKeyDown={e => (e.keyCode != 9 && e.keyCode !== 8 && (e.keyCode < 48 || e.keyCode > 57)) && e.preventDefault()}
                                                    onChange={value => this.updateProduct(orderItem?.product?.code, null, value)}
                                                    min={1}
                                                    max={orderItem?.amount}
                                                />
                                            </Form.Item>
                                        )}
                                        {(mode === ProductsInfoModes.CONFIRM_REASON || mode === ProductsInfoModes.SUMMARIZE) && (
                                            <span>{orderItem.amountScanned || 0}/{orderItem.amount}</span>
                                        )}
                                    </td>
                                    <td>
                                        {(mode === ProductsInfoModes.SCAN) && (
                                            <Checkbox checked={orderItem?.scanned} disabled />
                                        )}
                                        {(mode === ProductsInfoModes.CONFIRM_REASON) && (orderItem.amountScanned === orderItem.amount) && (
                                            <Checkbox checked={orderItem?.scanned} disabled />
                                        )}
                                        {(mode === ProductsInfoModes.CONFIRM_REASON) && (orderItem.scanned && (orderItem.amountScanned !== orderItem.amount) && (orderItem.amountScanned !== 0)) && (
                                            <Form.Item
                                                name={["orderItems", index, "scanReasonFailed"]}
                                                rules={[{
                                                    required: true,
                                                    message: t('messages.selectReason')
                                                }]}>
                                                <Select
                                                    optionFilterProp="children"
                                                    onChange={(value) => this.updateProduct(orderItem?.product?.code, null, null, value)}
                                                    placeholder={t('placeholder.selectReason')}>
                                                    {
                                                        productNotEnoughReasons?.map((name, index) => (
                                                            <Select.Option key={index} value={name}>{t(name)}</Select.Option>
                                                        ))
                                                    }
                                                </Select>
                                            </Form.Item>
                                        )}
                                        {(mode === ProductsInfoModes.CONFIRM_REASON) && (!orderItem.scanned || (orderItem.scanned && (orderItem.amountScanned !== orderItem.amount) && (orderItem.amountScanned === 0))) && (
                                            <Form.Item
                                                name={["orderItems", index, "scanReasonFailed"]}
                                                rules={[{
                                                    required: true,
                                                    message: t('messages.selectReason')
                                                }]}>
                                                <Select
                                                    optionFilterProp="children"
                                                    onChange={(value) => this.updateProduct(orderItem?.product?.code, null, null, value)}
                                                    placeholder={t('placeholder.selectReason')}>
                                                    {
                                                        productNotFoundReasons?.map((name, index) => (
                                                            <Select.Option key={index} value={name}>{t(name)}</Select.Option>
                                                        ))
                                                    }
                                                </Select>
                                            </Form.Item>
                                        )}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="4"></td>
                            <td colSpan="2">
                                <span>
                                    {t('tableColumn.totalItemsInOrder')}:
                                    &nbsp;{_.sumBy(initialData?.orderItems, "amountScanned")}
                                </span>
                            </td>
                            <td colSpan="2">
                                <span className="beecow-text-red">
                                    {t('text.scannedProducts')}:
                                    &nbsp;{initialData?.orderItems?.filter(i => i.scanned).length}/{initialData?.orderItems?.length}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="4"></td>
                            <td colSpan="2">
                                <span>
                                    {t('tableColumn.totalWeightInOrder')}:
                                    &nbsp;{_.sumBy(initialData?.orderItems, function (i) { return (i.amountScanned || 0) * i.product?.weight })}kg
                                </span>
                            </td>
                            <td colSpan="2"></td>
                        </tr>
                    </tfoot>
                </table>
            </Form>
        )
    }

    setFieldsValue(values) {
        this.formRef.current.setFieldsValue(values);
        this.setState({ initialData: values });
    }

    validateFields() {
        return this.formRef.current.validateFields();
    }

    onReceiveScan(event) {
        if (event && event.charCode === 13) {
            let barcode = this.barcode;
            let arr = barcode.split(',');
            if (arr && arr.length === 3) {
                let orderCode = (arr[0]).trim();
                let productCode = (arr[1]).trim();
                let amount = parseInt((arr[2]).trim());
                this.verifyProduct(productCode, amount, orderCode);
            }
            this.barcode = '';
        } else {
            this.barcode += event.key;
        }
    }

    verifyProduct(productCode, amountScanned, orderCode) {
        if (this.state.initialData?.code === orderCode) {
            this.updateProduct(productCode, true, amountScanned);
        }
    }

    updateProduct(productCode, scanned, amountScanned, scanReasonFailed) {
        let orderItems = this.state.initialData?.orderItems;
        let index = orderItems?.findIndex(i => i.product?.code === productCode);
        if (index !== -1) {
            let orderItem = orderItems[index];
            if (scanned !== undefined && scanned !== null) {
                orderItem['scanned'] = scanned;
            }
            if (amountScanned !== undefined && amountScanned !== null) {
                orderItem['amountScanned'] = amountScanned;
            }
            if (scanReasonFailed !== undefined && scanReasonFailed !== null) {
                orderItem['scanReasonFailed'] = scanReasonFailed;
            }
            orderItems.splice(index, 1, orderItem);
            this.setState({
                initialData: { ...this.state.initialData, orderItems: orderItems }
            }, () => {
                console.log(this.state.initialData);
            });
        }
    }

    getFieldsValue() {
        return this.state.initialData;
    }
}