import { Component } from "react";
import { Card, Layout, Space, Table } from "antd";

import DetailPackages from "../shared/components/create-order/detail-package/detail-packages.container";
import TopBar from "../../components/topbar";
import orderDataService from "../../data-services/orders/orders-data.service";
import { Content } from "antd/lib/layout/layout";
import "./view-lost-order-details.screen.scss";
import { Link } from "react-router-dom";
import "./view-lost-order-details.screen.scss";
import languageService from "../../services/language.service";
import moment from "moment";

export class ViewLostOrderDetailsScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orderInfo: null,
      importStepInfo: null,
      exportStepInfo: null,
      importFromVehicleStepInfo: null,
    };
  }

  componentDidMount() {
    const { orderId } = this.props?.match?.params || {};
    if (orderId) {
      orderDataService.getLostOrderDetails(orderId).then((response) => {
        this.setState({
          orderInfo: response.orderInfo,
          importStepInfo: response.importStepInfo,
          exportStepInfo: response.exportStepInfo,
          importFromVehicleStepInfo: response.importFromVehicleStepInfo,
        });
      });
    }
  }

  getTableColumn() {
    const { t } = this.props;
    let columns = [
      {
        className: "w-60px",
        title: t("tableColumn.no"),
        dataIndex: "no",
        fixed: "left",
        align: "right",
      },
      {
        className: "w-100px",
        title: t("tableColumn.productCode"),
        dataIndex: "productCode",
        fixed: "left",
      },
      {
        className: "w-120px",
        title: t("tableColumn.productName"),
        dataIndex: "productName",
      },
      {
        className: "w-100px",
        title: t("tableColumn.productCategory"),
        dataIndex: "productCategory",
        render: (productCategory) => {
          return <>{t(productCategory)}</>
        }
      },
      {
        className: "w-100px",
        title: `${t("tableColumn.size")} (m)`,
        dataIndex: "sizeName",
      },
      {
        className: "w-120px",
        title: `${t("tableColumn.weight")} (kg)`,
        dataIndex: "weight",
        align: "center",
      },
      {
        className: "w-100px",
        title: t("tableColumn.amount"),
        dataIndex: "amount",
        render: (text, data) => (
          <span>
            {data.currentAmount}/{data.originalAmount}
          </span>
        ),
      },
      {
        title: t("tableColumn.reasons"),
        dataIndex: "reasons",
        render: (text, data) => (
          <span>
            {t(data.reasons)}
          </span>
        ),
      },
    ];
    return columns;
  }

  render() {
    const { t } = this.props;
    const {
      orderInfo,
      importStepInfo,
      exportStepInfo,
      importFromVehicleStepInfo,
    } = this.state;
    let columns = this.getTableColumn();
    return (
      <Layout className="bg-content">
        <div className="bg-body margin-bottom">
          <TopBar title={t("title.viewLostOrderDetails")} />
          <Content>
            <Card>
              <div className="row">
                <div className="col-md-3">
                  <span className="text-bold">
                    {t("text.orderId")}:{" "}
                    <Link to={`/view-order/${orderInfo?.orderId}`}>
                      {orderInfo?.orderCode}
                    </Link>
                  </span>
                </div>
                <div className="col-md-9">
                  <span className="text-bold">
                    {t("text.lostItems")}:{" "}
                    {orderInfo?.totalLostItemsFromAllSteps}
                  </span>
                </div>
              </div>
            </Card>
            {importStepInfo && importStepInfo?.lostProductList && (
              <Card>
                <div className="row margin-bottom">
                  <div className="col-md-3">
                    <span className="text-bold">
                      {t("tableColumn.importId")}:{" "}
                      <Link to={`/packages/view/${importStepInfo?.stepId}`}>
                        {importStepInfo?.stepCode}
                      </Link>
                    </span>
                  </div>

                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-md-3">
                        <span className="text-bold">
                          {t("text.lostItems")}:{" "}
                          {importStepInfo?.totalLostItemsInThisStep}
                        </span>
                      </div>

                      <div className="col-md-9">
                        {importStepInfo.confirmed === true ? (
                          <>
                            <span className="text-bold pull-left">
                              {moment
                                .utc(importStepInfo.createdDate)
                                .locale(languageService.getLang())
                                .format("hh:mm A, DD/MM/YYYY")}
                            </span>

                            <span className="pull-right">
                              <span className="text-bold">
                                {t("text.confirmed")}:{" "}
                              </span>
                              {importStepInfo.isOrderRefund != null ? (
                                importStepInfo.isOrderRefund === false ? (
                                  t("button.continueToShip")
                                ) : (
                                  t("button.returnToSender")
                                )
                              ) : (
                                <></>
                              )}
                            </span>
                          </>
                        ) : (
                          <span className="text-bold pull-right">
                            {moment
                              .utc(importStepInfo.createdDate)
                              .locale(languageService.getLang())
                              .format("hh:mm A, DD/MM/YYYY")}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <Table
                  bordered
                  columns={columns}
                  dataSource={importStepInfo?.lostProductList}
                  locale={{ emptyText: t("messages.noDataToDisplay") }}
                />
              </Card>
            )}

            {exportStepInfo && exportStepInfo?.lostProductList && (
              <Card>
                <div className="row margin-bottom">
                  <div className="col-md-3">
                    <span className="text-bold">
                      {t("tableColumn.exportId")}:{" "}
                      <Link to={`/view-export-list/${exportStepInfo?.stepId}`}>
                        {exportStepInfo?.stepCode}
                      </Link>
                    </span>
                  </div>

                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-md-3">
                        <span className="text-bold">
                          {t("text.lostItems")}:{" "}
                          {exportStepInfo?.totalLostItemsInThisStep}
                        </span>
                      </div>

                      <div className="col-md-9">
                        {exportStepInfo.confirmed === true ? (
                          <>
                            <span className="text-bold pull-left">
                              {moment
                                .utc(exportStepInfo.createdDate)
                                .locale(languageService.getLang())
                                .format("hh:mm A, DD/MM/YYYY")}
                            </span>

                            <span className="pull-right">
                              <span className="text-bold">
                                {t("text.confirmed")}:{" "}
                              </span>
                              {exportStepInfo.isOrderRefund != null ? (
                                exportStepInfo.isOrderRefund === false ? (
                                  t("button.continueToShip")
                                ) : (
                                  t("button.returnToSender")
                                )
                              ) : (
                                <></>
                              )}
                            </span>
                          </>
                        ) : (
                          <span className="text-bold pull-right">
                            {moment
                              .utc(exportStepInfo.createdDate)
                              .locale(languageService.getLang())
                              .format("hh:mm A, DD/MM/YYYY")}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <Table
                  bordered
                  columns={columns}
                  dataSource={exportStepInfo?.lostProductList}
                  locale={{ emptyText: t("messages.noDataToDisplay") }}
                />
              </Card>
            )}

            {importFromVehicleStepInfo &&
              importFromVehicleStepInfo?.lostProductList && (
                <Card>
                  <div className="row margin-bottom">
                    <div className="col-md-3">
                      <span className="text-bold">
                        {t("tableColumn.importFromVehicleId")}:{" "}
                        <Link
                          to={`/view-import-from-vehicle/${importFromVehicleStepInfo?.stepId}`}
                        >
                          {importFromVehicleStepInfo?.stepCode}
                        </Link>
                      </span>
                    </div>

                    <div className="col-md-9">
                      <div className="row">
                        <div className="col-md-3">
                          <span className="text-bold">
                            {t("text.lostItems")}:{" "}
                            {
                              importFromVehicleStepInfo?.totalLostItemsInThisStep
                            }
                          </span>
                        </div>

                        <div className="col-md-9">
                          {importFromVehicleStepInfo.confirmed === true ? (
                            <>
                              <span className="text-bold pull-left">
                                {moment
                                  .utc(importFromVehicleStepInfo.createdDate)
                                  .locale(languageService.getLang())
                                  .format("hh:mm A, DD/MM/YYYY")}
                              </span>

                              <span className="pull-right">
                                <span className="text-bold">
                                  {t("text.confirmed")}:{" "}
                                </span>
                                {importFromVehicleStepInfo.isOrderRefund !=
                                  null ? (
                                  importFromVehicleStepInfo.isOrderRefund ===
                                    false ? (
                                    t("button.continueToShip")
                                  ) : (
                                    t("button.returnToSender")
                                  )
                                ) : (
                                  <></>
                                )}
                              </span>
                            </>
                          ) : (
                            <span className="text-bold pull-right">
                              {moment
                                .utc(importFromVehicleStepInfo.createdDate)
                                .locale(languageService.getLang())
                                .format("hh:mm A, DD/MM/YYYY")}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <Table
                    bordered
                    columns={columns}
                    dataSource={importFromVehicleStepInfo?.lostProductList}
                    locale={{ emptyText: t("messages.noDataToDisplay") }}
                  />
                </Card>
              )}
          </Content>
        </div>
      </Layout>
    );
  }
}
