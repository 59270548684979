import { DataService } from "../base/base-data.service";

class RouteDataService extends DataService {
    apiUrl = '/route';

    async loadData() {
        return await this.get(`${this.apiUrl}/loadData`);
    }

    async getById(id) {
        return await this.get(`${this.apiUrl}/${id}`);
    }

    async insertData(data) {
        return await this.post(`${this.apiUrl}/insert`, data);
    }

    async updateData(data) {
        return await this.post(`${this.apiUrl}/update`, data);
    }

    async deleteData(data) {
        return await this.post(`${this.apiUrl}/delete`, data);
    }

    async loadStatus() {
        return await this.get(`${this.apiUrl}/status`);
    }

    async getRouteByDelivery(data) {
        return await this.post(`${this.apiUrl}/routes-by-delivery`, data);
    }
    
    async getRouteFromStation(fromStationId) {
        return await this.get(`${this.apiUrl}/get-routes-data-by-from-station-id?fromStationId=${fromStationId}`);
    }

    async getRouteFromStationToEditOrderExportList(fromStationId, orderExportListId) {
        return await this.get(`${this.apiUrl}/get-route-data-to-edit-export-package/${fromStationId}/${orderExportListId}`);
    }
}

const routeDataService = new RouteDataService();

export default routeDataService;