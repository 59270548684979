import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { getPickupListById } from '../../modules/orders/orders.actions';
import { ViewPickupListScreen } from './view-pickup-list.screen';

const mapStateToProps = (state) => {
  return {
    orders: state.order?.list,
    currentStationId: state.session?.auth?.user?.currentStationId,
    vehicles: state.order?.vehicles || [],
    pickupList: state.order?.pickupList || {}
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getPickupListById
  }, dispatch)
}

export default compose(
    withTranslation('translations'),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(ViewPickupListScreen)

