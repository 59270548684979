import { DataService } from "../base/base-data.service";

class ExportDataService extends DataService{
    apiUrl = '/export'

    async deleteExportById(exportListId) {
        return await this.delete(`${this.apiUrl}/delete/${exportListId}`);
    }
}

let exportDataService = new ExportDataService();

export default exportDataService;