
import React, { Component } from 'react';
import { Table, Checkbox, Select, Form, InputNumber, notification } from 'antd';
import './scan-packages.component.scss'
import { FORMAT_TIME_DATE } from '../../../common/constants';
import moment from 'moment';
import BarcodeReader from 'react-barcode-reader';

const Unit = 'Kg';

export default class ScanPackagesComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            initialData: props.initialData,
            productExportReason: props.productExportReason,

            calcSummazire: {
                totalOrders: 0,
                totalProducts: 0,
                totalItems: 0,
                totalWeight: 0,
                exportOrders: 0,
                exportProducts: 0,
                totalItemsInExportList: 0,
                totalWeightInExportList: 0,
                missingProduct: 0,
                missingOrder: 0
            }
        }

        this.barcode = '';
        this.formRef = React.createRef();
    }

    async componentDidMount() {
        const { orders } = this.state.initialData;
        this.prepareOrderItems(orders);
        this.calcSummarize();
    }

    render() {
        const { t } = this.props;
        const { initialData } = this.state;
        const { route, orders, vehicle } = initialData;
        var ordersExport = orders;
        return (
            <div className='mt-5'>
                <div className='col-12 col-sm-12 col-lg-12'>
                    <div className='d-flex'>
                        <div className='col-2 col-sm-2 col-lg-2'>
                            <lable className='font-weight-bold'>{t('text.selectedRoute')}</lable>
                            <p>{route?.routeName}</p>
                        </div>
                        <div className='col-2 col-sm-2 col-lg-2'>
                            <lable className='font-weight-bold'>{t('text.selectedVehicle')}</lable>
                            <p>{vehicle?.code}</p>
                        </div>
                        <div className='col-2 col-sm-2 col-lg-2'>
                            <lable className='font-weight-bold'>{t('text.vehicleType')}</lable>
                            <p>{vehicle?.type?.name}</p>
                        </div>
                    </div>

                    <div className='mt-5'>
                        <Form ref={this.formRef}>
                            {this.renderOrders(ordersExport)}
                        </Form>
                        {this.renderSummarize(initialData.orders)}

                    </div>
                </div>
                <BarcodeReader timeBeforeScanTest={1000} onReceive={this.onReceiveScan} />
            </div>
        )
    }

    prepareOrderItems = (orders) => {
        if (orders) {
            var perpared = orders.map((o) => {
                return {
                    ...o,
                    orderItems: [
                        ...o.orderItems?.map((i) => {
                            return {
                                ...i,
                                scanned: false,
                                amountScanned: 0,
                                scanReasonFailed: null,
                            }
                        }),
                    ]
                }
            });

            var { initialData } = this.state;
            this.setState({
                initialData: {
                    ...initialData,
                    orders: perpared
                }
            });
        }
    }

    renderOrders = (orders) => {
        const { t } = this.props;
        const { productExportReason } = this.state;
        if (orders) {
            return orders?.map((o, index) => {
                return (
                    <>
                        <div className='row mL30 header-table'>
                            <div className='col-md-3 title-parent'>
                                <span className='title'>{t('tableColumn.orderCode')}: </span>{o?.code}
                            </div>
                            <div className='col-md-3 title-parent'>
                                <span className='title'>{t('tableColumn.createdDate')}: </span>{moment.utc(o?.createdTime).format(FORMAT_TIME_DATE)}
                            </div>
                        </div>
                        <div className='list-order-export'>
                            <Table
                                columns={this.columns(o)}
                                dataSource={this.mappingToDataTable(o?.orderItems)}
                                pagination={false}
                                bordered
                            />
                        </div>
                        {
                            (index < orders.length) &&
                            <hr className='line' />
                        }
                    </>
                )
            });
        }
    }

    renderSummarize = (ordersExport) => {
        const { t } = this.props;
        var { calcSummazire } = this.state;
        if (ordersExport) {
            var { totalOrders, totalProducts, totalItems, totalWeight, exportOrders, exportProducts, totalItemsInExportList, totalWeightInExportList, missingProduct, missingOrder } = calcSummazire;
            return (
                <div className='d-flex ml-5'>
                    <div className='col-sm-4 col-lg-4'>
                        <div className='d-flex'>
                            <lable className='mr-4 col-sm-7'>{t('text.totalOrder')}:</lable>
                            <p className='col-sm-6'>{totalOrders}</p>
                        </div>
                        <div className='d-flex'>
                            <lable className='mr-4 col-sm-7'>{t('text.totalProduct')}:</lable>
                            <p className='col-sm-4'>{totalProducts}</p>
                        </div>
                        <div className='d-flex'>
                            <lable className='mr-4 col-sm-7'>{t('text.totalItems')}:</lable>
                            <p className='col-sm-4'>{totalItems}</p>
                        </div>
                        <div className='d-flex'>
                            <lable className='mr-4 col-sm-7'>{t('text.totalWeight')}:</lable>
                            <p className='col-sm-4'>{`${totalWeight} ${Unit}`}</p>
                        </div>
                    </div>

                    <div className='col-sm-4 col-lg-4'>
                        <div className='d-flex'>
                            <lable className='mr-3 col-sm-7'>{t('text.scannedProducts')}:</lable>
                            <p className='col-sm-4 exported'>{exportProducts}</p>
                        </div>
                    </div>

                    <div className='col-sm-4 col-lg-4'>
                        <div className='d-flex'>
                            <lable className='mr-3 col-sm-7'>{t('text.remainingProducts')}:</lable>
                            <p className='col-sm-4 missed'>{missingProduct}</p>
                        </div>
                    </div>
                </div>
            )
        }
    }

    calcSummarize = () => {
        var { orders } = this.state.initialData;
        var ordersExport = orders;

        var totalOrders = ordersExport?.length;
        var totalProducts = ordersExport.reduce((total, o) => o.orderItems ? total += o.orderItems.length : total, 0);
        var totalItems = ordersExport.reduce((total, o) => {
            var _total = 0;
            if (o.orderItems) {
                _total = o.orderItems?.reduce((result, i) => result += (i?.importedAmount), 0)
            }
            return total += _total
        }, 0);

        var totalWeight = ordersExport.reduce((total, o) => {
            var _total = 0;
            if (o.orderItems) {
                _total = o.orderItems?.reduce((result, i) => result += (i?.product?.weight * i?.importedAmount), 0)
            }
            return total += _total
        }, 0);

        var exportOrders = ordersExport.reduce((total, o) => {
            var _total = 0;
            if (o.orderItems) {
                _total = o.orderItems?.reduce((result, i) => i.exported == true ? ++result : 0, 0)
            }
            return total += _total > 0 ? 1 : 0;
        }, 0);

        var exportProducts = ordersExport.reduce((total, o) => {
            var _total = 0;
            if (o.orderItems) {
                _total = o.orderItems?.reduce((result, i) => i.scanned == true ? ++result : 0, 0)
            }

            return total += _total;
        }, 0);

        var totalItemsInExportList = ordersExport.reduce((total, o) => {
            var _total = 0;
            if (o.orderItems) {
                _total = o.orderItems?.reduce((result, i) => result += (i?.exportedAmount), 0)
            }
            return total += _total
        }, 0);

        var totalWeightInExportList = ordersExport.reduce((total, o) => {
            var _total = 0;
            if (o.orderItems) {
                _total = o.orderItems?.reduce((result, i) => result += (i?.product?.weight * i?.exportedAmount), 0)
            }
            return total += _total
        }, 0);

        var missingOrder = totalOrders - exportOrders;
        var missingProduct = totalProducts - exportProducts;

        this.setState({
            calcSummazire: {
                totalOrders: totalOrders,
                totalProducts: totalProducts,
                totalItems: totalItems,
                totalWeight: totalWeight,
                exportOrders: exportOrders,
                exportProducts: exportProducts,
                totalItemsInExportList: totalItemsInExportList,
                totalWeightInExportList: totalWeightInExportList,
                missingProduct: missingProduct,
                missingOrder: missingOrder
            }
        })
    }

    columns = () => {
        const { t } = this.props;
        var { productExportReason } = this.props;
        return [
            {
                title: t('tableColumn.no'),
                dataIndex: 'index',
            },
            {
                title: t('tableColumn.productCode'),
                dataIndex: 'productCode',
            },
            {
                title: t('tableColumn.productName'),
                dataIndex: 'productName',
            },
            {
                title: t('tableColumn.productCategory'),
                dataIndex: 'productCategory',
            },
            {
                title: t('tableColumn.assignedSpaces'),
                dataIndex: 'assignedSpaces',
                render: (spaces) => {
                    return <>
                        {
                            spaces?.map((s) => {
                                return (
                                    <div className='d-flex'>
                                        <p className='pr-3'>{s.area}</p>
                                        <p>
                                            {
                                                s.spaces.map((i) => {
                                                    return i.code
                                                }).join(', ')
                                            }
                                        </p>
                                    </div>
                                )
                            })
                        }
                    </>;
                }
            },
            {
                title: t('tableColumn.amount'),
                dataIndex: 'amount',
                render: (amount, record) => {
                    var notEnough = (record.amountScanned < record.amount) ? true : false;
                    var scanned = record.scanned;
                    return (
                        <>
                            {
                                !scanned &&
                                <span>{record.amount}</span>
                            }
                            {
                                scanned &&
                                <Form.Item
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: t('messages.amount'),
                                        }
                                    ]}>
                                    <InputNumber
                                        value={record.amountScanned}
                                        pattern='^\d+$'
                                        onKeyDown={e => (e.keyCode != 9 && e.keyCode !== 8 && (e.keyCode < 48 || e.keyCode > 57)) && e.preventDefault()}
                                        onKeyUp={e => this.onInput(e, record.amount)}
                                        onChange={(value) => this.updateOrderItem(record.productCode, null, value)}
                                        min={1}
                                        max={record.amount}
                                    />
                                </Form.Item>
                            }
                        </>
                    )
                }
            },
            {
                title: t('tableColumn.scanned'),
                dataIndex: 'scanned',
                render: (scanned, record) => {
                    return (
                        <Checkbox
                            checked={scanned}
                            disabled={true}
                        />
                    )
                }
            },
            {
                title: t('tableColumn.reason'),
                dataIndex: 'scanReasonFailed',
                render: (scanReasonFailed, record) => {
                    var scanned = record.scanned;
                    var notEnough = record.amountScanned < record.amount ? true : false;
                    return (
                        <>
                            {
                                scanned && notEnough &&
                                <Form.Item
                                    name={['orderItems', record.index, 'scanReasonFailed']}
                                    rules={[{
                                        required: true,
                                        message: t('messages.selectReason')
                                    }]}>

                                    <Select
                                        optionFilterProp='children'
                                        onChange={(value) => this.updateOrderItem(record.productCode, null, null, value)}
                                        placeholder={t('messages.selectReason')}>
                                        {
                                            productExportReason?.map((name, index) => (
                                                <Select.Option key={index} value={name}>{name}</Select.Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            }
                        </>
                    )
                }
            },
        ];
    }

    onInput = (e, maxValue) => {
        var value = e.target.value;
        if (value > maxValue) {
            return e.target.value = maxValue;
        }
        return e;
    }

    mappingToDataTable = (orderItems) => {
        return orderItems?.map((i, index) => {
            return {
                index: index + 1,
                productCode: i.product?.code,
                productName: i.product?.name,
                productCategory: i.product?.packageType?.name,
                assignedSpaces: this.assignedSpaceInfoFormatted(i.product?.spaceAreas),
                amount: i.importedAmount,
                amountScanned: i.amountScanned,
                scanned: i.scanned ?? false,
                scanReasonFailed: i.scanReasonFailed ?? null,
            }
        });
    }

    assignedSpaceInfoFormatted = (spaceAreas) => {
        let groupsByArea = spaceAreas.reduce((r, a) => {
            r[a.area.code] = [...r[a.area.code] || [], a];
            return r;
        }, {});
        var areas = Object.keys(groupsByArea);

        return areas.map((area) => {
            return {
                area: area,
                spaces: groupsByArea[area]
            }
        })
    }

    onReceiveScan = (event) => {
        if (event && event.charCode === 13) {
            this.barcodeDecoding(this.barcode);
            this.barcode = '';
        } else {
            this.barcode += event.key;
        }
    }

    barcodeDecoding = (scannedResult) => {
        var barcodeDecode = scannedResult.split(',');
        if (barcodeDecode && barcodeDecode.length === 3) {
            let orderCode = (barcodeDecode[0]).trim();
            let productCode = (barcodeDecode[1]).trim();
            let amount = parseInt((barcodeDecode[2]).trim());

            this.verifyProduct(productCode, amount, orderCode);
        }
    }

    verifyProduct = (productCode, amountScanned, orderCode) => {
        const { orders } = this.state.initialData;
        var order = orders?.find((o) => o.code === orderCode);
        if (order) {
            this.updateOrderItem(productCode, true, amountScanned);
        }
    }

    updateOrderItem(productCode, scanned, amountScanned, scanReasonFailed) {
        var { orders } = this.state.initialData;
        var order = orders?.find((o) => {
            var orderItems = o.orderItems;
            let index = orderItems?.findIndex(i => i.product?.code === productCode);
            if (index !== -1) {
                return o;
            }
        });

        let indexOrder = orders?.findIndex(o => o.id === order.id);
        var orderItems = order.orderItems;
        let index = orderItems?.findIndex(i => i.product?.code === productCode);
        if (index !== -1) {
            let orderItem = orderItems[index];
            if (scanned !== undefined && scanned !== null) {
                orderItem['scanned'] = scanned;
            }
            if (amountScanned !== undefined && amountScanned !== null) {
                orderItem['amountScanned'] = amountScanned;
            }
            if (scanReasonFailed !== undefined && scanReasonFailed !== null) {
                orderItem['scanReasonFailed'] = scanReasonFailed;
            }

            orderItems.splice(index, 1, orderItem);
            orders.splice(indexOrder, 1, order);

            this.setState({
                initialData: {
                    ...this.state.initialData,
                    orders: orders
                }
            }, () => {
                this.calcSummarize();
            });
        }
    }

    getFieldsValue = () => {
        var { initialData } = this.state;
        return initialData;
    }

    validateFields = () => {
        return this.formRef.current.validateFields();
    }
}
