import { compose } from 'redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { PickupOrderScreen } from './pickup-order.screen';
import { clearData, updateSelectedOrder } from '../../modules/orders/orders.actions'


const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    clearData,
    updateSelectedOrder
  }, dispatch)
}

const mapStateToProps = ({ order, session }) => {
  return {
    orders: order?.listOrderPickup,
    selectedOrders: order?.selectedOrders || [],
    currentStationId: session?.auth?.user?.currentStationId
  }
}

export default compose(
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(PickupOrderScreen)
