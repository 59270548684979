import { Table, Image } from 'antd';
import moment from 'moment';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';
import { getSpecialServiceIcon } from '../../../utils/order';
import { deliveryHouseMethods } from '../../../constants/order-constant';
import iconWareHouse from '../../../assets/icons/warehouse.png'

const unitWeight = 'Kg';

export class ReviewOrderPickupScreen extends Component {
    constructor(props) {
        super(props)
        this.onChange = this.onChange.bind(this);
        this.state = {
            searchText: '',
            searchedColumn: '',
            selectedRowKeys: [],
        }
    }

    componentDidMount() {
        if (this.props.currentStationId) {
            this.props.listOrders(this.props.currentStationId);
        }
    }

    getTableColumns(orders) {
        const { t } = this.props;
        let deliveries = orders?.map(d => { return { text: d.deliveryMethod, value: d.deliveryMethod } });
        let delivieryOptions = deliveries?.filter((d, i) => deliveries?.findIndex(o => o.value === d.value) === i);
        let packages = orders?.map(d => { return { text: d.packageInfo, value: d.packageInfo } });
        let packageInfoOptions = packages?.filter((d, i) => packages?.findIndex(o => o.value === d.value) === i);

        let columns = [
            {
                title: t('tableColumn.orderId'),
                dataIndex: 'orderId',
                sorter: (a, b) => a.orderId.localeCompare(b.orderId),
                render: (text, data) => <Link target='_blank' to={`/view-order/${data.id}`}>{text}</Link>
            },
            {
                title: t('tableColumn.pickupAddress'),
                dataIndex: 'from',
                render: text => <span dangerouslySetInnerHTML={{ __html: text }} />
            },
            {
                title: t('tableColumn.packageInfo'),
                dataIndex: 'packageInfo',
                sorter: (a, b) => a.packageInfo.localeCompare(b.packageInfo),
                render: (name) => <span>{name}{getSpecialServiceIcon(name)}</span>,
                filters: packageInfoOptions,
                onFilter: (value, record) => record.packageInfo.indexOf(value) === 0,
            },
            {
                title: t('tableColumn.deliveryMethod'),
                dataIndex: 'deliveryMethod',
                sorter: (a, b) => a.deliveryMethod.localeCompare(b.deliveryMethod),
                render: (text) => <span>{text}<br />{deliveryHouseMethods.includes(text?.toLowerCase()) && <Image src={iconWareHouse} width={30} />}</span>,
                filters: delivieryOptions,
                onFilter: (value, record) => record.deliveryMethod.indexOf(value) === 0,
            },
            {
                title: t('tableColumn.totalWeight'),
                dataIndex: 'totalWeight',
                sorter: (a, b) => a.totalWeight - b.totalWeight,
                render: text => <span>{text} {unitWeight}</span>
            },
            {
                title: t('tableColumn.totalProductsInPackage'),
                dataIndex: 'totalProductsInPackage',
                sorter: (a, b) => a.totalProductsInPackage - b.totalProductsInPackage,
            },
            {
                title: t('tableColumn.totalItemsInPackage'),
                dataIndex: 'totalItemsInPackage',
                sorter: (a, b) => a.totalItemsInPackage - b.totalItemsInPackage,
            },
            {
                title: t('tableColumn.createdDate'),
                dataIndex: 'createdDate',
                sorter: (a, b) => moment(a.createdDate) - moment(b.createdDate),
                render: text => <div dangerouslySetInnerHTML={{ __html: moment.utc(text).local().format('hh:mm A <br> DD/MM/YYYY') }} />
            }
        ];
        return columns;
    }

    getSenderInfo(order) {
        let formattedAddress = this.getFormattedAddress(order?.addressSender) || this.props.t('text.noAddress');
        let senderInfo = [order.senderName, order.senderPhone, formattedAddress];
        return senderInfo.join('<br/>');
    }

    getReceiverInfo(order) {
        let formattedAddress = this.getFormattedAddress(order?.addressRecipient) || this.props.t('text.noAddress');
        let senderInfo = [order.receiverName, order.receiverPhone, formattedAddress];
        return senderInfo.join('<br/>');
    }

    getFormattedAddress(address) {
        let arr = [];
        if (address && address.street) {
            arr.push(address.street);
        }
        if (address && address.ward) {
            let ward = address.ward.prefix + ' ' + address.ward.name;
            arr.push(ward);
        }
        if (address && address.district) {
            let district = address.district.prefix + ' ' + address.district.name;
            arr.push(district);
        }
        if (address && address.city) {
            arr.push(address.city.name);
        }
        return arr.join(', ');
    }

    onChange(pagination, filters, sorter, extra) {
        console.log('params', pagination, filters, sorter, extra);
    }

    transform = (order) => {
        var result = {
            key: order.id,
            id: order.id,
            orderId: order.code,
            from: this.getSenderInfo(order),
            deliveryMethod: order.delivery?.name,
            packageInfo: order.specialOption?.name,
            createdDate: order.createdTime,
            amount: order.amount,
            packageSizeWeight: order.packageSizeWeight,
            totalWeight: order.totalWeight,
            totalProductsInPackage: order.totalProductsInPackage,
            totalItemsInPackage: order.totalItemsInPackage,
        }

        if (order.status.subStatusName != null) {
            result.status = `${order.status.statusName}-${order.status.subStatusName}`
        }
        else {
            result.status = order.status.statusName;
        }

        return result
    }

    render() {
        const { t } = this.props;
        const { vehicleId } = this.props.data || {}
        const { vehicles } = this.props
        const selectedVheicle = vehicles.find((x) => x.id === vehicleId)
        let rows = this.props.selectedOrders?.map(o => this.transform(o)) || [];

        let columns = this.getTableColumns(rows);
        return <Content className='content'>
            <div className='list-pickup'>
                <div className='review-vehicle'>
                    <p><b>{t('text.selectedVehicle')}: </b>{selectedVheicle?.code || ''}</p>
                    <p><b>{t('text.vehicleType')}: </b>{t(selectedVheicle?.type?.name) || ''}</p>
                </div>
                <Table
                    bordered
                    columns={columns}
                    dataSource={rows}
                    onchange={this.onChange} />
            </div>
        </Content>
    }
}