import _ from 'lodash';
import moment from 'moment';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import TopBar from '../../components/topbar';
import { Content } from 'antd/lib/layout/layout';
import { getDateTime } from '../../utils/datetime';
import { Table, Button, Space, Layout } from 'antd';
import languageService from '../../services/language.service';

export default class ListOrderImportScreen extends Component {

    constructor(props) {
        super(props)
        this.state = {
            listImportOrder: []
        }
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        const { currentStationId, current, pageSize } = this.props
        if (currentStationId) {
            this.props.listImport(currentStationId, current, pageSize)
        }
    }

    onChange(pagination, filters, sorter, extra) {
        console.log('params', pagination, filters, sorter, extra);
    }

    getTableColumns() {
        const { t } = this.props;
        let columns = [
            {
                title: t('tableColumn.no'),
                dataIndex: 'key',
            },
            {
                title: t('tableColumn.importId'),
                dataIndex: 'importId',
                render: (text, data) => <Link className='text-primary text-underline' to={`/packages/view/${data.id}`}>{text}</Link>,
                sorter: (a, b) => a.importId.localeCompare(b.importId)
            },
            {
                title: t('tableColumn.orders'),
                dataIndex: 'orders',
                sorter: (a, b) => a.orders - b.orders
            },
            {
                title: t('tableColumn.products'),
                dataIndex: 'products',
                sorter: (a, b) => a.products - b.products
            },
            {
                title: t('tableColumn.items'),
                dataIndex: 'items',
                sorter: (a, b) => a.items - b.items
            },
            {
                title: t('tableColumn.status'),
                dataIndex: 'status',
                sorter: (a, b) => a.status - b.status
            },
            {
                title: t('tableColumn.createdDate'),
                dataIndex: 'createdDate',
                sorter: (a, b) => moment(a.createdDate) - moment(b.createdDate),
                render: text => <div dangerouslySetInnerHTML={{
                    __html: getDateTime(text, 'hh:mm A <br> DD/MM/YYYY')
                }} />
            },
            {
                title: t('tableColumn.action'),
                dataIndex: 'action',
                render: (text, record) => (
                    <Space>
                        <Button
                            type='link'
                            className='pl-0 pr-0 mr-2'
                            onClick={() => this.redirectTo(`packages/edit/${record?.id}`)}
                        ><i className='fa fa-pencil-square-o beecow-icon beecow-icon-edit-color' aria-hidden='true'></i></Button>

                        <Button style={{ display: 'none' }} type='link' className='pl-0 pr-0'><i className='fa fa-trash-o beecow-icon beecow-icon-delete-color' aria-hidden='true'></i></Button>
                    </Space>
                )

            }
        ];
        return columns;
    }

    transform = (list, index) => {
        const { t } = this.props;
        const product = list?.orders?.map(o => o.products.length)
        let totalItems = 0
        list?.orders?.forEach(o => {
            totalItems += o.products.map(x => x.importedAmount).reduce((a, b) => a + b, 0)
        })
        var result = {
            id: list.id,
            key: index + 1,
            importId: list.code,
            createdDate: list.createdTime,
            orders: list?.orders.length || 0,
            products: product || 0,
            items: totalItems || 0,
            status: t(list.status)
        }

        return result
    }

    render() {
        const { t } = this.props;
        let columns = this.getTableColumns();
        const dataSource = this.props.import?.map((x, i) => this.transform(x, i))
        const paging = {
            current: this.props.current,
            pageSize: this.props.pageSize,
            total: this.props.total,
            onChange: this.onPageChanged
        };
        return (
            <Layout className='bg-content'>
                <div className='bg-body'>
                    <TopBar title='List of Import' />
                    <div className='bg-white clearfix'>
                        <Content className='content'>
                            <div className='list-pickup'>
                                <Table
                                    columns={columns}
                                    bordered
                                    dataSource={dataSource}
                                    pagination={paging}
                                    onchange={this.onChange}
                                />
                            </div>
                        </Content>
                    </div>
                </div>
            </Layout>
        );

    }

    onPageChanged = (page, pageSize) => {
        const { currentStationId } = this.props;
        this.props.listImport(currentStationId, page, pageSize);
    }

    redirectTo = (url) => {
        this.props.history.push(url);
    }
}