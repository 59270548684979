
import React, { Component } from 'react';
import { Table, Checkbox, Select, Form, InputNumber, notification } from 'antd';
import './summazize-export-list.component.scss'
import { FORMAT_TIME_DATE } from '../../../common/constants';
import moment from 'moment';
import BarcodeReader from 'react-barcode-reader';

const Unit = 'Kg';

export default class SummazizeExportListComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            scanMode: true,
            summarizeMode: false,
            initialData: props.initialData,
            productExportReason: props.productExportReason,
            calcSummazire: {
                totalOrders: 0,
                totalProducts: 0,
                totalItems: 0,
                totalWeight: 0,
                exportOrders: 0,
                exportProducts: 0,
                totalItemsInExportList: 0,
                totalWeightInExportList: 0,
                missingProduct: 0,
                missingOrder: 0
            }
        }

        this.barcode = '';
    }

    componentDidMount() {
        this.calcSummarize();

    }

    render() {
        const { t } = this.props;
        const { initialData } = this.state;
        const { route, orders, vehicle } = initialData;
        var ordersExport = orders;
        return (
            <div className='mt-5'>
                <div className='col-12 col-sm-12 col-lg-12'>
                    <div className='d-flex'>
                        <div className='col-2 col-sm-2 col-lg-2'>
                            <lable className='font-weight-bold'>{t('text.selectedRoute')}</lable>
                            <p>{route?.routeName}</p>
                        </div>
                        <div className='col-2 col-sm-2 col-lg-2'>
                            <lable className='font-weight-bold'>{t('text.selectedVehicle')}</lable>
                            <p>{vehicle?.code}</p>
                        </div>
                        <div className='col-2 col-sm-2 col-lg-2'>
                            <lable className='font-weight-bold'>{t('text.vehicleType')}</lable>
                            <p>{t(vehicle?.type?.name)}</p>
                        </div>
                    </div>

                    <div className='mt-5'>
                        {this.renderOrders(ordersExport)}
                        {this.renderSummarize(initialData.orders)}

                    </div>
                </div>
                <BarcodeReader timeBeforeScanTest={1000} onReceive={this.onReceiveScan} />
            </div>
        )
    }

    renderOrders = (orders) => {
        const { t } = this.props;
        if (orders) {
            return orders?.map((o, index) => {
                return (
                    <>
                        <div className='row mL30 header-table'>
                            <div className='col-md-3 title-parent'>
                                <span className='title'>{t('tableColumn.orderCode')}: </span>{o?.code}
                            </div>
                            <div className='col-md-3 title-parent'>
                                <span className='title'>{t('tableColumn.createdDate')}: </span>{moment.utc(o?.createdTime).format(FORMAT_TIME_DATE)}
                            </div>
                        </div>
                        <div className='list-order-export'>
                            <Table
                                columns={this.columns(o)}
                                dataSource={this.mappingToDataTable(o?.orderItems)}
                                pagination={false}
                                bordered
                            />
                        </div>
                        {
                            (index < orders.length) &&
                            <hr className='line' />
                        }
                    </>
                )
            });
        }
    }

    renderSummarize = (ordersExport) => {
        const { t } = this.props;
        var { calcSummazire } = this.state;
        if (ordersExport) {
            var { totalOrders, totalProducts, totalItems, totalWeight, exportOrders, exportProducts, totalItemsInExportList, totalWeightInExportList, missingProduct, missingOrder } = calcSummazire;
            return (
                <div className='d-flex ml-5'>
                    <div className='col-sm-4 col-lg-4'>
                        <div className='d-flex'>
                            <lable className='mr-4 col-sm-7'>{t('text.totalOrder')}:</lable>
                            <p className='col-sm-6'>{totalOrders}</p>
                        </div>
                        <div className='d-flex'>
                            <lable className='mr-4 col-sm-7'>{t('text.totalProduct')}:</lable>
                            <p className='col-sm-4'>{totalProducts}</p>
                        </div>
                        <div className='d-flex'>
                            <lable className='mr-4 col-sm-7'>{t('text.totalItems')}:</lable>
                            <p className='col-sm-4'>{totalItems}</p>
                        </div>
                        <div className='d-flex'>
                            <lable className='mr-4 col-sm-7'>{t('text.totalWeight')}:</lable>
                            <p className='col-sm-4'>{`${totalWeight} ${Unit}`}</p>
                        </div>
                    </div>

                    <div className='col-sm-4 col-lg-4'>
                        <div className='d-flex'>
                            <lable className='mr-3 col-sm-7'>{t('text.exportProduct')}:</lable>
                            <p className='col-sm-4 exported'>{exportProducts}</p>
                        </div>
                    </div>

                    <div className='col-sm-4 col-lg-4'>
                        <div className='d-flex'>
                            <lable className='mr-3 col-sm-7'>{t('text.missingProduct')}:</lable>
                            <p className='col-sm-4 missed'>{missingProduct}</p>
                        </div>
                    </div>
                </div>
            )
        }
    }

    calcSummarize = () => {
        var { orders } = this.state.initialData;
        var ordersExport = orders;

        var totalOrders = ordersExport?.length;
        var totalProducts = ordersExport.reduce((total, o) => o.orderItems ? total += o.orderItems.length : total, 0);
        var totalItems = ordersExport.reduce((total, o) => {
            var _total = 0;
            if (o.orderItems) {
                _total = o.orderItems?.reduce((result, i) => result += (i?.importedAmount), 0)
            }
            return total += _total
        }, 0);

        var totalWeight = ordersExport.reduce((total, o) => {
            var _total = 0;
            if (o.orderItems) {
                _total = o.orderItems?.reduce((result, i) => result += (i?.product?.weight * i?.importedAmount), 0)
            }
            return total += _total
        }, 0);

        var exportOrders = ordersExport.reduce((total, o) => {
            var _total = 0;
            if (o.orderItems) {
                var scannedItems = o.orderItems.filter(i => i.exported === true);
                _total = scannedItems.length;
            }
            return total += _total > 0 ? 1 : 0;
        }, 0);

        var exportProducts = ordersExport.reduce((total, o) => {
            var _total = 0;
            if (o.orderItems) {
                var scannedItems = o.orderItems.filter(i => i.scanned === true);
                _total = scannedItems.length;
            }

            return total += _total;
        }, 0);

        var totalItemsInExportList = ordersExport.reduce((total, o) => {
            var _total = 0;
            if (o.orderItems) {
                _total = o.orderItems?.reduce((result, i) => result += (i?.exportedAmount), 0)
            }
            return total += _total
        }, 0);

        var totalWeightInExportList = ordersExport.reduce((total, o) => {
            var _total = 0;
            if (o.orderItems) {
                _total = o.orderItems?.reduce((result, i) => result += (i?.product?.weight * i?.exportedAmount), 0)
            }
            return total += _total
        }, 0);

        var missingOrder = totalOrders - exportOrders;
        var missingProduct = totalProducts - exportProducts;

        this.setState({
            calcSummazire: {
                totalOrders: totalOrders,
                totalProducts: totalProducts,
                totalItems: totalItems,
                totalWeight: totalWeight,
                exportOrders: exportOrders,
                exportProducts: exportProducts,
                totalItemsInExportList: totalItemsInExportList,
                totalWeightInExportList: totalWeightInExportList,
                missingProduct: missingProduct,
                missingOrder: missingOrder
            }
        })
    }

    columns = () => {
        const { t } = this.props;
        return [
            {
                title: t('tableColumn.no'),
                dataIndex: 'index',
            },
            {
                title: t('tableColumn.productCode'),
                dataIndex: 'productCode',
            },
            {
                title: t('tableColumn.productName'),
                dataIndex: 'productName',
            },
            {
                title: t('tableColumn.productCategory'),
                dataIndex: 'productCategory',
                render: (productCategory, data) => {
                    return <>{t(productCategory)}</>
                }
            },
            {
                title: t('tableColumn.assignedSpaces'),
                dataIndex: 'assignedSpaces',
                render: (spaces) => {
                    return <>
                        {
                            spaces?.map((s) => {
                                return (
                                    <div className='d-flex'>
                                        <p className='pr-3'>{s.area}</p>
                                        <p>
                                            {
                                                s.spaces.map((i) => {
                                                    return i.code
                                                }).join(', ')
                                            }
                                        </p>
                                    </div>
                                )
                            })
                        }
                    </>;
                }
            },
            {
                title: t('tableColumn.amount'),
                dataIndex: 'amount',
                render: (amount, record) => {
                    return (
                        <>
                            <span className='m-auto'>{`${record.amountScanned}/${record.amount}`}</span>
                        </>
                    )
                }
            },
            {
                title: t('tableColumn.scanned'),
                dataIndex: 'scanned',
                render: (scanned, record) => {
                    return (
                        <Checkbox
                            checked={scanned}
                            disabled={true}
                        />
                    )
                }
            },
            {
                title: t('tableColumn.reason'),
                dataIndex: 'scanReasonFailed',
                render: (scanReasonFailed, record) => {
                    return (
                        <span className='m-auto'>{record.scanReasonFailed}</span>
                    )
                }
            },
        ];
    }

    mappingToDataTable = (orderItems) => {
        return orderItems?.map((i, index) => {
            return {
                index: index + 1,
                productCode: i.product?.code,
                productName: i.product?.name,
                productCategory: i.product?.packageType?.languageKey,
                assignedSpaces: this.assignedSpaceInfoFormatted(i.product?.spaceAreas),
                amount: i.importedAmount,
                amountScanned: i.amountScanned,
                scanned: i.scanned ?? false,
                scanReasonFailed: i.scanReasonFailed ?? null,
            }
        });
    }

    assignedSpaceInfoFormatted = (spaceAreas) => {
        let groupsByArea = spaceAreas.reduce((r, a) => {
            r[a.area.code] = [...r[a.area.code] || [], a];
            return r;
        }, {});
        var areas = Object.keys(groupsByArea);

        return areas.map((area) => {
            return {
                area: area,
                spaces: groupsByArea[area]
            }
        })
    }

    getFieldsValue = () => {
        var { initialData } = this.state;
        return initialData;
    }

    refresh = () => {
    }

    isFailAll = (orderItems) => {
        var anyScanned = orderItems?.find((i) => i.scanned == true);
        if (anyScanned) {
            return false;
        }

        return true;
    }
}
