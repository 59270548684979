
export const DeliveryConstants = {

    DELIVERY_STAFF: 0,

    CREATE_ORDER: 1,

    PLACE_ORDER_FROM_WAREHOUSE: 2,

    TO_STATION: "to station"
}