import { Table, Button, Layout, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';
import _ from 'lodash';
import TopBar from '../../components/topbar';
import orderDataService from '../../data-services/orders/orders-data.service';
import { deliveryStatus } from './../../constants/order-constant';
import { showAlert } from '../../common/constants';
import deliveryDataService from '../../data-services/deliveries/delivery-data.service';
import languageService from '../../services/language.service';

export default class DeliveryListScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pagination: {
                current: 1,
                pageSize: 20,
                total: 0,
            },
            deliveryList: [],
        };
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.InitDataTable();
    };

    InitDataTable() {
        if (this.props.currentStationId) {
            this.loadData(this.state.pagination);
        }
    };

    onChange(pagination, filters, sorter, extra) {
        this.loadData(pagination, sorter, filters);
    }

    loadData = (pagination, sorter = null, filters = null) => {
        let sortOrder = 'desc';
        let sortField = 'CreatedDate';
        if (sorter) {
            sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';
            sortField = sorter?.field || 'CreatedDate';
        }

        let formData = {
            stationId: this.props.currentStationId,
            currentPageNumber: pagination.current,
            totalNumberOfItemsOnPage: pagination.pageSize,
            SortField: sortField,
            SortOrder: sortOrder,
        };

        orderDataService.getDeliveryList(formData).then((response) => {
            let itemList = response?.orderDeliveryItems?.map((anItem) =>
                this.transform(anItem)
            );
            this.setState({
                pagination: {
                    ...pagination,
                    total: response.totalOrderDeliveries,
                },
                deliveryList: itemList,
            });
        });
    };

    getTableColumns(list) {
        const { t } = this.props;
        let vehicles = list?.map((d) => {
            return { text: d.vehicleName, value: d.vehicleName };
        });
        let vehicleOptions = vehicles?.filter(
            (d, i) => vehicles?.findIndex((o) => o.value === d.value) === i
        );
        let status = list?.map((d) => {
            return { text: t(d.orderDeliveryStatus), value: t(d.orderDeliveryStatus) };
        });
        let statusOptions = status?.filter(
            (d, i) => status?.findIndex((o) => o.value === d.value) === i
        );

        let columns = [
            {
                title: t('tableColumn.no'),
                dataIndex: 'no',
            },
            {
                title: t('tableColumn.deliveryId'),
                dataIndex: 'orderDeliveryCode',
                render: (text, data) => (
                    <Link
                        target='_blank'
                        to={`/view-delivery-list-detail/${data.orderDeliveryId}`}
                    >
                        {text}
                    </Link>
                ),
                sorter: (a, b) =>
                    a.orderDeliveryCode.length - b.orderDeliveryCode.length,
            },
            {
                title: t('tableColumn.selectedVehicle'),
                dataIndex: 'vehicleName',
                sorter: (a, b) => a.vehicleName.length - b.vehicleName.length,
                filters: vehicleOptions,
                onFilter: (value, record) => record.vehicleName.indexOf(value) === 0,
            },
            {
                title: t('tableColumn.orders'),
                dataIndex: 'totalOrdersInOrderDeliveryItem',
            },
            {
                title: t('tableColumn.products'),
                dataIndex: 'totalProductsInOrderDeliveryItem',
            },
            {
                title: t('tableColumn.items'),
                dataIndex: 'totalProductItemsInOrderDeliveryItem',
            },
            {
                title: t('tableColumn.status'),
                dataIndex: 'orderDeliveryStatus',
                sorter: (a, b) =>
                    a.orderDeliveryStatus.length - b.orderDeliveryStatus.length,
                filters: statusOptions,
                onFilter: (value, record) =>
                    record.orderDeliveryStatus.indexOf(value) === 0,
            },
            {
                title: t('tableColumn.createdDate'),
                dataIndex: 'createdDate',
                render: (text) => (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: moment
                                .utc(text)
                                .locale(languageService.getLang())
                                .format('hh:mm A <br> DD/MM/YYYY'),
                        }}
                    />
                ),
                sorter: (a, b) => a.createdDate.length - b.createdDate.length,
            },
            {
                title: t('tableColumn.action'),
                dataIndex: 'action',
                render: (text, record) => {
                    if (record.statusId === deliveryStatus.new) {
                        return <>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div className='text-center' style={{ marginRight: 5 }}>
                                    <Link
                                        to={`/edit-delivery-list/${record?.orderDeliveryId}`}
                                        className='ant-btn ant-btn-primary'
                                    >
                                        {t('button.edit')}
                                    </Link>
                                </div>
                                <div className='text-center' style={{ marginRight: 5 }}>
                                    <Button
                                        onClick={() => this.clickButtonDelete(record?.orderDeliveryId)}
                                        className='ant-btn ant-btn-primary ant-btn-dangerous'
                                    >
                                        {t('button.delete')}
                                    </Button>
                                </div>
                            </div>
                        </>
                    }
                },
            },
        ];
        return columns;
    };

    clickButtonDelete(deliveryId) {
        const currentComponent = this;
        const { t } = this.props;
        Modal.confirm({
            title: `${t('confirm.removeOrder')}`,
            icon: <ExclamationCircleOutlined />,
            onOk() {
                currentComponent.handleDeleteDelivery(deliveryId);
            },
            okType: 'danger',
            okText: `${t('button.ok')}`,
            cancelText: `${t('button.cancel')}`,
        });
    };

    handleDeleteDelivery = (deliveryId) => {
        const { t } = this.props;
        let message = '';
        let description = '';
        deliveryDataService.deleteDeliveryById(deliveryId).then((res) => {
            if (res) {
                message = t('confirm.success');
                description = t('confirm.deleteSuccess');
                showAlert(message, description, true);
                this.InitDataTable();
            } else {
                message = t('confirm.warning');
                description = t('confirm.deleteFail');
                showAlert(message, description, false);
            }
        });
    };

    transform = (anItem) => {
        const { t } = this.props;
        var result = {
            ...anItem,
            vehicleName: `${anItem.vehicleName} (${t(anItem.vehicleType)})`,
            orderDeliveryStatus: t(anItem.orderDeliveryStatus),
            key: `delivery-list-${anItem.no}`,
        };

        return result;
    };

    render() {
        const { deliveryList } = this.state;
        let columns = this.getTableColumns(deliveryList);

        return (
            <Layout className='bg-content'>
                <div className='bg-body'>
                    <TopBar title={this.props.t('title.listOfDelivery')} />
                    <div className='bg-white clearfix'>
                        <Content className='content'>
                            <div className='list-pickup'>
                                <Table
                                    bordered
                                    columns={columns}
                                    dataSource={deliveryList}
                                    pagination={this.state.pagination}
                                    onChange={this.onChange}
                                    locale={{
                                        emptyText: <span>No data to display!</span>,
                                    }}
                                />
                            </div>
                        </Content>
                    </div>
                </div>
            </Layout>
        );
    }
}
