import { Col, Input, Row, Select } from 'antd'
import { Form } from 'antd';
import { Option } from 'antd/lib/mentions'
import React, { Component } from 'react'

export class SelectAdress extends Component {
  constructor(props) {
    super(props)
    this.onChangeCity = this.onChangeCity.bind(this)
    this.onChangeDistrict = this.onChangeDistrict.bind(this)
  }

  onChangeCity(id) {
    this.props.onChangeCity(id)
  }

  onChangeDistrict(id) {
    this.props.onChangeDistrict(id)
  }

  isSender = () => this.props.fields?.city === 'senderCity'

    render() {
        const { t } = this.props;
    const { address, cities, districts, wards } = this.props
    return (
      <div className='select'>
        <Form.Item name={[address, 'id']} hidden />
        <div className='input-icon'>
          <i className='fa fa-map-marker' aria-hidden='true'></i>
                <Form.Item name={[address, 'street']} rules={[{
                    required: true,
                    message: t('messages.pleaseInputAddress')
                }]}>
                    <Input placeholder={t('placeholder.inputAddress')} />
          </Form.Item>
        </div>

        <Row>
          <Col span={11}>
                    <Form.Item name={[address, 'cityId']}
                        rules={[{
                            required: true,
                            message: t('messages.city')
                        }]}>
              <Select
                showSearch
                placeholder={t('placeholder.city')}
                optionFilterProp='children'
                onChange={this.onChangeCity}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onSearch={this.onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {
                  cities?.map(({ id, name }) => <Option value={id} key={id}>{name}</Option>)
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={11} offset={2}>
                    <Form.Item name={[address, 'districtId']} rules={[{
                        required: true,
                        message: t('messages.district')
                    }]}>
              <Select
                showSearch
                placeholder={t('placeholder.district')}
                optionFilterProp='children'
                onChange={this.onChangeDistrict}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onSearch={this.onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {
                  districts?.map(({ id, name, prefix }) => <Option value={id} key={id}>{`${prefix} ${name}`}</Option>)
                }
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={11}>
                    <Form.Item name={[address, 'wardId']}
                        rules={[{
                            required: true,
                            message: t('messages.ward')
                        }]}>
              <Select
                showSearch
                placeholder={t('placeholder.ward')}
                optionFilterProp='children'
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onSearch={this.onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {
                  wards?.map(({ id, name, prefix }) => <Option value={id} key={id}>{`${prefix} ${name}`}</Option>)
                }
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </div>
    )
  }
}
