import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchListOrders } from '../../../modules/orders/orders.actions';
import { ReviewOrderPickupScreen } from './review-orders.screen';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
  return {
      orders: state.order?.listOrderPickup,
      currentStationId: state.session?.auth?.user?.currentStationId,
      vehicles: state.order?.vehicles || [],
      selectedOrders: state.order?.selectedOrders
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
      listOrders: (stationId) => dispatch(fetchListOrders(stationId))
  }
}

export default compose(
    withTranslation('translations'),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(ReviewOrderPickupScreen)

