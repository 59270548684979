import React from 'react';
import NotFoundScreen from '../screens/shared/not-found';
import Login from '../screens/shared/login';
import DashboardScreen from '../screens/dashboard';
import HomeCreateOrderScreen from '../screens/create-order';
import HomeEditOrderScreen from '../screens/edit-order';
import ListOrdersScreen from '../screens/list-orders';
import ViewOrderHistoryContainer from '../screens/view-order-history';
import ProfileScreen from '../screens/profile';
import ResetPasssWord from '../screens/resetpassword/reset-password.container';
import PickupOrderContainer from '../screens/pickup-order/pickup-order.container'
import ViewPickupListContainer from '../screens/view-pickup-list/view-pickup-list.container';
import ImportPackagesScreen from '../screens/import-packages';
import ViewImportListScreen from '../screens/view-import-list';
import EditImportListScreen from '../screens/view-import-list/edit-import-list';
import ExportPackagesScreen from '../screens/export-packages';
import EditExportPackagesScreen from '../screens/edit-export-packages';
import ViewOrderDetailScreen from '../screens/view-detail-order';
import ListProductComponent from '../components/list-product';
import ViewExportListDetailScreen from '../screens/export-packages/view-export-list-detail';
import ImportFromVehicleContainer from '../screens/import-from-vehicle';
import ViewImportFromVehicleDetailContainer from '../screens/import-from-vehicle/view-detail';
import EditImportFromVehicleContainer from '../screens/import-from-vehicle/edit-import-vehicle';
import ResetPasswordByEmail from '../screens/reset-password-by-email';

// Added on: 2021-10-06
import DeliverOrderContainer from '../screens/deliver-order/deliver-order.container';
import ViewDeliveryListDetailContainer from '../screens/view-delivery-list-detail';
import ListOrderImportScreen from '../screens/list-order-import';
import ListOrderExportScreen from '../screens/list-order-export';
import ListPickupsContainer from '../screens/list-pickups';

// Added on: 2021-10-21
import DeliveryListContainer from '../screens/delivery-list';
import ListImportFromVehicleContainer from '../screens/list-import-from-vehicle';

import EditOrderPickupContainer from '../screens/pickup-order/edit/pickup.container';

// Added on: 2021-11-15
import EditDeliverOrderContainer from '../screens/edit-deliver-order';

// Added on: 2021-12-06
import LostOrderListContainer from '../screens/lost-order-list';

// Added on: 2021-12-08
import ViewLostOrderDetailsContainer from '../screens/view-lost-order-details';

const routes = [
    {
        key: 'app.login',
        path: '/login/:userId',
        isMenu: false,
        auth: false,
        exact: true,
        main: () => <Login />
    },
    {
        key: 'app.login',
        path: '/',
        isMenu: false,
        auth: false,
        exact: true,
        main: () => <Login />
    },
    {
        key: 'app.login',
        path: '/login',
        isMenu: false,
        auth: false,
        exact: true,
        main: () => <Login />
    },
    {
        key: 'app.reset.password.by.email',
        path: '/forgot-pasword/:refreshTokenKey',
        isMenu: false,
        auth: true,
        exact: true,
        main: () => <ResetPasswordByEmail />
    },
    {
        key: 'app.dashboard',
        path: '/dashboard',
        icon: 'fa fa-tachometer',
        name: 'menu.dashboard',
        isMenu: true,
        auth: true,
        exact: false,
        main: () => <DashboardScreen />
    },
    {
        key: 'app.dashboard.02',
        path: '/',
        isMenu: false,
        auth: true,
        exact: true,
        main: () => <DashboardScreen />
    },

    // {
    //     key: 'app.stations',
    //     path: '#',
    //     icon: 'fa fa-database',
    //     name: 'Warehouse Management',
    //     isMenu: true,
    //     abstract: true,
    //     auth: true,
    //     exact : false,
    //     main: () => <NotFoundScreen />
    // },
    {
        key: 'app.order',
        path: '#',
        icon: 'fa fa-shopping-cart',
        name: 'menu.order',
        isMenu: true,
        abstract: true,
        auth: true,
        exact: false,
        main: () => <NotFoundScreen />
    },
    {
        key: 'app.orders.new',
        path: '/create-order',
        icon: 'fa fa-plus-square',
        name: 'menu.createOrder',
        isMenu: true,
        parent: 'app.order',
        auth: true,
        exact: false,
        main: () => <HomeCreateOrderScreen />
    },
    {
        key: 'app.orders.editOrder',
        path: '/edit-order/:id',
        icon: 'fa fa-plus-square',
        name: 'menu.editOrder',
        isMenu: false,
        // parent: 'app.order',
        auth: true,
        exact: false,
        main: () => <HomeEditOrderScreen />
    },
    {
        key: 'app.orders.list',
        path: '/list-orders',
        icon: 'fa fa-list',
        name: 'menu.listOrder',
        isMenu: true,
        parent: 'app.order',
        auth: true,
        exact: false,
        main: () => <ListOrdersScreen />
    },
    {
        key: 'app.pickup',
        path: '#',
        icon: 'fa fa-motorcycle',
        name: 'menu.pickup',
        isMenu: true,
        abstract: true,
        auth: true,
        exact : false,
        main: () => <NotFoundScreen />
    },
    {
        key: 'app.pickup.new',
        path: '/create-orders-pickup',
        icon: 'fa fa-plus-square',
        name: 'menu.createPickupList',
        isMenu: true,
        parent: 'app.pickup',
        auth: true,
        exact: false,
        main: () => <PickupOrderContainer />
    },
    {
        key: 'app.pickup.edit',
        path: '/orders-pickup/:id/edit',
        isMenu: false,
        auth: true,
        exact: false,
        main: () => <EditOrderPickupContainer />
    },
    {
        key: 'app.pickup.list',
        path: '/list-pickup',
        icon: 'fa fa-list',
        name: 'menu.listPickup',
        isMenu: true,
        parent: 'app.pickup',
        auth: true,
        exact: false,
        main: () => <ListPickupsContainer />
    },
    {
        key: 'app.pickup.detail',
        path: '/view-pickup-list/:id',
        isMenu: false,
        auth: true,
        exact: false,
        main: () => <ViewPickupListContainer />
    },
    {
        key: 'app.packages.import',
        path: '#',
        icon: 'fa fa-download',
        name: 'menu.import',
        isMenu: true,
        abstract: true,
        auth: true,
        exact : false,
        main: () => <NotFoundScreen />
    },
    {
        key: 'app.packages.import.new',
        path: '/packages/import',
        icon: 'fa fa-plus-square',
        name: 'menu.createImportWarehouse',
        isMenu: true,
        parent: 'app.packages.import',
        auth: true,
        exact: true,
        main: () => <ImportPackagesScreen />
    },
    {
        key: 'app.packages.import.list',
        path: '/list-order-import',
        icon: 'fa fa-list',
        name: 'menu.listImport',
        isMenu: true,
        parent: 'app.packages.import',
        auth: true,
        exact: false,
        main: () => <ListOrderImportScreen />
    },
    {
        key: 'app.packages.import.detail',
        path: '/packages/view/:id',
        icon: 'fa fa-list',
        name: 'menu.importDetail',
        isMenu: false,
        auth: true,
        exact: true,
        main: () => <ViewImportListScreen />
    },
    {
        key: 'app.packages.export',
        path: '#',
        icon: 'fa fa-upload',
        name: 'menu.export',
        isMenu: true,
        abstract: true,
        auth: true,
        exact : false,
        main: () => <NotFoundScreen />
    },
    {
        key: 'app.packages.export.new',
        key: 'app.packages.view',
        path: '/packages/edit/:id',
        name: 'menu.modifyImportList',
        auth: true,
        exact: true,
        main: () => <EditImportListScreen />
    },
    {
        key: 'app.exportList.edit',
        path: '/edit-export-packages/:id',
        icon: 'fa fa-plus-square',
        name: 'menu.editExportList',
        isMenu: false,
        auth: true,
        exact: true,
        main: () => <EditExportPackagesScreen />
    },
    {
        key: 'app.exportlist',
        path: '/export-packages',
        icon: 'fa fa-plus-square',
        name: 'menu.createExportList',
        isMenu: true,
        parent: 'app.packages.export',
        auth: true,
        exact: false,
        main: () => <ExportPackagesScreen />
    },
    {
        key: 'app.packages.export.detail',
        path: '/view-export-list/:id',
        name: 'menu.exportPackagesDetail',
        isMenu: false,
        auth: true,
        exact: true,
        main: () => <ViewExportListDetailScreen />
    },
    {
        key: 'app.packages.import2station',
        path: '#',
        icon: 'fa fa-database',
        name: 'menu.importFromVehicle',
        isMenu: true,
        abstract: true,
        auth: true,
        exact : false,
        main: () => <NotFoundScreen />
    },
    {
        key: 'app.packages.import2station.detail',
        path: '/view-import-from-vehicle/:id',
        name: 'menu.viewImportListFromVehicle',
        isMenu: false,
        auth: true,
        exact: false,
        main: () => <ViewImportFromVehicleDetailContainer />
    },
    {
        key: 'app.packages.import2station.detail',
        path: '/edit-import-from-vehicle/:id',
        name: 'Modify import list from vehicle',
        isMenu: false,
        auth: true,
        exact: false,
        main: () => <EditImportFromVehicleContainer />
    },
    {
        key: 'app.packages.import2station.new',
        path: '/import-from-vehicle',
        icon: 'fa fa-plus-square',
        name: 'menu.importFromVehicleToWarehouse',
        isMenu: true,
        parent: 'app.packages.import2station',
        auth: true,
        exact: false,
        main: () => <ImportFromVehicleContainer />
    },
    {
        key: 'app.packages.import2station.list',
        path: '/list-import-from-vehicle',
        icon: 'fa fa-list',
        name: 'menu.listImportFromVehicle',
        isMenu: true,
        parent: 'app.packages.import2station',
        auth: true,
        exact: false,
        main: () => <ListImportFromVehicleContainer />
    },
    {
        key: 'app.users.detail',
        path: '/profile/:userId',
        isMenu: false,
        auth: true,
        exact: false,
        main: () => <ProfileScreen />
    },
    {
        path: '/reset-password/:userId',
        icon: '',
        name: 'menu.resetPassword',
        isMenu: false,
        auth: true,
        exact: false,
        main: () => <ResetPasssWord />
    },
    {
        key: 'app.view.detail.order',
        path: '/view-order/:orderId',
        isMenu: false,
        auth: true,
        exact: true,
        main: () => <ViewOrderDetailScreen />
    },
    {
        key: 'app.list.products',
        path: '/print-products/:orderId',
        isMenu: false,
        auth: true,
        exact: true,
        main: () => <ListProductComponent />
    },
    {
        key: 'app.deliveryOrders',
        path: '#',
        icon: 'fa fa-truck',
        name: 'menu.delivery',
        isMenu: true,
        abstract: true,
        auth: true,
        exact : false,
        main: () => <NotFoundScreen />
    },
    {
        key: 'app.deliveryOrders.orderListDelivery',
        path: '/create-delivery-list',
        icon: 'fa fa-plus-square',
        name: 'menu.createDeliveryList',
        isMenu: true,
        parent: 'app.deliveryOrders',
        auth: true,
        exact: false,
        main: () => <DeliverOrderContainer />
    },
    {
        key: 'app.deliveryOrders.orderListDelivery.edit',
        path: '/edit-delivery-list/:id',
        icon: 'fa fa-plus-square',
        name: 'menu.editDeliveryList',
        isMenu: false,
        //parent: 'app.deliveryOrders',
        auth: true,
        exact: false,
        main: () => <EditDeliverOrderContainer />
    },
    {
        key: 'app.deliveryOrders.reviewOrderListDelivery',
        path: '/view-delivery-list-detail/:id',
        name: 'menu.viewDeliveryListDetail',
        isMenu: false,
        auth: true,
        exact: false,
        main: () => <ViewDeliveryListDetailContainer />
    },
    {
        key: 'app.deliveryOrders.list',
        path: '/view-delivery-list',
        icon: 'fa fa-list',
        name: 'menu.listDelivery',
        isMenu: true,
        parent: 'app.deliveryOrders',
        auth: true,
        exact: false,
        main: () => <DeliveryListContainer />
    },
    {
        key: 'app.packages.export.list',
        path: '/list-order-export',
        icon: 'fa fa-list',
        name: 'menu.listExport',
        isMenu: true,
        parent: 'app.packages.export',
        auth: true,
        exact: false,
        main: () => <ListOrderExportScreen />
    },
    {
        key: 'app.orders.history.orderCode',
        path: '/view-order-history/:orderCode',
        icon: 'fa fa-search-plus',
        name: 'menu.trackingOrder',
        isMenu: false,
        auth: true,
        exact : true,
        main: () => <ViewOrderHistoryContainer />
    },
    {
        key: 'app.orders.history',
        path: '/view-order-history',
        icon: 'fa fa-history',
        name: 'menu.trackingOrder',
        isMenu: true,
        auth: true,
        exact : false,
        main: () => <ViewOrderHistoryContainer />
    },
    {
        key: 'app.lost-order-list',
        path: '/lost-order-list',
        icon: 'fa fa-exclamation-triangle',
        name: 'menu.lostOrders',
        isMenu: true,
        auth: true,
        exact: true,
        main: () => <LostOrderListContainer />
    },
    {
        key: 'app.lost-order-list.details',
        path: '/view-lost-order-details/:orderId',
        icon: 'fa fa-exclamation-triangle',
        name: 'menu.lostOrders',
        isMenu: false,
        auth: true,
        exact: true,
        main: () => <ViewLostOrderDetailsContainer />
    },
    {
        key: 'app.notfound',
        path: '/page-not-found',
        auth: false,
        exact: false,
        main: () => <NotFoundScreen />
    },
    {
        key: 'app.notfound',
        path: '',
        auth: false,
        exact: false,
        main: () => <NotFoundScreen />
    }
];

export default routes;