import { connect } from 'react-redux';
import { ResetPassWordScreen } from './reset-password.sceen';
import { setAuth,setWorkspace } from '../../modules/session/session.actions';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

const mapStateToProps = (state) => ({
    auth:state.session?.auth,
    changedPassWord:state.session?.auth?.user?.changedPassWord=='True'?true:false,
    isAuthenticated: (state.session?.auth?.token && (state.session?.auth?.user?.role === 'STATION')) ? true : false,

})

const mapDispatchToProps = (dispatch) => {
    return {
        setAuth: (auth) => dispatch(setAuth(auth)),
        setWorkspace: (workspaceId) => dispatch(setWorkspace(workspaceId)),
    }
}

export default compose(
    withTranslation('translations'),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(ResetPassWordScreen)
