import { Button } from 'antd'
import React, { Component } from 'react'

export default class NextPage extends Component {
    render() {
        const { t } = this.props;
        const { hideBack, hideNext, hideFinish, textFinish, showCancel } = this.props
        return (
            <div >
                <div className='next-page pull-right'>
                    {!hideNext && <Button type='primary' onClick={() => this.props.handleNext()}>{t('button.next')}</Button>}
                </div>
                {
                    !hideFinish && <div className='next-page back pull-right'>
                        <Button type='primary' onClick={() => this.props.handleFinish()}>{textFinish || t('button.finish')}</Button>
                    </div>
                }
                {
                    !hideBack && <div className='next-page back pull-right'>
                        <Button type='primary' onClick={() => this.props.handleBack()}>{t('button.back')}</Button>
                    </div>
                }
                {
                    showCancel && <div className='next-page pull-right'>
                        <Button type='primary' onClick={() => this.props.handleCancel()}>{t('button.cancel')}</Button>
                    </div>
                }
            </div>
        )
    }
}
