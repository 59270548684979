
import React, { Component } from 'react'
import { Option } from 'antd/lib/mentions';
import { Select, Form, Button, Table, Checkbox } from 'antd';
import orderDataService from '../../../data-services/orders/orders-data.service';

const unitWeight = 'kg';
export default class SelectVehicleComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectVehicle: true,
            vehicles: props.vehicles ?? [],
            initialData: props.initialData ?? {
                vehicle: null,
                exportLists: [],
                route: null
            },
        }

        this.formRef = React.createRef();
    }

    render() {
        const { t } = this.props;
        const { vehicles, initialData } = this.state;
        const { vehicle, route, exportLists } = initialData;

        const columns = this.getTableColumns();
        const dataSource = this.getDataTable(exportLists);
        var routeName = route?.code && route?.routeName ? `${route?.code} (${route?.routeName})` : '';

        var totalExportList = 0;
        var totalOrders = 0;
        var totalProducts = 0;
        var totalItems = 0;
        var totalWeight = 0;

        if (exportLists) {
            totalExportList = exportLists.length;
            totalOrders = exportLists?.map(x => x.totalOrders).reduce((a, b) => a + b, 0);
            totalProducts = exportLists?.map(x => x.totalProducts).reduce((a, b) => a + b, 0);
            totalItems = exportLists?.map(x => x.totalItems).reduce((a, b) => a + b, 0);
            totalWeight = exportLists?.map(x => x.totalWeight).reduce((a, b) => a + b, 0);
        }

        return (
            <div>
                <Form ref={this.formRef}>
                    <div>
                        <div className='row ml-5'>
                            <div className='col-4'>
                                <div className='select-route'>
                                    <label>{t('title.selectVehicle')}</label>
                                    <Form.Item name={['vehicle', 'id']} rules={[{
                                        required: true,
                                        message: t('messages.pleaseSelectVehicle')
                                    }]}>
                                        <Select
                                            showSearch
                                            placeholder={t('placeholder.selectVehicle')}
                                            optionFilterProp='children'
                                            onChange={this.onChangeVehicle}
                                        >
                                            {
                                                vehicles.map(({ id, code, type }) => <Option value={id} key={id}>{`${code}, ${t(type)}`}</Option>)
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>

                            {
                                vehicle &&
                                <>
                                    <div className='col-4'>
                                        <label>{t('tableColumn.routeId')} - {t('tableColumn.routeName')}</label>
                                        <p>{routeName}</p>
                                    </div>

                                    <div className='col-4'>
                                        <label>Routine</label>
                                        <p>{route?.routine}</p>
                                    </div>
                                </>
                            }

                        </div>

                        {
                            vehicle &&
                            <div className='row'>
                                <div className='col-2 m-auto'>
                                    <p className='text-center'><b>{t('text.totalExportList')}</b></p>
                                    <p className='text-center'>{totalExportList}</p>
                                </div>

                                <div className='col-2 m-auto'>
                                    <p className='text-center'><b>{t('tableColumn.totalOrders')}</b></p>
                                    <p className='text-center'>{totalOrders}</p>
                                </div>

                                <div className='col-2 m-auto'>
                                    <p className='text-center'><b>{t('tableColumn.totalProducts')}</b></p>
                                    <p className='text-center'>{totalProducts}</p>
                                </div>

                                <div className='col-2 m-auto'>
                                    <p className='text-center'><b>{t('tableColumn.totalItems')}</b></p>
                                    <p className='text-center'>{totalItems}</p>
                                </div>

                                <div className='col-2 m-auto'>
                                    <p className='text-center'><b>{t('tableColumn.totalWeight')}</b></p>
                                    <p className='text-center'>{`${totalWeight} ${unitWeight}`}</p>
                                </div>

                            </div>
                        }

                    </div>
                </Form>

                {
                    vehicle &&
                    <Table
                        className='m-4'
                        columns={columns}
                        bordered
                        dataSource={dataSource}
                        onchange={this.onChange}
                    />
                }

            </div>
        );
    }

    getTableColumns() {
        const { t } = this.props;
        let columns = [
            {
                title: t('tableColumn.stationId'),
                dataIndex: 'stationCode',
                align: 'center',
            },
            {
                title: t('tableColumn.exportListId'),
                dataIndex: 'code',
                align: 'center',
            },
            {
                title: t('tableColumn.orders'),
                dataIndex: 'totalOrders',
                align: 'center',
            },
            {
                title: t('tableColumn.productsItems'),
                dataIndex: 'totalProductItem',
                align: 'center',
            },
            {
                title: t('tableColumn.totalWeight'),
                dataIndex: 'totalWeight',
                align: 'center',
                render: (value) => {
                    return <span>{value} {unitWeight}</span>
                }
            },
            {
                title: t('tableColumn.status'),
                dataIndex: 'status',
                align: 'center',
                render: (value, record) => {
                    var scanned = record.finished;
                    return <Checkbox checked={scanned} disabled />
                }
            },
            {
                title: t('tableColumn.action'),
                align: 'center',
                render: (value, record) => {
                    var exportListId = record.id;
                    var buttonText = record.finished ? t("button.modify") : t("button.import");

                    if (record.finished === true && this.state.selectVehicle === true) {
                        this.setState({ selectVehicle: false })
                    }

                    return <Button type='primary' onClick={() => this.importProcessing(exportListId)}>
                        {buttonText}
                    </Button>
                }
            },
        ];

        return columns;
    }

    getDataTable(exportLists) {
        return exportLists?.map((e) => {
            var isConfirmed = e.status;
            const result = {
                key: e.id,
                id: e.id,
                code: e.code,
                createdTime: e.createdTime,
                status: e.status,
                totalOrders: e.totalOrders,
                totalProducts: e.totalProducts,
                totalItems: e.totalItems,
                totalWeight: e.totalWeight,
                stationId: e.stationId,
                station: e.station,
                stationCode: e.station?.code,
                vehicleId: e.vehicleId,
                vehicle: e.vehicle,
                orders: e.orders,
                routeId: e.routeId,
                route: e.route,
                routine: e.routine,
                totalProductItem: `${e.totalProducts} - ${e.totalItems}`,
                finished: isConfirmed == true ? isConfirmed : (e.finished ?? false),
            }

            return result;
        }) ?? []
    }

    onChangeVehicle = (vehicleId) => {
        var { vehicles, initialData } = this.state;
        var vehicle = vehicles.find((v) => v.id === vehicleId);
        orderDataService.getExportListByVehicleId(vehicleId).then((res) => {

            var orderImportFromVehicleListId = null;
            if (res && res?.length > 0) {
                orderImportFromVehicleListId = res[0].orderImportFromVehicleListId;
            }

            this.setState({
                initialData: {
                    ...initialData,
                    vehicle: vehicle,
                    route: vehicle.route,
                    exportLists: res,
                    orderImportFromVehicleList: {
                        id: orderImportFromVehicleListId
                    }
                }
            }, () => {

                if (this.props.onChangeVehicle) {
                    this.props.onChangeVehicle(this.state.initialData);
                }
            });
        });
    }

    importProcessing = (exportListId) => {
        var { initialData } = this.state;
        var { exportLists } = initialData;
        var exportList = exportLists.find((e) => e.id === exportListId);

        if (this.props.reConfirmPage && exportList) {
            this.props.reConfirmPage(exportList)
        }
    }

    setFieldsValue = ({ vehicles, initialData }) => {
        this.setState({
            vehicles: vehicles,
            initialData: initialData
        }, () => {

            this.formRef.current.setFieldsValue(this.state.initialData);
        });
    }

    getFieldsValue = () => {
        return this.state.initialData;
    }

}